import "./PlanParamsTab.scss";
import React from "react";
import { CardStyled, DropdownSelector, StyledInput } from "components";
import { Checkbox } from "@mui/material";
import { defaultKnowledgeStrengthAlgOptions, defaultQuickTestOptions, defaultPlanOptions } from "constants/constants";

const PlanParamsTab = ({ unityUser, onUserChange }) => {
  const handlePlanOptionsParamChange = (paramName, value) => {
    if (paramName) {
      const newPlanOptions = { ...unityUser.planOptions, [paramName]: value };
      onUserChange({ ...unityUser, planOptions: newPlanOptions });
    }
  };

  const handleStrengthAlgParamChange = (paramName, value) => {
    if (paramName) {
      const newStrengthAlgOptions = { ...unityUser.knowledgeStrengthAlgOptions, [paramName]: value };
      onUserChange({ ...unityUser, knowledgeStrengthAlgOptions: newStrengthAlgOptions });
    }
  };

  const handleQuickTestParamChange = (paramName, value) => {
    if (paramName) {
      const newQuickTestOptions = { ...unityUser.quickTestOptions, [paramName]: value };
      onUserChange({ ...unityUser, quickTestOptions: newQuickTestOptions });
    }
  };

  const getClassNameForCheckbox = (paramName) => {
    return typeof unityUser.knowledgeStrengthAlgOptions?.[paramName] === "undefined" ? "usedDefaultValue" : "";
  };

  return (
    <CardStyled className="planOptions-tab-content">
      <div className="planOptions-tab-columns">
        <div className="planOptions-tab-column">
          <div className="header">Параметри плану</div>

          <div className="inputWithTitle">
            <div className="param-title">{"Одночасно груп"}</div>
            <StyledInput
              className="param-input"
              tooltipProps={{ title: "Одночасна кількість груп плану", placement: "bottom" }}
              type={"number"}
              value={unityUser.planOptions?.concurentPlanElementsCount}
              placeholder={defaultPlanOptions.concurentPlanElementsCount}
              onChange={(event) => handlePlanOptionsParamChange("concurentPlanElementsCount", event.target.value)}
            />
          </div>
          <div className="inputWithTitle">
            <div className="param-title">{"Одночасна кількість знань"}</div>
            <StyledInput
              className="param-input"
              tooltipProps={{ title: "Одночасна кількість знань в грі", placement: "bottom" }}
              type={"number"}
              value={unityUser.planOptions?.concurentCount}
              placeholder={defaultPlanOptions.concurentCount}
              onChange={(event) => handlePlanOptionsParamChange("concurentCount", event.target.value)}
            />
          </div>

          <div className="inputWithTitle">
            <div className="param-title">{"Етапи"}</div>
            <StyledInput
              className="param-input"
              tooltipProps={{ title: "Кількість етапів", placement: "bottom" }}
              type={"number"}
              value={unityUser.planOptions?.stagesCount}
              placeholder={defaultPlanOptions.stagesCount}
              onChange={(event) => handlePlanOptionsParamChange("stagesCount", event.target.value)}
            />
          </div>

          <div className="inputWithTitle">
            <div className="param-title">{"levelSuccessThreshold "}</div>
            <StyledInput
              className="param-input"
              tooltipProps={{
                title: "скільки необхідно завершити рівня щоб перейти до наступного",
                placement: "bottom",
              }}
              type={"number"}
              value={unityUser.planOptions?.levelSuccessThreshold}
              placeholder={defaultPlanOptions.levelSuccessThreshold}
              onChange={(event) => handlePlanOptionsParamChange("levelSuccessThreshold", event.target.value)}
            />
          </div>
        </div>
        <div className="planOptions-tab-column knowledgeStrengthAlgOptions">
          <div className="header">Параметри оцінки рівня знання</div>

          <div
            className={`checkbox-with-label useForgetRateCheckbox-block ${getClassNameForCheckbox("useForgetRate")}`}
          >
            <Checkbox
              disabled={false}
              checked={unityUser.knowledgeStrengthAlgOptions?.useForgetRate}
              defaultChecked={defaultKnowledgeStrengthAlgOptions.useForgetRate}
              onChange={(event) => handleStrengthAlgParamChange("useForgetRate", event.target.checked)}
              className="checkbox"
            />
            Забування
          </div>
          <div
            className={`checkbox-with-label useForgetRateCheckbox-block ${getClassNameForCheckbox(
              "useForgetRateForMinorKnowledge"
            )}`}
          >
            <Checkbox
              disabled={false}
              checked={unityUser.knowledgeStrengthAlgOptions?.useForgetRateForMinorKnowledge}
              defaultChecked={defaultKnowledgeStrengthAlgOptions.useForgetRateForMinorKnowledge}
              onChange={(event) => handleStrengthAlgParamChange("useForgetRateForMinorKnowledge", event.target.checked)}
              className="checkbox "
            />
            Забування для допоміжних знань
          </div>

          <div className="inputWithTitle">
            <div className="param-title">{"learnThreshold"}</div>
            <StyledInput
              className="param-input"
              tooltipProps={{ title: "learnThreshold", placement: "bottom" }}
              type={"number"}
              value={unityUser.knowledgeStrengthAlgOptions?.learnThreshold}
              placeholder={defaultKnowledgeStrengthAlgOptions.learnThreshold}
              onChange={(event) => handleStrengthAlgParamChange("learnThreshold", Number(event.target.value) || 0)}
            />
          </div>

          <div className="inputWithTitle">
            <div className="param-title">{"knowThreshold"}</div>
            <StyledInput
              className="param-input"
              tooltipProps={{ title: "knowThreshold", placement: "bottom" }}
              type={"number"}
              value={unityUser.knowledgeStrengthAlgOptions?.knowThreshold}
              placeholder={defaultKnowledgeStrengthAlgOptions.knowThreshold}
              onChange={(event) => handleStrengthAlgParamChange("knowThreshold", Number(event.target.value) || 0)}
            />
          </div>

          <div className="inputWithTitle">
            <div className="param-title">{"knowThresholdForMinorKnowledge"}</div>
            <StyledInput
              className="param-input"
              tooltipProps={{ title: "knowThresholdForMinorKnowledge", placement: "bottom" }}
              type={"number"}
              value={unityUser.knowledgeStrengthAlgOptions?.knowThresholdForMinorKnowledge}
              placeholder={defaultKnowledgeStrengthAlgOptions.knowThresholdForMinorKnowledge}
              onChange={(event) =>
                handleStrengthAlgParamChange("knowThresholdForMinorKnowledge", Number(event.target.value) || 0)
              }
            />
          </div>

          <div className="inputWithTitle">
            <div className="param-title">{"startKnowledgeStrength"}</div>
            <StyledInput
              className="param-input"
              tooltipProps={{ title: "startKnowledgeStrength", placement: "bottom" }}
              type={"number"}
              value={unityUser.knowledgeStrengthAlgOptions?.startKnowledgeStrength}
              placeholder={defaultKnowledgeStrengthAlgOptions.startKnowledgeStrength}
              onChange={(event) =>
                handleStrengthAlgParamChange("startKnowledgeStrength", Number(event.target.value) || 0)
              }
            />
          </div>

          <div className="inputWithTitle">
            <div className="param-title">{"questionStep"}</div>
            <StyledInput
              className="param-input"
              tooltipProps={{ title: "questionStep", placement: "bottom" }}
              type={"number"}
              value={unityUser.knowledgeStrengthAlgOptions?.questionStep}
              placeholder={defaultKnowledgeStrengthAlgOptions.questionStep}
              onChange={(event) => handleStrengthAlgParamChange("questionStep", Number(event.target.value) || 0)}
            />
          </div>

          <div className="inputWithTitle">
            <div className="param-title">{"startForgetRate"}</div>
            <StyledInput
              className="param-input"
              tooltipProps={{ title: "startForgetRate", placement: "bottom" }}
              type={"number"}
              value={unityUser.knowledgeStrengthAlgOptions?.startForgetRate}
              placeholder={defaultKnowledgeStrengthAlgOptions.startForgetRate}
              onChange={(event) => handleStrengthAlgParamChange("startForgetRate", Number(event.target.value) || 0)}
            />
          </div>

          <div className="inputWithTitle">
            <div className="param-title">{"forgetRateChangeValueCorrect"}</div>
            <StyledInput
              className="param-input"
              tooltipProps={{ title: "forgetRateChangeValueCorrect", placement: "bottom" }}
              type={"number"}
              value={unityUser.knowledgeStrengthAlgOptions?.forgetRateChangeValueCorrect}
              placeholder={defaultKnowledgeStrengthAlgOptions.forgetRateChangeValueCorrect}
              onChange={(event) =>
                handleStrengthAlgParamChange("forgetRateChangeValueCorrect", Number(event.target.value) || 0)
              }
            />
          </div>

          <div className="inputWithTitle">
            <div className="param-title">{"forgetRateChangeValueIncorrect"}</div>
            <StyledInput
              className="param-input"
              tooltipProps={{ title: "forgetRateChangeValueIncorrect", placement: "bottom" }}
              type={"number"}
              value={unityUser.knowledgeStrengthAlgOptions?.forgetRateChangeValueIncorrect}
              placeholder={defaultKnowledgeStrengthAlgOptions.forgetRateChangeValueIncorrect}
              onChange={(event) =>
                handleStrengthAlgParamChange("forgetRateChangeValueIncorrect", Number(event.target.value) || 0)
              }
            />
          </div>

          <div className="inputWithTitle">
            <div className="param-title">{"strengthAcceptableDropLevel"}</div>
            <StyledInput
              className="param-input"
              tooltipProps={{ title: "strengthAcceptableDropLevel", placement: "bottom" }}
              type={"number"}
              value={unityUser.knowledgeStrengthAlgOptions?.strengthAcceptableDropLevel}
              placeholder={defaultKnowledgeStrengthAlgOptions.strengthAcceptableDropLevel}
              onChange={(event) =>
                handleStrengthAlgParamChange("strengthAcceptableDropLevel", Number(event.target.value) || 0)
              }
            />
          </div>
        </div>
        <div className="planOptions-tab-column">
          <div className="header">Параметри режиму тестування</div>
          <div className="checkbox-with-label useForgetRateCheckbox-block">
            <Checkbox
              disabled={false}
              checked={unityUser.quickTest}
              onChange={() => {
                onUserChange({ ...unityUser, quickTest: !unityUser.quickTest });
              }}
              className=""
            />
            Режим тестування
          </div>
          <div className="inputWithTitle">
            <div className="param-title">{"correctStrength"}</div>
            <StyledInput
              className="param-input"
              tooltipProps={{ title: "correctStrength", placement: "bottom" }}
              type={"number"}
              value={unityUser.quickTestOptions?.correctStrength}
              placeholder={defaultQuickTestOptions.correctStrength}
              onChange={(event) => handleQuickTestParamChange("correctStrength", Number(event.target.value) || 0)}
            />
          </div>
          <div className="inputWithTitle">
            <div className="param-title">{"incorrectStrength"}</div>
            <StyledInput
              className="param-input"
              tooltipProps={{ title: "incorrectStrength", placement: "bottom" }}
              type={"number"}
              value={unityUser.quickTestOptions?.incorrectStrength}
              placeholder={defaultQuickTestOptions.incorrectStrength}
              onChange={(event) => handleQuickTestParamChange("incorrectStrength", Number(event.target.value) || 0)}
            />
          </div>
          <div className="inputWithTitle">
            <div className="param-title">{"correctForgetRate"}</div>
            <StyledInput
              className="param-input"
              tooltipProps={{ title: "correctForgetRate", placement: "bottom" }}
              type={"number"}
              value={unityUser.quickTestOptions?.correctForgetRate}
              placeholder={defaultQuickTestOptions.correctForgetRate}
              onChange={(event) => handleQuickTestParamChange("correctForgetRate", Number(event.target.value) || 0)}
            />
          </div>
          <div className="inputWithTitle">
            <div className="param-title">{"incorrectForgetRate"}</div>
            <StyledInput
              className="param-input"
              tooltipProps={{ title: "incorrectForgetRate", placement: "bottom" }}
              type={"number"}
              value={unityUser.quickTestOptions?.incorrectForgetRate}
              placeholder={defaultQuickTestOptions.incorrectForgetRate}
              onChange={(event) => handleQuickTestParamChange("incorrectForgetRate", Number(event.target.value) || 0)}
            />
          </div>
          <div className="inputWithTitle">
            <div className="param-title">{"stagesCount"}</div>
            <StyledInput
              className="param-input"
              tooltipProps={{ title: "stagesCount", placement: "bottom" }}
              type={"number"}
              value={unityUser.quickTestOptions?.stagesCount}
              placeholder={defaultQuickTestOptions.stagesCount}
              onChange={(event) => handleQuickTestParamChange("stagesCount", Number(event.target.value) || 5)}
            />
          </div>
          <div className="checkbox-with-label useForgetRateCheckbox-block">
            <Checkbox
              disabled={false}
              checked={unityUser.quickTestOptions?.unlimitedStagesCount}
              onChange={(event) => handleQuickTestParamChange("unlimitedStagesCount", event.target.checked)}
              className=""
            />
            unlimitedStagesCount
          </div>
          <div className="inputWithTitle">
            <div className="param-title">{"testSuccessThresholdForGroup"}</div>
            <StyledInput
              className="param-input"
              tooltipProps={{ title: "stagesCount", placement: "bottom" }}
              type={"number"}
              value={unityUser.quickTestOptions?.testSuccessThresholdForGroup}
              placeholder={defaultQuickTestOptions.testSuccessThresholdForGroup}
              onChange={(event) =>
                handleQuickTestParamChange("testSuccessThresholdForGroup", Number(event.target.value) || 5)
              }
            />
          </div>
        </div>
        <div className="planOptions-tab-column"></div>
      </div>
    </CardStyled>
  );
};

export default PlanParamsTab;
