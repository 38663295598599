import React, { useEffect, useState } from "react";
import "./Tags.scss";

const Tags = ({ tagList, selectedTags, onTagClick }) => {
  const tagSelected = (tagId) => selectedTags.includes(tagId);
  const [sortedTagList, setSortedTagList] = useState(null);
  useEffect(() => {
    tagList &&
      setSortedTagList(
        tagList.sort(function (a, b) {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        })
      );
  }, [tagList]);

  return (
    <div className="tags">
      {sortedTagList &&
        sortedTagList.map((tag, index) => (
          <div key={tag._id} className={tagSelected(tag._id) ? "tag selected" : "tag"} onClick={() => onTagClick(tag)}>
            {"count" in tag ? `#${tag.name} (${tag.count})` : `#${tag.name}`}
          </div>
        ))}
    </div>
  );
};

export default Tags;
