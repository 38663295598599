import "./MainScreenUniversal.scss";
import React, { useEffect, useRef, useState } from "react";
import { useResourcesContext } from "resources";

import ImageExitButtonBase from "assets/images/exit_button_base.png";
import ImageExitButton from "assets/images/exit_button.png";
import { getItemsWithChildrenPlain } from "helpers/stageItems";
import ItemUniversalOld from "components/ItemUniversal/itemUniversalOld";

const ExitButton = ({ scale }) => {
  return (
    <div
      className="exit-button-wrapper"
      style={{
        transform: `scale(${1 / scale})`,
        top: `${8 + 52 / scale}px`,
        left: `${9 + 40 / scale}px`,
      }}
    >
      <img src={ImageExitButtonBase} className="exit-button-base" alt="" />
      <img src={ImageExitButton} className="exit-button" alt="" />
    </div>
  );
};

const MainScreenUniversal = ({
  locked,
  stageItemsPlain,
  activeItem,
  onPressDelete,
  onPressCopy = () => {},
  onPressPaste = () => {},
  onSorterClick,
  onChange,
}) => {
  const [backgroundSize, setBackgroundSize] = useState(null);
  const backgroundRef = useRef();
  const mousePrevPosition = useRef({});
  const movingItem = useRef(null);
  const resizeObserverRef = useRef();

  const {
    imageListStore: { getImagePathById },
  } = useResourcesContext();

  useEffect(() => {
    return () => {
      resizeObserverRef.current && resizeObserverRef.current.disconnect();
    };
  }, []);

  const updateBackgroundSize = () => {
    backgroundRef.current &&
      setBackgroundSize({
        clientHeight: backgroundRef.current.clientHeight,
        clientWidth: backgroundRef.current.clientWidth,
        scale1920: 1920 / backgroundRef.current.clientWidth,
      });
  };

  const handleOnBackgroundLoad = () => {
    resizeObserverRef.current = new ResizeObserver(updateBackgroundSize).observe(backgroundRef.current);
  };

  const handleBackgroundMouseMove = (mouseEvent) => {
    if (movingItem.current) {
      handleItemMove(mouseEvent);
    } else {
      mousePrevPosition.current.x = mouseEvent.clientX;
      mousePrevPosition.current.y = mouseEvent.clientY;
    }
  };

  const handleMainScreenKeyUp = (event) => {
    (event.key === "Delete" || event.key === "Backspace") && onPressDelete();

    const copyKeys = ["c", "C", "с", "С"];
    const pasteKeys = ["v", "V", "м", "М"];
    (((event.ctrlKey || event.metaKey) && copyKeys.includes(event.key)) || (event.key === "Insert" && event.ctrlKey)) &&
      onPressCopy();
    (((event.ctrlKey || event.metaKey) && pasteKeys.includes(event.key)) ||
      (event.key === "Insert" && event.shiftKey)) &&
      onPressPaste();
  };

  const handleItemMove = (mouseEvent, item) => {
    if (mouseEvent.buttons === 1) {
      if (item && !movingItem.current) movingItem.current = item;
      getItemsWithChildrenPlain([movingItem.current]).forEach((item) => {
        item.valueX = Math.round(
          item.valueX + (mouseEvent.clientX - mousePrevPosition.current.x) * backgroundSize.scale1920
        );
        item.valueY = Math.round(
          item.valueY - (mouseEvent.clientY - mousePrevPosition.current.y) * backgroundSize.scale1920
        );
      });

      onChange({ passCtrlzBuffer: true });
    }
    mousePrevPosition.current.x = mouseEvent.clientX;
    mousePrevPosition.current.y = mouseEvent.clientY;
  };

  const handleItemPointerLeave = (event) => {
    !event.relatedTarget.id === "stageMainScreen" &&
      !stageItemsPlain.some((item) => event.relatedTarget.id && event.relatedTarget.id.includes(item.stageItemId)) &&
      handleItemMouseClick(movingItem.current);
  };

  const handleItemMouseClick = (item) => {
    onSorterClick(item);
    if (movingItem.current) {
      movingItem.current = null;
      onChange();
    }
  };

  const handleItemMouseDown = (item) => {
    onSorterClick(item);
  };

  const isActiveItem = (item) => item === activeItem;
  const isPassiveItem = (item) => false;

  const handleMouseWheel = (event, item) => {
    if (event.shiftKey && event.deltaY) {
      if (!item.angle) item.angle = 0;
      item.angle = Math.floor(item.angle + event.deltaY / 15);

      if (item.angle > 360) item.angle = item.angle - 360;
      if (item.angle < 0) item.angle = item.angle + 360;

      onChange();
    }
  };

  const findParentAndItem = (rootItem, stageItemId) => {
    const result = (rootItem.items || [])
      .map((item, index) => {
        return (
          (item.stageItemId === stageItemId && { parent: rootItem, item, index }) ||
          findParentAndItem(item, stageItemId)
        );
      })
      .filter(Boolean);

    return (result.length && result[0]) || null;
  };

  return (
    <>
      <div
        id="stageMainScreen"
        className="main-screen"
        onMouseMoveCapture={(e) => e.preventDefault()}
        onKeyUp={handleMainScreenKeyUp}
        tabIndex="-1"
        onMouseMove={handleBackgroundMouseMove}
      >
        <img
          ref={backgroundRef}
          //id={`background ${stageItemsPlain?.length > 0 ? stageItemsPlain[0].stageItemId : ""}`}
          id={`stageMainScreenBackgroundImage`}
          className="main-screen-bg"
          src={stageItemsPlain?.length > 0 ? getImagePathById(stageItemsPlain[0]._id) : ""}
          alt="Empty background"
          onLoad={handleOnBackgroundLoad}
          onMouseMoveCapture={(e) => e.preventDefault()}
        />

        {stageItemsPlain?.length > 0 &&
          backgroundSize &&
          stageItemsPlain.map((item, index) =>
            index === 0 ? null : (
              <ItemUniversalOld
                key={"board item " + item.stageItemId}
                imageClassName={isActiveItem(item) ? "img-active" : isPassiveItem(item) ? "img-passive" : ""}
                item={item}
                backgroundSize={backgroundSize}
                onMouseWheel={(event) => handleMouseWheel(event, item)}
                onMouseDown={() => handleItemMouseDown(item)}
                onMouseMove={(event) => handleItemMove(event, item)}
                onPointerLeave={handleItemPointerLeave}
                onClick={handleItemMouseClick}
                freeze={locked}
                moving={
                  item === movingItem.current ||
                  (movingItem.current && findParentAndItem(movingItem.current, item.stageItemId))
                }
              />
            )
          )}
      </div>
      {backgroundSize && <ExitButton scale={backgroundSize.scale1920} />}
    </>
  );
};
export default MainScreenUniversal;
