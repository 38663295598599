import "./ForgotPassword.scss";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { CardStyled, StyledButton } from "components";
import axios from "axios";

export default function ForgotPassword() {
  const [emailValid, setEmailValid] = useState(false);
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [isRegistered, setIsRegistered] = useState();

  useEffect(() => {
    if (!email) {
      setMessage();
      return;
    }

    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      setEmailValid(true);
      setMessage();
    } else {
      setMessage("Некоректний email");
      setEmailValid(false);
    }
  }, [email]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    axios
      .post("api/auth/resetPasswordRequest", { email: email })
      .then(() => {
        setIsRegistered(true);
        setMessage("Вітаємо! Запит відправлено на Ваш email!");
      })
      .catch((error) => {
        setIsRegistered(false);

        if (error.response.status === 404 || error.response.status === 500) {
          setMessage("Помилка при відправці запиту");
          return;
        }

        setMessage("Спробуйте пізніше");
      });
  };

  return (
    <div className="forgotPassword-page-wrapper">
      <div className="forgotPassword-wrapper">
        <div className="forgotPassword-title">Запит на скидання паролю</div>
        <CardStyled className="button-box">
          {<div className={isRegistered ? "ok-message" : "error-message"}>{message}</div>}
          <form className="forgotPassword-form">
            <label>
              <p className="forgotPassword-lable">Відправити запит на email</p>
              <input
                className="input"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
                required
              />
            </label>

            <StyledButton
              disabled={!emailValid || !email}
              title="Відправити"
              className="customize-button"
              type="submit"
              onClick={handleSubmit}
            />
          </form>
          <div className="link-box">
            <Link className="login-link" to="/login">
              Увійти
            </Link>
          </div>
        </CardStyled>
      </div>
    </div>
  );
}
