import "./KnowledgePairs.scss";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Suspense } from "react";
const KnowledgeTagList = React.lazy(() => import("components/KnowledgeTagList/KnowledgeTagList"));
import { LazyLoadComponent } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { ReactComponent as DeleteIcon } from "assets/icons/RemoveTag.svg";
import Tooltip from "@mui/material/Tooltip";
import _ from "lodash";

function KnowledgePairs({ soundSets = [], selectedSoundSet, soundSetsUsedInBundles, onClick, onDelete }) {
  const [filteredSoundSets, setFilteredSoundSets] = useState([]);
  const searchInputRef = useRef(null);

  const canBeDeleted = (soundSet) => {
    return !soundSetsUsedInBundles.includes(soundSet._id);
  };

  const sortedSoundSets = useMemo(() => {
    const getAlarmData = (soundSet) => {
      const alarmMessages = [];
      soundSet.noSounds && alarmMessages.push("Відсутні звуки");
      soundSet.noQustionSounds && alarmMessages.push("Відсутні звуки питань");
      soundSet.noKnowledgeTags && alarmMessages.push("Відсутні теги");
      soundSet.unlinked && alarmMessages.push("Не залінкован");

      const className = alarmMessages.length ? "alarm-color" : "";
      return { alarmMessages, className };
    };

    const soundSetHasProblems = (soundSet) => {
      if (soundSet.noKnowledgeTags) return true;
      if (soundSet.noQustionSounds) return true;
      if (soundSet.noSounds) return true;
      if (soundSet.unlinked) return true;

      return false;
    };

    const unlinkedSoundSetsIds = soundSets
      .filter((soundSet) => !soundSetsUsedInBundles.includes(soundSet._id))
      .map((soundSet) => soundSet._id);

    const soundSetsExtra = soundSets
      .map((soundSet) => {
        const unlinked = unlinkedSoundSetsIds.includes(soundSet._id);

        return {
          ...soundSet,
          noSounds: !soundSet.sounds.length,
          noQustionSounds: !soundSet.questionSounds.length,
          noKnowledgeTags: !soundSet.knowledgeTags.length,
          unlinked: unlinked,
        };
      })
      .map((soundSetExtra) => {
        return { ...soundSetExtra, ...getAlarmData(soundSetExtra) };
      });

    return soundSetsExtra.sort((a, b) => {
      const aSoundSetHasProblems = soundSetHasProblems(a);
      const bSoundSetHasProblems = soundSetHasProblems(b);

      if (aSoundSetHasProblems && bSoundSetHasProblems) {
        return 0;
      } else if (aSoundSetHasProblems) {
        return -1;
      } else if (bSoundSetHasProblems) {
        return 1;
      } else {
        const aSoundSetName = (a.ukSoundName || a.ruSoundName || a.enSoundName).toLowerCase();
        const bSoundSetName = (b.ukSoundName || b.ruSoundName || b.enSoundName).toLowerCase();
        return aSoundSetName.localeCompare(bSoundSetName);
      }
    });
  }, [soundSets, soundSetsUsedInBundles]);

  const filterSoundSets = useCallback((soundSets, searchValue) => {
    const filteredSoundSets = soundSets.filter(
      (soundSet) =>
        (!soundSet.knowledgeTags.length && !searchValue) ||
        soundSet.knowledgeTags.some((tag) => tag.name.toLowerCase().includes(searchValue.toLowerCase()))
    );
    setFilteredSoundSets(filteredSoundSets);
  }, []);

  const filterSoundSetsDebounced = useCallback(_.debounce(filterSoundSets, 300), []);

  useEffect(() => {
    // if (searchInputRef.current) {
    //   searchInputRef.current.value = "";
    // }
    filterSoundSets(sortedSoundSets, searchInputRef.current?.value || "");
  }, [sortedSoundSets, filterSoundSets]);

  return (
    <div className="knowledgePairs-component">
      <div className="title"></div>
      <div className="knowledgePairs-filter-box">
        <input
          ref={searchInputRef}
          className={`searchValue-input`}
          placeholder={"Пошук по тегам"}
          onChange={(event) => filterSoundSetsDebounced(sortedSoundSets, event.target.value)}
        />
      </div>
      <div className="knowledgePairs-list">
        {filteredSoundSets.map((soundSet) => (
          <div
            className={`knowledgePair-card ${soundSet._id === selectedSoundSet?._id ? "active" : ""} ${
              soundSet.className
            }`}
            key={soundSet._id}
            onClick={(event) => {
              onClick && onClick(soundSet);
              event.stopPropagation();
            }}
          >
            {canBeDeleted(soundSet) && (
              <Tooltip title="Видалити набір звуків" followCursor={true}>
                <div className="delete-button-wrapper" onClick={() => onDelete && onDelete(soundSet?._id)}>
                  <DeleteIcon className="delete-button" />
                </div>
              </Tooltip>
            )}
            {soundSet.noKnowledgeTags ? (
              <div className="noTags-label">Без тегів</div>
            ) : (
              <>
                <Suspense fallback={<div>Loading...</div>}>
                  <LazyLoadComponent delayTime={500}>
                    <KnowledgeTagList knowledgeTags={soundSet.knowledgeTags} sort={false} />
                  </LazyLoadComponent>
                </Suspense>

                {soundSet.alarmMessages && (
                  <div className="alarmMessages">
                    {soundSet.alarmMessages.map((message, index) => (
                      <div key={index}>{message}</div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default KnowledgePairs;
