import "./CommonTab.scss";
import axios from "axios";
import { Checkbox, Tooltip } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import { languages } from "constants/constants";
import { ROLE_ADMIN, ROLE_SUPERADMIN } from "constants/constants";

import { useAuthz } from "auth/AuthzProvider";
import calculateAge from "helpers/calculateAge";

import { openAlertDialog } from "components/AlertDialog/AlertDialog";
import { CardStyled, DropdownSelector, InputWithPopup, StyledButton, StyledInput } from "components";

const CommonTab = ({ toast, unityUser, unityIdBlocked, onUserChange, onDeleteUser, onCopyUser, onClearStatistic }) => {
  const { user } = useAuthz();
  const [unityId, setUnityId] = useState("");
  const [telegramBotClientId, setTelegramBotClientId] = useState("");
  const [language, setLanguage] = useState("");
  const [webUsersAdmins, setWebUsersAdmins] = useState([]);

  const isAdmin = user.roles?.includes(ROLE_ADMIN);
  const isSuperAdmin = user.roles?.includes(ROLE_SUPERADMIN);

  // we have year of birth, and month of birth, so we can calculate age and display it in format "X years Y months"

  const age = useMemo(() => {
    if (!unityUser) {
      return "";
    }

    return calculateAge(unityUser.birthdayYear, unityUser.birthdayMonth);
  }, [unityUser]);

  useEffect(() => {
    if (unityUser) {
      setUnityId(unityUser.unityId);
      setLanguage(unityUser.language);
      setTelegramBotClientId(unityUser.telegramBotClientId);
    }
  }, [unityUser]);

  useEffect(() => {
    if (isAdmin || isSuperAdmin) {
      axios
        .get(`/api/webUsers?roles=${ROLE_ADMIN},${ROLE_SUPERADMIN}`)
        .then((res) => setWebUsersAdmins(res.data))
        .catch();
    }
  }, [isAdmin, isSuperAdmin]);

  const checkUnityId = async (unityId) => {
    if (!unityId) {
      return true;
    }

    const unityIdExists = await axios.get(`/api/unityUsers/byUnityId/${unityId}`);
    unityIdExists && toast.error("UnityId вже існує", { toastId: "1" });
    return !unityIdExists;
  };

  const handleWebUserChange = (event, newValue) => {
    if (newValue) {
      openAlertDialog({
        onOk: async () => {
          onUserChange({ ...unityUser, webUserId: newValue._id, webUserEmail: newValue.email });
        },
        onCancel: () => {},
        title: `Зміна підключеного Веб юзера на ${newValue.webUserEmail}`,
        message: "Натиснить Ok для зміни, або Cancel для відміни операції.",
      });
    } else {
      openAlertDialog({
        onOk: async () => {
          onUserChange({ ...unityUser, webUserId: "", webUserEmail: "" });
        },
        onCancel: () => {},
        title: `Від'єднання Веб юзера`,
        message: "Натиснить Ok для від'єднання, або Cancel для відміни операції.",
      });
    }
  };

  const onOpenSendPulse = () => {
    unityUser.telegramBotClientId &&
      window.open(
        `https://login.sendpulse.com/messengers/chats/6614fc966997603056065538/contacts/all/${unityUser.telegramBotClientId}`,
        "_blank"
      );
  };

  return (
    <CardStyled className="common-tab-content">
      <div className="left-block">
        <div className="email-block">{`Залінкований веб юзер:  ${unityUser.webUserEmail}`}</div>
        <div className="age-block">{`Вік дитини:  ${age}`}</div>
        <div className="demoCheckbox-block">
          <Checkbox
            disabled={false}
            checked={unityUser.demo}
            onChange={(event) => {
              onUserChange({ ...unityUser, demo: !unityUser.demo });
            }}
            className=""
          />
          Демо
        </div>

        <div className="userLanguageSelector-block">
          <div className="label">Мова озвучки:</div>
          <DropdownSelector
            className="userLanguageSelector"
            disabled={!unityUser}
            withNone={false}
            options={languages}
            selectedOption={
              languages.find((lang) => {
                return lang.name === language;
              }) || ""
            }
            onChange={(value) => {
              const newValue = value?.name || "";
              unityUser.language = newValue;
              setLanguage(newValue);
              onUserChange({ ...unityUser });
            }}
          />
        </div>

        <div className="unity-id-block">
          <div className="label">Unity ID:</div>
          <StyledInput
            disabled={unityIdBlocked}
            className="input"
            placeholder={"Введіть unity id"}
            value={unityId}
            onKeyPress={async (e) =>
              e.key === "Enter" &&
              unityUser.unityId !== unityId &&
              (await checkUnityId(unityId)) &&
              onUserChange({ ...unityUser, unityId: unityId })
            }
            onBlur={async () => {
              unityUser.unityId !== unityId &&
                (await checkUnityId(unityId)) &&
                onUserChange({ ...unityUser, unityId: unityId });
            }}
            onChange={(event) => {
              setUnityId(event.target.value);
            }}
          />
          {(isAdmin || isSuperAdmin) && (
            <Tooltip title="Приєднаний вебюзер">
              <InputWithPopup
                className="webUserSelector"
                placeholder="Підключити вебюзера"
                options={webUsersAdmins}
                value={webUsersAdmins.find((elem) => unityUser.webUserId && elem._id === unityUser.webUserId)}
                optionLabelField="email"
                onChange={handleWebUserChange}
              />
            </Tooltip>
          )}
        </div>

        {(isAdmin || isSuperAdmin) && (
          <>
            <div className="telegramBotClientId-block">
              <div className="label">SendPulse contact_id:</div>
              <StyledInput
                className="input"
                value={telegramBotClientId}
                onKeyPress={async (e) =>
                  e.key === "Enter" &&
                  unityUser.telegramBotClientId !== telegramBotClientId &&
                  onUserChange({ ...unityUser, telegramBotClientId: telegramBotClientId })
                }
                onBlur={async () => {
                  unityUser.telegramBotClientId !== telegramBotClientId &&
                    onUserChange({ ...unityUser, telegramBotClientId: telegramBotClientId });
                }}
                onChange={(event) => {
                  setTelegramBotClientId(event.target.value);
                }}
              />
            </div>
            <StyledButton
              disabled={!telegramBotClientId}
              className="openSendPulse-button"
              title="Відкрити SendPulse"
              onClick={onOpenSendPulse}
            />
          </>
        )}
      </div>
      {(isAdmin || isSuperAdmin) && (
        <div className="right-block">
          <div className="right-block-top">
            <StyledButton className="clearStatistic-button" title="Очистити статистику" onClick={onClearStatistic} />
          </div>
          <div className="right-block-bottom">
            <StyledButton className="delete-button" title="Видалити" onClick={onDeleteUser} />
            <StyledButton className="delete-button" title="Скопіювати" onClick={onCopyUser} />
          </div>
        </div>
      )}
    </CardStyled>
  );
};

export default CommonTab;
