import "./GameCard.scss";
import React from "react";
import { StyledButton, CardStyled, ErrorsIcon, GameIcon } from "components";
import Tooltip from "@mui/material/Tooltip";
import { ReactComponent as IconSave } from "assets/icons/save.svg";
import { ReactComponent as IconCancel } from "assets/icons/cancel.svg";
import { ReactComponent as IconLock } from "assets/icons/lockedGame.svg";
import { TooltipTitleList } from "components";
import { useNavigate } from "react-router-dom";
import { useResourcesContext } from "resources";

const GameCard = ({
  game,
  gameExtraData,
  selected,
  onClick,
  onSave,
  onUndo,
  locked,
  edited,
  isNew,
  showIconProgress,
  showFetchingGameProgress,
}) => {
  const navigate = useNavigate();

  const {
    imageListStore: { getImagePathById },
  } = useResourcesContext();

  return (
    <div>
      <CardStyled className={`gameCard-wrapper ${selected ? "active-gameCard-wrapper" : ""}`}>
        <div className={`gameCard-content `} onClick={() => onClick && onClick(game)}>
          <div className={`game-icon-wrapper ${locked && "locked-game-icon"}`}>
            <GameIcon
              gameIcon={typeof game.icon === "string" ? getImagePathById(game.icon) : game.icon}
              showProgress={showIconProgress}
            />
          </div>
          <Tooltip title={<p className="tooltip">{game.name}</p>} placement="bottom" arrow>
            <div className="game-name">{game.name}</div>
          </Tooltip>
        </div>

        {locked && (
          <Tooltip
            arrow
            className="disabled-delete-tooltip"
            title={
              <TooltipTitleList
                title="Locked. User statistics:"
                list={gameExtraData?.tracking?.trackingUsers}
                onClick={(element) => navigate.push("/statistics/", { state: { useId: element.id } })}
              />
            }
          >
            <div className="save-btn">
              <StyledButton className="save-btn-content">
                <IconLock className="button-icon" />
              </StyledButton>
            </div>
          </Tooltip>
        )}

        {onSave && (
          <Tooltip title={edited ? "СОХРАНИТЬ ИГРУ" : ""} placement="right" arrow>
            <div className="save-btn">
              <StyledButton
                className="save-btn-content"
                onClick={() => onSave && onSave(game)}
                disabled={locked || !edited || showFetchingGameProgress}
                circularProgressSize={showFetchingGameProgress && 25}
              >
                <IconSave className="button-icon" />
              </StyledButton>
            </div>
          </Tooltip>
        )}

        {onUndo && (
          <Tooltip title={edited ? "ОТМЕНИТЬ ИЗМЕНЕНИЯ" : ""} placement="right" arrow>
            <div className="cancel-btn">
              <StyledButton
                className="save-btn-content"
                onClick={() => onUndo && onUndo(game)}
                disabled={locked || isNew || !edited}
              >
                <IconCancel className="button-icon" />
              </StyledButton>
            </div>
          </Tooltip>
        )}

        {gameExtraData?.validation && !gameExtraData.validation.valid && gameExtraData.validation.errors && (
          <Tooltip title="ОШИБКИ" placement="left" arrow>
            <div className="errors-icon">
              <ErrorsIcon className="button-icon" />
            </div>
          </Tooltip>
        )}
      </CardStyled>
    </div>
  );
};

export default GameCard;
