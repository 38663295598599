import "./Trial.scss";
import React from "react";
import { useI18N } from "i18n";
import trialPageHeaderImage from "assets/trialPageHeader.png";
import { ReactComponent as TelegramIcon } from "assets/landing/socials/telegram2.svg";
import { ReactComponent as FirstStepArrow } from "assets/icons/arrow2.svg";
import tryNowBackground from "assets/landing/trial/tryNowBackground.png";
import tryNowBackgroundSmall from "assets/landing/trial/tryNowBackgroundSmall.png";
import { useAuthz } from "auth/AuthzProvider";
import Layout from "pages/Landing/Layout/Layout";
import { getDigitalOceanUrl } from "helpers/getDigitalOceanUrl";

const BULBEE_TRIAL_VIDEO_URL = getDigitalOceanUrl(window.location.host) + "/video/howitworks.mp4";
const BULBEE_TRIAL_POSTER_URL = getDigitalOceanUrl(window.location.host) + "/video/howitworks_poster.jpg";

const TelegramButton = ({ source = "" }) => {
  const href =
    source && typeof source === "string"
      ? "https://tg.pulse.is/bulbee_bot?start=66226d7324688dfe9806bbe2|source=" + source
      : "https://t.me/bulbee_bot?start=66226d7324688dfe9806bbe2";

  return (
    <a href={href} target="_blank" className="telegram-button">
      <div className="telegram-icon">
        <TelegramIcon />
      </div>
      <div className="telegram-text">Отримати доступ в телеграм-боті</div>
    </a>
  );
};

const Trial = () => {
  const { I18N } = useI18N();
  const { user } = useAuthz();

  const { utm_source } = Object.fromEntries(new URLSearchParams(window.location.search).entries());

  return (
    <Layout>
      <div className="aboutTrial-page">
        <div className={"aboutTrial"}>
          {/* <I18N id={"trial_page_header"} /> */}
          {/* <img className="header-image" src={trialPageHeaderImage} /> */}
          <div className="freeAccessBlockAndVideo">
            <div className="freeAccess-block">
              <div className="freeAccess-content">
                <div className="free7Days">
                  <span className="firstWord">Безкоштовний </span>
                  <span>доступ на 7 днів</span>
                </div>
                <div className="invite">
                  <span className="part1">
                    Шановні батьки, запрошуємо вас cпробувати новий додаток для дітей віком{" "}
                  </span>
                  <span className="part2">від 2 до 5 років </span>
                  <span className="part3">з особливостями розвитку!</span>
                </div>
              </div>
              <TelegramButton source={utm_source} />
            </div>
            <div className="howItWorks">Як це працює</div>
            <div className="video-block">
              <div className="video-wrapper">
                <video controls playsinline controlsList="nodownload" poster={BULBEE_TRIAL_POSTER_URL}>
                  <source src={BULBEE_TRIAL_VIDEO_URL} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            <TelegramButton source={utm_source} />
          </div>
          <div className="features-block">
            <div className="features-list">
              <div className="features-item">
                <div className="features-item-icon">1</div>
                <div className="features-item-content">
                  <div className="features-item-title">Високотехнологічне навчання</div>
                  <div className="features-item-text">
                    Наш додаток відзначається високотехнологічним підходом до навчання, забезпечуючи постійне
                    адаптування процесу під рівень вашої дитини для досягнення максимальної ефективності.
                  </div>
                </div>
              </div>
              <div className="features-item">
                <div className="features-item-icon">2</div>
                <div className="features-item-content">
                  <div className="features-item-title">Наша мета</div>
                  <div className="features-item-text">
                    Ми прагнемо створити продукт, який полегшує життя батьків та покращує навчання наших дітей
                  </div>
                </div>
              </div>
              <div className="features-item">
                <div className="features-item-icon">3</div>
                <div className="features-item-content">
                  <div className="features-item-title">Ваша думка важлива</div>
                  <div className="features-item-text">
                    Ваші враження та відгуки допоможуть нам зробити додаток ще кращим! Приєднуйтесь до нашого
                    телеграм-бота, щоб отримати доступ до тріал-версії додатку BulBee протягом 7 днів і вплинути на його
                    розвиток.
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="tryNow-block">
            <img className="img1" src={tryNowBackground} alt="" />
            <img className="img2" src={tryNowBackgroundSmall} alt="" />
            <div className="tryNow">Спробуйте зараз!</div>

            <TelegramButton source={utm_source} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Trial;
