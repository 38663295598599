import React, { useState, useRef } from "react";
import { HexColorPicker } from "react-colorful";

import useClickOutside from "hooks/useClickOutside";

const ColorPickerButton = ({ className = "", color, onChange, disabled }) => {
  const [opened, setOpened] = useState(false);
  const colorPickerRef = useRef()
  const close = () => setOpened(false);

  useClickOutside(colorPickerRef, close);

  return (
    <div className={className}>
      <div
        style={{ position: "relative", width: "20px", height: "20px", backgroundColor: color ? color : "" }}
        onClick={() => {
          !disabled && setOpened(!opened);
        }}
      ></div>
      {opened && (
        <div ref={colorPickerRef} >
          <HexColorPicker color={color ? color : ""} onChange={onChange} />
        </div>
      )
      }
    </div>
  );
};

export default ColorPickerButton;
