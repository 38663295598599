import "./CustomTooltip.scss";
import React, { useState } from "react";

function CustomTooltip({ children, tooltipText }) {
  const [showTooltip, setShowTooltip] = useState(false);

  return (
    <>
      <div
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        className="customTooltip-component"
      >
       {showTooltip && <div className="customTooltip">{tooltipText}</div>}
        {children}
      </div>
    </>
  );
}

export default CustomTooltip;
