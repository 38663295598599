import { useEffect } from "react";
import { useMemo, useCallback, useState } from "react";

import fixWebmDuration from "fix-webm-duration";

const getSoundDuration = (soundFileBlob) => {
  return new Promise((resolve, reject) => {
    try {
      let reader = new FileReader();
      reader.onload = function (event) {
        let audioContext = new (window.AudioContext || window.webkitAudioContext)();

        audioContext.decodeAudioData(event.target.result, function (buffer) {
          resolve(buffer.duration.toFixed(3) * 1000);
        });
      };

      reader.onerror = function (event) {
        resolve(0);
        console.log("An error ocurred reading the file: ", event);
      };
      reader.readAsArrayBuffer(soundFileBlob);
    } catch (error) {
      console.log("An error ocurred getting audio duration: ", error);
      resolve(0);
    }
  });
};

const useAudioRecorder = () => {
  const [audioBlob, setAudioBlob] = useState();
  const [audioUrl, setAudioUrl] = useState();
  const [audioDuration, setAudioDuration] = useState(0);
  const [audio, setAudio] = useState(new Audio());
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const { onTimeChange, start: startStopWatch, stop: stopStopWatch, reset: resetStopWatch } = useStopWatch();

  const { record, stop } = useMemo(() => {
    let mediaRecorder = {};

    const record = () => {
      setAudioBlob(null);
      setAudioUrl(null);
      setAudioDuration(0);
      setIsPlaying(false);
      setIsRecording(true);
      resetStopWatch();

      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        let audioChunks = [];
        mediaRecorder = new MediaRecorder(stream);

        const clearMediaRecorder = () => {
          audioChunks = [];
          mediaRecorder.ondataavailable = undefined;
          mediaRecorder.onerror = undefined;
          mediaRecorder.onstop = undefined;
          mediaRecorder = undefined;
        };

        mediaRecorder.onstop = async () => {
          stopStopWatch();
          const audioBlob = new Blob(audioChunks, { type: mediaRecorder.mimeType.split(";")[0] });
          const audioDuration = await getSoundDuration(audioBlob);

          const audioBlobWithDuration = await fixWebmDuration(audioBlob, audioDuration, undefined, { logger: false });
          const audioUrl = URL.createObjectURL(audioBlobWithDuration);
          const audio = new Audio(audioUrl);

          setAudioBlob(audioBlobWithDuration);
          setAudioUrl(audioUrl);
          setAudioDuration(audioDuration);
          setAudio(audio);
          setIsRecording(false);
          setIsPlaying(false);
          clearMediaRecorder();
        };

        mediaRecorder.ondataavailable = (event) => {
          audioChunks.push(event.data);
        };

        mediaRecorder.onerror = (error) => {
          stopStopWatch();
          console.log("The following error occured: " + error);
        };

        mediaRecorder.start();
        startStopWatch();
      });
    };

    const stop = () => {
      setIsRecording(false);
      mediaRecorder && mediaRecorder.stop();
    };

    return { record, stop };
  }, []);

  useEffect(() => {
    audio.onended = () => {
      setIsPlaying(false);
      resetStopWatch();
    };

    audio.onplay = () => {
      startStopWatch();
    };

    audio.onplaying = () => {
      startStopWatch();
    };

    audio.onwaiting = () => {
      stopStopWatch();
    };
  }, [audio, resetStopWatch, startStopWatch, stopStopWatch]);

  const startPlay = useCallback(() => {
    if (!isRecording) {
      resetStopWatch();
      setIsPlaying(true);
      audio.play();
    }
  }, [audio, isRecording, resetStopWatch]);

  const stopPlay = useCallback(() => {
    if (!isRecording) {
      setIsPlaying(false);
      resetStopWatch();
      audio.pause();
      audio.currentTime = 0;
    }
  }, [audio, isRecording, resetStopWatch]);

  const startRecord = useCallback(() => {
    if (!isRecording) {
      stopPlay();
      record();
    }
  }, [isRecording, record, stopPlay]);

  const stopRecord = useCallback(() => {
    if (isRecording) {
      stop();
    }
  }, [isRecording, stop]);

  const reset = () => {
    stopRecord();
    setAudioBlob(null);
    setAudioUrl(null);
    setAudio(new Audio());
    setAudioDuration(0);
    setIsPlaying(false);
    setIsRecording(false);
    resetStopWatch();
  };

  return {
    audioBlob,
    audioUrl,
    audioDuration,
    startRecord,
    stopRecord,
    isRecording,
    startPlay,
    stopPlay,
    isPlaying,
    onTimeChange,
    reset,
    audio,
  };
};

const useStopWatch = (interval = 100) => {
  //const [time, setTime] = useState(0);

  const { onTimeChange, start, stop, reset } = useMemo(() => {
    let timerId;
    let onTimeChangeHandler;
    let innerTime = 0;

    const onTimeChange = (handler) => {
      onTimeChangeHandler = handler;
    };

    const setTime = (newTime) => {
      innerTime = newTime;
      onTimeChangeHandler && onTimeChangeHandler(innerTime);
    };

    const start = () => {
      if (!timerId) {
        let prevDateNow = Date.now();
        timerId = setInterval(() => {
          innerTime = innerTime + (Date.now() - prevDateNow);
          setTime(innerTime);
          prevDateNow = Date.now();
        }, interval);
      }
    };

    const stop = () => {
      clearInterval(timerId);
      timerId = null;
    };

    const reset = () => {
      stop();
      setTime(0);
    };

    return { onTimeChange, start, stop, reset };
  }, [interval]);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  return { onTimeChange, start, stop, reset };
};

export { getSoundDuration, useAudioRecorder };
