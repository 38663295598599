export const setStateAsync = (setter, value) => {
  let promise = new Promise((resolve, reject) => {
    resolve();
  });
  promise.then(() => setter(value));
};

export const dataURLToBlob = (dataurl) => {
  let arr = dataurl.split(",");
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]);
  let n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};

export const getDateTimeUa = (date) =>
  new Date(date).toLocaleDateString("uk", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
export const getDateTimeUaShort = (date) =>
  new Date(date).toLocaleDateString("uk", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
export const getDateUaShort = (date) =>
  new Date(date).toLocaleDateString("uk", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  });

export const getDateAndTimeUa = (date) =>
  new Date(date).toLocaleDateString("uk", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

export const getDateTimeUaWithoutYear = (date) => {
  const options = {
    weekday: "short",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };

  return new Date(date).toLocaleDateString("uk", options).replace(',', ' о');
};
