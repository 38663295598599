import "./SvgConnectionArrow.scss";

const getSvgConnectionArrowDomElement = (domElemFrom, domElemTo, options = {}) => {
  const arrowHeadLength = options.arrowHeadLength || 10;
  const arrowHeadWidth = options.arrowHeadWidth || 10;
  const arrowColor = options.arrowColor || "#abbd77";
  const gapBetweenObjects = options.gapBetweenObjects || 40;
  const radius = options.radius || 10;
  const dimmed = options.dimmed || false;
  const id = options.id || `svgArrow${domElemFrom._id}${domElemTo._id}`;
  const sameLevel = options.sameLevel || false;

  const svgNS = "http://www.w3.org/2000/svg";
  const svg = document.createElementNS(svgNS, "svg");
  const path = document.createElementNS(svgNS, "path");
  const poligon = document.createElementNS(svgNS, "polygon");

  //pos in parent
  const domElemFromPosition = {
    x: 0,
    y: domElemFrom.offsetTop,
    width: domElemFrom.offsetWidth,
    height: domElemFrom.offsetHeight,
  };
  const domElemToPosition = {
    x: 0,
    y: domElemTo.offsetTop,
    width: domElemTo.offsetWidth,
    height: domElemTo.offsetHeight,
  };

  const x1 = Math.round(domElemFromPosition.x + domElemFromPosition.width);
  const y1 = Math.round(domElemFromPosition.y + domElemFromPosition.height / 2);
  const x2 = Math.round(domElemToPosition.x);
  const y2 = Math.round(domElemToPosition.y + domElemToPosition.height / 2);

  let d = "";
  let arrowPolygon = "";

  if (!sameLevel) {
    if (y1 < y2) {
      const segmentHW = Math.abs((gapBetweenObjects - 2 * radius) / 2);
      const verticalSegmentH = Math.abs(y2 - y1 - 2 * radius);

      d = `M ${x1} ${y1}
         L ${x1 + segmentHW} ${y1}
         A ${radius} ${radius} 0 0 1 ${x1 + segmentHW + radius} ${y1 + radius}
         L ${x1 + segmentHW + radius} ${y1 + radius + verticalSegmentH}
         A ${radius} ${radius} 0 0 0 ${x1 + segmentHW + radius + radius} ${y1 + radius + verticalSegmentH + radius}
         L ${x1 + gapBetweenObjects - 5} ${y2}
               
              `;

      const arrowX = x1 + gapBetweenObjects;
      const arrowY = y2;
      const arrowX1 = arrowX - arrowHeadLength;
      const arrowY1 = arrowY - arrowHeadWidth / 2;
      const arrowX2 = arrowX - arrowHeadLength;
      const arrowY2 = arrowY + arrowHeadWidth / 2;

      arrowPolygon = `${arrowX} ${arrowY} ${arrowX1} ${arrowY1} ${arrowX2} ${arrowY2} ${arrowX} ${arrowY}`;
    }

    if (y1 > y2) {
      const segmentHW = Math.abs((gapBetweenObjects - 2 * radius) / 2);
      const verticalSegmentH = Math.abs(y1 - y2 - 2 * radius);

      d = `M ${x1} ${y1}
         L ${x1 + segmentHW} ${y1}
         A ${radius} ${radius} 0 0 0 ${x1 + segmentHW + radius} ${y1 - radius}
         L ${x1 + segmentHW + radius} ${y1 - radius - verticalSegmentH}
         A ${radius} ${radius} 0 0 1 ${x1 + segmentHW + radius + radius} ${y1 - radius - verticalSegmentH - radius}
         L ${x1 + gapBetweenObjects - arrowHeadLength / 2} ${y2}
         

              `;
      const arrowX = x1 + gapBetweenObjects;
      const arrowY = y2;
      const arrowX1 = arrowX - arrowHeadLength;
      const arrowY1 = arrowY - arrowHeadWidth / 2;
      const arrowX2 = arrowX - arrowHeadLength;
      const arrowY2 = arrowY + arrowHeadWidth / 2;

      arrowPolygon = `${arrowX} ${arrowY} ${arrowX1} ${arrowY1} ${arrowX2} ${arrowY2} ${arrowX} ${arrowY}`;
    }
  }

  if (sameLevel) {
    if (y1 < y2) {
      const segmentHW = Math.abs((gapBetweenObjects - 2 * radius) / 2);
      const verticalSegmentH = Math.abs(y2 - y1 - 2 * radius);

      d = `M ${x1} ${y1}
         L ${x1 + segmentHW} ${y1}
         A ${radius} ${radius} 0 0 1 ${x1 + segmentHW + radius} ${y1 + radius}
         L ${x1 + segmentHW + radius} ${y1 + radius + verticalSegmentH}
         A ${radius} ${radius} 0 0 1 ${x1 + radius} ${y1 + radius + verticalSegmentH + radius}
         L ${x1 + 5} ${y2}
               
              `;

      const arrowX = x1;
      const arrowY = y2;
      const arrowX1 = arrowX + arrowHeadLength;
      const arrowY1 = arrowY + arrowHeadWidth / 2;
      const arrowX2 = arrowX + arrowHeadLength;
      const arrowY2 = arrowY - arrowHeadWidth / 2;

      arrowPolygon = `${arrowX} ${arrowY} ${arrowX1} ${arrowY1} ${arrowX2} ${arrowY2} ${arrowX} ${arrowY}`;
    }

    if (y1 > y2) {
      const segmentHW = Math.abs((gapBetweenObjects - 2 * radius) / 2);
      const verticalSegmentH = Math.abs(y1 - y2 - 2 * radius);

      d = `M ${x1} ${y1}
         L ${x1 + segmentHW} ${y1}
         A ${radius} ${radius} 0 0 0 ${x1 + segmentHW + radius} ${y1 - radius}
         L ${x1 + segmentHW + radius} ${y1 - radius - verticalSegmentH}
         A ${radius} ${radius} 0 0 0 ${x1 + radius} ${y1 - radius - verticalSegmentH - radius}
         L ${x1 + arrowHeadLength / 2} ${y2}
         

              `;
      const arrowX = x1;
      const arrowY = y2;
      const arrowX1 = arrowX + arrowHeadLength;
      const arrowY1 = arrowY + arrowHeadWidth / 2;
      const arrowX2 = arrowX + arrowHeadLength;
      const arrowY2 = arrowY - arrowHeadWidth / 2;

      arrowPolygon = `${arrowX} ${arrowY} ${arrowX1} ${arrowY1} ${arrowX2} ${arrowY2} ${arrowX} ${arrowY}`;
    }
  }

  if (y1 === y2) {
    d = `M ${x1} ${y1 + arrowHeadLength / 2} 
         A ${gapBetweenObjects / 2} ${gapBetweenObjects / 2} 0 0 0 ${x1 + gapBetweenObjects - arrowHeadLength / 2} ${
      y2 + arrowHeadLength / 2
    }
         `;

    const arrowX = x1 + gapBetweenObjects;
    const arrowY = y2 + arrowHeadLength / 2;
    const arrowX1 = arrowX - arrowHeadLength;
    const arrowY1 = arrowY - arrowHeadLength / 2;
    const arrowX2 = arrowX - arrowHeadLength;
    const arrowY2 = arrowY + arrowHeadLength / 2;

    //rotate arrow -45deg and move up 5px
    const angle = -45;
    const x = arrowX;
    const y = arrowY;
    const x1r = x + (arrowX1 - x) * Math.cos(angle) - (arrowY1 - y) * Math.sin(angle);
    const y1r = y + (arrowX1 - x) * Math.sin(angle) + (arrowY1 - y) * Math.cos(angle);
    const x2r = x + (arrowX2 - x) * Math.cos(angle) - (arrowY2 - y) * Math.sin(angle);
    const y2r = y + (arrowX2 - x) * Math.sin(angle) + (arrowY2 - y) * Math.cos(angle);

    arrowPolygon = `${arrowX} ${arrowY - arrowHeadLength / 1.5} ${x1r} ${y1r - arrowHeadLength / 1.5} ${x2r} ${
      y2r - arrowHeadLength / 1.5
    } ${arrowX} ${arrowY - arrowHeadLength / 1.5}`;
  }

  path.setAttribute("d", d);
  path.setAttribute("fill", "none");
  path.setAttribute("stroke", arrowColor);
  path.setAttribute("stroke-width", 3);
  svg.appendChild(path);

  poligon.setAttribute("points", arrowPolygon);
  poligon.setAttribute("fill", arrowColor);
  svg.appendChild(poligon);
  svg.setAttribute("class", `svgConnectionArrow-component ${dimmed ? "dimmed" : ""}`);
  svg.setAttribute("id", id);

  return svg;
};

export default getSvgConnectionArrowDomElement;
