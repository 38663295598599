import CardStyled from "components/CardStyled/CardStyled";
import StageItem from "components/StageItem/StageItem";
import { useMemo } from "react";
import { useResourcesContext } from "resources";
import "./StageItemLibrary.scss";

const StageItemLibrary = ({ className = "", onItemTemplateClick, onItemCopy, deleteButton }) => {
  const {
    itemTemplatesStore: { itemTemplatesList, deleteItemTemplate },
  } = useResourcesContext();

  const sortedList = useMemo(() => {
    return itemTemplatesList
      .map((element) => element)
      .sort((a, b) => {
        const idA = a.template._id;
        const idB = b.template._id;
        return idA > idB ? 1 : idA < idB ? -1 : 0;
      });
  }, [itemTemplatesList]);


  return (
    <CardStyled className={`stageItemLibrary-component ${className}`}>
      <div className="stageItemLibrary-content">
        <div className="stageItemLibrary-list">
          {sortedList.map((itemTemplate, index) => (
            <StageItem
              key={index}
              className="stageItemLibrary-element"
              item={itemTemplate.template}
              onItemCopy={onItemCopy}
              onClick={() => onItemTemplateClick(itemTemplate.template)}
              onItemDelete={deleteButton && (() => deleteItemTemplate(itemTemplate._id))}
            />
          ))}
        </div>
      </div>
    </CardStyled>
  );
};

export default StageItemLibrary;
