import "./StageCardUniversal.scss";
import React, { useEffect, useRef, useState } from "react";
import { AlertDialog, StyledButton } from "components";
import { ReactComponent as IconHasASound } from "assets/icons/HasASound.svg";
import { ReactComponent as IconRemove } from "assets/icons/remove.svg";
import { ReactComponent as IconCopy } from "assets/icons/copy.svg";
import { ReactComponent as IconAddToLibrary } from "assets/icons/plus2.svg";

import Tooltip from "@mui/material/Tooltip";
import IconEmptyIcon from "assets/icons/emptyIcon.svg";
import { useResourcesContext } from "resources";
import { getItemsWithChildrenPlain } from "helpers/stageItems";
import { CircularProgress } from "@mui/material";
import ItemUniversalOld from "components/ItemUniversal/itemUniversalOld";

const StageCardUniversal = ({
  className = "",
  selected,
  stage,
  stageIndex,
  onClick,
  onDoubleClick,
  onAddToLibrary,
  onDelete,
  onCopy,
  locked,
  controls = true,
  showCircularProgress,
}) => {
  const [backgroundSize, setBackgroundSize] = useState(null);
  const backgroundRef = useRef();
  const [alertDialogData, setAlertDialogData] = useState(null);

  const {
    imageListStore: { getImagePathById },
  } = useResourcesContext();

  const updateBackgroundSize = () => {
    setBackgroundSize({
      clientHeight: backgroundRef.current?.clientHeight,
      clientWidth: backgroundRef.current?.clientWidth,
      scale1920: 1920 / backgroundRef.current?.clientWidth,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", updateBackgroundSize);
    return () => {
      window.removeEventListener("resize", updateBackgroundSize);
    };
  }, []);

  const handleBackgroundOnLoad = () => {
    updateBackgroundSize();
  };

  const openDeleteStageDialog = () => {
    setAlertDialogData({
      onOk: () => {
        onDelete(stageIndex);
        setAlertDialogData(null);
      },
      onCancel: () => setAlertDialogData(null),
      title: "Deleting stage",
      message: "Press Ok to delete stage, or Cancel to cancel operation.",
    });
  };

  return (
    <>
      <div
        className={`stage-card-universal ${selected ? "active-stage-card-universal" : ""} ${className}`}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
      >
        {stage?.sounds?.stageStart && (
          <Tooltip title="Has a sound" placement="right" arrow>
            <IconHasASound className="sound-icon" />
          </Tooltip>
        )}
        {controls && (
          <>
            <StyledButton
              tooltipProps={{ title: "Видалити", placement: "right", arrow: true }}
              disabled={locked}
              //icon={IconRemove}
              className="customize-button remove"
              onClick={(e) => {
                openDeleteStageDialog();
                e.stopPropagation();
              }}
            >
              <IconRemove className="button-icon" />
            </StyledButton>
            <StyledButton
              tooltipProps={{ title: "Скопіювати", placement: "right", arrow: true }}
              disabled={locked}
              //icon={IconCopy}
              className="customize-button copy"
              onClick={(e) => {
                onCopy(stage);
                e.stopPropagation();
              }}
            >
              <IconCopy className="copy-button-icon" />
            </StyledButton>
            {selected && (
              <StyledButton
                tooltipProps={{ title: "Додати до бібліотеки", placement: "right", arrow: true }}
                disabled={!selected}
                //icon={IconCopy}
                className="customize-button addToLibrary"
                onClick={(e) => {
                  onAddToLibrary(stage);
                  e.stopPropagation();
                }}
              >
                <IconAddToLibrary className="addToLibrary-button-icon" />
              </StyledButton>
            )}
          </>
        )}
        <div id={`stageCardBase${stageIndex}`}>
          <img
            ref={backgroundRef}
            onLoad={handleBackgroundOnLoad}
            className={`stage-bg ${stage?.items?.length > 0 ? "" : "srcSvg"}`}
            src={stage?.items?.length > 0 ? getImagePathById(stage.items[0]._id) : IconEmptyIcon}
            alt="Empty background"
          />

          {backgroundSize &&
            stage?.items &&
            getItemsWithChildrenPlain(stage.items).map((item, index) =>
              index === 0 ? null : (
                <ItemUniversalOld
                  key={"stage item " + item.stageItemId}
                  item={item}
                  backgroundSize={backgroundSize}
                  freeze
                  showSoundIcon={false}
                />
              )
            )}
        </div>
        {showCircularProgress && (
          <CircularProgress className="circular-progress" size={100} style={{ color: "#FAA400" }} />
        )}
      </div>
      <AlertDialog alertDialogData={alertDialogData} />
    </>
  );
};
export default StageCardUniversal;
