import "./Tabs.scss";
import React, { useRef } from "react";
import { ReactComponent as BackBtnIcon } from "assets/icons/back.svg";
import { StyledButton } from "components";

const Tabs = ({
  tabs = [],
  selectedTabs = [],
  onClick,
  searchParamWorker,
  tabsScrollPosParamName = "tabsScrollPos",
}) => {
  let tabsListRef = useRef();
  let timerIdRef = useRef();
  let mouseDownTimerRef = useRef();

  const callbackTabListRef = (element) => {
    if (tabs.length) {
      if (element && element !== tabsListRef.current) {
        const tabsScrollPosParam = searchParamWorker ? searchParamWorker.getSearchParam(tabsScrollPosParamName) : null;

        setTimeout(() => {
          if (tabsScrollPosParam == null) {
            element.scrollLeft = element.scrollWidth;
          } else {
            element.scrollLeft = tabsScrollPosParam;
          }
        }, 300);
        tabsListRef.current = element;
      }
    }
  };

  const clearTimers = () => {
    clearTimeout(mouseDownTimerRef.current);
    clearInterval(timerIdRef.current);
    mouseDownTimerRef.current = null;
    timerIdRef.current = null;
  };

  const scrollLeft = () => {
    mouseDownTimerRef.current = setTimeout(() => {
      timerIdRef.current = setInterval(() => {
        tabsListRef.current.scrollLeft += -20;
      }, 20);
    }, 200);
  };

  const scrollLeftClick = () => {
    if (!timerIdRef.current && mouseDownTimerRef.current) {
      tabsListRef.current.scrollLeft += -70;
      searchParamWorker && searchParamWorker.setSearchParam(tabsScrollPosParamName, tabsListRef.current.scrollLeft);
    }
    clearTimers();
  };

  const scrollRight = () => {
    mouseDownTimerRef.current = setTimeout(() => {
      timerIdRef.current = setInterval(() => {
        tabsListRef.current.scrollLeft += 20;
      }, 20);
    }, 200);
  };

  const scrollRightClick = () => {
    if (!timerIdRef.current && mouseDownTimerRef.current) {
      tabsListRef.current.scrollLeft += 70;
      searchParamWorker && searchParamWorker.setSearchParam(tabsScrollPosParamName, tabsListRef.current.scrollLeft);
    }
    clearTimers();
  };

  const stopScroll = () => {
    if (timerIdRef.current) {
      clearTimers();
      searchParamWorker && searchParamWorker.setSearchParam(tabsScrollPosParamName, tabsListRef.current.scrollLeft);
    }
  };

  return (
    <div className="tabs-wrapper">
      <div className="left-button-block">
        <StyledButton className="scroll-back" onMouseDown={scrollLeft} onMouseUp={stopScroll} onClick={scrollLeftClick}>
          <BackBtnIcon />
        </StyledButton>
      </div>
      <div className="tabs-list-wrapper">
        <div ref={callbackTabListRef} id="tabs-list" className="tabs-list">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={`tab ${selectedTabs.includes(tab) ? "selected" : ""} `}
              onClick={() => {
                onClick && onClick(tab);
              }}
            >
              {tab.label}
            </div>
          ))}
        </div>
      </div>
      <div className="right-button-block">
        <StyledButton
          className="scroll-forward"
          onMouseDown={scrollRight}
          onMouseUp={stopScroll}
          onClick={scrollRightClick}
        >
          <BackBtnIcon />
        </StyledButton>
      </div>
    </div>
  );
};
export default Tabs;
