import "./Batteries.scss";
import { useResourcesContext } from "resources";
import React, { useEffect, useState } from "react";
import { ReactComponent as IconRefresh } from "assets/icons/cancel.svg";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

// const parentsStatisticStructure = {
//   knowledge: knTagId
//   knowledgeName
//   startShowStrength
//   endShowStrength
//   strength: endStrength
//   forgetRate: endForgetRate
// };

const Batteries = ({ className = "", unityUser, knowledgesData }) => {
  const [groupedKnData, setGroupedKnData] = useState([]);

  const {
    knowledgeGroupListStore: { knowledgeGroupList },
  } = useResourcesContext();

  useEffect(() => {
    const groupStatistic = (knowledgesData, knowledgeGroupList) => {
      const groupedData = [
        ...knowledgeGroupList.map((knGroup) => {
          const groupKnowledgesData = knowledgesData.filter((knData) =>
            knGroup.knowledgeTags.includes(knData.knowledge)
          );

          return { knowledgeGroup: knGroup, knowledgesData: groupKnowledgesData };
        }),
      ].sort((a, b) => {
        const aName = a.knowledgeGroup.name.toLowerCase();
        const bName = b.knowledgeGroup.name.toLowerCase();
        return aName.localeCompare(bName);
      });

      const usedTagsIds = knowledgeGroupList.reduce((result, knGroup) => {
        return [...result, ...knGroup.knowledgeTags];
      }, []);

      const emptyGroupknowledgesData = knowledgesData.filter((knData) => !usedTagsIds.includes(knData.knowledge));
      groupedData.push({ knowledgeGroup: { name: "без груп" }, knowledgesData: emptyGroupknowledgesData });

      return groupedData.filter((groupData) => groupData.knowledgesData.length);
    };

    if (knowledgesData) {
      setGroupedKnData(groupStatistic(knowledgesData, knowledgeGroupList));
    }
  }, [knowledgesData, knowledgeGroupList]);

  const downloadGroupCard = (knGroup, unityUser) => {
    console.log("downloadGroupCard", knGroup, unityUser);

    if (unityUser) {
      const url = `/api/statistic/getUserAchievementCardFile?unityUserId=${unityUser._id}&knowledgeGroupId=${knGroup._id}`;

      fetch(url, { method: "GET" })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${unityUser.name} група ${knGroup.name}.png`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
    }
  };

  const downloadKnowledgeCard = (knDataElement, unityUser) => {
    if (unityUser) {
      const url = `/api/statistic/getUserAchievementCardKnowledgeFile?unityUserId=${unityUser._id}&knowledgeId=${knDataElement.knowledge}`;

      fetch(url, { method: "GET" })
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${unityUser.name} знання ${knDataElement.knowledgeName}.png`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
    }
  };

  return (
    <div className={`batteries-component ${className}`}>
      <div className="battery-groups">
        {groupedKnData.map((groupData) => (
          <div className="battery-group">
            <div className="battery-group-header">
              {groupData.knowledgeGroup.name}{" "}
              <div onClick={() => downloadGroupCard(groupData.knowledgeGroup, unityUser)}>
                <FileDownloadIcon />
              </div>
            </div>
            <div className="batteries">
              {groupData.knowledgesData.map((elem) => (
                <div
                  className={`battery ${
                    elem.endShowStrength > 90 || (elem.strength > 90 && elem.forgetRate < 0.25) ? "known" : ""
                  }`}
                >
                  <div className="battery-name">
                    {elem.knowledgeName}
                    <div onClick={() => downloadKnowledgeCard(elem, unityUser)}>
                      <FileDownloadIcon />
                    </div>
                  </div>
                  <div className="battery-body">
                    <div className="battery-levels">
                      <div className="level-label">show</div>
                      <div>{Math.floor(elem.endShowStrength)}</div>
                      <div className="level-label">strength</div>
                      <div>{Math.floor(elem.strength)}</div>
                      <div className="level-label">fRate</div>
                      <div>{elem.forgetRate.toFixed(2)}</div>
                    </div>
                    {elem.startShowStrength <= elem.endShowStrength && (
                      <>
                        <div
                          className="battery-delta-fill green"
                          style={{ height: `${elem.endShowStrength - elem.startShowStrength}%` }}
                        ></div>
                        <div
                          className="battery-start-fill light-green"
                          style={{ height: `${elem.startShowStrength}%` }}
                        ></div>
                      </>
                    )}
                    {elem.startStrength > elem.endStrength && (
                      <>
                        <div
                          className="battery-delta-fill light-red"
                          style={{ height: `${elem.startStrength - elem.endStrength}%` }}
                        ></div>
                        <div
                          className="battery-start-fill light-green"
                          style={{ height: `${elem.endStrength}%` }}
                        ></div>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Batteries;
