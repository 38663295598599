import React, { useState } from "react";
import FilterBox from "./FilterBox/FilterBox";
import OptionElement from "./OptionElement";

const SelectFilter = ({ filter, onFilterChange, defaultOpened = false, withBadges = true }) => {
  const [searchString, setSearchString] = useState("");

  const filterTheOptions = (options, searchString) => {
    return options.filter((option) => {
      return option.value.toUpperCase().includes(searchString.toUpperCase());
    });
  };

  const optionSelected = (option, filter) => {
    return filter.value.includes(option.value);
  };

  const hasSearch = (filter) => {
    return filter.settings?.includes("withSearch");
  };

  const selectedShownAbove = (filter) => {
    return filter.settings?.includes("selectedShownAbove");
  };

  const isCheckBox = (filter) => {
    return filter.settings?.includes("checkBox");
  };

  const sortToShowSelectedAbove = (options, filter) => {
    return options.sort((a, b) => {
      return optionSelected(b, filter) - optionSelected(a, filter);
    });
  };

  const getModifiedFilterValue = (option, filter) => {
    if (filter.type !== "multi") {
      return filter.value === option.value ? "" : option.value;
    }

    return optionSelected(option, filter)
      ? [...filter.value].filter((el) => el !== option.value)
      : [...filter.value, option.value];
  };

  const getModifiedFilter = (option, filter) => {
    return { ...filter, value: getModifiedFilterValue(option, filter) };
  };

  const getOptionList = (filter, searchString) => {
    let options = [...filter.options];

    if (hasSearch(filter)) {
      options = filterTheOptions(options, searchString);
    }

    if (selectedShownAbove(filter)) {
      options = sortToShowSelectedAbove(options, filter);
    }

    return options;
  };

  const optionList = getOptionList(filter, searchString);

  const handleOptionChange = (option, filter) => {
    onFilterChange(getModifiedFilter(option, filter));
  };

  return (
    <FilterBox label={filter.label} defaultOpened={defaultOpened || filter.value.length}>
      <div className="options-list-wrapper" onClick={(e) => e.stopPropagation()}>
        {hasSearch(filter) && (
          <div className="search-box">
            <input
              className="search-input"
              type="text"
              placeholder="Шукати серед опцій"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
            />
          </div>
        )}
        <div className="options-list">
          <div className="options-box-wrapper">
            <div className="options-box">
              {optionList.map((option, index) => (
                <OptionElement
                  key={index}
                  option={option}
                  isCheckBox={isCheckBox(filter)}
                  isSelected={optionSelected(option, filter)}
                  onClick={() => handleOptionChange(option, filter)}
                  withBadge={withBadges}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </FilterBox>
  );
};

export default SelectFilter;
