import "./SubscriptionList.scss";
import React, { useEffect, useState } from "react";
import { useI18N } from "i18n";
import { FaSpinner } from "react-icons/fa";
import axios from "axios";
import { planName, planPrice, planPriceCurrency } from "constants/constants";
import { useAuthz } from "auth/AuthzProvider";
import SubscriptionCard from "./SubscriptionCard/SubscriptionCard";
import ConfirmationBox from "./ConfirmationBox/ConfirmationBox";
import { sendGtagData } from "helpers/sendGtagEvent";

const products = [
  {
    id: 1,
    name: planName,
    price: planPrice,
    currency: planPriceCurrency,
  },
];

const SubscriptionList = () => {
  const { I18N } = useI18N();
  const { user: webUser, isLoading: webUserIsLoaging, getMe } = useAuthz();

  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showUncancelConfirmation, setShowUncancelConfirmation] = useState(false);

  const [updatingSubscription, setUpdatingSubscription] = useState(false);

  const handleCancelSubscription = async () => {
    try {
      sendGtagData("event", "user_click_cancel_subscribtion");
      const webUserId = webUser?._id || "";
      const res = await axios.post(`/api/subscription/cancel`, { webUserId });
      if (res.status === 200) {
        setTimeout(() => getMe(), 1000);
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setShowCancelConfirmation(false);
      setUpdatingSubscription(false);
    }
  };

  const handleUncancelSubscription = async () => {
    try {
      sendGtagData("event", "user_click_uncancel_subscribtion");
      const webUserId = webUser?._id || "";
      const res = await axios.post(`/api/subscription/uncancel`, { webUserId });
      if (res.status === 200) {
        setTimeout(() => getMe(), 1000);
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setShowUncancelConfirmation(false);
      setUpdatingSubscription(false);
    }
  };

  const checkout = async () => {
    try {
      sendGtagData("event", "user_click_create_subscribtion");

      const locale = localStorage.getItem("locale") || "uk";
      console.log("checkout() locale", locale);
      const webUserId = webUser?._id || "";
      const res = await axios.post(`/api/subscription/create`, {
        webUserId: webUserId,
        locale: locale,
      });

      if (res.status === 200) {
        const { session } = res.data;
        window.location = session.url;
      } else {
        const json = res.data;
        console.error(json);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRefreshSubscription = async () => {
    try {
      getMe();
    } catch (error) {
      console.error(error.message);
    }
  };
  
  return (
    <div className={`subscription ${showCancelConfirmation ? "blur-background" : ""}`}>
      <div className="subscription_wrapper">
        {showCancelConfirmation || showUncancelConfirmation ? null : (
          <h1>
            <I18N id={"subscription_header"} />
          </h1>
        )}
        {webUserIsLoaging ? (
          <FaSpinner className="loading-spinner" />
        ) : showCancelConfirmation || showUncancelConfirmation ? null : (
          <div className="subscription_wrapper__products">
            {!webUser.subscription?.active && webUser.subscriptionAutoActive ? (
              <div className="autoActive">Тимчасово активована повна версія</div>
            ) : (
              <>
                {products.map((product) => (
                  <SubscriptionCard
                    product={product}
                    key={product.id}
                    onSubscribe={checkout}
                    onCancel={() => setShowCancelConfirmation(true)}
                    onUncancel={() => setShowUncancelConfirmation(true)}
                    onRefresh={() => {
                      handleRefreshSubscription();
                    }}
                    subscriptionActive={webUser.subscription?.active || webUser.subscriptionAutoActive}
                    endDate={webUser.subscription?.endDate}
                    isCancelled={webUser.subscription?.isCancelled}
                    isLoading={webUserIsLoaging || updatingSubscription}
                  />
                ))}
              </>
            )}
          </div>
        )}
        {showCancelConfirmation && (
          <ConfirmationBox
            confirmTextId="subscription_confirm_cancel_text"
            onConfirm={() => {
              setUpdatingSubscription(true);
              handleCancelSubscription();
            }}
            onCancel={() => setShowCancelConfirmation(false)}
          />
        )}
        {showUncancelConfirmation && (
          <ConfirmationBox
            confirmTextId="subscription_confirm_uncancel_text"
            onConfirm={() => {
              setUpdatingSubscription(true);
              handleUncancelSubscription();
            }}
            onCancel={() => setShowUncancelConfirmation(false)}
          />
        )}
      </div>
    </div>
  );
};

export default SubscriptionList;
