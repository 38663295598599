import "./KnowledgeTagList.scss";
import React from "react";
import { Chip, Tooltip, Typography } from "@mui/material/";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

const KnowledgeTagList = ({
  className = "",
  chipClassName = "",
  knowledgeTags = [],
  selectedTagsIds = [],
  selectedTagsIdsRoot = [],
  tagsUsageData = {
    freeTags: [],
    usedKnowledgeTags: {
      allGroupsTags: [],
      allLearningProgressTags: [],
      allPlanTags: [],
      allSoundSetsTags: [],
    },
  },
  showSelectedTags = true,
  highliteSelectedTags = true,
  onClick,
  onDeleteKnowledgeTag,
  plusButton,
  tagDefaultTooltip = "",
  selectedTagDefaultTooltip,
  tagButtonDefaultTooltip = "",
  sort = true,
  tagsWithoutSoundSet = [],
}) => {
  const getChipClassName = (knowledgeTag) => {
    const baseClassName = `chip ${chipClassName} `;

    const hasSoundSetClassName = tagsWithoutSoundSet.includes(knowledgeTag._id) ? "doesntHaveSoundSet" : "";

    if (selectedTagsIds.includes(knowledgeTag._id)) {
      return `${baseClassName} ${hasSoundSetClassName} selected`;
    }
    if (selectedTagsIdsRoot.includes(knowledgeTag._id)) {
      return `${baseClassName} ${hasSoundSetClassName} selectedRoot`;
    }
    return `${baseClassName} ${hasSoundSetClassName}`;
  };

  const getTooltip = (knowledgeTag) => {
    const tagWithoutSoundSetMessage = tagsWithoutSoundSet.includes(knowledgeTag._id)
      ? "Для базового знання відсутній набір звуків"
      : "";
    const selectedTagMessage =
      selectedTagDefaultTooltip && selectedTagsIds.includes(knowledgeTag._id) ? selectedTagDefaultTooltip + "." : "";

    const baseMessage = `${selectedTagMessage} ${tagWithoutSoundSetMessage}`;

    const usageMessages = [];
    if (tagWithoutSoundSetMessage && !tagsUsageData.freeTags.includes(knowledgeTag._id)) {
      tagsUsageData.usedKnowledgeTags.allGroupsTags.includes(knowledgeTag._id) &&
        usageMessages.push("Використовується в групах");
      tagsUsageData.usedKnowledgeTags.allLearningProgressTags.includes(knowledgeTag._id) &&
        usageMessages.push("Використовується в прогресі навчання");
      tagsUsageData.usedKnowledgeTags.allPlanTags.includes(knowledgeTag._id) &&
        usageMessages.push("Використовується в планах");
      tagsUsageData.usedKnowledgeTags.allSoundSetsTags.includes(knowledgeTag._id) &&
        usageMessages.push("Використовується в наборах звуків");
    }

    return baseMessage.trim() || usageMessages.length ? (
      <React.Fragment>
        {baseMessage.trim() && <Typography color="inherit">{baseMessage}</Typography>}
        {usageMessages.length ? (
          <>
            <Typography color="inherit">Не можливо видалити:</Typography>
            {usageMessages.map((message) => (
              <Typography color="inherit">{message}</Typography>
            ))}
          </>
        ) : null}
      </React.Fragment>
    ) : (
      ""
    );
  };

  return (
    <div className={`knowledgeTagList-component ${className}`}>
      <div className={`knowledgeTagList`}>
        {knowledgeTags
          .sort((a, b) => (!sort ? 0 : a.name > b.name ? 1 : -1))
          .map((knowledgeTag) =>
            !showSelectedTags && selectedTagsIds.includes(knowledgeTag._id) ? null : (
              <div key={knowledgeTag._id}>
                <Tooltip followCursor={true} title={getTooltip(knowledgeTag)}>
                  <Chip
                    deleteIcon={plusButton ? <AddOutlinedIcon className="chipIcon" /> : null}
                    onDelete={
                      onDeleteKnowledgeTag && tagsUsageData.freeTags.includes(knowledgeTag._id)
                        ? () => onDeleteKnowledgeTag(knowledgeTag)
                        : null
                    }
                    onClick={() => onClick && onClick(knowledgeTag)}
                    variant=""
                    label={`${knowledgeTag.name}`}
                    className={getChipClassName(knowledgeTag)}
                  />
                </Tooltip>
              </div>
            )
          )}
      </div>
    </div>
  );
};
export default KnowledgeTagList;
