import "./Messages.scss";
import { DeleteRoundButton, Header, ReloadButton } from "components";
import { CardStyled } from "components";
import { useState, useEffect } from "react";
import axios from "axios";

const Messages = () => {
  const [messages, setMessages] = useState([]);
  const [selectedMessageId, setSelectedMessageId] = useState();

  const reloadMessages = () => {
    axios
      .get("api/messages")
      .then((res) => setMessages(res.data))
      .catch(() => setMessages([]));
  };

  useEffect(() => {
    reloadMessages();
  }, []);

  const getDateTimeUa = (date) =>
    new Date(date).toLocaleDateString("uk", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });

  const handleDeleteMessage = (messageId) => {
    messageId &&
      axios
        .delete(`api/messages/${messageId}`)
        .then(() => reloadMessages())
        .catch(() => reloadMessages());
  };

  const handleMessageClick = (message) => {
    selectedMessageId === message._id && setSelectedMessageId();
    selectedMessageId !== message._id && setSelectedMessageId(message._id);
  };

  return (
    <div className="messages-wrapper">
      <Header className="messages-header" />
      <div className="content-wrapper">
        <div className="left-content-wrapper"></div>
        <div className="middle-content-wrapper">
          <div className="header">
            <div className="title">Повідомлення</div>
            <ReloadButton className="reload-messagelist-button" title="Оновити данні" onClick={reloadMessages} />
          </div>
          <div className="message-list">
            {messages.map((message) => (
              <div key={message._id} className="message-box" onClick={() => handleMessageClick(message)}>
                <CardStyled className="message-card">
                  <div className="header">
                    <div className="date">
                      <p>{getDateTimeUa(message.createdAt)}</p>
                    </div>
                    <div className="app-version">
                      <p>{`App: ${message.appVersion || ""}`}</p>
                    </div>
                    {message.webUserEmail && (
                      <div className="email">
                        <p>{`Email: ${message.webUserEmail || "не вказано"}`}</p>
                      </div>
                    )}
                    <div className="unityId">
                      <p>{`UnityId: ${message.userid || "не вказано"}`}</p>
                    </div>
                  </div>
                  <div className="content">
                    <div className="content-message">
                      {<div className="message-text">{message.text}</div>}
                      {selectedMessageId === message._id && (
                        <div className="message-text">
                          <pre>{JSON.stringify(message, null, 4)}</pre>
                        </div>
                      )}
                    </div>
                    <DeleteRoundButton
                      className="deleteMessageButton"
                      title={`Видалити повідомлення`}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteMessage(message._id);
                      }}
                      disabled={false}
                    />
                  </div>
                </CardStyled>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Messages;
