import "./GameSetTile.scss";
import React from "react";
import { GameIcon, GroupTile, StageCardUniversal } from "components";
import { useResourcesContext } from "resources";

const GameSetTile = ({ gameSet }) => {
  const {
    imageListStore: { getImagePathById },
  } = useResourcesContext();

  const getTime = (timestamp) => {
    const newDate = new Date(timestamp);
    return `${newDate.getUTCHours()}:${newDate.getUTCMinutes()}`;
  };

  return (
    <GroupTile mode={"single"} className="gameSetTile-border">
      <div className={`gameSetTile-component`}>
        <div className="gst-header">
          <div className="gst-time-row">
            <div className="gst-time">{`${getTime(gameSet.createdAt)}`}</div>
          </div>
          <div className="gst-gamesSet">
            {gameSet &&
              gameSet.icons.map((iconData) =>
                iconData.iconConfig ? (
                  <StageCardUniversal className="gst-gamesSet-stageIcon" stage={iconData.iconConfig} controls={false} />
                ) : (
                  <GameIcon
                    className="gst-gamesSet-icon"
                    gameIcon={iconData.iconSrc || getImagePathById(iconData.icon)}
                  />
                )
              )}
          </div>
        </div>

        <div className="gst-footer"></div>
      </div>
    </GroupTile>
  );
};

export default GameSetTile;
