import "./StatisticResultsView.scss";
import React, { useEffect, useState } from "react";
import { CustomTooltip, StyledButton, StyledInput } from "components";
import axios from "axios";
import { useResourcesContext } from "resources";
import { Tooltip, Checkbox } from "@mui/material";

const StatisticResultsView = ({ className = "", unityUser, knResultsRateBased, trackingKnowledges = [] }) => {
  const [groupedTrackingKnowledges, setGroupedTrackingKnowledges] = useState([]);
  const [groupLevels, setGroupLevels] = useState([]);
  const [scale, setScale] = useState(200000000);
  const [coloredMode, setColoredMode] = useState(true);

  const {
    knowledgeGroupListStore: { knowledgeGroupList },
  } = useResourcesContext();

  const groupTrackingKnowledges = (rawTrackingKnowledges) => {
    const knowledgesData = rawTrackingKnowledges.reduce((result, currentData) => {
      const existedResult = result.find((item) => item.knowledge.name === currentData.knowledge.name);

      if (existedResult) {
        existedResult.data.push(currentData);
      } else {
        result.push({
          knowledge: currentData.knowledge,
          data: [currentData],
        });
      }

      return result;
    }, []);

    const groupedData = [
      ...knowledgeGroupList.map((knGroup) => {
        const groupKnowledgesData = knowledgesData.filter((knData) =>
          knGroup.knowledgeTags.includes(knData.knowledge._id)
        );

        return { knowledgeGroup: knGroup, knowledgesData: groupKnowledgesData };
      }),
    ].sort((a, b) => {
      const aName = a.knowledgeGroup.name.toLowerCase();
      const bName = b.knowledgeGroup.name.toLowerCase();
      return aName.localeCompare(bName);
    });

    const usedTagsIds = knowledgeGroupList.reduce((result, knGroup) => {
      return [...result, ...knGroup.knowledgeTags];
    }, []);

    const emptyGroupknowledgesData = knowledgesData.filter((knData) => !usedTagsIds.includes(knData.knowledge._id));
    groupedData.push({ knowledgeGroup: { name: "без груп" }, knowledgesData: emptyGroupknowledgesData });

    return groupedData.filter((groupData) => groupData.knowledgesData.length);
  };

  useEffect(() => {
    new Promise((resolve) => {
      const groupedTrackingKnowledges = groupTrackingKnowledges(trackingKnowledges);
      const groupLevels = Array.from(
        new Set(
          groupedTrackingKnowledges.map((groupData) => {
            return groupData.knowledgeGroup.level;
          })
        )
      ).sort((a, b) => a - b);
      setGroupLevels(groupLevels);
      setGroupedTrackingKnowledges(recalculatePositionInfo(groupedTrackingKnowledges, scale));
      resolve();
    });
  }, [trackingKnowledges]);

  const handleExportToCsv = () => {
    const localResults = trackingKnowledges.map((result) => {
      return {
        _id: result._id,
        unityUserId: result.unityUserId,
        trackingId: result.trackingId,
        knowledge: result.knowledge.name,
        timestamp: result.timestamp,
        type: result.type,
        soundText: result.soundText,
        strategy: result.strategy,
        knowledgeStrength: result.knowledgeStrength,
        knowledgeForgetRate: result.knowledgeForgetRate,
      };
    });
    const titleKeys = Object.keys(localResults[0]);

    const refinedData = [];
    refinedData.push(titleKeys);
    localResults.forEach((item) => refinedData.push(Object.values(item)));

    let csvContent = "";

    refinedData.forEach((row) => {
      csvContent += row.join(",") + "\n";
    });

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8," });

    const objUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = objUrl;
    link.download = "bulbeeStat.csv";
    link.click();
  };

  const recalculatePositionInfo = (groupedResults, xScale = 2000000, elemWidth = 7) => {
    const result = groupedResults.map((groupData) => {
      const knowledgesData = groupData.knowledgesData.map((knData) => {
        const minTimestamp = Math.min(...knData.data.map((knEvent) => knEvent.timestamp));

        let prevElemEndPos = 0;

        const data = knData.data.map((knEvent, index) => {
          let pos = 0;
          let tempPos = 0;

          if (index !== 0) {
            tempPos = (knEvent.timestamp - minTimestamp) / xScale;
            pos = tempPos <= prevElemEndPos ? prevElemEndPos : tempPos;
          }
          prevElemEndPos = pos + elemWidth + 1;

          return { ...knEvent, style: { left: pos + "px" } };
        });
        return { ...knData, data };
      });
      return { ...groupData, knowledgesData };
    });
    return result;
  };

  const getKnowledgeStateClassName = (knowledgeTagId) => {
    if (knResultsRateBased.knownList.includes(knowledgeTagId)) {
      return "known-color";
    }
    if (knResultsRateBased.knownListForMinorKnowledges.includes(knowledgeTagId)) {
      return "knownMinor-color";
    }

    if (knResultsRateBased.readyToControlList.includes(knowledgeTagId)) {
      return "control-color";
    }
    if (knResultsRateBased.readyToLearnList.includes(knowledgeTagId)) {
      return "learn-color";
    }
  };

  const getKnowledgeNameExtraInfo = (knowledge, actualLearningProgress = [], learningProgress = []) => {
    const knLearningProgress = learningProgress?.find((kn) => kn.knowledge === knowledge._id);
    const knowledgeStrength = Math.round(knLearningProgress?.strength || 0);

    const actualKnLearningProgress = actualLearningProgress?.find((kn) => kn.knowledge === knowledge._id);

    const actualKnowledgeStrength = Math.round(actualKnLearningProgress?.strength || 0);
    const forgetRate = knLearningProgress?.forgetRate || 0;
    const showStrength = Math.round(knLearningProgress?.showStrength || 0);
    const know = Boolean(knLearningProgress?.know);

    return `${knowledgeStrength}, ${forgetRate.toFixed(2)}, (${actualKnowledgeStrength}, ${showStrength}${
      know ? ", " : ""
    }${knLearningProgress?.knowTimestamp ? new Date(knLearningProgress?.knowTimestamp).toLocaleDateString() : ""})`;
  };

  const getGroupKnowStatusClassName = (groupData, learningProgress) => {
    if (!learningProgress) return "";

    if (
      groupData.knowledgesData.every((knData) => {
        const knLearningProgress = learningProgress.find((kn) => kn.knowledge === knData.knowledge._id);
        return knLearningProgress?.know;
      })
    )
      return "known-color";

    return "";
  };

  const getGroupKnowDate = (groupData, learningProgress) => {
    if (!learningProgress) return "";

    const knowTimestamps = groupData.knowledgesData.map((knData) => {
      const knLearningProgress = learningProgress.find((kn) => kn.knowledge === knData.knowledge._id);
      if (knLearningProgress?.know && knLearningProgress.knowTimestamp) {
        return new Date(knLearningProgress.knowTimestamp).getTime();
      }
      return 0;
    });

    const minKnowTimestamp = Math.max(...knowTimestamps);
    return minKnowTimestamp ? new Date(minKnowTimestamp).toLocaleDateString() : 0;
  };

  const getGroupHeaderLabel = (groupData) => {
    if (getGroupKnowDate(groupData, unityUser?.learningProgress)) {
      return `${groupData.knowledgeGroup.name} (знає з ${getGroupKnowDate(groupData, unityUser?.learningProgress)})`;
    }
    return groupData.knowledgeGroup.name;
  };

  const getKnEventClassNameNormal = (knEvent) => {
    if (knEvent.type === "INCORRECT") {
      return "controlled-incorrect";
    }
    if (knEvent.type === "CORRECT") {
      return "controlled";
    }
    if (knEvent.type === "SHOW") {
      return "uncontrolled";
    }
  };

  const getKnEventColor = (knEvent) => {
    const colors = [
      { strengs: 95, color: "#8DBC03" },
      { strengs: 90, color: "#98C207" },
      { strengs: 85, color: "#A5C80C" },
      { strengs: 80, color: "#B1CE10" },
      { strengs: 75, color: "#BBD315" },
      { strengs: 70, color: "#CBD514" },
      { strengs: 65, color: "#E3D50C" },
      { strengs: 60, color: "#F6D407" },
      { strengs: 55, color: "#FFD007" },
      { strengs: 50, color: "#FEC90D" },
      { strengs: 45, color: "#FDC213" },
      { strengs: 40, color: "#FCBB1A" },
      { strengs: 35, color: "#FBAB21" },
      { strengs: 30, color: "#FA9628" },
      { strengs: 25, color: "#F9802F" },
      { strengs: 20, color: "#F94C3D" },
    ];

    const strength = knEvent.knowledgeStrength;
    const color = { backgroundColor: colors.find((c) => c.strengs === 20).color };

    for (let i = 95; i >= 20; i = i - 5) {
      if (strength > i) {
        color.backgroundColor = colors.find((c) => c.strengs === i).color;
        break;
      }
    }

    return color;
  };

  return (
    <div className={`statisticResultsView-component ${className}`}>
      <div className="filter-row">
        <div className="timestampScale-block">
          <div className="timestampScale-title">Масштаб</div>
          <StyledInput
            value={scale}
            onChange={(e) => {
              setGroupedTrackingKnowledges(recalculatePositionInfo(groupedTrackingKnowledges, e.target.value));
              setScale(e.target.value);
            }}
          />
        </div>

        <StyledButton
          disabled={false}
          className="recreate-button"
          title="Експорт то csv"
          onClick={() => handleExportToCsv()}
        />
        <div className="colorModeCheckbox-block">
          <Checkbox
            disabled={false}
            checked={coloredMode}
            onChange={() => {
              setColoredMode(!coloredMode);
            }}
            className=""
          />
          Розширена палітра
        </div>
      </div>
      <div className="groups">
        {groupLevels.map((level) => (
          <div className="level-block" key={level}>
            <div className="level-header">{`${level} Рівень`}</div>
            {groupedTrackingKnowledges
              .filter((groupData) => groupData.knowledgeGroup.level === level)
              .map((groupData) => (
                <div className="group-block" key={groupData.knowledgeGroup._id}>
                  <div
                    className={`group-header ${getGroupKnowStatusClassName(groupData, unityUser?.learningProgress)}`}
                  >
                    <div className="group-name">{getGroupHeaderLabel(groupData)}</div>
                  </div>
                  {groupData.knowledgesData.map((knData) => (
                    <div className="knowledge-row">
                      <div className={`knowledge-name-block ${getKnowledgeStateClassName(knData.knowledge._id)}`}>
                        <div className="knowledge-name">{knData.knowledge.name}</div>
                        <div className="knowledge-progress">
                          {getKnowledgeNameExtraInfo(
                            knData.knowledge,
                            knResultsRateBased.actualLearningProgress,
                            unityUser.learningProgress
                          )}
                        </div>
                      </div>

                      <div className="knowledge-elements">
                        {knData.data.map((knEvent) => {
                          return (
                            <div
                              className={`knowledge-element ${!coloredMode ? getKnEventClassNameNormal(knEvent) : ""}`}
                              style={{ ...(coloredMode ? getKnEventColor(knEvent) : {}), ...knEvent.style }}
                            ></div>
                          );
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default StatisticResultsView;
