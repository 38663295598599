import { useCallback, useState } from "react";

const useImageSize = (initSize) => {
  const [size, setSize] = useState(initSize ? initSize : null);
  const updateSizeByPath = useCallback((imagePath) => {
    imagePath && getImageSize(imagePath).then((res) => setSize(res));
    imagePath == null && setSize(null);
  }, []);
  return [size, updateSizeByPath];
};

const getImageSize = (imagePath) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({
        width: img.naturalWidth,
        height: img.naturalHeight,
      });
    };
    img.onerror = (err) => {
      resolve({
        width: 0,
        height: 0,
      });
    };
    img.src = imagePath;
  });

const getFileSizeKb = (imagePath) => {
  return fetch(imagePath)
    .then((res) => res.blob())
    .then((blob) => blob.size / 1000)
    .catch((e) => 0);
};

export { useImageSize, getImageSize, getFileSizeKb };
