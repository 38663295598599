import React from "react";
import Tooltip from "@mui/material/Tooltip";

import "./RoundButton.scss";

const RoundButton = ({ icon, tooltipTitle = "кнопка", tooltipPlacement = "bottom", disabled, className, onClick }) => {
  const Icon = icon;
  return (
    <Tooltip title={tooltipTitle} placement={tooltipPlacement} arrow>
      <div className={`round-button ${className}`} disabled={disabled} onClick={(event) => {
        event.preventDefault();
        onClick(event)
      }}>
        <Icon disabled={disabled} className="round-button-icon" />
      </div>
    </Tooltip>
  );
};
export default RoundButton;
