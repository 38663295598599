import "./AssetBundleEditor.scss";
import React, { useState, useMemo, useEffect } from "react";

import {
  AlertDialog,
  StyledButton,
  CardStyled,
  Tabs,
  StageItemLibrary,
  ImageLibrary,
  Header,
  Layers,
  StageTemplateLibrary,
} from "components";
import _ from "lodash";
import { v4 as newStageItemId } from "uuid";
import { useNavigate } from "react-router-dom";
import { useBufferContext, useResourcesContext } from "resources";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { ReactComponent as BigPlusIcon } from "assets/icons/bigPlus.svg";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { CircularProgress } from "@mui/material";
import useUndoRedoBuffer from "helpers/useUndoRedoBuffer";
import { findParentAndItem, getItemsWithChildrenPlain } from "helpers/stageItems";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import KnowledgeCard from "./KnowledgeCard/KnowledgeCard";
import ScenesBar from "./ScenesBar/ScenesBar";
import AssetBundleCommonConfig from "./AssetBundleCommonConfig/AssetBundleCommonConfig";
import AssetBundleActiveItem from "./AssetBundleActiveItem/AssetBundleActiveItem";
import AssetBundleMainScreen from "./AssetBundleMainScreen/AssetBundleMainScreen";
import AssetBundleCard from "./AssetBundleCard/AssetBundleCard";
import withIdParam from "helpers/withIdParam";
import { createIconFromDomElement } from "helpers/createIconFromDomElement";
import { getSoundBySoundSetId } from "helpers/soundLanguage";
import { LazyLoadComponent } from "react-lazy-load-image-component";

const createSceneItem = (selectedImage) => {
  let sceneItem = {
    stageItemId: newStageItemId(),
    _id: selectedImage._id,
    valueX: 0,
    valueY: 0,
    scale: 100,
    scaleX: 100,
    scaleY: 100,
    angle: 0,
    flipHorizontal: false,
    flipVertical: false,
    blur: 0,
    opacity: 100,
    items: [],
    sounds: { main: "" },
  };

  return sceneItem;
};

const assetBundleStructure = {
  _id: "",
  name: "",
  icon: "",
  scenes: [{ items: [] }],
  knowledges: [{ soundSet: "", wrongSoundSets: [], items: [] }],
  sounds: { startSoundSet: "", endSoundSet: "", correctSoundSets: [], incorrectSoundSets: [] },
};

const AssetBundleEditor = ({ id }) => {
  const [assetBundle, setAssetBundle] = useState();
  const [selectedKnowledgeIdx, setSelectedKnowledgeIdx] = useState(-1);
  const [selectedSceneIdx, setSelectedSceneIdx] = useState(0);

  const [selectedKnowledgeItemStageItemId, setSelectedKnowledgeItemStageItemId] = useState();
  const [activeItemData, setActiveItemData] = useState({ type: "", stageItemId: null });

  const [knowledgeItemPlaceholderIdx, setKnowledgeItemPlaceholderIdx] = useState(0);

  const [showCommonConfig, setShowCommonConfig] = useState(false);
  const [alertDialogData, setAlertDialogData] = useState(null);

  const [activeTab, setActiveTab] = useState("Бібліотека");
  const tabs = ["Бібліотека", "Слої", "Групи", "Сцени"];

  const [getingAssetBundle, setGetingAssetBundle] = useState(false);
  const [creatingSceneIcon, setCreatingSceneIcon] = useState(false);

  const [soundListExtra, setSoundListExtra] = useState([]);

  const [usedOtherSounds, setUsedOtherSounds] = useState([]);
  const [usedKnowledgeSounds, setUsedKnowledgeSounds] = useState([]);
  const [usedWrongSounds, setUsedWrongSounds] = useState([]);

  const [availableOtherSounds, setAvailableOtherSounds] = useState([]);
  const [availableKnowledgeSounds, setAvailableKnowledgeSounds] = useState([]);

  const { addToUndoBuffer, init: initUndoBufer, redo: getRedoData, undo: getUndoData } = useUndoRedoBuffer();

  const navigate = useNavigate();

  const { itemCopyPastBuffer, setItemCopyPastBuffer } = useBufferContext();

  const {
    soundListStore: { soundList, getSoundList },
    soundSetListStore: { soundSetList, getSoundSetList },
    tagListStore: { tagList, getTagList },

    imageListStore: { getImageList, getImagePathById },
    assetBundleListStore: {
      fetchingOne,
      isNewAssetBundle,
      isEditedAssetBundle,
      getAssetBundleForEditing,
      updateEditedAssetBundle,
      copyAssetBundle,
      deleteAssetBundle,
      deleteAssetBundleFromEditedList,
      saveAssetBundle,
      getAssetBundleList,
      getAssetBundleExtraDataList,
    },
    itemTemplatesStore: { getItemTemplatesList },
    stageTemplatesStore: { getStageTemplatesList, createStageTemplate },
  } = useResourcesContext();

  useEffect(() => {
    getTagList();
    getImageList();
    getSoundList();
    getSoundSetList();
    getAssetBundleList();
    getAssetBundleExtraDataList();
    getItemTemplatesList();
    getStageTemplatesList();
  }, []);

  useEffect(() => {
    if (!soundList.length || !soundSetList.length) return;

    const soundsIds = soundSetList.flatMap((soundSet) => soundSet.sounds);
    const filteredSoundList = soundList.filter((sound) => soundsIds.includes(sound._id));

    const soundListExtra = filteredSoundList.map((sound) => {
      const soundSet = soundSetList.find((soundSet) => soundSet.sounds.includes(sound._id));
      return { ...sound, soundSetType: soundSet.type, soundSetId: soundSet._id };
    });

    setSoundListExtra(soundListExtra);
  }, [soundSetList, soundList]);

  useEffect(() => {
    if (!soundListExtra.length || !soundSetList.length) return;

    const availableKnowledgeSounds = soundSetList
      .filter((soundSet) => soundSet.type === "knowledge")
      .map((soundSet) => getSoundBySoundSetId(soundSet._id, soundListExtra));

    setAvailableKnowledgeSounds(availableKnowledgeSounds);
  }, [soundSetList, soundListExtra]);

  useEffect(() => {
    if (!soundListExtra.length || !soundSetList.length) return;

    const availableOtherSounds = soundSetList
      .filter((soundSet) => soundSet.type !== "knowledge")
      .map((soundSet) => getSoundBySoundSetId(soundSet._id, soundListExtra));

    setAvailableOtherSounds(availableOtherSounds);
  }, [soundSetList, soundListExtra]);

  useEffect(() => {
    if (!assetBundle || !soundListExtra.length) return;

    const usedKnowledgeSoundSets = assetBundle.knowledges.map((knowledge) => knowledge.soundSet).filter(Boolean);
    const usedWrongSoundSets = assetBundle.knowledges.flatMap((knowledge) => knowledge.wrongSoundSets).filter(Boolean);

    const usedOtherSoundSets = [
      assetBundle.sounds.startSoundSet,
      assetBundle.sounds.endSoundSet,
      ...assetBundle.sounds.correctSoundSets,
      ...assetBundle.sounds.incorrectSoundSets,
    ].filter(Boolean);

    setUsedKnowledgeSounds(
      usedKnowledgeSoundSets.map((soundSetId) => getSoundBySoundSetId(soundSetId, soundListExtra)).filter(Boolean)
    );
    setUsedWrongSounds(
      usedWrongSoundSets.map((soundSetId) => getSoundBySoundSetId(soundSetId, soundListExtra)).filter(Boolean)
    );
    setUsedOtherSounds(
      usedOtherSoundSets.map((soundSetId) => getSoundBySoundSetId(soundSetId, soundListExtra)).filter(Boolean)
    );
  }, [soundListExtra, assetBundle]);

  useEffect(() => {
    if (id && id !== assetBundle?._id && !getingAssetBundle) {
      setGetingAssetBundle(true);
      getAssetBundleForEditing(id).then(({ assetBundle, extraData }) => {
        setAssetBundle(assetBundle);
        setGetingAssetBundle(false);
        initUndoBufer(assetBundle);
      });
    }
  }, [id, getAssetBundleForEditing, initUndoBufer, assetBundle, getingAssetBundle]);

  const selectedKnowledge = useMemo(() => {
    return selectedKnowledgeIdx >= 0 && assetBundle && assetBundle.knowledges.length > selectedKnowledgeIdx
      ? assetBundle.knowledges[selectedKnowledgeIdx]
      : null;
  }, [selectedKnowledgeIdx, assetBundle]);

  const allKnowledgesItems = useMemo(() => {
    if (!assetBundle) {
      return [];
    }
    return assetBundle.knowledges.reduce((result, knowledge) => [...result, ...knowledge.items], []);
  }, [assetBundle]);

  const selectedScene = useMemo(() => {
    return selectedSceneIdx >= 0 && assetBundle && assetBundle.scenes.length > selectedSceneIdx
      ? assetBundle.scenes[selectedSceneIdx]
      : null;
  }, [selectedSceneIdx, assetBundle]);

  const scenePlaceholders = useMemo(() => {
    return selectedScene?.items ? selectedScene.items.filter((sceneItem) => sceneItem.type === "placeholder") : [];
  }, [selectedScene]);

  const assetBundleLocked = false;

  const assetBundleIsEdited = useMemo(() => {
    return assetBundle ? isEditedAssetBundle(assetBundle._id) : false;
  }, [isEditedAssetBundle, assetBundle]);

  const assetBundleIsNew = useMemo(() => {
    return assetBundle ? isNewAssetBundle(assetBundle._id) : false;
  }, [isNewAssetBundle, assetBundle]);

  const activeItem = useMemo(() => {
    switch (activeItemData.type) {
      case "knowledgeItem":
        return allKnowledgesItems.length
          ? allKnowledgesItems.find((knowledgeItem) => knowledgeItem.stageItemId === activeItemData.stageItemId)
          : null;
      default:
        return selectedScene?.items?.length
          ? selectedScene.items.find((sceneItem) => sceneItem.stageItemId === activeItemData.stageItemId)
          : null;
    }
  }, [activeItemData, selectedScene, allKnowledgesItems]);

  useEffect(() => {
    if (assetBundle && assetBundle.knowledges.length && selectedKnowledgeIdx === -1) {
      setSelectedKnowledgeIdx(0);
      const newSelectedKnowledgeStageItemId = assetBundle.knowledges[0].items[0]?.stageItemId || "";
      setSelectedKnowledgeItemStageItemId(newSelectedKnowledgeStageItemId);
      newSelectedKnowledgeStageItemId &&
        setActiveItemData({ type: "knowledgeItem", stageItemId: newSelectedKnowledgeStageItemId });
    }
  }, [assetBundle, selectedKnowledgeIdx, allKnowledgesItems]);

  const validateAssetBundle = (bundle) => {
    const errors = [];

    return errors;
  };

  const handleSaveAssetBundle = async () => {
    const bundleErrors = validateAssetBundle(assetBundle);
    if (bundleErrors.length) {
      bundleErrors.forEach((error, index) => toast.error(error, { toastId: index }));
      return;
    }

    const { assetBundle: savedAssetBundle } = await saveAssetBundle(assetBundle);
    await getImageList();

    if (savedAssetBundle) {
      if (assetBundle._id === savedAssetBundle._id) {
        setAssetBundle({
          ...savedAssetBundle,
        });

        initUndoBufer(savedAssetBundle);
      }
      navigate(`/assetBundle-editor/${savedAssetBundle._id}`, { replace: true });
    }

    if (!savedAssetBundle) {
      toast.error("Не вдалося зберегти зміни. Виникла помилка.", { toastId: "1" });
    }
  };

  const handleAssetBundleChange = (newAssetBundleConfig) => {
    setAssetBundle(newAssetBundleConfig);
    updateEditedAssetBundle(newAssetBundleConfig);
    addToUndoBuffer(newAssetBundleConfig);
  };

  const addItemToSelectedScene = (item) => {
    if (!selectedScene) {
      return;
    }
    const newSceneItems = [...(selectedScene.items || []), item];
    const newScene = { ...selectedScene, items: newSceneItems };
    const newScenes = assetBundle.scenes.map((scene, index) => (index === selectedSceneIdx ? newScene : { ...scene }));
    const newAssetBundle = { ...assetBundle, scenes: newScenes };
    handleAssetBundleChange(newAssetBundle);
    setActiveItemData({ type: "", stageItemId: item.stageItemId });
  };

  const handleLibraryImageSelect = (libraryImage) => {
    if (!selectedScene) {
      return;
    }

    if (libraryImage) {
      const imageHasTag = (image, tagName) => {
        return image.tags.some((imageTag) => {
          const imageTagName = tagList.find((tag) => tag._id === imageTag)?.name;
          return imageTagName === tagName;
        });
      };

      if (imageHasTag(libraryImage, "background")) {
        const newBackground = { _id: libraryImage._id, stageItemId: newStageItemId() };
        const newSceneItems = [newBackground, ...(selectedScene.items || []).slice(1)];

        !newSceneItems.some((item) => item.type === "placeholder") &&
          newSceneItems.push({
            stageItemId: newStageItemId(),
            type: "placeholder",
            valueX: 0,
            valueY: 0,
          });

        const newScene = { ...selectedScene, items: newSceneItems };
        const newScenes = assetBundle.scenes.map((scene, index) =>
          index === selectedSceneIdx ? newScene : { ...scene }
        );
        const newAssetBundle = { ...assetBundle, scenes: newScenes };
        handleAssetBundleChange(newAssetBundle);
        return;
      }

      if (selectedScene.items.length) {
        const newSceneItem = createSceneItem(libraryImage);
        addItemToSelectedScene(newSceneItem);
        return;
      }
    }
  };

  const handleUndoAssetBundle = () => {
    const { data } = getUndoData();
    if (data) {
      handleAssetBundleChange(data, { passUndoBufer: true });
    } else {
      if (!assetBundleIsNew) {
        deleteAssetBundleFromEditedList(assetBundle._id);
      }
    }
  };

  const handleRedoAssetBundle = () => {
    const { data } = getRedoData();
    if (data) {
      handleAssetBundleChange(data, { passUndoBufer: true });
    }
  };

  const handleUndoAssetBundleToOriginal = async () => {
    deleteAssetBundleFromEditedList(assetBundle._id);
    setAssetBundle({ ...assetBundle, _id: "" });
  };

  const handleAssetBundleEditorKeyUp = (event) => {
    const redoKeys = ["y", "Y", "н", "Н"];
    (event.ctrlKey || event.metaKey) && redoKeys.includes(event.key) && handleRedoAssetBundle();

    const undoKeys = ["z", "Z", "я", "Я"];
    (event.ctrlKey || event.metaKey) && undoKeys.includes(event.key) && handleUndoAssetBundle();
  };

  const handleCommonConfigChange = (newAssetBundle) => {
    handleAssetBundleChange(newAssetBundle);
  };

  const handleDeleteKnowledge = (knowledgeIndex) => {
    const newKnowledges = [...assetBundle.knowledges.filter((knowledge, index) => index !== knowledgeIndex)];

    let newSelectedKnowledgeIdx = -1;

    if (selectedKnowledgeIdx < knowledgeIndex) {
      newSelectedKnowledgeIdx = selectedKnowledgeIdx;
    } else if (selectedKnowledgeIdx === 0 && knowledgeIndex === 0 && newKnowledges.length) {
      newSelectedKnowledgeIdx = selectedKnowledgeIdx;
    } else if (selectedKnowledgeIdx > knowledgeIndex) {
      newSelectedKnowledgeIdx = selectedKnowledgeIdx - 1;
    } else if (selectedKnowledgeIdx === knowledgeIndex && knowledgeIndex !== 0) {
      newSelectedKnowledgeIdx = 0;
    } else if (selectedKnowledgeIdx === knowledgeIndex && knowledgeIndex === 0 && !newKnowledges.length) {
      newSelectedKnowledgeIdx = -1;
    }

    if (newSelectedKnowledgeIdx === -1) {
      setSelectedKnowledgeItemStageItemId(null);
    } else {
      const selectedKnowledgeIncludesSelectedItem = newKnowledges[newSelectedKnowledgeIdx].items.some(
        (item) => item.stageItemId === selectedKnowledgeItemStageItemId
      );

      !selectedKnowledgeIncludesSelectedItem &&
        setSelectedKnowledgeItemStageItemId(
          newKnowledges[newSelectedKnowledgeIdx].items.length
            ? newKnowledges[newSelectedKnowledgeIdx].items[0].stageItemId
            : null
        );
    }
    setSelectedKnowledgeIdx(newSelectedKnowledgeIdx);
    handleAssetBundleChange({ ...assetBundle, knowledges: newKnowledges });
  };

  const handleAddKnowledge = () => {
    const newKnowledge = { items: [], soundSet: "", wrongSoundSets: [] };
    const newKnowledges = [...assetBundle.knowledges, newKnowledge];
    handleAssetBundleChange({ ...assetBundle, knowledges: newKnowledges });
    setSelectedKnowledgeIdx(newKnowledges.length - 1);
    //setSelectedKnowledgeItemIdx(null);
  };

  const moveKnowledgeUp = (knowledge, knowledgeIndex) => {
    const newIndex = knowledgeIndex - 1;
    const knowledges = assetBundle.knowledges;
    const newKnowledges = [
      ...knowledges.slice(0, newIndex),
      knowledge,
      knowledges[newIndex],
      ...knowledges.slice(knowledgeIndex + 1),
    ];
    handleAssetBundleChange({ ...assetBundle, knowledges: newKnowledges });
    setSelectedKnowledgeIdx(newIndex);
  };

  const moveKnowledgeDown = (knowledge, knowledgeIndex) => {
    const newIndex = knowledgeIndex + 1;
    const knowledges = assetBundle.knowledges;
    const newKnowledges = [
      ...knowledges.slice(0, knowledgeIndex),
      knowledges[newIndex],
      knowledge,
      ...knowledges.slice(newIndex + 1),
    ];
    handleAssetBundleChange({ ...assetBundle, knowledges: newKnowledges });
    setSelectedKnowledgeIdx(newIndex);
  };

  const handleCreateSceneFromTemplate = (template) => {
    const newScene = _.cloneDeep(template);
    newScene.items = newScene.items || [];

    if (newScene.items.length && !newScene.items.some((item) => item.type === "placeholder")) {
      newScene.items.push({
        stageItemId: newStageItemId(),
        type: "placeholder",
        valueX: 0,
        valueY: 0,
      });
    }

    getItemsWithChildrenPlain(newScene.items).forEach((item) => (item.stageItemId = newStageItemId()));
    handleAddScene(newScene);
  };

  const handleItemTemplateClick = (itemTemplate) => {
    const newItem = _.cloneDeep(itemTemplate);
    renewStageItemIdRecursive(newItem);
    addItemToSelectedScene(newItem);
  };

  const handlePressItemTemplateCopy = (itemTemplate) => {};

  const mapItemsAndUpdateOne = (items, newItem) => {
    return items.map((sceneItem) => {
      if (sceneItem.stageItemId === newItem.stageItemId) {
        return newItem;
      } else {
        const newSceneItem = { ...sceneItem };
        newSceneItem.items = mapItemsAndUpdateOne(newSceneItem.items || [], newItem);
        return newSceneItem;
      }
    });
  };

  const renewStageItemIdRecursive = (item) => {
    item.stageItemId = newStageItemId();
    item.items?.forEach((childItem) => renewStageItemIdRecursive(childItem));
  };

  const handleSceneItemChange = (newItemObject, options = {}) => {
    if (!selectedScene) {
      return;
    }
    const newSceneItems = mapItemsAndUpdateOne(selectedScene.items || [], newItemObject);
    const newScene = { ...selectedScene, items: newSceneItems };
    handleSceneChange(newScene);
  };

  const handleSceneChange = (newScene) => {
    if (!selectedScene) {
      return;
    }

    const newScenes = assetBundle.scenes.map((scene, index) => (index === selectedSceneIdx ? newScene : { ...scene }));
    const newAssetBundle = { ...assetBundle, scenes: newScenes };
    handleAssetBundleChange(newAssetBundle);
  };

  const handleKnowledgeItemChange = (newKnowledgeItem, options = {}) => {
    const newKnowledgeItems = selectedKnowledge.items.map((knowledgeItem, index) =>
      knowledgeItem.stageItemId === newKnowledgeItem.stageItemId ? newKnowledgeItem : { ...knowledgeItem }
    );
    const newKnowledge = { ...selectedKnowledge, items: newKnowledgeItems };
    const newKnowledges = assetBundle.knowledges.map((knowledge, index) =>
      index === selectedKnowledgeIdx ? newKnowledge : { ...knowledge }
    );
    const newAssetBundle = { ...assetBundle, knowledges: newKnowledges };
    handleAssetBundleChange(newAssetBundle, options);
  };

  const resetActiveItemData = () => setActiveItemData({ type: "", stageItemId: null });

  const handleSelectScene = (sceneIndex) => {
    if (sceneIndex !== selectedSceneIdx) {
      activeItemData.type !== "knowledgeItem" && resetActiveItemData();

      const newScenePlaceholders = assetBundle.scenes[sceneIndex].items
        ? assetBundle.scenes[sceneIndex].items.filter((sceneItem) => sceneItem.type === "placeholder")
        : [];

      if (knowledgeItemPlaceholderIdx > newScenePlaceholders.length - 1) {
        setKnowledgeItemPlaceholderIdx(0);
      }
    }
    setSelectedSceneIdx(sceneIndex);
  };

  const handleAddScene = (scene) => {
    const newScenes = [...assetBundle.scenes, scene || { items: [] }];
    const newAssetBundle = { ...assetBundle, scenes: newScenes };

    handleAssetBundleChange(newAssetBundle);
    setSelectedSceneIdx(newAssetBundle.scenes.length - 1);
  };

  const handleDeleteScene = (sceneIndex) => {
    const newScenes = assetBundle.scenes.map((scene, index) => index !== sceneIndex && scene).filter(Boolean);
    const newAssetBundle = { ...assetBundle, scenes: newScenes };
    handleAssetBundleChange(newAssetBundle);
    if (newScenes.length) {
      selectedSceneIdx > 0 && setSelectedSceneIdx(selectedSceneIdx - 1);
    } else {
      setSelectedSceneIdx(-1);
    }
  };

  const handleCreateBundleIcon = (sceneIndex) => {
    if (selectedScene) {
      setCreatingSceneIcon(true);
      const sceneMainScreen = document.getElementById(`stageMainScreen`);
      const backgroundImage = document.getElementById("stageMainScreenBackgroundImage");
      const scale1920 = backgroundImage && backgroundImage.clientWidth ? 1920 / backgroundImage.clientWidth : 1;

      createIconFromDomElement(sceneMainScreen, 0.23 * scale1920).then((iconBlob) => {
        if (iconBlob) {
          handleAssetBundleChange({ ...assetBundle, icon: iconBlob });
          getImageList();
        }
        setCreatingSceneIcon(false);
      });
    }
  };

  const handleAddSceneToLibrary = (sceneIndex) => {
    if (selectedScene) {
      setCreatingSceneIcon(true);
      const sceneCopy = _.cloneDeep(selectedScene);
      const sceneCopyItemsPlain = getItemsWithChildrenPlain(sceneCopy.items || []);
      sceneCopyItemsPlain.forEach((item, index) => {
        item.stageItemId = "";
      });

      const sceneMainScreen = document.getElementById(`stageMainScreen`);
      const backgroundImage = document.getElementById("stageMainScreenBackgroundImage");
      const scale1920 = backgroundImage && backgroundImage.clientWidth ? 1920 / backgroundImage.clientWidth : 1;
      createIconFromDomElement(sceneMainScreen, 0.15 * scale1920).then((iconBlob) => {
        iconBlob &&
          createStageTemplate({ template: { items: sceneCopy.items }, icon: iconBlob }).then(() => {
            getImageList();
            setCreatingSceneIcon(false);
          });
        !iconBlob && setCreatingSceneIcon(false);
      });
    }
  };

  const handleNextKnowledgeProps = () => {
    if (allKnowledgesItems.length) {
      const knowledgeItemIndex = allKnowledgesItems.findIndex(
        (item) => item.stageItemId === selectedKnowledgeItemStageItemId
      );
      const newIndex = knowledgeItemIndex < allKnowledgesItems.length - 1 ? knowledgeItemIndex + 1 : 0;
      setSelectedKnowledgeItemStageItemId(allKnowledgesItems[newIndex].stageItemId);
      setActiveItemData({ type: "knowledgeItem", stageItemId: allKnowledgesItems[newIndex].stageItemId });
    }
  };

  const handleAddPlaceholder = () => {
    if (selectedScene) {
      const newItems = [
        ...selectedScene.items,
        {
          stageItemId: newStageItemId(),
          type: "placeholder",
          valueX: Math.random() * 50 - Math.random() * 50,
          valueY: Math.random() * 50 - Math.random() * 50,
        },
      ];
      const newScene = { ...selectedScene, items: newItems };
      const newScenes = assetBundle.scenes.map((scene, index) =>
        index === selectedSceneIdx ? newScene : { ...scene }
      );
      const newAssetBundle = { ...assetBundle, scenes: newScenes };
      handleAssetBundleChange(newAssetBundle);
    }
  };

  const handleNextPlaceholder = () => {
    if (scenePlaceholders.length) {
      if (selectedScene && knowledgeItemPlaceholderIdx < scenePlaceholders.length - 1) {
        setKnowledgeItemPlaceholderIdx(knowledgeItemPlaceholderIdx + 1);
      } else {
        setKnowledgeItemPlaceholderIdx(0);
      }
    }
  };

  const handleNextScene = () => {
    if (assetBundle.scenes.length) {
      if (selectedSceneIdx < assetBundle.scenes.length - 1) {
        handleSelectScene(selectedSceneIdx + 1);
      } else {
        handleSelectScene(0);
      }
    }
  };

  const handleAddActiveItemToKnowledge = () => {
    if (
      selectedKnowledge &&
      selectedScene &&
      activeItem &&
      activeItem.type !== "knowledgeItem" &&
      activeItem.type !== "placeholder"
    ) {
      const newKnowledgeItem = {
        type: "knowledgeItem",
        _id: activeItem._id,
        stageItemId: activeItem.stageItemId,
        valueX: 0,
        valueY: 0,
        scale: activeItem.scale,
        scaleX: activeItem.scaleX,
        scaleY: activeItem.scaleY,
        angle: activeItem.angle,
        flipHorizontal: activeItem.flipHorizontal,
        flipVertical: activeItem.flipVertical,
        opacity: activeItem.opacity,
        items: activeItem.items || [],
      };

      const newKnowLedgeItems = [...(selectedKnowledge.items || []), newKnowledgeItem];
      const newKnowledge = { ...selectedKnowledge, items: newKnowLedgeItems };
      const newKnowledges = assetBundle.knowledges.map((knowledge, index) =>
        index === selectedKnowledgeIdx ? newKnowledge : { ...knowledge }
      );

      const newSceneItems = [
        ...(selectedScene.items || []).filter(
          (sceneItem, index) => sceneItem.stageItemId !== activeItemData.stageItemId
        ),
      ];
      const newScene = { ...selectedScene, items: newSceneItems };
      const newScenes = assetBundle.scenes.map((scene, index) =>
        index === selectedSceneIdx ? newScene : { ...scene }
      );

      const newAssetBundle = { ...assetBundle, knowledges: newKnowledges, scenes: newScenes };

      setActiveItemData({ type: "knowledgeItem", stageItemId: newKnowledgeItem.stageItemId });
      setSelectedKnowledgeItemStageItemId(newKnowledgeItem.stageItemId);
      handleAssetBundleChange(newAssetBundle);
    }
  };

  const handleLayersItemClick = (stageItemId) => {
    const { item } = findParentAndItem(selectedScene || [], stageItemId);
    setActiveItemData({ type: item.type, stageItemId });
  };

  const handlePressLayerItemCopy = (item) => {
    item && setItemCopyPastBuffer(_.cloneDeep(item));
  };

  const handlePressPaste = () => {
    const itemClone = _.cloneDeep(itemCopyPastBuffer);
    if (itemClone) {
      itemClone.valueX = itemClone.valueX + Math.random() * 40 - 40;
      itemClone.valueY = itemClone.valueY + Math.random() * 40 - 40;
      handleItemTemplateClick(itemClone);
    }
  };

  const handlePressCopy = () => {
    activeItem && activeItem.type !== "knowledgeItem" && setItemCopyPastBuffer(_.cloneDeep(activeItem));
  };

  const handleDropLayersItem = (destParentId, destIndex, dropedStageItemId) => {
    if (destParentId === dropedStageItemId) {
      return;
    }

    const { item: destItem } =
      (destParentId === "root" && { item: selectedScene }) || findParentAndItem(selectedScene, destParentId) || {};
    const { parent: sourceItem, item: dropedItem } = findParentAndItem(selectedScene, dropedStageItemId) || {};

    if (findParentAndItem(dropedItem, destItem.stageItemId)) {
      return;
    }

    const reverseDestIndex = (destItem.items || []).length - destIndex;
    // stage has else one leyer with index 0 but its not showing in the list - background
    const realDestIndex = destParentId === "root" ? reverseDestIndex : reverseDestIndex;
    const destPartOne = !destItem.items ? [] : destItem.items.slice(0, realDestIndex);
    const destPartTwo =
      !destItem.items || realDestIndex === destItem.items.length ? [] : destItem.items.splice(realDestIndex);
    const destNewItems = [...destPartOne, dropedItem, ...destPartTwo];

    if (destItem === sourceItem) {
      const oldItemIndex = destNewItems.findIndex((item, index) => item === dropedItem && index !== realDestIndex);
      destNewItems.splice(oldItemIndex, 1);
      destItem.items = destNewItems;
    } else {
      const sourceNewItems = sourceItem.items.map((item) => item !== dropedItem && item).filter(Boolean);
      sourceItem.items = sourceNewItems;
      destItem.items = destNewItems;
    }

    const newScenes = assetBundle.scenes.map((scene, index) =>
      index === selectedSceneIdx ? { ...selectedScene } : { ...scene }
    );
    const newAssetBundle = { ...assetBundle, scenes: newScenes };
    handleAssetBundleChange(newAssetBundle);
  };

  const deleteActiveItem = () => {
    if (selectedScene && activeItem && activeItemData.type !== "knowledgeItem") {
      const { parent, index } = findParentAndItem(selectedScene, activeItem.stageItemId);
      parent.items.splice(index, 1);
      const newSceneItems = [...selectedScene.items];
      const newScene = { ...selectedScene, items: newSceneItems };
      const newScenes = assetBundle.scenes.map((scene, index) =>
        index === selectedSceneIdx ? newScene : { ...scene }
      );
      const newAssetBundle = { ...assetBundle, scenes: newScenes };
      handleAssetBundleChange(newAssetBundle);
      resetActiveItemData();
    }
    if (selectedKnowledge && activeItem && activeItemData.type === "knowledgeItem") {
      const newKnowledgeItems = [
        ...selectedKnowledge.items.filter((knowledgeItem) => knowledgeItem.stageItemId !== activeItem.stageItemId),
      ];
      const newKnowledge = { ...selectedKnowledge, items: newKnowledgeItems };

      const newKnowledges = assetBundle.knowledges.map((knowledge, index) =>
        index === selectedKnowledgeIdx ? newKnowledge : { ...knowledge }
      );
      const newAssetBundle = { ...assetBundle, knowledges: newKnowledges };
      handleAssetBundleChange(newAssetBundle);

      resetActiveItemData();
    }

    if (selectedKnowledgeItemStageItemId === activeItemData.stageItemId) {
      setSelectedKnowledgeItemStageItemId("");
    }
  };

  const handleCopyAssetBundle = async () => {
    const copyedAssetBundle = await copyAssetBundle(assetBundle);
    const newIcon =
      typeof assetBundle.icon === "object"
        ? assetBundle.icon
        : assetBundle.icon
        ? await fetch(getImagePathById(assetBundle.icon)).then((res) => res.blob())
        : "";
    copyedAssetBundle.icon = newIcon;

    navigate(`/assetBundle-editor/${copyedAssetBundle._id}`);
  };

  const openDeleteAssetBundleDialog = () => {
    setAlertDialogData({
      onOk: async () => {
        setAlertDialogData(null);
        await deleteAssetBundle(assetBundle._id);
        navigate("/assetBundles");
      },
      onCancel: () => setAlertDialogData(null),
      title: "Видалити бандл",
      message: "Натиснить Ok для видалення бандла, або Cancel для відміни операції.",
    });
  };

  const handleKnowledgeCardChange = (newKnowledge, newKnowledgeIndex) => {
    const newKnowledges = assetBundle.knowledges.map((knowledge, index) =>
      index === newKnowledgeIndex ? newKnowledge : { ...knowledge }
    );
    const newAssetBundle = { ...assetBundle, knowledges: newKnowledges };
    handleAssetBundleChange(newAssetBundle);
  };

  const handleKnowledgeCardClick = (knowledge, knowledgeIndex) => {
    if (!knowledge.items.some((item) => item.stageItemId === selectedKnowledgeItemStageItemId)) {
      if (knowledge.items.length) {
        setSelectedKnowledgeItemStageItemId(knowledge.items[0].stageItemId);
        setActiveItemData({
          type: "knowledgeItem",
          stageItemId: knowledge.items[0].stageItemId,
        });
      } else {
        setSelectedKnowledgeItemStageItemId("");
        setActiveItemData({ type: "", stageItemId: "" });
      }
    }
    setSelectedKnowledgeIdx(knowledgeIndex);
    setShowCommonConfig(false);
  };

  const handleKnowledgeItemClick = (stageItemId, knowledgeIndex) => {
    if (stageItemId !== selectedKnowledgeItemStageItemId) {
      setSelectedKnowledgeItemStageItemId(stageItemId);
      setActiveItemData({
        type: "knowledgeItem",
        stageItemId: stageItemId,
      });
    } else {
      setSelectedKnowledgeItemStageItemId("");
      setActiveItemData({ type: "", stageItemId: "" });
    }
    setSelectedKnowledgeIdx(knowledgeIndex);
    setShowCommonConfig(false);
  };

  return (
    <>
      <div className="assetBundleEditor-wrapper" tabIndex={-1} onKeyUp={handleAssetBundleEditorKeyUp}>
        {!assetBundle && (
          <div className="no-assetBundle-content-wrapper">
            <Header className="assetBundleEditor-header" />
            <CardStyled className="no-assetBundle-content-left" />
            <CardStyled className="no-assetBundle-content-right">
              <div className="progressFetchingAssetBundle">
                <span className="progressFetchingAssetBundleLabel">{"Завантаження ..."}</span>
                <CircularProgress size={400} style={{ color: "#FAA400" }} />
              </div>
            </CardStyled>
          </div>
        )}
        {assetBundle && (
          <div className="content-wrapper">
            <Header className="assetBundleEditor-header" />
            <div className="content-left-wrapper">
              <CardStyled className="card-and-knowledges-wrapper">
                <div className="assetBundle-card-wrapper">
                  <AssetBundleCard
                    assetBundle={assetBundle}
                    usedOtherSounds={usedOtherSounds}
                    availableOtherSounds={availableOtherSounds}
                    selected={showCommonConfig}
                    onClick={() => setShowCommonConfig(!showCommonConfig)}
                    onSave={handleSaveAssetBundle}
                    onUndo={handleUndoAssetBundleToOriginal}
                    onChange={handleAssetBundleChange}
                    handleAddActiveItemToKnowledge
                    isLocked={assetBundleLocked}
                    isEdited={assetBundleIsEdited}
                    isNew={assetBundleIsNew}
                    showFetchingAssetBundleProgress={fetchingOne || getingAssetBundle}
                    showIconProgress={false}
                  />
                </div>
                <StyledButton
                  title="Додати знання"
                  className="customize-button knowledge-add-btn"
                  onClick={handleAddKnowledge}
                  disabled={assetBundleLocked}
                >
                  <PlusIcon className="plus-icon" />
                </StyledButton>
                <div className="knowledges-wrapper">
                  {assetBundle.knowledges.map((knowledge, index) => (
                    <LazyLoadComponent>
                      <div key={index} className="knowledge-card-with-updown">
                        {!showCommonConfig && (
                          <>
                            {index !== 0 && (
                              <StyledButton
                                disabled={assetBundleLocked}
                                className="customize-button moveUp"
                                onClick={(e) => {
                                  moveKnowledgeUp(knowledge, index);
                                  e.stopPropagation();
                                }}
                              >
                                <KeyboardDoubleArrowUpIcon />
                              </StyledButton>
                            )}
                            {index !== assetBundle.knowledges.length - 1 && (
                              <StyledButton
                                disabled={assetBundleLocked}
                                className="customize-button moveDown"
                                onClick={(e) => {
                                  moveKnowledgeDown(knowledge, index);
                                  e.stopPropagation();
                                }}
                              >
                                <KeyboardDoubleArrowDownIcon />
                              </StyledButton>
                            )}
                          </>
                        )}

                        <KnowledgeCard
                          className="knowledge-card-position"
                          locked={assetBundleLocked}
                          selected={index === selectedKnowledgeIdx && !showCommonConfig}
                          key={index}
                          knowledge={knowledge}
                          usedKnowledgeSounds={usedKnowledgeSounds}
                          availableKnowledgeSounds={availableKnowledgeSounds}
                          usedWrongSounds={usedWrongSounds}
                          onClick={() => handleKnowledgeCardClick(knowledge, index)}
                          selectedItemStageItemId={selectedKnowledgeItemStageItemId}
                          onItemClick={(stageItemId) => handleKnowledgeItemClick(stageItemId, index)}
                          onDelete={() => handleDeleteKnowledge(index)}
                          onPressDeleteKey={() => selectedKnowledgeItemStageItemId && deleteActiveItem()}
                          onChange={(newKnowledge) => handleKnowledgeCardChange(newKnowledge, index)}
                          showControls={!showCommonConfig}
                          showCircularProgress={false}
                        />
                      </div>
                    </LazyLoadComponent>
                  ))}
                </div>
              </CardStyled>
            </div>
            {!showCommonConfig && (
              <>
                <div className="content-middle-wrapper">
                  <ToastContainer
                    className={"toast-position"}
                    toastClassName="toastClassName"
                    position="top-center"
                    autoClose={6000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />
                  <ScenesBar
                    className="scenesBar-wrapper"
                    scenes={assetBundle.scenes}
                    selectedSceneIdx={selectedSceneIdx}
                    onSelectScene={handleSelectScene}
                    onAddScene={() => handleAddScene()}
                    onDeleteScene={handleDeleteScene}
                    onAddToLibrary={handleAddSceneToLibrary}
                    onSceneDoubleClick={handleCreateBundleIcon}
                    showIconProgress={creatingSceneIcon}
                  />
                  <div className="buttons-top-block">
                    <div className="left-buttons">
                      <button
                        disabled={!allKnowledgesItems.length || !selectedScene || !selectedScene.items.length}
                        className={`top-block-button svgRotate180`}
                        title={"Наступний пропс"}
                        onClick={handleNextKnowledgeProps}
                      >
                        <BackIcon />
                      </button>
                      <button
                        disabled={!selectedScene || !scenePlaceholders.length}
                        className={`top-block-button svgRotate180`}
                        title={"Наступний плейсхолдер"}
                        onClick={handleNextPlaceholder}
                      >
                        <BackIcon />
                      </button>
                      <button
                        disabled={!assetBundle.scenes.length}
                        className={`top-block-button svgRotate180`}
                        title={"Наступна сцена"}
                        onClick={handleNextScene}
                      >
                        <BackIcon />
                      </button>
                    </div>
                    <div className="middle-buttons">
                      {assetBundle.scenes.length
                        ? `Сцена ${selectedSceneIdx + 1} з ${assetBundle.scenes.length}`
                        : null}
                    </div>
                    <div className="right-buttons">
                      <button
                        disabled={!selectedScene || !selectedScene.items.length}
                        className={`top-block-button`}
                        title={"Додати плейсхолдер"}
                        onClick={handleAddPlaceholder}
                      >
                        <BigPlusIcon />
                      </button>
                    </div>
                  </div>
                  <AssetBundleMainScreen
                    className="mainScreen-wrapper"
                    activeItemData={activeItemData}
                    selectedKnowledgeItemStageItemId={selectedKnowledgeItemStageItemId}
                    scene={selectedScene}
                    allKnowledgesItems={allKnowledgesItems}
                    knowledgeItemPlaceholderIdx={knowledgeItemPlaceholderIdx}
                    onItemClick={setActiveItemData}
                    onPressCopy={handlePressCopy}
                    onPressDelete={deleteActiveItem}
                    onPressPaste={handlePressPaste}
                    onSceneChange={handleSceneChange}
                    onKnowledgeItemChange={handleKnowledgeItemChange}
                  />
                  <div className="activeItem-and-buttons">
                    {activeItemData.type === "placeholder" && (
                      <AssetBundleActiveItem
                        className="activeItem-wrapper"
                        item={activeItem}
                        disabled={!activeItem || !selectedScene?.items?.length}
                        onChange={(newItem) => handleSceneItemChange(newItem)}
                        onDelete={deleteActiveItem}
                        isPlaceholder
                      />
                    )}
                    {activeItemData.type === "knowledgeItem" && (
                      <AssetBundleActiveItem
                        className="activeItem-wrapper"
                        item={activeItem}
                        disabled={!activeItem || !selectedScene?.items?.length}
                        onChange={(newItem) => handleKnowledgeItemChange(newItem)}
                        onDelete={deleteActiveItem}
                        isKnowlegeItem
                      />
                    )}
                    {activeItemData.type === "" && (
                      <AssetBundleActiveItem
                        className="activeItem-wrapper"
                        item={activeItem}
                        disabled={!activeItem || !selectedScene?.items?.length}
                        onChange={(newItem) => handleSceneItemChange(newItem)}
                        onDelete={deleteActiveItem}
                      />
                    )}

                    <button
                      disabled={
                        !selectedKnowledge ||
                        !activeItem ||
                        activeItemData.type === "knowledgeItem" ||
                        activeItemData.type === "placeholder"
                      }
                      className={`activeItem-plus-button`}
                      title={"Додати до обраного знання"}
                      onClick={handleAddActiveItemToKnowledge}
                    >
                      <BigPlusIcon />
                    </button>
                  </div>
                </div>
                <div className="content-right-wrapper">
                  <Tabs className="tabs-block" tabs={tabs} activeTab={activeTab} onChange={setActiveTab} />
                  <div className="tabs-content-block">
                    {activeTab === "Бібліотека" && (
                      <ImageLibrary
                        selectedImageId={null}
                        onImageSelect={handleLibraryImageSelect}
                        withoutSelection
                        imagesOnPage={100}
                      />
                    )}
                    {activeTab === "Слої" && selectedScene && (
                      <Layers
                        items={
                          selectedScene.items.length
                            ? selectedScene.items
                                .slice(1)
                                .map((element) => element)
                                .reverse()
                            : []
                        }
                        parentId={"root"}
                        selectedStageItemId={activeItemData.stageItemId}
                        onItemClick={handleLayersItemClick}
                        onItemCopy={handlePressLayerItemCopy}
                        onPressPaste={handlePressPaste}
                        onDropItem={handleDropLayersItem}
                      />
                    )}
                    {activeTab === "Групи" && (
                      <StageItemLibrary
                        onItemCopy={handlePressItemTemplateCopy}
                        onItemTemplateClick={handleItemTemplateClick}
                      />
                    )}

                    {activeTab === "Сцени" && (
                      <StageTemplateLibrary onStageTemplateClick={handleCreateSceneFromTemplate} />
                    )}
                  </div>
                </div>
              </>
            )}

            {showCommonConfig && (
              <AssetBundleCommonConfig
                assetBundle={assetBundle}
                usedOtherSounds={usedOtherSounds}
                availableOtherSounds={availableOtherSounds}
                onChange={handleCommonConfigChange}
                onDelete={openDeleteAssetBundleDialog}
                onCopy={handleCopyAssetBundle}
              />
            )}
          </div>
        )}
      </div>
      <AlertDialog alertDialogData={alertDialogData} />
    </>
  );
};
export default withIdParam(AssetBundleEditor);
