import "./ResetPassword.scss";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CardStyled, StyledButton } from "components";
import axios from "axios";
import useSearchParamWorker from "helpers/useSearchParam";

export default function ResetPassword() {
  const navigate = useNavigate();

  const searchParamWorker = useSearchParamWorker();
  const [email] = useState(searchParamWorker.getSearchParam("email"));
  const [token] = useState(searchParamWorker.getSearchParam("token"));

  const [password, setPassword] = useState();
  const [repeatPassword, setRepeatPassword] = useState();
  const [message, setMessage] = useState();
  const [isRegistered, setIsRegistered] = useState();

  const handleSubmit = async (event) => {
    event.preventDefault();

    const resetData = { email: email, token: token, password: password };

    axios
      .post("api/auth/resetPassword", resetData)
      .then(() => {
        setIsRegistered(true);
        setMessage("Вітаємо! Ви успішно змінили пароль!");
        setTimeout(() => {
          navigate("/login");
        }, 4000);
      })
      .catch((error) => {
        setIsRegistered(false);

        if (error.response.status === 404) {
          setMessage("Помилка при спробі зміни пароля");
          return;
        }

        setMessage("Спробуйте пізніше");
      });
  };

  useEffect(() => {
    if (password && password.length < 6) {
      setMessage("Довжина паролю не менше 6 символів!");
      return setIsRegistered(false);
    }
    if (password !== repeatPassword) {
      setIsRegistered(false);
      return setMessage("Паролі не співпадають!");
    }
    setMessage();
  }, [password, repeatPassword]);

  return (
    <div className="resetPassword-page-wrapper">
      <div className="resetPassword-wrapper">
        <div className="resetPassword-title">Зміна паролю</div>
        <CardStyled className="button-box">
          {<div className={isRegistered ? "ok-message" : "error-message"}>{message}</div>}
          <form className="resetPassword-form" autoComplete="off">
            <label>
              <p className="resetPassword-lable">Зміна паролю для</p>
              <input className="input" type="email" value={email} autoComplete="off" />
            </label>
            <label>
              <p className="resetPassword-lable">Введіть новий пароль</p>
              <input
                className="input"
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                autoComplete="off"
                required
              />
            </label>
            <label>
              <p className="resetPassword-lable">Повторіть пароль</p>
              <input
                className="input"
                type="password"
                onChange={(e) => setRepeatPassword(e.target.value)}
                autoComplete="off"
                required
              />
            </label>
            <StyledButton
              disabled={Boolean(message) || password !== repeatPassword || !password || !repeatPassword}
              title="Підтвердити"
              className="customize-button"
              type="submit"
              onClick={handleSubmit}
            />
          </form>
          <div className="link-box">
            <Link className="login-link" to="/login">
              Увійти
            </Link>
          </div>
        </CardStyled>
      </div>
    </div>
  );
}
