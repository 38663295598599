import html2canvas from "html2canvas";
import { dataURLToBlob } from "helpers/utils";

export const createIconFromDomElement = (domElement, scale = 1) => {
  return new Promise((resolve, reject) => {
    html2canvas(domElement, {
      allowTaint: true,
      useCORS: true,
      scale: scale,
    })
      .then((canvas) => {
        let image = canvas.toDataURL("image/png", 1);
        const iconBlob = dataURLToBlob(image);
        resolve(iconBlob);
      })
      .catch((error) => {
        console.log(error);
        resolve();
      });
  });
};
