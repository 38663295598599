import "./AssetBundleTile.scss";
import React from "react";
import { GameIcon, GroupTile } from "components";
import { useResourcesContext } from "resources";

const AssetBundleTile = ({ assetBundle, onClick, defaultGroupMode = "single", edited }) => {
  const {
    imageListStore: { getImagePathById },
  } = useResourcesContext();

  const handleOnClick = (e) => {
    onClick();
  };

  return (
    //<GroupTile mode="single">
    //<GroupTile mode="parallel">
    <GroupTile mode={defaultGroupMode}>
      <div className={`assetBundleTile-component`} onClick={handleOnClick}>
        <div className="sgt-header"></div>
        <div className="sgt-middle">
          <div className="sgt-main-block">
            <div className="sgt-assetBundle-name">{`${assetBundle?.name || "не визначено"}`}</div>
            <div className="sgt-assetBundle-icon-and-type">
              <div className="sgt-assetBundle-icon">
                <GameIcon
                  gameIcon={
                    typeof assetBundle?.icon === "string" ? getImagePathById(assetBundle.icon) : assetBundle.icon
                  }
                />
              </div>
              <div className="sgt-assetBundle-type-block">
                <div className="sgt-assetBundle-createdBy">{assetBundle?.createdBy}</div>
              </div>
            </div>
          </div>
          <div className="sgt-knowledges-block"></div>
        </div>
        <div className="sgt-footer"></div>
      </div>
    </GroupTile>
  );
};

export default AssetBundleTile;
