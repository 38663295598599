import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as createId } from "uuid";
import _, { isArray } from "lodash";
import { useAuthz } from "auth/AuthzProvider";
import { getItemsWithChildrenPlain } from "helpers/stageItems";

const createEmptyUniversalGame = (name = "") => {
  return {
    _id: createId(),
    name: name,
    description: "",
    gameType: "universal",
    icon: "",
    stages: [
      {
        items: [],
        tasks: [
          {
            soundId: "",
            type: "tap",
            correctReaction: "PARTICLES_AND_DELETE",
            incorrectReaction: "SHAKE",
            items: [],
            controlledKnowledge: "",
          },
        ],
      },
    ],
    sounds: {
      gameStart: "",
      gameEnd: "",
      tutorial: "",
      correct: [],
      incorrect: [],
    },
    tags: [],
    updatedBy: "",
    createdBy: "",
  };
};

const basicGameConfig = {
  _id: "",
  name: "",
  description: "",
  gameType: "",
  icon: "",
  stages: [
    {
      sounds: { stageStart: "" },
      items: [],
      background: "",
      correctItems: [],
      correctCombinations: [],
    },
  ],
  sounds: {
    gameStart: "",
    tutorial: "",
    correct: [],
    incorrect: [],
  },
  tags: [],
  changed: true,
  trackingIds: [],
  trackingUsers: [],
  valid: true,
  errors: [],
};

const basicTemplateConfig = {
  stages: [
    {
      items: [],
    },
  ],
};

const useGameList = () => {
  const { user } = useAuthz();
  const [list, setList] = useState([]);
  const [editedList, setEditedList] = useState([]);
  const [extraData, setExtraData] = useState([]);
  const [fetchingGameList, setFetchingGameList] = useState(false);
  const [fetchingExtraData, setFetchingExtraData] = useState(false);
  const [fetchingGame, setFetchingGame] = useState(false);

  const listWithExtraData = useMemo(() => {
    if (list) {
      return list
        .map((game) => {
          const gameExtraData = extraData?.find((gameExtra) => gameExtra._id === game._id);
          return { ...game, ...gameExtraData };
        })
        .reverse();
    } else {
      return [];
    }
  }, [list, extraData]);

  const getGameList = () => {
    setFetchingGameList(true);
    fetch(`/api/games`, { method: "GET" })
      .then((res) => (res.status !== 200 ? [] : res.json()))
      .then((games) => {
        setList(isArray(games) ? games : []);
        setFetchingGameList(false);
      })
      .catch((error) => {
        setList([]);
        setFetchingGameList(false);
      });
  };

  const getExtraData = () => {
    setFetchingExtraData(true);

    fetch(`/api/games-extradata`, { method: "GET" })
      .then((res) => (res.status !== 200 ? [] : res.json()))
      .then((extradata) => {
        setExtraData(isArray(extradata) ? extradata : []);
        setFetchingExtraData(false);
      })
      .catch((error) => {
        setExtraData([]);
        setFetchingExtraData(false);
      });
  };

  const isNewGame = (gameId) => {
    return Boolean(!list.some((game) => game._id === gameId));
  };

  const getGamesWithImage = useCallback(
    (imageId) => {
      return [...list, ...editedList]
        .filter(
          (game) =>
            game.icon === imageId ||
            game.stages.some(
              (stage) =>
                stage.background === imageId ||
                getItemsWithChildrenPlain(stage.items).some((item) => item._id === imageId)
            )
        )
        .reduce((resultGames, game) => {
          if (resultGames.some((resGame) => resGame._id === game._id)) {
            return [...resultGames];
          } else {
            return [...resultGames, game];
          }
        }, []);
    },
    [list, editedList]
  );

  const getGamesWithSound = useCallback(
    (soundId) => {
      return [...list, ...editedList]
        .filter((game) => {
          return (
            game.sounds?.gameStart === soundId ||
            game.sounds?.tutorial === soundId ||
            game.sounds?.correct?.includes(soundId) ||
            game.sounds?.incorrect?.includes(soundId) ||
            game.stages?.some(
              (stage) =>
                getItemsWithChildrenPlain(stage.items || []).some((item) => item.sounds?.main === soundId) ||
                stage.tasks?.some((task) => task.soundId === soundId)
            )
          );
        })
        .reduce((resultGames, game) => {
          if (resultGames.some((resGame) => resGame._id === game._id)) {
            return [...resultGames];
          } else {
            return [...resultGames, game];
          }
        }, []);
    },
    [list, editedList]
  );

  const getGameRemote = (gameId) => {
    return fetch(`/api/games/${gameId}`, { method: "GET" })
      .then((res) => (res.status !== 200 ? null : res.json()))
      .catch(() => null);
  };

  const getGameExtraDataRemote = (gameId) => {
    return fetch(`/api/games-extraData/${gameId}`, { method: "GET" })
      .then((res) => (res.status !== 200 ? null : res.json()))
      .catch(() => null);
  };

  const getGameAndExtraDataRemote = async (gameId) => {
    const game = await getGameRemote(gameId);
    const gameExtraData = await getGameExtraDataRemote(gameId);
    return { game, gameExtraData };
  };

  const undoGame = async (gameId) => {
    deleteGameFromEditedList(gameId);
    return await getGameAndExtraDataRemote(gameId);
  };

  const getGameForEditing = async (gameId) => {
    const editedListGame = editedList.find((game) => game._id === gameId);
    if (editedListGame) {
      const gameExtraData = await getGameExtraDataRemote(gameId);
      return { game: editedListGame, gameExtraData, edited: true, isNew: isNewGame(gameId) };
    }

    const gameAndExtraData = await getGameAndExtraDataRemote(gameId);
    return { ...gameAndExtraData, edited: false, isNew: false };
  };

  const deleteGameFromEditedList = (gameId) => {
    setEditedList(editedList.filter((listGame) => listGame._id !== gameId));
  };

  const deleteGameFromList = (gameId) => {
    setList(list.filter((listGame) => listGame._id !== gameId));
  };

  const deleteGameFromLists = (gameId) => {
    deleteGameFromEditedList(gameId);
    deleteGameFromList(gameId);
  };

  const deleteGame = async (gameId) => {
    return new Promise((resolve, reject) => {
      if (gameId) {
        setFetchingGame(gameId);
        fetch(`/api/games/${gameId}`, { method: "DELETE" })
          .then(() => {
            setFetchingGame(false);
            deleteGameFromLists(gameId);
            resolve();
          })
          .catch((error) => {
            setFetchingGame(false);
            console.log(error.message);
            resolve();
          });
      } else {
        resolve();
      }
    });
  };

  const transformTemplateToUniversalConfig = (template) => {
    let localConfig = _.cloneDeep(template);
    let stages = localConfig.stages?.map((stage) => {
      if (stage.background) {
        let backgroundItem = { _id: stage.background, stageItemId: createId(), valueX: 0, valueY: 0 };
        stage.items?.length > 0 ? stage.items.unshift(backgroundItem) : (stage.items = [backgroundItem]);
        stage.background = undefined;
      }

      return stage;
    });

    localConfig.stages = stages || undefined;
    return localConfig;
  };

  const copyGameFromTemplate = async (gameType, template) => {
    let newGame;
    if (template) {
      newGame = _.cloneDeep({
        ...template,
        name: gameType.label + " (создана из шаблона)",
        icon: "",
      });

      if (gameType.name === "universal") {
        newGame = transformTemplateToUniversalConfig(newGame);
      }
    } else {
      switch (gameType.name) {
        case "universal":
          newGame = _.cloneDeep({ ...createEmptyUniversalGame(), name: gameType.label });
          break;
        case "template":
          newGame = _.cloneDeep({ ...basicTemplateConfig, name: gameType.label });
          break;
        default:
          newGame = _.cloneDeep({ ...basicGameConfig, name: gameType.label });
          break;
      }
    }

    newGame._id = createId();
    newGame.gameType = gameType.name;
    setEditedList([...editedList, newGame]);
    return newGame;
  };

  const copyGame = async (game) => {
    let newGame = {
      ..._.cloneDeep(game),
      _id: createId(),
      name: game.name + " копия",
      icon: "",
    };

    setEditedList([...editedList, newGame]);
    return newGame;
  };

  const updateEditedGame = (editedGame) => {
    setEditedList([...editedList.filter((game) => game._id !== editedGame._id), editedGame]);
  };

  const createGame = async (game) => {
    setFetchingGame(game._id);

    const fields = {
      ...game,
      icon: undefined,
      createdBy: user?.email,
    };

    return fetch(`/api/games`, {
      method: "POST",
      body: JSON.stringify(fields),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .catch(() => {
        return null;
      });
  };

  const updateGame = async (game) => {
    setFetchingGame(game._id);
    const fields = {
      ...game,
      icon: undefined,
      updatedBy: user?.email,
    };

    return fetch(`/api/games/${game._id}`, {
      method: "PUT",
      body: JSON.stringify(fields),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        return res.json();
      })
      .catch(() => {
        return null;
      });
  };

  const updateGameIcon = async (gameIconBlob, gameId) => {
    if (typeof gameIconBlob === "object" && gameId) {
      setFetchingGame(gameId);
      const files = new FormData();
      files.append("iconFile", gameIconBlob);
      return fetch(`/api/games/${gameId}`, {
        method: "PUT",
        body: files,
        headers: {},
      })
        .then((res) => {
          return res.json();
        })
        .catch(() => {
          return null;
        });
    } else {
      return null;
    }
  };

  const updateOneGameExtraData = (gameExtraData) => {
    gameExtraData?._id &&
      setExtraData([...extraData.filter((extraData) => extraData._id !== gameExtraData._id), gameExtraData]);
  };

  const saveGame = async (game) => {
    let savedGame = isNewGame(game._id) ? await createGame(game) : await updateGame(game);
    let savedGameWithIcon = await updateGameIcon(game.icon, savedGame._id);

    if (savedGameWithIcon || savedGame) {
      deleteGameFromEditedList(game._id);
    }

    getGameList();

    return {
      game: savedGameWithIcon || savedGame,
      gameExtraData: await getGameExtraDataRemote(savedGame._id)
        .then((res) => {
          setFetchingGame(false);
          res?._id && updateOneGameExtraData(res);
          return res;
        })
        .catch(() => {
          setFetchingGame(false);
          return null;
        }),
      isNew: false,
      edited: false,
    };
  };

  return {
    gameList: listWithExtraData,
    editedGameList: editedList,
    getGameList,
    getGamesWithImage,
    getGamesWithSound,
    getGameForEditing,
    getExtraData,
    updateEditedGame,
    copyGameFromTemplate,
    copyGame,
    deleteGame,
    saveGame,
    undoGame,

    fetchingGameList: fetchingGameList,
    fetchingExtraData: fetchingExtraData,
    fetchingGame: fetchingGame,
  };
};

export default useGameList;
