import React, { useState } from "react";
import { Tooltip } from "@material-ui/core";
import { MenuItem, Select } from "@mui/material";
import "./DropdownSelector.scss";

const DropdownSelector = ({
  className = "",
  options = [],
  selectedOption,
  onChange,
  disabled,
  withNone = true,
  useNameAsLabel,
  uppercase,
  placeholder = "",
  title = "",
  icon = null,
  showArrow = true,
  disableBackground = false,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const getOptionLabel = (option) => {
    const label = useNameAsLabel ? option.name : option.label || option.name;
    return uppercase ? label.toUpperCase() : label;
  };

  return (
    <Tooltip
      arrow
      placement="top"
      disableFocusListener
      open={tooltipOpen}
      title={selectedOption ? selectedOption.label || selectedOption.name || "" : ""}
    >
      <div className="dropdown-selector-wrapper">
        <div className={`dropdown-selector-component ${className}`}>
          {title && <div className="input-title">{title}</div>}
          <div className={`input ${disableBackground ? "without-background" : "with-background"}`}>
            {icon && icon}
            <Select
              displayEmpty
              disabled={disabled}
              value={selectedOption || ""}
              onChange={(event) => onChange(event.target.value)}
              inputProps={{ "aria-label": "Without label" }}
              onMouseEnter={() => setTooltipOpen(true)}
              onMouseLeave={() => setTooltipOpen(false)}
              onClick={() => setTooltipOpen(false)}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 250,
                    borderRadius: "10px",
                    boxShadow:
                      "30px 122px 35px 0px rgba(131, 156, 180, 0.00), 20px 78px 32px 0px rgba(131, 156, 180, 0.01), 11px 44px 27px 0px rgba(131, 156, 180, 0.05), 5px 20px 20px 0px rgba(131, 156, 180, 0.09), 1px 5px 11px 0px rgba(131, 156, 180, 0.10)",
                  },
                },
              }}
              IconComponent={showArrow ? undefined : () => null}
            >
              {placeholder && !selectedOption && (
                <MenuItem value="" disabled>
                  {placeholder}
                </MenuItem>
              )}
              {withNone && (
                <MenuItem value="" onMouseEnter={() => setTooltipOpen(false)}>
                  <em>None</em>
                </MenuItem>
              )}
              {options.map((option, index) => (
                <MenuItem
                  key={index}
                  value={option}
                  onMouseEnter={() => setTooltipOpen(false)}
                  style={{
                    backgroundColor: selectedOption === option ? "transparent" : "inherit",
                    color: selectedOption === option ? "#FAA400" : "inherit",
                  }}
                >
                  {getOptionLabel(option)}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>
    </Tooltip>
  );
};

export default DropdownSelector;
