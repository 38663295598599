import "./Registration.scss";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CardStyled, StyledButton } from "components";
import axios from "axios";
import { sendGtagData } from "helpers/sendGtagEvent";

export default function Registration() {
  const navigate = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [repeatPassword, setRepeatPassword] = useState();
  const [message, setMessage] = useState();
  const [isRegistered, setIsRegistered] = useState();

  const handleSubmitRegistration = async (event) => {
    sendGtagData("event", "user_click_register");

    event.preventDefault();

    const webUser = { email: email, password: password };

    axios
      .post("api/auth/register", webUser)
      .then(() => {
        sendGtagData("event", "user_registration_success");
        setIsRegistered(true);
        setMessage("Вітаємо! Ви успішно зарееєстровані!");
        setTimeout(() => {
          navigate("/login");
        }, 4000);
      })
      .catch((error) => {
        sendGtagData("event", "user_registration_failure");
        setIsRegistered(false);

        if (error.response.status === 400) {
          switch (error.response.data.message) {
            case "user_already_exists":
              setMessage("Користувач з таким Email вже існує!");
              break;
            case "not_valid_email":
              setMessage("Email не відповідає вимогам!");
              break;
            case "not_valid_password":
              setMessage("Пароль не відповідає вимогам!");
              break;
            default:
              setMessage("Спробуйте пізніше");
              break;
          }
        } else {
          setMessage("Спробуйте пізніше");
        }
      });
  };

  useEffect(() => {
    if (password && password.length < 6) {
      setMessage("Довжина паролю не менше 6 символів!");
      return setIsRegistered(false);
    }
    if (password !== repeatPassword) {
      setIsRegistered(false);
      setMessage("Паролі не співпадають!");
    } else {
      setMessage();
    }
  }, [password, repeatPassword]);

  return (
    <div className="registration-page-wrapper">
      <div className="registration-wrapper">
        <div className="registration-title">Реєстрація</div>
        <CardStyled className="button-box">
          {message && <div className={isRegistered ? "ok-message" : "error-message"}>{message}</div>}
          <form className="registration-form">
            <label>
              <p className="registration-lable">Введіть email</p>
              <input
                className="input"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
                required
              />
            </label>
            <label>
              <p className="registration-lable">Введіть пароль</p>
              <input className="input" type="password" onChange={(e) => setPassword(e.target.value)} required />
            </label>
            <label>
              <p className="registration-lable">Повторіть пароль</p>
              <input className="input" type="password" onChange={(e) => setRepeatPassword(e.target.value)} required />
            </label>
            <StyledButton
              title="Зареєструватись"
              className="customize-button"
              type="submit"
              onClick={handleSubmitRegistration}
            />
          </form>
          <div className="link-box">
            <Link className="login-link" to="/login">
              Увійти
            </Link>
          </div>
        </CardStyled>
      </div>
    </div>
  );
}
