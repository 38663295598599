import React from "react";
import { useNavigate } from "react-router-dom";
import { StyledButton } from "components";

const NavigateButton = ({ iconHeader, title, path, goBack, className, onClick, сhildren }) => {
  const navigate = useNavigate();
  const navigateTo = () => {
    navigate(path);
  };
  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <StyledButton
      iconHeader={iconHeader}
      title={title}
      className={className}
      onClick={() => (onClick ? onClick() : goBack ? navigateBack() : navigateTo())}
    >
      {сhildren}
    </StyledButton>
  );
};
export default NavigateButton;
