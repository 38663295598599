import React from "react";

const Delimiter = ({ onDropDownElementMounted, dragOver }) => {
  return (
    <div ref={onDropDownElementMounted} className={`drop-delimiter`}>
      <div className={`drop-delimiter-content ${dragOver ? "delimiter-drag-over" : ""}`}></div>
    </div>
  );
};

export default Delimiter;
