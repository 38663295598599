import React, { useEffect } from "react";

const PixelScript = ({ id = "" }) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.innerHTML = `{!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '${id}');fbq('track', 'PageView');}`;
    document.head.insertBefore(script, document.head.firstChild);

    const noscript = document.createElement("noscript");
    noscript.innerHTML = `<img height="1" width="1" style={{ display: "none" }} src="https://www.facebook.com/tr?id=${id}&ev=PageView&noscript=1"/>`;
    document.head.insertBefore(noscript, document.head.firstChild);

    // Cleanup when the component unmounts
    return () => {
      document.head.removeChild(script);
      document.head.removeChild(noscript);

      const scriptElements = document.querySelectorAll('script[src*="https://connect.facebook.net"]');
      scriptElements.forEach((element) => {
        element.parentNode.removeChild(element);
      });

      const noscriptElements = document.querySelectorAll('noscript img[src*="https://www.facebook.com"]');
      noscriptElements.forEach((element) => {
        element.parentNode.removeChild(element);
      });
    };
  }, []); // Empty dependency array to run the effect only once

  return null; // This component doesn't render anything
};

export default PixelScript;