import { set } from "lodash";
import { useCallback, useState } from "react";

const useKnowledgeList = () => {
  const [list, setList] = useState([]);
  const [status, setStatus] = useState({ error: false, message: "" });

  const getKnowledgeGroupList = () => {
    return fetch(`/api/knowledgeGroups`, { method: "GET" })
      .then((res) => res.json())
      .then((res) => {
        setList(res);
      })
      .catch(() => {
        setStatus({ error: true, message: "Помилка завантаження знань. Спробуйте оновити сторінку." });
        setList([]);
      });
  };

  const findEqval = (knowledgeGroup) => {
    return list.some(
      (element) =>
        knowledgeGroup.name.toLowerCase() === element.name.toLowerCase() && knowledgeGroup._id !== element._id
    );
  };

  const createKnowledgeGroup = (knowledgeGroup) => {
    if (!knowledgeGroup.name) {
      setStatus({ error: true, message: "Не можливо зберегти без назви" });
      return new Promise((resolve, reject) => reject(null));
    }
    // if (!knowledgeGroup.knowledgesTags) {
    //   setStatus({ error: true, message: "Не можливо зберегти з пустими знаннями" });
    //   return new Promise((resolve, reject) => reject(null));
    // }
    if (findEqval(knowledgeGroup)) {
      setStatus({ error: true, message: "Група вже існує" });
      return new Promise((resolve, reject) => resolve(null));
    }

    return fetch("/api/knowledgeGroups", {
      method: "POST",
      body: JSON.stringify(knowledgeGroup),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())

      .then((knowledgeGroup) => {
        setList([...list, knowledgeGroup]);
        setStatus({ error: false, message: "Створення здійснено успішно." });
        return knowledgeGroup;
      })
      .catch(() => {
        setStatus({ error: true, message: "Помилка при створенні групи. Спробуйте ще." });
        return null;
      });
  };

  const updateKnowledgeGroup = (knowledgeGroup) => {
    if (!knowledgeGroup.name) {
      setStatus({ error: true, message: "Не можливо зберегти без назви" });
      return new Promise((resolve, reject) => reject(null));
    }

    if (!knowledgeGroup._id || findEqval(knowledgeGroup)) {
      setStatus({ error: true, message: "Група з таким ім'ям вже існує" });
      return new Promise((resolve, reject) => reject(null));
    }

    return fetch(`/api/knowledgeGroups/${knowledgeGroup._id}`, {
      method: "PUT",
      body: JSON.stringify(knowledgeGroup),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((knowledgeGroup) => {
        setList(list.map((element) => (element._id === knowledgeGroup._id ? knowledgeGroup : element)));
        setStatus({ error: false, message: "Збереження здійснено успішно." });
        return knowledgeGroup;
      })
      .catch(() => {
        setStatus({ error: true, message: "Помилка при збереженні. Спробуйте ще." });
        return null;
      });
  };

  const deleteKnowledgeGroup = (knowledgeGroup) => {
    return fetch(`/api/knowledgeGroups/${knowledgeGroup._id}`, { method: "DELETE", headers: {} })
      .then((res) => res.json())
      .then((knowledgeGroup) => {
        setList(list.filter((element) => element._id !== knowledgeGroup._id));
        setStatus({ error: false, message: "Видалення здійснено успішно." });
        return knowledgeGroup;
      })
      .catch(() => {
        setStatus({ error: true, message: "Помилка видалення знання. Спробуйте ще." });
        return null;
      });
  };

  const resetStatus = useCallback(() => {
    setStatus({ error: false, message: "" });
  }, []);

  return {
    knowledgeGroupList: list,
    createKnowledgeGroup,
    updateKnowledgeGroup,
    deleteKnowledgeGroup,
    status,
    resetStatus,
    getKnowledgeGroupList,
  };
};

export default useKnowledgeList;
