import "./DepsGraph.scss";
import { useResourcesContext } from "resources";
import { useEffect, useState } from "react";
import { defaultKnowledgeStrengthAlgOptions, defaultQuickTestOptions } from "constants/constants";
import getSvgConnectionArrowDomElement from "./SvgConnectionArrow/SvgConnectionArrow";

const KNOW = "know";
const ALMOST_KNOW = "almost-know";
const IN_PROGRESS = "in-progress";

const DepsGraph = ({ className = "", unityUser, onClick, selectedGroup = {} }) => {
  const [levels, setLevels] = useState([]);
  const [groupsPairs, setGroupsPairs] = useState([]);
  const [extraGroupList, setExtraGroupList] = useState([]);
  const [activeGroupId, setActiveGroupIdId] = useState(null);
  const [activeChain, setActiveChain] = useState([]);

  const {
    knowledgeGroupListStore: { knowledgeGroupList },
  } = useResourcesContext();

  const getGroupKnowStatus = (group, learningProgress) => {
    if (!learningProgress) {
      return "";
    }

    const getGroupResult = (strength) => {
      if (!unityUser || !group.knowledgeTags.length) {
        return 0;
      }

      let count = group.knowledgeTags.reduce((acc, knTag) => {
        const kn = learningProgress.find((kn) => kn.knowledge === knTag);
        if (!kn) {
          return acc;
        }
        return acc + (kn.strength && kn.strength >= strength ? 1 : 0);
      }, 0);

      return (100 * count) / group.knowledgeTags.length;
    };

    const defaultKnowThreshold = defaultKnowledgeStrengthAlgOptions.knowThreshold;
    const defaultKnowThresholdForMinorKnowledge = defaultKnowledgeStrengthAlgOptions.knowThresholdForMinorKnowledge;
    const defaultTestSuccessThresholdForGroup = defaultQuickTestOptions.testSuccessThresholdForGroup;

    const userKnowThreshold = unityUser.knowledgeStrengthAlgOptions?.knowThreshold;
    const userKnowThresholdForMinorKnowledge = unityUser.knowledgeStrengthAlgOptions?.knowThresholdForMinorKnowledge;
    const userTestSuccessThresholdForGroup = unityUser.quickTestOptions?.testSuccessThresholdForGroup;

    const knowThreshold = userKnowThreshold || defaultKnowThreshold;
    const knowThresholdForMinorKnowledge = userKnowThresholdForMinorKnowledge || defaultKnowThresholdForMinorKnowledge;
    const testSuccessThresholdForGroup = userTestSuccessThresholdForGroup || defaultTestSuccessThresholdForGroup;

    if (getGroupResult(knowThreshold) >= testSuccessThresholdForGroup) {
      return KNOW;
    }
    if (getGroupResult(knowThresholdForMinorKnowledge) >= testSuccessThresholdForGroup) {
      return ALMOST_KNOW;
    }
    if (getGroupResult(0) > testSuccessThresholdForGroup) {
      return IN_PROGRESS;
    }

    return "";
  };

  useEffect(() => {
    if (!knowledgeGroupList.length) {
      return;
    }
    const extraGroupList = knowledgeGroupList.map((group) => {
      return {
        ...group,
        status: getGroupKnowStatus(group, unityUser?.learningProgress),
      };
    });

    setExtraGroupList(extraGroupList);
    setGroupsPairs(getGroupPairs(extraGroupList));
    setLevels(Array.from(new Set(extraGroupList.map((group) => group.level))));
  }, [knowledgeGroupList, unityUser]);

  const getGroupPairs = (extraGroupList) => {
    const groupsPairs = [];

    for (let i = 0; i < extraGroupList.length; i++) {
      const group = extraGroupList[i];
      group.dependsOnGroups.forEach((groupId) => {
        groupsPairs.push({
          groupFrom: extraGroupList.find((group) => group._id === groupId),
          groupTo: group,
        });
      });
    }

    return groupsPairs;
  };

  const getChain = (mainGroupId, extraGroupList) => {
    if (!mainGroupId) {
      return [];
    }

    const chain = [mainGroupId];

    const extraGroupListObj = {};
    extraGroupList.forEach((group) => {
      if (group.dependsOnGroups.length) {
        extraGroupListObj[group._id] = group.dependsOnGroups;
      }
    });

    const getAllParents = (groupId) => {
      const parrents = [];

      if (!extraGroupListObj[groupId]) {
        return [];
      }

      extraGroupListObj[groupId].forEach((parentId) => {
        parrents.push(parentId);
        parrents.push(...getAllParents(parentId));
      });

      return parrents;
    };

    const getAllChildren = (groupId) => {
      const children = [];
      if (!extraGroupList.length) {
        return [];
      }

      extraGroupList.forEach((group) => {
        if (group.dependsOnGroups.includes(groupId)) {
          children.push(group._id);
          children.push(...getAllChildren(group._id));
        }
      });

      return children;
    };

    chain.push(...getAllParents(mainGroupId));
    chain.push(...getAllChildren(mainGroupId));

    return chain;
  };

  const getGroupStatusClassName = (extraGroup) => {
    switch (extraGroup.status) {
      case KNOW:
        return "know-color";
      case ALMOST_KNOW:
        return "almost-know-color";
      case IN_PROGRESS:
        return "in-progress-color";
      default:
        return "";
    }
  };

  const getGroupArrowColor = (extraGroup) => {
    switch (extraGroup.status) {
      case KNOW:
        return "#abbd77";
      case ALMOST_KNOW:
        return "#abbd77";
      case IN_PROGRESS:
        return "#ffcd1c";
      default:
        return "gray";
    }
  };

  useEffect(() => {
    groupsPairs.forEach((pair) => {
      const domElemFrom = document.getElementById(pair.groupFrom._id);
      const domElemTo = document.getElementById(pair.groupTo._id);

      if (domElemFrom && domElemTo) {
        if (document.getElementById(pair.groupFrom._id + pair.groupTo._id)) {
          return null;
        }

        const levelsGroupsElement = document.getElementById(`level-groups ${pair.groupFrom.level}`);
        if (!levelsGroupsElement) {
          return null;
        }

        const id = `svgArrow${pair.groupFrom._id}${pair.groupTo._id}`;
        const sameLevel = pair.groupFrom.level === pair.groupTo.level;

        const svg = getSvgConnectionArrowDomElement(domElemFrom, domElemTo, {
          arrowColor: getGroupArrowColor(pair.groupFrom),
          gapBetweenObjects: 40,
          radius: 10,
          dimmed: activeChain.length && !activeChain.includes(pair.groupFrom._id),
          id: id,
          sameLevel: sameLevel,
        });

        const prevElement = document.getElementById(id);

        if (prevElement) {
          prevElement.remove();
        }

        levelsGroupsElement.appendChild(svg);
      }
    });
  });

  return (
    <div className="depsGraph-component">
      <div className="depsGraph-content">
        <div className="depsGraph-levels">
          {levels.map((level) => {
            const levelGroups = extraGroupList
              .filter((group) => group.level === level)
              .sort((a, b) => a.name.localeCompare(b.name));
            return (
              <div key={level} className="depsGraph-level">
                <div className="depsGraph-level-title">Рівень {level}</div>
                <div id={`level-groups ${level}`} className="depsGraph-level-groups">
                  {levelGroups.map((group) => {
                    const groupKnowStatusClassName = getGroupStatusClassName(group);
                    const showDotsClassName =
                      group.studiesAtGroups0.length || group.studiesAtGroups1.length ? "" : "dontShow";
                    return (
                      <div
                        key={group._id}
                        id={group._id}
                        onClick={(e) => {
                          if (activeGroupId === group._id) {
                            setActiveGroupIdId(null);
                            setActiveChain([]);
                          } else {
                            setActiveGroupIdId(group._id);
                            setActiveChain(getChain(group._id, extraGroupList));
                          }
                          e.stopPropagation();
                          e.preventDefault();

                          onClick && onClick(group);
                        }}
                        className={`depsGraph-level-group ${groupKnowStatusClassName} ${
                          activeGroupId && !activeChain.includes(group._id) ? "dimmed" : ""
                        } && ${selectedGroup._id === group._id ? "selected" : ""}`}
                      >
                        <div className="depsGraph-level-group-dots-and-title">
                          <div className="depsGraph-level-group-dots">
                            <div className={`depsGraph-level-group-dots-content ${showDotsClassName}`}>
                              <div className={`dot ${groupKnowStatusClassName}`}></div>
                              <div className={`dot ${groupKnowStatusClassName}`}></div>
                              <div className={`dot ${groupKnowStatusClassName}`}></div>
                            </div>
                          </div>
                          <div className="depsGraph-level-group-title">{group.name}</div>
                        </div>
                        <div className="depsGraph-level-group-knCount">{group.knowledgeTags.length}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default DepsGraph;
