import "./Layers.scss";
import React from "react";

import Layer from "./Layer";
import Delimiter from "./Delimiter";
import CardStyled from "components/CardStyled/CardStyled";
import DragDropWrapper from "components/DragDropWrapper/DragDropWrapper";

const Layers = ({
  items,
  className = "",
  cardStyled = true,
  parentId = "root",
  selectedStageItemId,
  onItemClick,
  onItemCopy,
  onPressPaste,
  onDropItem,
}) => {
  const handleKeyUp = (event) => {
    const pasteKeys = ["v", "V", "м", "М"];
    (((event.ctrlKey || event.metaKey) && pasteKeys.includes(event.key)) ||
      (event.key === "Insert" && event.shiftKey)) &&
      onPressPaste();
  };

  const renderContent = () => (
    <div className="layers-content" tabIndex={-1} onKeyUp={handleKeyUp}>
      <div className="layers-list">
        {items?.map((item, index) => (
          <div key={index}>
            <DragDropWrapper onDrop={(dropedStageItemId) => onDropItem(parentId, index, dropedStageItemId)}>
              <Delimiter />
            </DragDropWrapper>
            <DragDropWrapper
              data={item.stageItemId}
              draggable={true}
              onDrop={(dropedStageItemId) => {
                onDropItem(item.stageItemId, item.items?.length || 0, dropedStageItemId);
              }}
            >
              <Layer
                item={item}
                index={index}
                selectedStageItemId={selectedStageItemId}
                onItemClick={onItemClick}
                onItemCopy={onItemCopy}
                key={item.stageItemId}
                onDropItem={onDropItem}
              />
            </DragDropWrapper>

            {index + 1 === items.length && (
              <DragDropWrapper onDrop={(dropedStageItemId) => onDropItem(parentId, index + 1, dropedStageItemId)}>
                <Delimiter />
              </DragDropWrapper>
            )}
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <>
      {!cardStyled && renderContent()}
      {cardStyled && <CardStyled className={`layers-component ${className} `}>{renderContent()}</CardStyled>}
    </>
  );
};

export default Layers;
