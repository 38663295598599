import itemTemplatesApi from "API/itemTemplatesApi";
import _ from "lodash";
import { useState } from "react";

const useItemTemplatesList = () => {
  const [list, setList] = useState([]);

  const getItemTemplatesList = () => {
    itemTemplatesApi
      .getAll()
      .then((res) => setList(res))
      .catch(() => setList([]));
  };

  const findEqvalTemplate = (template) => {
    return list.some((element) => _.isEqual(template, element.template));
  };

  const createItemTemplate = (template) => {
    if (findEqvalTemplate(template)) {
      return new Promise((resolve) => resolve(null));
    }

    return itemTemplatesApi
      .create({ template: template })
      .then((itemTemplate) => {
        getItemTemplatesList();
        return itemTemplate;
      })
      .catch(() => null);
  };

  const deleteItemTemplate = (id) => {
    return itemTemplatesApi
      .deleteOne(id)
      .then((itemTemplate) => {
        getItemTemplatesList();
        return itemTemplate;
      })
      .catch(() => null);
  };

  const getItemTemplatesWithImage = (imageId) => {
    const itemUseImageRecursive = (item, imageId) => {
      return item._id === imageId || item.items?.some((item) => itemUseImageRecursive(item, imageId));
    };

    return list.filter((itemTemplate) => {
      return itemUseImageRecursive(itemTemplate.template, imageId);
    });
  };

  return {
    itemTemplatesList: list,
    createItemTemplate,
    deleteItemTemplate,
    getItemTemplatesWithImage,
    getItemTemplatesList,
  };
};

export default useItemTemplatesList;
