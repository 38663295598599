import "./NewElementButton.scss";
import React from "react";
import { ReactComponent as NewElementBtnSmallIcon } from "assets/icons/NewElementBtnSmall.svg";

function NewElementButton({ className = "", active, title, activeTitle, onClick }) {
  return (
    <button className={`new-element-btn ${className}`} onClick={onClick || (() => {})}>
      <div
        className={`new-element-btn-content ${active ? "new-element-btn-content-active" : ""}`}
        title={active ? activeTitle : title}
      >
        <NewElementBtnSmallIcon />
      </div>
    </button>
  );
}

export default NewElementButton;
