import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthzProvider } from "./auth/AuthzProvider";
import { Locales, messages } from "./i18n";
import { IntlProvider } from "react-intl";

function getUserPreferredLanguage() {
  return localStorage.getItem("locale") || navigator.language.toLowerCase();
}
function mapUserLanguageToLocale(userLanguage) {
  switch (userLanguage) {
    case "en":
      return Locales.ENGLISH;
    case "uk":
      return Locales.UKRAINIAN;
    default:
      return Locales.UKRAINIAN;
  }
}

const initialLocale = mapUserLanguageToLocale(getUserPreferredLanguage());

ReactDOM.render(
  <BrowserRouter>
    <AuthzProvider>
      <IntlProvider messages={messages[initialLocale]} locale={initialLocale} defaultLocale={Locales.UKRAINIAN}>
        <App />
      </IntlProvider>
    </AuthzProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
