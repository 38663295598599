import "./StageItem.scss";

import React, { useMemo, useState } from "react";
import { useResourcesContext } from "resources";
import { getItemsWithChildrenPlain } from "helpers/stageItems";
import StyledButton from "components/StyledButton/StyledButton";
import { ReactComponent as IconAddToLibrary } from "assets/icons/plus2.svg";
import AlertDialog from "components/AlertDialog/AlertDialog";

const StageItem = ({ item, className = "", onClick, onItemCopy, onItemDelete}) => {
  const [alertDialogData, setAlertDialogData] = useState(null);
  const {
    imageListStore: { getImagePathById },
  } = useResourcesContext();

  const allSubItems = useMemo(() => getItemsWithChildrenPlain(item.items || []), [item]);

  const handleKeyUp = (event) => {
    const copyKeys = ["c", "C", "с", "С"];
    //const pasteKeys = ["v", "V", "м", "М"];
    (((event.ctrlKey || event.metaKey) && copyKeys.includes(event.key)) || (event.key === "Insert" && event.ctrlKey)) &&
      onItemCopy(item);
    // (((event.ctrlKey || event.metaKey) && pasteKeys.includes(event.key)) ||
    //   (event.key === "Insert" && event.shiftKey)) &&
    //   onPressPaste();
  };

const openDeleteItemDialog = () => {
  setAlertDialogData({
    onOk: () => {
      onItemDelete(item);
      setAlertDialogData(null);
    },
    onCancel: () => setAlertDialogData(null),
    title: "Видалення групи",
    message: "Натисніть Ok для видалення, або Cancel для відміни операції.",
  });
};



  return (
    <div className={`stageItem-component ${className}`} onClick={onClick} tabIndex="-1" onKeyUp={handleKeyUp}>
      <img className="main-picture" src={getImagePathById(item._id)} alt="" />
      <div className="sub-pictures">
        {allSubItems.map((subItem, index) => (
          <img key={index} className="sub-picture" src={getImagePathById(subItem._id)} alt="" />
        ))}
      </div>

      {onItemDelete && (
        <StyledButton
          tooltipProps={{ title: "Видалити з бібліотеки", placement: "right", arrow: true }}
          //icon={IconCopy}
          className="customize-button deleteItemFromLibrary"
          onClick={(e) => {
            openDeleteItemDialog();
            e.stopPropagation();
          }}
        >
          <IconAddToLibrary className="deleteItemFromLibrary-button-icon" />
        </StyledButton>
      )}
      <AlertDialog alertDialogData={alertDialogData} />
    </div>
  );
};

export default StageItem;
