import "./FiltersPanel.scss";
import { useMemo } from "react";
import SearchFilter from "./SearchFilter";
import SelectFilter from "./SelectFilter";

const FiltersPanel = ({ filters, onFiltersChange, defaultOpened = false, withBadges = true }) => {
  const textFilters = useMemo(() => filters?.filter((filter) => filter.type === "text") || [], [filters]);

  const handleFilterChange = (modifiedFilter, filters) => {
    const modifiedFilters = filters.map((filter) => {
      if (filter.name === modifiedFilter.name) {
        return modifiedFilter;
      }

      return filter;
    });

    onFiltersChange(modifiedFilters);
  };

  return (
    <div className="filtersPanel-component">
      {textFilters.map((textFilter) => (
        <div className="searchFilter">
          <SearchFilter
            filter={textFilter}
            onFilterChange={(modifiedFilter) => handleFilterChange(modifiedFilter, filters)}
          />
        </div>
      ))}

      <div className="selectFilters-container">
        {filters?.map(
          (filter, idx) =>
            filter.type !== "text" && (
              <SelectFilter
                key={idx}
                filter={filter}
                onFilterChange={(modifiedFilter) => handleFilterChange(modifiedFilter, filters)}
                defaultOpened={defaultOpened}
                withBadges={withBadges}
              />
            )
        )}
      </div>
    </div>
  );
};

export default FiltersPanel;
