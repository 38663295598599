import { useCallback, useRef } from "react";
import { useSearchParams } from "react-router-dom";

const useSearchParamWorker = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const innerSearchParamsRef = useRef({
    ...Object.fromEntries([...searchParams]),
  });

  const getSearchParam = useCallback(
    (paramName) => {
      return searchParams.get(paramName);
    },
    [searchParams]
  );

  const setSearchParam = useCallback(
    (paramName, value) => {
      let newSearchParams = { ...innerSearchParamsRef.current };
      if (value == null) {
        delete newSearchParams[paramName];
      } else {
        newSearchParams = { ...newSearchParams, [paramName]: value };
      }
      setSearchParams(newSearchParams, { replace: true });
      innerSearchParamsRef.current = newSearchParams;
    },
    [setSearchParams]
  );

  return { getSearchParam, setSearchParam, searchParamsAll: innerSearchParamsRef.current };
};

export default useSearchParamWorker;
