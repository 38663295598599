import "./UnityUsers.scss";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { VerticalHeader, EditorTemplate, NewElementButton, FiltersPanel, Tabs } from "components";
import useFilteredUnityUserList from "./useFilteredUnityUserList";
import UnityUserTile from "./UnityUserTile/UnityUserTile";
import NewUserTile from "./NewUserTile/NewUserTile";
import { useNavigate } from "react-router-dom";
import unityUserListApi from "API/unityUserListApi";
import { Checkbox, CircularProgress } from "@mui/material";
import { useAuthz } from "auth/AuthzProvider";
import axios from "axios";
import UnityUsersPlot from "../../components/UnityUsersPlot/UnityUsersPlot";
import _ from "lodash";

const UnityUsers = () => {
  const { user: webUser, setUser: setWebUser } = useAuthz();
  const [showGrouped, setShowGrouped] = useState(true);
  const [selectedTab, setSelectedTab] = useState("Список");
  const tabs = ["Список", "Список прихованих", "Статистика"];
  const [hiddenUsersList, setHiddenUsersList] = useState([]);

  const {
    unityUserList,
    filteredUnityUserList,
    filteredGroupedUnityUserList,
    applyFilterPanelData,
    fulfilledFilterPanelData,
    fetchingList,
    setCurrentPage,
    hasMore,
    showPinnedUsersOnly,
    showTeachersOnly,
    togglePinnedUsersOnly,
    toggleTeachersOnly,
  } = useFilteredUnityUserList();

  const [newUserMode, setNewUserMode] = useState(false);
  const navigate = useNavigate();
  const listInnerRef = useRef();

  const reloadReservedDeviceIds = () => {
    axios.get("/api/unityUsers/hiddenUsers").then((res) => {
      setHiddenUsersList(res.data);
    });
  };

  useEffect(() => {
    axios.get("/api/unityUsers/hiddenUsers").then((res) => {
      setHiddenUsersList(res.data);
    });
  }, []);

  const handleTileClick = (unityUser) => {
    navigate(`/unity-user-editor/${unityUser._id}`);
  };

  const isPinned = useCallback(
    (unityUserId) => {
      return webUser?.assignedUsers?.includes(unityUserId);
    },
    [webUser]
  );

  const sortUsersList = (usersListToSort) => {
    return [...(usersListToSort || [])].sort((a, b) => {
      if (a.unityId === "demoUserTemplate" || a.unityId === "trialUserTemplate") {
        return -1;
      }
      if (b.unityId === "demoUserTemplate" || b.unityId === "trialUserTemplate") {
        return 1;
      }
      const isPinnedA = isPinned(a._id);
      const isPinnedB = isPinned(b._id);
      if (isPinnedA && !isPinnedB) {
        return -1;
      }
      if (!isPinnedA && isPinnedB) {
        return 1;
      }
      const hasWebUserEmailA = !!a.webUserEmail;
      const hasWebUserEmailB = !!b.webUserEmail;
      if (hasWebUserEmailA && !hasWebUserEmailB) {
        return -1;
      }
      if (!hasWebUserEmailA && hasWebUserEmailB) {
        return 1;
      }
      return 0;
    });
  };

  const sortedGroupedUsers = useMemo(() => {
    return (filteredGroupedUnityUserList || [])
      .sort((a, b) => {
        if (a.some((elem) => elem.unityId === "demoUserTemplate" || elem.unityId === "trialUserTemplate")) {
          return -1;
        }
        if (b.some((elem) => elem.unityId === "demoUserTemplate" || elem.unityId === "trialUserTemplate")) {
          return 1;
        }
        const isPinnedA = a.some((elem) => isPinned(elem._id));
        const isPinnedB = b.some((elem) => isPinned(elem._id));
        if (isPinnedA && !isPinnedB) {
          return -1;
        }
        if (!isPinnedA && isPinnedB) {
          return 1;
        }
        const hasWebUserEmailA = a.some((elem) => !!elem.webUserEmail);
        const hasWebUserEmailB = b.some((elem) => !!elem.webUserEmail);
        if (hasWebUserEmailA && !hasWebUserEmailB) {
          return -1;
        }
        if (!hasWebUserEmailA && hasWebUserEmailB) {
          return 1;
        }
        return 0;
      })
      .map((usersGroup) => sortUsersList(usersGroup));
  }, [filteredGroupedUnityUserList, isPinned]);

  const sortedUsers = useMemo(() => {
    return sortUsersList(filteredUnityUserList);
  }, [filteredUnityUserList, isPinned]);

  const handleCreateUser = (newUserToCreate) => {
    unityUserListApi.createUnityUser(newUserToCreate).then((createdUser) => {
      navigate(`/unity-user-editor/${createdUser._id}`);
    });
  };

  const handlePushPin = (unityUserId) => {
    if (webUser) {
      const assignedUsers = webUser.assignedUsers || [];
      const newAssignedUsers = isPinned(unityUserId)
        ? assignedUsers.filter((id) => id !== unityUserId)
        : [...assignedUsers, unityUserId];
      axios.put(`/api/webUsers/uipreferences/${webUser._id}`, { assignedUsers: newAssignedUsers }).then((res) => {
        setWebUser(res.data);
      });
    }
  };

  const handlePushHideByDeviceId = (deviceId) => {
    deviceId &&
      axios.post(`/api/reservedDeviceIds/`, { deviceId }).then(() => {
        applyFilterPanelData([...fulfilledFilterPanelData]);
        reloadReservedDeviceIds();
      });
  };

  const handlePushCancelHideByDeviceId = (deviceId) => {
    axios.delete(`/api/reservedDeviceIds/${deviceId}`).then(() => {
      applyFilterPanelData([...fulfilledFilterPanelData]);
      reloadReservedDeviceIds();
    });
  };

  const getTileClassName = (unityUser) => {
    if (webUser._id === unityUser.webUserId) {
      return "currentWebUserTile";
    }
    if (unityUser.unityId === "demoUserTemplate" || unityUser.unityId === "trialUserTemplate") {
      return "defaultUserTile";
    }
    if (isPinned(unityUser._id)) {
      return "pinnedUserTile";
    }
    if (unityUser.webUserEmail) {
      return "linkedUserTile";
    }
    return "";
  };

  const handleScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5 && hasMore && !fetchingList) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    const listRef = listInnerRef.current;
    if (listRef) {
      listRef.addEventListener("scroll", handleScroll);
      return () => {
        listRef.removeEventListener("scroll", handleScroll);
      };
    }
  }, [hasMore, fetchingList]);

  return (
    <>
      <EditorTemplate
        header={<VerticalHeader backPath={"/"} />}
        leftContent={
          <div className="unityUsers-leftContent">
            <FiltersPanel
              filters={fulfilledFilterPanelData}
              onFiltersChange={applyFilterPanelData}
              defaultOpened
              withBadges={false}
            />

            <div className="checkbox-showGrouped">
              <Checkbox
                disabled={false}
                checked={showGrouped}
                defaultChecked={false}
                onChange={(event) => setShowGrouped(event.target.checked)}
                className="checkbox"
              />
              Групувати по DeviceId
            </div>

            <div className="checkbox-showGrouped">
              <Checkbox
                disabled={false}
                checked={showPinnedUsersOnly}
                defaultChecked={false}
                onChange={(event) => togglePinnedUsersOnly(event.target.checked)}
                className="checkbox"
              />
              Показати закріплених користувачів
            </div>
            <div className="checkbox-showGrouped">
              <Checkbox
                disabled={false}
                checked={showTeachersOnly}
                defaultChecked={false}
                onChange={(event) => toggleTeachersOnly(event.target.checked)}
                className="checkbox"
              />
              Показати Спеціалистів
            </div>
          </div>
        }
        middleContent={
          <div className="middleContent">
            <Tabs tabs={tabs} activeTab={selectedTab} onChange={setSelectedTab}>
              <>
                {fetchingList ? (
                  <div className="progressFetchingMore">
                    <CircularProgress size={18} style={{ color: "var(--main-accent-color)" }} />
                  </div>
                ) : (
                  <>
                    {selectedTab === "Список" && (
                      <NewElementButton
                        className="new-element-button"
                        active={newUserMode}
                        title={"Створити користувача"}
                        activeTitle={"Повернутися до списку"}
                        onClick={() => setNewUserMode(!newUserMode)}
                      />
                    )}
                  </>
                )}
              </>
            </Tabs>
            {selectedTab === "Список" && (
              <div className="unityUsersList-content">
                {newUserMode && <NewUserTile className={"new-user-tile"} onCreateUser={handleCreateUser} />}

                {!newUserMode && (
                  <div className="users-list-wrapper" ref={listInnerRef}>
                    {showGrouped &&
                      sortedGroupedUsers.map((usersGroup, index) => (
                        <React.Fragment key={index}>
                          {usersGroup.length > 1 && (
                            <div className="users-group">
                              {usersGroup.map((unityUser) => (
                                <UnityUserTile
                                  className={`${getTileClassName(unityUser)}`}
                                  key={unityUser._id}
                                  unityUser={unityUser}
                                  onClick={() => handleTileClick(unityUser)}
                                  onDoubleClick={() => {}}
                                  pinned={isPinned(unityUser._id)}
                                  onPushPin={handlePushPin}
                                  onPushHideByDeviceId={handlePushHideByDeviceId}
                                  noPin={["demoUserTemplate", "trialUserTemplate"].includes(unityUser.unityId)}
                                  noHideByDeviceId={["demoUserTemplate", "trialUserTemplate"].includes(
                                    unityUser.unityId
                                  )}
                                />
                              ))}
                            </div>
                          )}
                          {usersGroup.length === 1 && (
                            <UnityUserTile
                              className={`${getTileClassName(usersGroup[0])}`}
                              key={usersGroup[0]._id}
                              unityUser={usersGroup[0]}
                              onClick={() => handleTileClick(usersGroup[0])}
                              onDoubleClick={() => {}}
                              pinned={isPinned(usersGroup[0]._id)}
                              onPushPin={handlePushPin}
                              onPushHideByDeviceId={handlePushHideByDeviceId}
                              noPin={["demoUserTemplate", "trialUserTemplate"].includes(usersGroup[0].unityId)}
                              noHideByDeviceId={["demoUserTemplate", "trialUserTemplate"].includes(
                                usersGroup[0].unityId
                              )}
                            />
                          )}
                        </React.Fragment>
                      ))}

                    {!showGrouped &&
                      sortedUsers.map((unityUser) => (
                        <UnityUserTile
                          className={`${getTileClassName(unityUser)}`}
                          key={unityUser._id}
                          unityUser={unityUser}
                          onClick={() => handleTileClick(unityUser)}
                          onDoubleClick={() => {}}
                          pinned={isPinned(unityUser._id)}
                          onPushPin={handlePushPin}
                          onPushHideByDeviceId={handlePushHideByDeviceId}
                          noPin={["demoUserTemplate", "trialUserTemplate"].includes(unityUser.unityId)}
                          noHideByDeviceId={["demoUserTemplate", "trialUserTemplate"].includes(unityUser.unityId)}
                        />
                      ))}
                  </div>
                )}
              </div>
            )}
            {selectedTab === "Список прихованих" && (
              <div className="unityUsersList-content">
                <div className="users-list-wrapper">
                  {hiddenUsersList.map((unityUser) => (
                    <UnityUserTile
                      className={`${getTileClassName(unityUser)}`}
                      key={unityUser._id}
                      unityUser={unityUser}
                      onClick={() => handleTileClick(unityUser)}
                      onDoubleClick={() => {}}
                      pinned={isPinned(unityUser._id)}
                      hidden={true}
                      onPushPin={handlePushPin}
                      onPushHideByDeviceId={handlePushHideByDeviceId}
                      onPushCancelHideByDeviceId={handlePushCancelHideByDeviceId}
                      noPin={["demoUserTemplate", "trialUserTemplate"].includes(unityUser.unityId)}
                      noHideByDeviceId={["demoUserTemplate", "trialUserTemplate"].includes(unityUser.unityId)}
                    />
                  ))}
                </div>
              </div>
            )}
            {selectedTab === "Статистика" && (
              <div className="newUsersStatistic">
                <UnityUsersPlot unityUserList={unityUserList} />
              </div>
            )}
          </div>
        }
        rightContent={
          <div className="unity-users-right-content">
            <div className="buttons-bar"></div>
          </div>
        }
      />
    </>
  );
};
export default UnityUsers;
