import React, { useContext, createContext } from "react";
import useGameList from "./useGameList";
import useUserList from "./useUserList";
import useImageList from "./useImageList";
import useSoundList from "./useSoundList";
import useTagList from "./useTagList";
import useSettings from "./useSettings";
import useCookiedStates from "./useCookiedStates";
import useCommonStates from "./useCommonStates";
import useItemTemplatesList from "./useItemTemplatesList";
import useStageTemplatesList from "./useStageTemplatesList";
import useAssetBundleList from "./useAssetBundleList";
import useSoundSetList from "./useSoundSetList";
import useKnowledgeTagList from "./useKnowledgeTagList";
import useKnowledgeGroupList from "./useKnowledgeGroupList";
import { Outlet } from "react-router-dom";

export const ResourceProviderLayout = () => {
  return (
    <ResourceProvider>
      <Outlet />
    </ResourceProvider>
  );
};

export const BufferProviderLayout = () => {
  return (
    <BufferProvider>
      <Outlet />
    </BufferProvider>
  );
};

const ResourceContext = createContext("ResourceContext");

export const useResourcesContext = () => {
  return useContext(ResourceContext);
};

export const ResourceProvider = ({ children }) => {
  const value = {
    userListStore: useUserList(),
    soundListStore: useSoundList(),
    imageListStore: useImageList(),
    tagListStore: useTagList(),
    gameListStore: useGameList(),
    settingsStore: useSettings(),
    cookiedStatesStorage: useCookiedStates(),
    itemTemplatesStore: useItemTemplatesList(),
    stageTemplatesStore: useStageTemplatesList(),
    assetBundleListStore: useAssetBundleList(),
    soundSetListStore: useSoundSetList(),
    knowledgeTagListStore: useKnowledgeTagList(),
    knowledgeGroupListStore: useKnowledgeGroupList(),
  };

  return <ResourceContext.Provider value={value}>{children}</ResourceContext.Provider>;
};

const BufferContext = createContext("BufferContext");

export const useBufferContext = () => {
  return useContext(BufferContext);
};
export const BufferProvider = ({ children }) => {
  const value = useCommonStates();
  return <BufferContext.Provider value={value}>{children}</BufferContext.Provider>;
};
