import { useEffect, useState } from "react";
import "./Games.scss";

import { CardStyled, FiltersPanel, GameTile, Header } from "components";
import { fulfillGameData, getFilteredGames } from "helpers/filters/gameFilters/gameFilters";
import { gameFilterStructure } from "components/Filter/gameFilterStructure";
import { useResourcesContext } from "resources";
import useSearchParamWorker from "helpers/useSearchParam";
import GameAddButton from "components/GameAddButton/GameAddButton";
import FetchingProgressWrapper from "pages/Games/FetchingProgressWrapper/FetchingProgressWrapper";

const Games = () => {
  const {
    imageListStore: { getImageList },
    gameListStore: { gameList, fetchingGameList, fetchingExtraData, getGameList, getExtraData },
  } = useResourcesContext();

  const [filters, setFilters] = useState();

  const selectedFilters = useSearchParamWorker();

  useEffect(() => {
    getImageList();
    getGameList();
    getExtraData();
  }, []);

  useEffect(() => {
    return setFilters(fulfillGameData(gameFilterStructure, selectedFilters, gameList));
  }, [selectedFilters.searchParamsAll, gameList]);

  const handleFiltersChange = (modifiedFilters) => {
    modifiedFilters.map((filter) => {
      const value = filter.value.length ? filter.value : null;
      selectedFilters.setSearchParam(filter.name, value);
    });
  };

  const filteredGames = getFilteredGames(gameList, filters);

  return (
    <div className="game-box-wrapper">
      <Header className="game-box-header" />
      <CardStyled className="game-box-container">
        <div className="left-side">
          <FiltersPanel filters={filters} onFiltersChange={handleFiltersChange} />
        </div>
        <div className="right-side">
          <div className="right-side-content">
            <FetchingProgressWrapper
              fetchingGameList={fetchingGameList}
              fetchingExtraData={fetchingExtraData}
            >
              <GameAddButton />
              <div className="games-list">
                {filteredGames.map((game, idx) => {
                  return <GameTile key={idx} game={game} />;
                })}
              </div>
            </FetchingProgressWrapper>
          </div>
        </div>
      </CardStyled>
    </div>
  );
};

export default Games;
