import "./SceneCard.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { AlertDialog, StyledButton, ItemUniversal } from "components";
import { ReactComponent as IconAddToLibrary } from "assets/icons/plus2.svg";
import { ReactComponent as RemoveIcon } from "assets/icons/remove.svg";

import IconEmptyIcon from "assets/icons/emptyIcon.svg";
import { useResourcesContext } from "resources";
import { getItemsWithChildrenPlain } from "helpers/stageItems";
import { CircularProgress } from "@mui/material";
import ItemPlaceholder from "pages/AssetBundleEditor/ItemPlaceholder/ItemPlaceholder";

const SceneCard = ({
  className = "",
  scene,
  selected,
  onClick,
  onDoubleClick,
  onDelete,
  onAddToLibrary,
  showCircularProgress,
}) => {
  const [backgroundSize, setBackgroundSize] = useState(null);
  const backgroundRef = useRef();
  const [alertDialogData, setAlertDialogData] = useState(null);

  const {
    imageListStore: { getImagePathById },
  } = useResourcesContext();

  const sceneItems = useMemo(() => getItemsWithChildrenPlain(scene?.items || []), [scene]);
  const sceneBackground = useMemo(
    () => (scene?.items?.length > 0 ? getImagePathById(scene.items[0]._id) : null),
    [scene, getImagePathById]
  );

  const updateBackgroundSize = () => {
    setBackgroundSize({
      clientHeight: backgroundRef.current?.clientHeight,
      clientWidth: backgroundRef.current?.clientWidth,
      scale1920: 1920 / backgroundRef.current?.clientWidth,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", updateBackgroundSize);
    return () => {
      window.removeEventListener("resize", updateBackgroundSize);
    };
  }, []);

  const handleBackgroundOnLoad = () => {
    updateBackgroundSize();
  };

  const openDeleteDialog = () => {
    setAlertDialogData({
      onOk: () => {
        onDelete(scene);
        setAlertDialogData(null);
      },
      onCancel: () => setAlertDialogData(null),
      title: "Видалення сцени",
      message: "Натисніть Ok для видалення, або Cancel для відміни операції.",
    });
  };

  return (
    <>
      <div
        className={`sceneCard-component ${selected ? "active-sceneCard-component" : ""} ${className}`}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
      >
        <div className="sceneCard-content" id={`sceneCard`}>
          <img
            ref={backgroundRef}
            onLoad={handleBackgroundOnLoad}
            className={`scene-bg ${scene?.items?.length > 0 ? "" : "srcSvg"}`}
            src={sceneBackground || IconEmptyIcon}
            alt="Empty background"
          />

          {backgroundSize &&
            sceneItems.map((item, index) =>
              index === 0 ? null : (
                <div key={index} style={{  }}>
                  {item.type !== "placeholder" && (
                    <ItemUniversal
                      key={index}
                      item={item}
                      backgroundSize={backgroundSize}
                      freeze
                      showSoundIcon={false}
                    />
                  )}
                  {item.type === "placeholder" && (
                    <ItemPlaceholder key={index} item={item} backgroundSize={backgroundSize} />
                  )}
                </div>
              )
            )}
        </div>

        {onDelete && (
          <StyledButton
            tooltipProps={{ title: "Видалити сцену", placement: "right", arrow: true }}
            className="customize-button deleteScene"
            onClick={(e) => {
              openDeleteDialog();
              e.stopPropagation();
            }}
          >
            <RemoveIcon className="deleteScene-button-icon" />
          </StyledButton>
        )}

        {selected && (
          <StyledButton
            tooltipProps={{ title: "Додати до бібліотеки", placement: "right", arrow: true }}
            className="customize-button addToLibrary"
            onClick={(e) => {
              onAddToLibrary(scene);
              e.stopPropagation();
            }}
          >
            <IconAddToLibrary className="addToLibrary-button-icon" />
          </StyledButton>
        )}
        {showCircularProgress && (
          <CircularProgress className="circular-progress" size={80} style={{ color: "#FAA400" }} />
        )}
      </div>
      <AlertDialog alertDialogData={alertDialogData} />
    </>
  );
};
export default SceneCard;
