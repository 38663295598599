import "./KnowledgeGroupSelector.scss";
import React, { useEffect, useState } from "react";
import { Chip, TextField } from "@mui/material/";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { useResourcesContext } from "resources";
import { Popper, Tooltip } from "@material-ui/core";

const filter = createFilterOptions();

const KnowledgeGroupSelector = ({
  className = "",
  inputClassName = "",
  chipClassName,
  label,
  knowledgeGroups,
  value = null,
  groupsStylesData = [],
  onClick,
  onChange,
  disabled,
  useCreateButton = true,
  multiple = false,
}) => {
  const [autocompleteValue, setAutocompleteValue] = useState(multiple ? [] : "");
  const [options, setOptions] = useState([]);

  const {
    knowledgeGroupListStore: { knowledgeGroupList, createKnowledgeGroup },
  } = useResourcesContext();

  useEffect(() => {
    const newOptions =
      (knowledgeGroups && [...knowledgeGroups]) || (knowledgeGroupList && [...knowledgeGroupList]) || [];
    !newOptions.length && newOptions.push({ _id: -2, name: "", isEmptyListOption: true });
    newOptions.push({ _id: -1, name: "", isUndefinedOption: true });
    setOptions(newOptions);
  }, [knowledgeGroups, knowledgeGroupList]);

  useEffect(() => {
    if (multiple) {
      if (!Array.isArray(value)) {
        setAutocompleteValue([]);
      } else {
        const filteredOptions = options.filter((option) => value.includes(option._id));
        const newAutocompleteValue = value
          .map((knowledgeGroupId) => filteredOptions.find((option) => option._id === knowledgeGroupId))
          .filter(Boolean);
        setAutocompleteValue(newAutocompleteValue);
      }
    }

    if (!multiple) {
      setAutocompleteValue(options.find((option) => option._id === value) || "");
    }
  }, [value, multiple, options]);

  const createTag = async (name) => {
    const createdGroup = await createKnowledgeGroup({ name: name });

    if (createdGroup) {
      if (multiple) {
        onChange([...autocompleteValue.map((option) => option._id), createdGroup._id]);
      }
      if (!multiple) {
        onChange(createdGroup._id);
      }
    }
  };

  const handleAutocomleteChangeMultiple = (event, newValue) => {
    event.preventDefault();
    event.stopPropagation();

    if (!newValue.length) {
      setAutocompleteValue([]);
      onChange([]);
      return;
    }

    const addedOption = newValue[newValue.length - 1];

    if (addedOption.isEmptyListOption) {
      return;
    }

    if (addedOption.isCreateButton) {
      createTag(addedOption.paramsInputValue);
      return;
    }

    setAutocompleteValue(newValue);

    onChange(newValue.map((option) => option._id));
  };

  const handleAutocomleteChange = (event, newValue) => {
    event.preventDefault();
    event.stopPropagation();

    if (newValue?.isEmptyListOption) {
      return;
    }
    if (newValue?.isCreateButton) {
      createTag(newValue.paramsInputValue);
      return;
    }

    if (newValue?.isUndefinedOption) {
      setAutocompleteValue(newValue);
      return;
    }

    setAutocompleteValue(newValue);

    onChange(newValue._id);
  };

  const handleFilterOptions = (options, params) => {
    let filtered = filter(options, params);
    if (!params.inputValue) {
      if (filtered.length > 10) {
        filtered.splice(10, filtered.length - 10);
      }
    }
    if (params.inputValue) {
      filtered.sort((a, b) => {
        const aPos = a.name.toLowerCase().indexOf(params.inputValue.toLowerCase());
        const bPos = b.name.toLowerCase().indexOf(params.inputValue.toLowerCase());

        if (aPos > bPos) {
          return 1;
        }
        if (aPos < bPos) {
          return -1;
        }
        if (a.name.length > b.name.length) {
          return 1;
        }

        return -1;
      });
    }

    useCreateButton && params.inputValue &&
      (!filtered.length || !filtered.some((option) => option.name.length === params.inputValue.length)) &&
      filtered.push({ _id: -3, name: "", isCreateButton: true, paramsInputValue: params.inputValue });

    return filtered;
  };

  const renderTags = (value, getTagProps) => {
    return value.map((option, index) => {
      const tagProps = getTagProps({ index });
      let groupClassName = "";
      if (Array.isArray(groupsStylesData)) {
        groupClassName = groupsStylesData.find((elem) => elem._id === option._id)?.className || groupClassName;
      }
      return (
        <Chip
          variant=""
          label={`${option.name}`}
          {...tagProps}
          className={tagProps.className + " " + groupClassName + " " + chipClassName}
        />
      );
    });
  };

  const renderInputMultiple = (params) => {
    return (
      <TextField
        {...params}
        label={label}
        InputProps={{
          ...params.InputProps,
          className: params.InputProps.className + " " + inputClassName,
        }}
      />
    );
  };
  const renderInput = (params) => {
    const textField = (
      <TextField
        {...params}
        label={label}
        InputProps={{
          ...params.InputProps,
          className: params.InputProps.className + " " + inputClassName,
          startAdornment: <span></span>,
        }}
      />
    );

    if (value?.name) {
      return (
        <Tooltip title={value?.name} placement="top" arrow>
          {textField}
        </Tooltip>
      );
    } else {
      return textField;
    }
  };

  const renderOption = (props, option) => {
    return (
      !option.isUndefinedOption && (
        <li {...props} key={option._id}>
          {option.isCreateButton ? (
            <div className="option-box">{`Створити: ${option.paramsInputValue}`}</div>
          ) : option.isEmptyListOption ? (
            <div className="option-box">Щє немає тегів</div>
          ) : option.isUndefinedOption ? null : (
            <div className="option-box">{`${option.name}`}</div>
          )}
        </li>
      )
    );
  };

  return (
    <div className={`knowledgeGroupSelector ${className}`}>
      <Autocomplete
        onClick={onClick}
        //open={true}
        multiple={multiple}
        disableCloseOnSelect={multiple}
        onChange={multiple ? handleAutocomleteChangeMultiple : handleAutocomleteChange}
        value={autocompleteValue}
        disableClearable={multiple || !autocompleteValue}
        autoHighlight
        options={options}
        filterOptions={handleFilterOptions}
        getOptionLabel={(option) => option.name || ""}
        isOptionEqualToValue={(option, value) => (option.isUndefinedOption && !value) || option._id === value?._id}
        renderTags={renderTags}
        renderOption={renderOption}
        renderInput={multiple ? renderInputMultiple : renderInput}
        PopperComponent={(props) => (
          <Popper {...props} className={props.className + " knowledgeGroupSelector-popper"} placement="bottom"></Popper>
        )}
      />
    </div>
  );
};
export default KnowledgeGroupSelector;
