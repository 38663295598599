import "./PlanElementCheckResults.scss";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useResourcesContext } from "resources";
import AddToSoundSetsIcon from "@mui/icons-material/AddToPhotos";
import { Tooltip } from "@mui/material";

const PlanElementCheckResults = ({ planElementCheckResults }) => {
  const [combinations, setCombinations] = useState([]);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const {
    knowledgeTagListStore: { knowledgeTagList: knowledgeTags },
    soundSetListStore: { soundSetList, createSoundSetMany },
    assetBundleListStore: { assetBundleList },
  } = useResourcesContext();

  useEffect(() => {
    if (!planElementCheckResults) {
      setCombinations([]);
      return;
    }

    const getPopulatedKnTagsExtra = (knTagsToPopulate, knTags, soundSets, bundles) => {
      const populatedKnTags = knTagsToPopulate.map((id) => knTags.find((knTag) => knTag._id === id));

      const bindedSoundSets = soundSets.filter((soundSet) => {
        return (
          soundSet.knowledgeTags.length === knTagsToPopulate.length &&
          !soundSet.knowledgeTags.some((knTag) => {
            return !knTagsToPopulate.includes(knTag._id);
          })
        );
      });

      const bindedSoundSetsIds = bindedSoundSets.map((soundSet) => soundSet._id);
      const bindedBundles = bundles.filter((bundle) =>
        bundle.knowledges.some((knowledge) => bindedSoundSetsIds.includes(knowledge.soundSet))
      );
      const hasSoundSets = bindedSoundSets.length > 0;
      const hasBundles = bindedBundles.length > 0;

      return {
        knTags: populatedKnTags,
        knTagsNames: populatedKnTags.map((knTag) => knTag.name),
        hasSoundSets: hasSoundSets,
        hasBundles: hasBundles,
      };
    };

    setCombinations(
      planElementCheckResults.combinationsExtra.map((combination) => {
        return {
          ...combination,
          firstPartExtra: getPopulatedKnTagsExtra(combination.firstPart, knowledgeTags, soundSetList, assetBundleList),
          secondPartExtra: getPopulatedKnTagsExtra(
            combination.secondPart,
            knowledgeTags,
            soundSetList,
            assetBundleList
          ),
          thirdPartExtra: getPopulatedKnTagsExtra(combination.thirdPart, knowledgeTags, soundSetList, assetBundleList),
        };
      })
    );
  }, [planElementCheckResults, knowledgeTags, soundSetList, assetBundleList]);

  const getCommbinationClassName = (combination) => {
    if (combination.hasAllResources) return "has-all-resources-color";
    return "";
  };

  const getCombinationPartClassName = (part) => {
    return !part.hasSoundSets ? "no-soundSets-color" : !part.hasBundles ? "no-bundles-color" : "";
  };

  const getAddButtonStyle = (combination) => {
    return !buttonsDisabled &&
      (!combination.firstPartExtra.hasSoundSets ||
        !combination.secondPartExtra.hasSoundSets ||
        !combination.thirdPartExtra.hasSoundSets)
      ? "active"
      : "";
  };

  const getTooltipTitle = (part) => {
    if (part.hasSoundSets && part.hasBundles) return "";
    if (!part.hasSoundSets) return "Не має наборів звуків";
    if (!part.hasBundles) return "Не має бандлів";
  };

  const handleAddCombinationPartsToSoundSets = (combination) => {
    const newSoundSets = [];

    const addSoundSet = (knowledgeTags) => {
      if (!knowledgeTags.length) return;
      if (newSoundSets.some((soundSet) => !_.difference(soundSet.knowledgeTags, knowledgeTags).length)) return;

      newSoundSets.push({ type: "knowledge", knowledgeTags: knowledgeTags });
    };

    !combination.firstPartExtra.hasSoundSets && addSoundSet(combination.firstPart);
    !combination.secondPartExtra.hasSoundSets && addSoundSet(combination.secondPart);
    !combination.thirdPartExtra.hasSoundSets && addSoundSet(combination.thirdPart);
    if (newSoundSets.length) {
      setButtonsDisabled(true);
      createSoundSetMany(newSoundSets)
        .then(() => {
          setButtonsDisabled(false);
        })
        .catch(() => {
          setButtonsDisabled(false);
        });
    }
  };

  return (
    <div className="planElementCheckResults-component">
      <div className="controlFormula-block">{planElementCheckResults.controlFormula}</div>
      <div className="formulaBasedCombinations">
        {combinations.map((combination, index) => (
          <div className="combination-block" key={index}>
            <div className={`combination ${getCommbinationClassName(combination)}`} key={index}>
              <Tooltip title={getTooltipTitle(combination.firstPartExtra)}>
                <div className={`firstPartExtra ${getCombinationPartClassName(combination.firstPartExtra)}`}>
                  {combination.firstPartExtra.knTagsNames.length > 1
                    ? combination.firstPartExtra.knTagsNames.join(" ")
                    : combination.firstPartExtra.knTagsNames.join("")}
                </div>
              </Tooltip>
              _
              <Tooltip title={getTooltipTitle(combination.secondPartExtra)}>
                <div className={`secondPartExtra ${getCombinationPartClassName(combination.secondPartExtra)}`}>
                  {combination.secondPartExtra.knTagsNames.length > 1
                    ? combination.secondPartExtra.knTagsNames.join(" ")
                    : combination.secondPartExtra.knTagsNames.join("")}
                </div>
              </Tooltip>
              .
              <Tooltip title={getTooltipTitle(combination.thirdPartExtra)}>
                <div className={`thirdPartExtra ${getCombinationPartClassName(combination.thirdPartExtra)}`}>
                  {combination.thirdPartExtra.knTagsNames.length > 1
                    ? combination.thirdPartExtra.knTagsNames.join(" ")
                    : combination.thirdPartExtra.knTagsNames.join("")}
                </div>
              </Tooltip>
            </div>
            <div
              className={`addToSoundSets-button ${getAddButtonStyle(combination)}`}
              onClick={() => handleAddCombinationPartsToSoundSets(combination)}
            >
              <AddToSoundSetsIcon />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PlanElementCheckResults;
