import "./UserProfile.scss";
import { CardStyled } from "components";
import { useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Tabs from "../Tabs/Tabs";
import CommonTab from "./CommonTab/CommonTab";
import PlanParamsTab from "./PlanParamsTab/PlanParamsTab";
import CommonGameParamsTab from "./CommonGameParamsTab/CommonGameParamsTab";
import NotificationTab from "./NotificationTab/NotificationTab";
import AboutChild from "./AboutChild/AboutChild";

const tabs = [
  { label: "Загальні" },
  { label: "Про дитину" },
  { label: "Параметри плану" },
  { label: "Параметри ігр" },
  { label: "Повідомлення" },
];

const UserProfile = ({ unityUser, onUserChange, unityIdBlocked, onDeleteUser, onCopyUser, onClearStatistic }) => {
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  return (
    <div className="user-profile-component">
      <ToastContainer
        className={"toast-position"}
        toastClassName="toastClassName"
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="lig}ht"
      />
      {unityUser && (
        <>
          <CardStyled className="user-profile-tabs-block">
            <Tabs tabs={tabs} selectedTabs={[selectedTab]} onClick={setSelectedTab} />
          </CardStyled>
          {selectedTab.label === "Загальні" && (
            <CommonTab
              unityUser={unityUser}
              toast={toast}
              unityIdBlocked={unityIdBlocked}
              onUserChange={onUserChange}
              onClearStatistic={onClearStatistic}
              onDeleteUser={onDeleteUser}
              onCopyUser={onCopyUser}
            />
          )}
          {selectedTab.label === "Параметри плану" && (
            <PlanParamsTab unityUser={unityUser} onUserChange={onUserChange} />
          )}
          {selectedTab.label === "Параметри ігр" && (
            <CommonGameParamsTab unityUser={unityUser} onUserChange={onUserChange} />
          )}

          {selectedTab.label === "Повідомлення" && (
            <NotificationTab unityUser={unityUser} onUserChange={onUserChange} toast={toast} />
          )}
          {selectedTab.label === "Про дитину" && <AboutChild unityUser={unityUser} onUserChange={onUserChange} />}
        </>
      )}
    </div>
  );
};

export default UserProfile;
