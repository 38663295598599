import "./SoundSetList.scss";
import React, { useMemo } from "react";

const SoundSetList = ({ soundSetList, onClick, selectedSoundSet }) => {
  const sortedSoundSets = useMemo(() => {
    const soundSetHasProblems = (soundSet) => {
      const soundSetName = soundSet.ukSoundName || soundSet.ruSoundName || soundSet.enSoundName;
      if (!soundSetName) {
        return true;
      }
      return false;
    };

    return soundSetList.sort((a, b) => {
      const aSoundSetHasProblems = soundSetHasProblems(a);
      const bSoundSetHasProblems = soundSetHasProblems(b);
      if (aSoundSetHasProblems && bSoundSetHasProblems) {
        return 0;
      } else if (aSoundSetHasProblems) {
        return -1;
      } else if (bSoundSetHasProblems) {
        return 1;
      } else {
        const aSoundSetName = (a.ukSoundName || a.ruSoundName || a.enSoundName).toLowerCase();
        const bSoundSetName = (b.ukSoundName || b.ruSoundName || b.enSoundName).toLowerCase();
        return aSoundSetName.localeCompare(bSoundSetName);
      }
    });
  }, [soundSetList]);

  const getAlertStyle = (soundSet) => {
    const soundSetName = soundSet.ukSoundName || soundSet.ruSoundName || soundSet.enSoundName;
    if (!soundSetName) {
      return "alarm_noSoundName";
    }
    return "";
  };

  return (
    <div className="soundSetList-component">
      <div className="soundSetList">
        {sortedSoundSets.map((soundSet) => (
          <div
            className={`soundSetCard ${selectedSoundSet?._id === soundSet._id ? "selected" : ""} ${getAlertStyle(
              soundSet
            )}`}
            onClick={() => onClick(soundSet._id)}
          >
            <div className="nameRow">
              <div className="nameLabel">
                {soundSet.ukSoundName || soundSet.ruSoundName || soundSet.enSoundName || "Звук не обраний"}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SoundSetList;
