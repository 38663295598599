import React from "react";
import { useI18N } from "i18n";

import "./ConfirmationBox.scss";

const ConfirmationBox = ({ confirmTextId = "confirmTestId", onConfirm, onCancel }) => {
  const { I18N } = useI18N();
  return (
    <div className="custom-confirm-box">
      <p>
        <I18N id={confirmTextId} />
      </p>
      <div className="custom-confirm-box__buttons">
        <button onClick={onConfirm}>
          <I18N id={"confirm_yes"} />
        </button>
        <button onClick={onCancel}>
          <I18N id={"confirm_no"} />
        </button>
      </div>
    </div>
  );
};

export default ConfirmationBox;
