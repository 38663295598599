import "./Overview.scss";
import React from "react";
import { useI18N } from "i18n";
import wave from "assets/landing/wave.svg";
import { getDigitalOceanUrl } from "helpers/getDigitalOceanUrl";

const BULBEE_INTRO_VIDEO_URL = getDigitalOceanUrl(window.location.host) + "/video/bulbee_intro.MP4";
const BULBEE_INTRO_POSTER_URL = getDigitalOceanUrl(window.location.host) + "/video/bulbeeintro_poster.jpg";

const Overview = () => {
  const { I18N, i18n } = useI18N();

  return (
    <section className="overview">
      <div className="overview-top">
        <div className="overview-text">
          <I18N id="overview_header" />{" "}
          <span>
            <I18N id="overview_child" />
          </span>
        </div>
        <img src={wave} alt="wave icon" className="wave-icon" />
      </div>
      <div className="overview-bottom">
        <div className="video-wrapper">
          <video controls playsinline controlsList="nodownload" poster={BULBEE_INTRO_POSTER_URL}>
            <source src={BULBEE_INTRO_VIDEO_URL} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </section>
  );
};

export default Overview;
