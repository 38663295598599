import "./AboutChild.scss";
import { useEffect, useMemo, useState } from "react";
import { CardStyled, DropdownSelector, StyledInput } from "components";
import _ from "lodash";

import calculateAge from "helpers/calculateAge";

const genders = [
  { label: "Хлопчик", name: "male" },
  { label: "Дівчинка", name: "female" },
];

const AboutChild = ({ unityUser, onUserChange }) => {
  const [aboutChild, setAboutChild] = useState("");
  const [gender, setGender] = useState("");
  const [name, setName] = useState("");
  const [contactName, setContactName] = useState("");
  const [birthdayMonth, setBirthdayMonth] = useState("");
  const [birthdayYear, setBirthdayYear] = useState("");

  const age = useMemo(() => {  
    return calculateAge(birthdayYear, birthdayMonth);
  }, [unityUser]);

  useEffect(() => {
    if (unityUser) {
      setGender(unityUser.gender || "");
      setAboutChild(unityUser.aboutChild || "");
      setName(unityUser.name || "");
      setContactName(unityUser.contactName || "");
      setBirthdayMonth(unityUser.birthdayMonth || "");
      setBirthdayYear(unityUser.birthdayYear || "");
    }
  }, [unityUser]);

  const userChangeDebounced = useMemo(
    () =>
      _.debounce((user) => {
        onUserChange(user);
      }, 1000),
    []
  );

  return (
    <CardStyled className="aboutChild-tab-content">
      <div className="name-block">
        <div className="label">Ім'я дитини:</div>
        <StyledInput
          className="input"
          placeholder={"Введіть імʼя користувача"}
          value={name}
          onKeyPress={(e) => e.key === "Enter" && onUserChange({ ...unityUser, name: name })}
          onBlur={() => onUserChange({ ...unityUser, name: name })}
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
      </div>
      <div className="name-block">
        <div className="label">Ім'я контакта:</div>
        <StyledInput
          className="input"
          placeholder={"Введіть імʼя контакта"}
          value={contactName}
          onKeyPress={(e) => e.key === "Enter" && onUserChange({ ...unityUser, contactName: contactName })}
          onBlur={() => onUserChange({ ...unityUser, contactName: contactName })}
          onChange={(event) => {
            setContactName(event.target.value);
          }}
        />
      </div>

      <div className="birthdayMonth-block">
        <div className="label">Місяць народження:</div>
        <StyledInput
          className="input"
          value={birthdayMonth}
          placeholder={"Місяць"}
          onKeyPress={(e) => e.key === "Enter" && onUserChange({ ...unityUser, birthdayMonth: birthdayMonth })}
          onBlur={() => onUserChange({ ...unityUser, birthdayMonth: birthdayMonth })}
          onChange={(event) => {
            setBirthdayMonth(event.target.value);
          }}
        />
        <div>{` (наприклад, місяць - 11, рік - 2012)`}</div>
      </div>
      <div className="birthdayYear-block">
        <div className="label">Рік народження:</div>
        <StyledInput
          className="input"
          value={birthdayYear}
          placeholder={"Рік"}
          onKeyPress={(e) => e.key === "Enter" && onUserChange({ ...unityUser, birthdayYear: birthdayYear })}
          onBlur={() => onUserChange({ ...unityUser, birthdayYear: birthdayYear })}
          onChange={(event) => {
            setBirthdayYear(event.target.value);
          }}
        />
        <div className="age-block">{`Вік: ${age}`}</div>
      </div>

      <div className="genderSelector-block">
        <div className="genderSelector-label">Стать дитини:</div>
        <DropdownSelector
          className="genderSelector"
          disabled={!unityUser}
          withNone={false}
          options={genders}
          selectedOption={genders.find((elem) => elem.name === gender) || ""}
          onChange={(value) => {
            setGender(value);
            const newValue = value?.name || "";
            onUserChange({ ...unityUser, gender: newValue });
          }}
        />
      </div>

      <div className={"aboutChild-block"}>
        <div className="aboutChild-label">Про дитину</div>
        <textarea
          className="aboutChild-input"
          rows={5}
          placeholder={"Про дитину"}
          value={aboutChild}
          onChange={(event) => {
            setAboutChild(event.target.value);
            userChangeDebounced({ ...unityUser, aboutChild: event.target.value });
          }}
        />
      </div>
    </CardStyled>
  );
};

export default AboutChild;
