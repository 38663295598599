import "./SoundSelector.scss";
import React, { useEffect, useState } from "react";
import { ReactComponent as IconMic } from "assets/icons/RecorderMic.svg";
import { ReactComponent as IconPlay } from "assets/icons/Play.svg";
import { ReactComponent as IconPause } from "assets/icons/Pause.svg";
import { ReactComponent as IconClearSound } from "assets/icons/ExclamationCircleRed.svg";
import { Chip, TextField } from "@mui/material/";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import RoundButton from "components/RoundButton/RoundButton";
import { useMemo } from "react";
import AudioRecorderModal from "components/AudioRecorder/AudioRecorderModal";
import { useResourcesContext } from "resources";
import { Popper, Tooltip } from "@material-ui/core";
import LinkIcon from "@mui/icons-material/Link";

const filter = createFilterOptions();

const SoundSelector = ({
  className = "",
  label,
  sounds,
  value = null,
  tagsStylesData = [],
  onClick,
  onChangeValue,
  onSoundRecorded,
  createSoundInDatabase = true,
  disabled,
  multiple = false,
}) => {
  const [autocompleteValue, setAutocompleteValue] = useState(multiple ? [] : "");
  const [playingOption, setPlayingOption] = useState();

  const [audioRecorderConfig, setAudioRecorderConfig] = useState();

  const {
    soundListStore: { soundList, createSound },
  } = useResourcesContext();

  const options = useMemo(() => {
    const list = [...(sounds || soundList || [])];
    return [...list, { _id: -2, name: "", isUndefinedOption: true }];
  }, [sounds, soundList]);

  useEffect(() => {
    if (multiple) {
      setAutocompleteValue(Array.isArray(value) ? value : []);
    }

    if (!multiple) {
      setAutocompleteValue(value || "");
    }
  }, [value, multiple]);

  const audioPlayer = useMemo(() => {
    const audioPlayer = new Audio();
    audioPlayer.onended = () => setPlayingOption(null);
    return audioPlayer;
  }, []);

  useEffect(() => {
    return () => {
      audioPlayer.pause();
    };
  }, [audioPlayer]);

  useEffect(() => {
    audioPlayer.pause();
  }, [autocompleteValue, audioPlayer]);

  const handleAutocomleteChange = (event, newValue) => {
    event.preventDefault();
    event.stopPropagation();

    setAutocompleteValue(newValue);
    onChangeValue(newValue);
  };

  const handlePlay = (sound) => {
    if (sound === playingOption) {
      if (audioPlayer.paused) {
        setPlayingOption(sound);
        audioPlayer.src = sound.path;
        audioPlayer.play();
      } else {
        setPlayingOption(null);
        audioPlayer.pause();
      }
    }
    if (sound !== playingOption) {
      setPlayingOption(sound);
      !audioPlayer.paused && audioPlayer.pause();
      audioPlayer.src = sound.path;
      audioPlayer.play();
    }
  };

  const handleFilterOptions = (options, params) => {
    let filtered = filter(options, params);
    if (!params.inputValue) {
      if (filtered.length > 10) {
        filtered.splice(10, filtered.length - 10);
      }
    }

    if (params.inputValue) {
      filtered.sort((a, b) => {
        const aPos = a.name.toLowerCase().indexOf(params.inputValue.toLowerCase());
        const bPos = b.name.toLowerCase().indexOf(params.inputValue.toLowerCase());

        if (aPos > bPos) {
          return 1;
        }

        if (aPos < bPos) {
          return -1;
        }

        if (a.name.length > b.name.length) {
          return 1;
        }

        return -1;
      });
    }

    onSoundRecorded && filtered.push({ _id: -1, name: "", recordButton: true });
    filtered.push({ _id: -2, name: "", isUndefinedOption: true });
    return filtered;
  };

  const renderTags = (value, getTagProps) => {
    return value.map((option, index) => {
      const tagProps = getTagProps({ index });
      let tagClassName = "";
      if (Array.isArray(tagsStylesData)) {
        tagClassName = tagsStylesData.find((elem) => elem._id === option?._id)?.className || tagClassName;
      }
      return (
        <Chip variant="" label={`${option?.name}`} {...tagProps} className={tagProps.className + " " + tagClassName} />
      );
    });
  };

  const renderInputMultiple = (params) => {
    return (
      <TextField
        {...params}
        label={label}
        InputProps={{
          ...params.InputProps,
          onKeyUp: (e) => {
            //if (e.key === "Backspace" || e.key === "Delete") {
            e.stopPropagation();
            e.preventDefault();
            //}
          },
        }}
      />
    );
  };
  const renderInput = (params) => {
    const textField = (
      <TextField
        {...params}
        label={label}
        InputProps={{
          ...params.InputProps,
          onKeyUp: (e) => {
            e.stopPropagation();
            e.preventDefault();
          },
          startAdornment: !autocompleteValue ? (
            onSoundRecorded ? (
              <RoundButton
                icon={IconMic}
                className="rec-btn"
                onClick={(event) => {
                  event.stopPropagation();
                  recordSound();
                }}
                disabled={disabled}
                tooltipTitle="Запис звуку"
              />
            ) : null
          ) : autocompleteValue ? (
            <>
              <RoundButton
                icon={playingOption === autocompleteValue ? IconPause : IconPlay}
                className={`play-btn ${autocompleteValue.draft ? "draft-sound-fill" : ""}`}
                disabled={disabled}
                tooltipTitle="Програти звук"
                onClick={(event) => {
                  handlePlay(autocompleteValue);
                  event.stopPropagation();
                }}
              />
              <span>{"| "}</span>
            </>
          ) : (
            <span></span>
          ),
        }}
      />
    );

    if (value?.name) {
      return (
        <Tooltip title={value?.name} placement="top" arrow>
          {textField}
        </Tooltip>
      );
    } else {
      return textField;
    }
  };

  const recordSound = () => {
    setAudioRecorderConfig({
      onConfirm: (soundBlob, soundUrl, soundName, soundDuration, language) => {
        setAudioRecorderConfig(null);
        const fileName = `SoundDraft`;
        const fullFileName = fileName + "." + soundBlob.type.split("/")[1];
        const newSound = {
          name: soundName || fileName,
          draft: true,
          path: new File([soundBlob], fullFileName, { type: soundBlob.type }),
          duration: soundDuration,
          language: language,
        };
        if (createSoundInDatabase) {
          createSound(newSound, { assignUser: true }).then((createdSound) => onSoundRecorded(createdSound));
        } else {
          onSoundRecorded(newSound);
        }
      },
      onCancel: () => setAudioRecorderConfig(null),
    });
  };

  const renderOption = (props, option, state) =>
    !option.isUndefinedOption && (
      <li {...props} key={option._id}>
        {!option.recordButton && (
          <div className="option-box">
            <div className="play-btn-and-separator">
              <RoundButton
                icon={playingOption === option ? IconPause : IconPlay}
                className={`play-btn ${option.draft ? "draft-sound-fill" : ""}`}
                disabled={disabled}
                tooltipTitle="Програти звук"
                onClick={(event) => {
                  event.stopPropagation();
                  handlePlay(option);
                }}
              />
              <span>{"| "}</span>
            </div>
            <div className="option-details">
              <div className="option-name">{option.name}</div>
              <div className="option-additional-info">
                <div className={`option-bind ${!option.soundSetId ? "display-none" : ""}`}>
                  <Tooltip title="Присутній звʼязок" placement="top" arrow>
                    <LinkIcon />
                  </Tooltip>
                </div>
                <div className={`option-language ${!option.language ? "display-none" : ""}`}>{option.language}</div>
                <div className={`option-author ${!option.user ? "display-none" : ""}`}>{option.user}</div>
              </div>
            </div>

            <div className="clear-sound-icon">
              {option.draft && (
                <Tooltip title="Draft sound" placement="top" arrow>
                  <IconClearSound />
                </Tooltip>
              )}
            </div>
          </div>
        )}
        {option.recordButton && (
          <div className="rec-btn-box">
            <RoundButton
              icon={IconMic}
              className="rec-btn"
              onClick={(event) => {
                event.stopPropagation();
                recordSound();
              }}
              disabled={disabled}
              tooltipTitle="Запис звуку"
            />
          </div>
        )}
      </li>
    );

  return (
    <div className={`soundSelector ${className}`}>
      <Autocomplete
        onClick={(event) => {
          event.stopPropagation();
          onClick && onClick(event);
        }}
        //open={true}
        multiple={multiple}
        disableCloseOnSelect={multiple}
        onChange={handleAutocomleteChange}
        value={autocompleteValue}
        disableClearable={multiple || !autocompleteValue}
        autoHighlight
        options={options}
        filterOptions={handleFilterOptions}
        getOptionLabel={(option) => option.name || ""}
        isOptionEqualToValue={(option, value) =>
          (option.isUndefinedOption && !value) || String(option._id) === String(value?._id)
        }
        renderTags={renderTags}
        renderOption={renderOption}
        renderInput={multiple ? renderInputMultiple : renderInput}
        PopperComponent={(props) => (
          <Popper {...props} className={props.className + " soundSelector-popper"} placement="bottom"></Popper>
        )}
      />

      <AudioRecorderModal audioRecorderConfig={audioRecorderConfig} />
    </div>
  );
};
export default SoundSelector;
