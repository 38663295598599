import "./KnowledgeGroup.scss";
import React, { useEffect, useState, useMemo } from "react";
import { ReactComponent as IconRemove } from "assets/icons/remove.svg";
import { StyledInput, StyledButton, KnowledgeTagSelector, KnowledgeGroupSelector, DropdownSelector } from "components";
import { Checkbox } from "@mui/material";
import { useResourcesContext } from "resources";

const KnowledgeGroup = ({ value, onChange, onDelete }) => {
  const [availableDependsOnGroups, setAvailableDependsOnGroups] = useState([]);
  const [availableStudiesAtGroups, setAvailableStudiesAtGroups] = useState([]);

  const {
    knowledgeGroupListStore: { knowledgeGroupList },
  } = useResourcesContext();

  const levels = useMemo(() => {
    const levels = [];
    for (let i = 1; i <= 15; i++) {
      levels.push({ value: i, label: i.toString() });
    }
    return levels;
  }, []);

  useEffect(() => {
    setAvailableDependsOnGroups(
      knowledgeGroupList.filter((group) => {
        if (group._id === value._id) {
          return false;
        }

        const getDependsOnRecursive = (dependsOnGroups = []) => {
          return dependsOnGroups.reduce((prev, groupId) => {
            const group = knowledgeGroupList.find((group) => group._id === groupId);
            return [...prev, groupId, ...getDependsOnRecursive(group.dependsOnGroups)];
          }, []);
        };

        const dependsOn = getDependsOnRecursive(group.dependsOnGroups);

        return !dependsOn.includes(value._id);
      })
    );
  }, [knowledgeGroupList, value]);

  useEffect(() => {
    setAvailableStudiesAtGroups(
      knowledgeGroupList.filter((group) => {
        if (group._id === value._id) {
          return false;
        }

        const getStudiesAtRecursive = (studiesAtGroups = []) => {
          return studiesAtGroups.reduce((prev, groupId) => {
            const group = knowledgeGroupList.find((group) => group._id === groupId);
            return [
              ...prev,
              groupId,
              ...getStudiesAtRecursive(group.studiesAtGroups0),
              ...getStudiesAtRecursive(group.studiesAtGroups1),
            ];
          }, []);
        };

        const studiesAt = [
          ...getStudiesAtRecursive(group.studiesAtGroups0),
          ...getStudiesAtRecursive(group.studiesAtGroups1),
        ];
        return !studiesAt.includes(value._id);
      })
    );
  }, [knowledgeGroupList, value]);

  const handleChangeStudiesAtGroups0 = (knowledgeGroups) => {
    onChange({ ...value, studiesAtGroups0: knowledgeGroups });
  };
  const handleChangeStudiesAtGroups1 = (knowledgeGroups) => {
    onChange({ ...value, studiesAtGroups1: knowledgeGroups });
  };

  return (
    <div className="knowledgeGroup-component">
      <div className="top-wrapper">
        <div className="title">{value.name}</div>

        <StyledButton
          tooltipProps={{
            arrow: true,
            className: "",
            title: "Видалити знання",
          }}
          title="delete"
          onClick={onDelete}
          className="customize-button"
        >
          <IconRemove className="button-icon" />
        </StyledButton>
      </div>
      <div className="nameInput-wrapper">
        <StyledInput
          className="nameInput"
          //title="Знання"
          value={value.name || ""}
          placeholder="Назва групи знань"
          onChange={(event) => onChange({ ...value, name: event.target.value })}
        />
        <div className="checkbox-block">
          <Checkbox
            disabled={false}
            checked={value.antagonists}
            onChange={() => {
              onChange({ ...value, antagonists: Boolean(!value.antagonists) });
            }}
            className=""
          />
          Антагоністи
        </div>
      </div>
      <div className="knowledgeTagsSelector-label">Знання:</div>
      <div className="knowledgeTags-wrapper">
        <KnowledgeTagSelector
          inputClassName="knowledgeTagsSelectorInput"
          chipClassName={"knowledgeTagsSelectorChip"}
          multiple
          onChange={(knowledgeTags) => onChange({ ...value, knowledgeTags: knowledgeTags })}
          value={value.knowledgeTags}
        />
      </div>
      <div className="levelInput-wrapper">
        <DropdownSelector
          className="levelDropdownSelector"
          title="Рівень"
          withNone={false}
          selectedOption={levels.find((level) => level.value === value.level) || levels[0]}
          onChange={(level) => onChange({ ...value, level: level.value })}
          options={levels}
        />
      </div>
      <div className="dependsOnGroupsSelector-label">Залежить від:</div>
      <div className="dependsOnGroups-wrapper">
        <KnowledgeGroupSelector
          inputClassName="knowledgeTagsSelectorInput"
          chipClassName={"knowledgeTagsSelectorChip"}
          multiple
          useCreateButton={false}
          knowledgeGroups={availableDependsOnGroups}
          onChange={(knowledgeGroups) => onChange({ ...value, dependsOnGroups: knowledgeGroups })}
          value={value.dependsOnGroups}
        />
      </div>
      <div className="studiesAtGroups0Selector-label">Вивчається на:</div>
      <div className="studiesAtGroups0-wrapper">
        <KnowledgeGroupSelector
          inputClassName="knowledgeTagsSelectorInput"
          chipClassName={"knowledgeTagsSelectorChip"}
          multiple
          useCreateButton={false}
          knowledgeGroups={availableStudiesAtGroups.filter(
            (group) => [value.studiesAtGroups1 || []].indexOf(group._id) === -1
          )}
          onChange={handleChangeStudiesAtGroups0}
          value={value.studiesAtGroups0}
        />
      </div>
      <div className="studiesAtGroups1Selector-label">та</div>
      <div className="studiesAtGroups1-wrapper">
        <KnowledgeGroupSelector
          inputClassName="knowledgeTagsSelectorInput"
          chipClassName={"knowledgeTagsSelectorChip"}
          multiple
          useCreateButton={false}
          knowledgeGroups={availableStudiesAtGroups.filter(
            (group) => (value.studiesAtGroups0 || []).indexOf(group._id) === -1
          )}
          onChange={handleChangeStudiesAtGroups1}
          value={value.studiesAtGroups1}
        />
      </div>
    </div>
  );
};

export default KnowledgeGroup;
