import "./NotificationTab.scss";
import React, { useEffect, useState } from "react";
import { CardStyled, StyledButton, StyledInput } from "components";
import axios from "axios";
import { Checkbox } from "@mui/material";

const NotificationTab = ({ toast, unityUser, onUserChange }) => {
  const [notifications, setNotifications] = useState([]);
  const [notification, setNotification] = useState({ token: "", title: "", body: "" });

  useEffect(() => {
    getNotification();
  }, []);

  useEffect(() => {
    if (unityUser) {
      setNotification((prev) => {
        return { ...prev, token: unityUser.notificationToken };
      });
    }
  }, [unityUser]);

  const sendNotification = (notificationData) => {
    axios
      .post("/api/notify", { ...notificationData, unityUserId: unityUser._id })
      .then((res) => {
        toast.success("Відправлено успішно. Id: " + res.data, { toastId: "1" });
      })
      .catch((error) => {
        toast.error("Не відправлено. " + error.response?.data?.code + " " + error.response?.data?.message, {
          toastId: "1",
        });
      });
  };

  const getNotification = () => {
    axios
      .get("/api/notify/" + unityUser._id)
      .then((res) => {
        setNotifications(res.data);
      })
  };

  return (
    <CardStyled className="notification-tab-content">
      <div className={"title-block"}>
        <div className="label">Заголовок</div>
        <StyledInput
          className="input-title"
          placeholder={"Заголовок повідомлення"}
          value={notification.title}
          onChange={(event) => {
            setNotification((prev) => {
              return { ...prev, title: event.target.value };
            });
          }}
        />
      </div>
      <div className={"body-block"}>
        <div className="label">Повідомлення</div>
        <StyledInput
          className="input-body"
          placeholder={"Текст повідомлення"}
          value={notification.body}
          onChange={(event) => {
            setNotification((prev) => {
              return { ...prev, body: event.target.value };
            });
          }}
        />
        <StyledButton className="send-button" title="Відправити" onClick={() => sendNotification(notification)} />
      </div>

      <div className={`showCommunicationRequestCheckbox-block`}>
        <Checkbox
          disabled={false}
          checked={unityUser.showCommunicationRequest}
          defaultChecked={false}
          onChange={(event) => onUserChange({ ...unityUser, showCommunicationRequest: event.target.checked })}
          className="checkbox"
        />
        показати вікно з запитом на звʼязок
      </div>

      <div className="notifications-list">
        {notifications.map((message) => (
          <CardStyled className="notification-card" key={message.id}>
            <div>Заголовок: {message.title}</div>
            <div>Повідомлення: {message.body}</div>
          </CardStyled>
        ))}
      </div>
    </CardStyled>
  );
};

export default NotificationTab;
