import PrettoSlider from "@material-ui/core/Slider";
import "./SliderWithInput.scss";

const SliderWithInput = ({
  className = "",
  title,
  value,
  min,
  max,
  onChange,
  onBlur = () => { },
  onKeyDown = () => { },
  onlyInput,
  disabled,
}) => {
  return (
    <div className={`slider-with-input ${className}`}>
      <span className="slider-title">{title}</span>
      {!onlyInput && (
        <PrettoSlider
          disabled={disabled}
          className="slider-range"
          value={value}
          onChange={(e, newValue) => {
            onChange(newValue);
          }}
          min={min}
          max={max}
        />
      )}
      <input
        disabled={disabled}
        className="slider-input"
        value={value}
        type="number"
        min={min}
        max={max}
        onChange={(e) => {
          if (e.target.value === "") {
            onChange("");
            return;
          }
          onChange(Number(e.target.value));
        }}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
      />
    </div>
  );
};

export default SliderWithInput;
