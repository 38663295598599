import React from "react";
import { ReactComponent as ExclamationCircleRed } from "assets/icons/ExclamationCircleRed.svg";

const ErrorsIcon = ({ className, title }) => {
  return (
    <div className={className} title={title}>
      <ExclamationCircleRed />
    </div>
  );
};
export default ErrorsIcon;