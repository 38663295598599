import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ReactDOM from "react-dom";
import React from "react";

const AlertDialog = ({ alertDialogData }) => {
  return (
    <div>
      {alertDialogData && (
        <Dialog
          open={alertDialogData ? true : false}
          onClose={alertDialogData.onCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{alertDialogData.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{alertDialogData.message}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={alertDialogData.onOk} color="primary">
              Ok
            </Button>
            <Button onClick={alertDialogData.onCancel} color="primary" autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export const openAlertDialog = (title, message, onOk, onCancel) => {
  const container = document.createElement("div");
  document.body.appendChild(container);

  ReactDOM.render(
    <AlertDialog
      alertDialogData={{
        onOk: () => {
          onOk && onOk();
          ReactDOM.unmountComponentAtNode(container);
          container.remove();
        },
        onCancel: () => {
          onCancel && onCancel();
          ReactDOM.unmountComponentAtNode(container);
          container.remove();
        },
        title: title,
        message: message,
      }}
    />,
    container
  );
};

export default AlertDialog;
