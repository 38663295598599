import axios from "axios";

const stageTemplatesApi = {
  getAll: () => {
    return axios.get("/api/stageTemplates").then((res) => res.data);
  },

  deleteOne: (id) => {
    return axios.delete(`/api/stageTemplates/${id}`).then((res) => res.data);
  },

  create: (fields) => {
    const formData = new FormData();
    typeof fields.template === "object" && formData.append("template", JSON.stringify(fields.template));
    typeof fields.icon === "object" && formData.append("iconFile", fields.icon);
    typeof fields.icon === "string" && formData.append("icon", fields.icon);

    // return fetch(`/api/stageTemplates`, {
    //   method: "POST",
    //   body: formData,
    //   headers: {},
    // }).then((res) => res.json());

    return axios
      .post(`/api/stageTemplates`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => res.data);
  },
};

export default stageTemplatesApi;
