import { useCallback, useEffect, useState } from "react";
import { states, filterStructure } from "./unityUsersFilterStructure";
import useSearchParamWorker from "helpers/useSearchParam";
import unityUserListApi from "API/unityUserListApi";

const findFilterValue = (filterName, filterPanelData) => {
  const filterData = filterPanelData.find((elem) => elem.name === filterName);
  return filterData.value ? filterData.value : null;
};

const userWithStates = (unityUser, filterPanelData) => {
  const searchValue = findFilterValue("states", filterPanelData);

  if (searchValue?.length) {
    const condition1 = searchValue.includes(states.STATE_STAT.value) ? Boolean(unityUser.lastActivityDate) : true;
    const condition2 = searchValue.includes(states.STATE_NOT_STAT.value) ? !unityUser.lastActivityDate : true;
    const condition3 =
      searchValue.includes(states.STATE_STAT.value) && searchValue.includes(states.STATE_NOT_STAT.value);

    return (condition1 && condition2) || condition3;
  } else {
    return true;
  }
};

const getFilter = (filterPanelData, filterName) => {
  return filterPanelData.find((elem) => elem.name === filterName);
};

const filterGroupedList = (groupedList, filterPanelData) => {
  const check = (handler) => (group) => group.some((user) => handler(user, filterPanelData));

  return groupedList.filter(check(userWithStates));
};

const useFilteredUnityUserList = () => {
  const { setSearchParam, getSearchParam } = useSearchParamWorker();
  const [fetchingList, setFetchingList] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [showPinnedUsersOnly, setShowPinnedUsersOnly] = useState(getSearchParam("pinnedUsers") === "true");
  const [showTeachersOnly, setShowTeachersOnly] = useState(getSearchParam("teachers") === "true");

  const initFilterPanelData = (filterStructure) => {
    const emailParam = getSearchParam("email");
    const nameParam = getSearchParam("name");
    const unityIdParam = getSearchParam("unityId");
    const statesParam = getSearchParam("states");

    const emailFilterData = { ...getFilter(filterStructure, "email"), value: emailParam || "" };
    const nameFilterData = { ...getFilter(filterStructure, "name"), value: nameParam || "" };
    const unityIdFilterData = { ...getFilter(filterStructure, "unityId"), value: unityIdParam || "" };
    const statesFilterData = {
      ...getFilter(filterStructure, "states"),
      value: statesParam ? statesParam.split(",") : [],
    };

    return [nameFilterData, emailFilterData, unityIdFilterData, statesFilterData];
  };

  const [unityUserList, setUnityUserList] = useState([]);
  const [filteredUnityUserList, setFilteredUnityUserList] = useState([]);
  const [filteredGroupedUnityUserList, setFilteredGroupedUnityUserList] = useState([]);
  const [filterPanelData, setFilterPanelData] = useState(initFilterPanelData(filterStructure));
  const [fulfilledFilterPanelData, setFullFilledFilterPanelData] = useState(filterPanelData);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const applyFilterPanelData = (newFilterPanelData) => {
    const emailFilterData = getFilter(newFilterPanelData, "email");
    const nameFilterData = getFilter(newFilterPanelData, "name");
    const unityIdFilterData = getFilter(newFilterPanelData, "unityId");
    const statesFilterData = getFilter(newFilterPanelData, "states");

    setSearchParam("name", nameFilterData.value || null);
    setSearchParam("email", emailFilterData.value || null);
    setSearchParam("unityId", unityIdFilterData.value || null);
    setSearchParam("states", statesFilterData.value.length ? statesFilterData.value.join(",") : null);
    setFilterPanelData(newFilterPanelData);
    setFullFilledFilterPanelData(newFilterPanelData);
    setCurrentPage(1);
    setUnityUserList([]);
  };

  const fetchData = useCallback((filterPanelData, page, showPinned, showTeachers) => {
    setFetchingList(true);

    const nameFilterData = getFilter(filterPanelData, "name");
    const emailFilterData = getFilter(filterPanelData, "email");
    const statesFilterData = getFilter(filterPanelData, "states");
    const unityIdFilterData = getFilter(filterPanelData, "unityId");

    const nameSearchParam = nameFilterData.value || null;
    const emailSearchParam = emailFilterData.value || null;
    const unityIdSearchParam = unityIdFilterData.value || null;
    const statesSearchParam = statesFilterData.value.length ? statesFilterData.value.join(",") : null;

    const searchParams = {
      page,
      limit: 100,
      ...(showPinned && { pinnedUsers: "true" }),
      ...(showTeachers && { teachers: "true" }),
    };

    if (nameSearchParam) {
      searchParams.name = nameFilterData.value;
    }

    if (emailSearchParam) {
      searchParams.email = emailFilterData.value;
    }

    if (unityIdSearchParam) {
      searchParams.unityId = unityIdFilterData.value;
    }

    if (statesSearchParam) {
      searchParams.states = statesFilterData.value.join(",");
    }

    const searchParamString = "?" + new URLSearchParams(searchParams).toString();

    unityUserListApi.getList(searchParamString).then((response) => {
      const { data: list, totalPages } = response;
      setFetchingList(false);
      setTotalPages(totalPages);

      setUnityUserList((prevList) => {
        const newList = page === 1 ? list : [...prevList, ...list];

        setFilteredUnityUserList(newList);

        const groupedListByDeviceId_obj = newList.reduce((acc, unityUser) => {
          const deviceId = unityUser.deviceId || unityUser._id;
          if (!acc[deviceId]) {
            acc[deviceId] = [];
          }
          acc[deviceId].push(unityUser);
          return acc;
        }, {});

        const groupedListByDeviceId = Object.values(groupedListByDeviceId_obj);
        const filteredGroupedListByDeviceId = filterGroupedList(groupedListByDeviceId, filterPanelData);

        setFilteredGroupedUnityUserList(filteredGroupedListByDeviceId);
        setHasMore(page < totalPages);

        return newList;
      });
    });
  }, []);

  useEffect(() => {
    fetchData(filterPanelData, currentPage, showPinnedUsersOnly, showTeachersOnly);
  }, [filterPanelData, currentPage, showPinnedUsersOnly, showTeachersOnly, fetchData]);

  const togglePinnedUsersOnly = (checked) => {
    setShowPinnedUsersOnly(checked);
    setSearchParam("pinnedUsers", checked ? "true" : null);

    if (checked) {
      setShowTeachersOnly(false);
      setSearchParam("teachers", null);
    }
  };
  const toggleTeachersOnly = (checked) => {
    setShowTeachersOnly(checked);
    setSearchParam("teachers", checked ? "true" : null);
    if (checked) {
      setShowPinnedUsersOnly(false);
      setSearchParam("pinnedUsers", null);
    }
  };

  return {
    unityUserList,
    filteredUnityUserList,
    filteredGroupedUnityUserList,
    fulfilledFilterPanelData,
    applyFilterPanelData,
    fetchingList,
    currentPage,
    setCurrentPage,
    totalPages,
    hasMore,
    fetchData,
    showPinnedUsersOnly,
    showTeachersOnly,
    togglePinnedUsersOnly,
    toggleTeachersOnly,
  };
};

export default useFilteredUnityUserList;
