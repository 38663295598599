import React, { useState, useEffect } from "react";
import "./MainMenu.scss";
import { CardStyled, NavigateButton } from "components";
import { useAuthz } from "../../auth/AuthzProvider";
import { ROLE_ADMIN, ROLE_MARKETING, ROLE_SUPERADMIN } from "constants/constants";

const MainMenu = () => {
  const { user } = useAuthz();

  const superAdmin = Boolean(user.roles.find((role) => role === ROLE_SUPERADMIN));
  const admin_superAdmin = Boolean(user.roles.find((role) => role === ROLE_ADMIN || role === ROLE_SUPERADMIN));
  const admin_superAdmin_marketing = Boolean(
    user.roles.find((role) => role === ROLE_ADMIN || role === ROLE_SUPERADMIN || role === ROLE_MARKETING)
  );

  const getButton = (title, path) => {
    return <NavigateButton title={title} className="customize-button" path={path} />;
  };

  return (
    <div className="nav-page-wrapper">
      <div className="menu-wrapper">
        <div className="title">Головне меню</div>
        <CardStyled className="button-box">
          {admin_superAdmin && getButton("Ігри", "/games")}
          {admin_superAdmin && getButton("Бандли", "/assetBundles")}
          {admin_superAdmin && getButton("Користувачі", "/unity-users")}
          {admin_superAdmin && getButton("Зображення", "/image-editor")}
          {admin_superAdmin && getButton("Звуки знань", "/knowledge-sound-set-editor")}
          {admin_superAdmin && getButton("Інші звуки", "/other-sound-set-editor")}
          {admin_superAdmin && getButton("Знання", "/knowledgeGroup-editor")}
          {admin_superAdmin && getButton("Повідомлення", "/messages")}
          {admin_superAdmin && getButton("Debug", "/debug")}
          {superAdmin && getButton("Адміністрування", "/admin")}
          {admin_superAdmin_marketing && getButton("Загальна статистика", "/marketingStatistic")}
          {getButton("Вихід", "/")}
        </CardStyled>
      </div>
    </div>
  );
};
export default MainMenu;
