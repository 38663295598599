import "./AssetBundleCard.scss";
import React, { useMemo } from "react";
import { StyledButton, CardStyled, ErrorsIcon, GameIcon, SoundSelector } from "components";
import Tooltip from "@mui/material/Tooltip";
import { ReactComponent as IconSave } from "assets/icons/save.svg";
import { ReactComponent as IconCancel } from "assets/icons/cancel.svg";
import { useResourcesContext } from "resources";
import { v4 as newUuid } from "uuid";
import { getSoundBySoundSetId } from "helpers/soundLanguage";

const AssetBundleCard = ({
  assetBundle,
  usedOtherSounds = [],
  availableOtherSounds = [],
  assetBundleExtraData,
  selected,
  onClick,
  onSave,
  onUndo,
  onChange,
  isLocked,
  isEdited,
  isNew,
  showIconProgress,
  showFetchingAssetBundleProgress,
}) => {
  const {
    imageListStore: { getImagePathById },
  } = useResourcesContext();

  const assetBundleIcon = useMemo(
    () => (typeof assetBundle.icon === "string" ? getImagePathById(assetBundle.icon) : assetBundle.icon),
    [assetBundle, getImagePathById]
  );

  const handleStartSoundChange = (value) => {
    if (value) {
      const newSounds = { ...assetBundle.sounds, startSoundSet: value.soundSetId };
      onChange({ ...assetBundle, sounds: newSounds });
    }

    if (!value) {
      const newSounds = { ...assetBundle.sounds, startSoundSet: "" };
      onChange({ ...assetBundle, sounds: newSounds });
    }
  };

  return (
    <div>
      <CardStyled className={`assetBundleCard-component ${selected ? "active-card" : ""}`}>
        <div className={`assetBundleCard-content `} onClick={() => onClick && onClick(assetBundle)}>
          <div className={`assetBundle-icon-wrapper ${!assetBundleIcon ? "empty-icon" : ""}`}>
            <GameIcon gameIcon={assetBundleIcon} showProgress={showIconProgress} />
          </div>
          <Tooltip title={<p className="tooltip">{assetBundle.name || "не визначено"}</p>} placement="bottom" arrow>
            <div className="assetBundle-name">{assetBundle.name || "не визначено"}</div>
          </Tooltip>
          <SoundSelector
            className="sound-selector-wrapper"
            label="Старт (навчання)"
            sounds={availableOtherSounds}
            value={getSoundBySoundSetId(assetBundle.sounds?.startSoundSet, usedOtherSounds)}
            onChangeValue={handleStartSoundChange}
          />
        </div>

        {onSave && (
          <Tooltip title={isEdited ? "Зберегти зміни" : ""} placement="right" arrow>
            <div className="save-btn">
              <StyledButton
                className="btn-content"
                onClick={() => onSave && onSave(assetBundle)}
                disabled={isLocked || !isEdited || showFetchingAssetBundleProgress}
                circularProgressSize={showFetchingAssetBundleProgress && 25}
              >
                <IconSave className="button-icon" />
              </StyledButton>
            </div>
          </Tooltip>
        )}

        {onUndo && (
          <Tooltip title={isEdited ? "Відміна всіх змін" : ""} placement="right" arrow>
            <div className="cancel-btn">
              <StyledButton
                className="btn-content"
                onClick={() => onUndo && onUndo(assetBundle)}
                disabled={isLocked || isNew || !isEdited}
              >
                <IconCancel className="button-icon" />
              </StyledButton>
            </div>
          </Tooltip>
        )}

        {assetBundleExtraData?.validation &&
          !assetBundleExtraData.validation.valid &&
          assetBundleExtraData.validation.errors && (
            <Tooltip title="Помилки" placement="left" arrow>
              <div className="errors-icon">
                <ErrorsIcon />
              </div>
            </Tooltip>
          )}
      </CardStyled>
    </div>
  );
};

export default AssetBundleCard;
