const { default: ModalWindow } = require("components/ModalWindow/ModalWindow");
const { default: AudioRecorder } = require("./AudioRecorder");

const AudioRecorderModal = ({ audioRecorderConfig}) => {
  return audioRecorderConfig ? (
    <ModalWindow onCancel={audioRecorderConfig.onCancel}>
      <AudioRecorder
        onConfirm={audioRecorderConfig.onConfirm}
        onCancel={audioRecorderConfig.onCancel}
        soundName={audioRecorderConfig.soundName}
        soundLanguage={audioRecorderConfig.soundLanguage}
      />
    </ModalWindow>
  ) : null;
};

export default AudioRecorderModal;
