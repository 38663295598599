import "./Tabs.scss";

const Tabs = ({ activeTab, onChange, tabs, className = "", children = null }) => {
  return (
    <div className={`tabs-component ${className}`}>
      <div className="tabs-list">
        {tabs.map((tab, i) => (
          <div key={i} className={`tab ${activeTab === tab ? "active" : ""}`} onClick={() => onChange(tab)}>
            {tab}
          </div>
        ))}
      </div>
      {children}
    </div>
  );
};
export default Tabs;
