import "./Benefits.scss";
import React from "react";
import benefits_icon_first from "assets/landing/benefits/blue.svg";
import benefits_icon_sec from "assets/landing/benefits/green.svg";
import benefits_icon_third from "assets/landing/benefits/orange.svg";
import benefits_icon_fourth from "assets/landing/benefits/purple.svg";
import benefits_icon_fifth from "assets/landing/benefits/lightblue.svg";
import benefits_icon_sixth from "assets/landing/benefits/red.svg";
import { useI18N } from "i18n";

const BenefitsSection = () => {
  const { I18N } = useI18N();

  return (
    <article className="benefits">
      <div className="benefits_header">
        <h3>
          <I18N id="benefits_header" />
        </h3>
        <p>
          <I18N id="benefits_subheader" />
        </p>
      </div>
      <div className="benefits_wrapper">
        <div className="benefits_container">
          <div className="benefits_container__text">
            <div className="benefit-header-text-and-icon">
              <img src={benefits_icon_first} alt="benefits_icon_first" />
              <h4>
                <I18N id="benefit_1_header" />
              </h4>
            </div>
            <p>
              <I18N id="benefit_1_text" />
            </p>
          </div>
        </div>
        <div className="benefits_container">
          <div className="benefits_container__text">
            <div className="benefit-header-text-and-icon">
              <img src={benefits_icon_sec} alt="benefits_icon_sec" />
              <h4>
                <I18N id="benefit_2_header" />
              </h4>
            </div>
            <p>
              <I18N id="benefit_2_text" />
            </p>
          </div>
        </div>
        <div className="benefits_container">
          <div className="benefits_container__text">
            <div className="benefit-header-text-and-icon">
              <img src={benefits_icon_third} alt="benefits_icon_third" />
              <h4>
                <I18N id="benefit_3_header" />
              </h4>
            </div>
            <p>
              <I18N id="benefit_3_text" />
            </p>
          </div>
        </div>
        <div className="benefits_container">
          <div className="benefits_container__text">
            <div className="benefit-header-text-and-icon">
              <img src={benefits_icon_fourth} alt="benefits_icon_fourth" />
              <h4>
                <I18N id="benefit_4_header" />
              </h4>
            </div>
            <p>
              <I18N id="benefit_4_text" />
            </p>
          </div>
        </div>
        <div className="benefits_container">
          <div className="benefits_container__text">
            <div className="benefit-header-text-and-icon">
              <img src={benefits_icon_fifth} alt="benefits_icon_fifth" />
              <h4>
                <I18N id="benefit_5_header" />
              </h4>
            </div>
            <p>
              <I18N id="benefit_5_text" />
            </p>
          </div>
        </div>
        <div className="benefits_container">
          <div className="benefits_container__text">
            <div className="benefit-header-text-and-icon">
              <img src={benefits_icon_sixth} alt="benefits_icon_sixth" />
              <h4>
                <I18N id="benefit_6_header" />
              </h4>
            </div>
            <p>
              <I18N id="benefit_6_text" />
            </p>
          </div>
        </div>
      </div>
    </article>
  );
};

export default BenefitsSection;
