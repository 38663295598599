import "./App.css";
import { Routes, Route, useLocation } from "react-router-dom";
import React, { useEffect } from "react";

import GameEditorUniversal from "./pages/GameEditorUniversal/GameEditorUniversal";
import Games from "./pages/Games/Games";
import UnityUserEditor from "pages/UnityUserEditor/UnityUserEditor";
import UnityUsers from "pages/UnityUsers/UnityUsers";
import MainMenu from "./pages/MainMenu/MainMenu";
import ImageEditor from "./pages/ImageEditor/ImageEditor";
import Login from "pages/auth/LogIn/LogIn";
import Registration from "pages/auth/Registration/Registration";
import Administration from "pages/Administration/Administration";
import ProtectedRoute from "auth/ProtectedRoute";
import {
  ROLE_SUPERADMIN,
  ROLE_CLIENT,
  ROLE_ADMIN,
  ROLE_TEACHER,
  PIXEL_ID,
  GTM_ID,
  GA_ID,
  ROLE_MARKETING,
} from "constants/constants";
import AssetBundleEditor from "pages/AssetBundleEditor/AssetBundleEditor";
import AssetBundles from "pages/AssetBundles/AssetBundles";
import Messages from "pages/Messages/Messages";
import Debug from "pages/Debug/Debug";
import KnowledgeGroupEditor from "pages/KnowledgeGroupEditor/KnowledgeGroupEditor";
import Profile from "./pages/Landing/Profile/Profile";
import DashboardUsers from "./pages/Dashboard/DashboardUsers/DashboardUsers";
import DashboardBilling from "./pages/Dashboard/DashboardBilling/DashboardBilling";
import DashboardUserDetails from "./pages/Dashboard/DashboardUserDetails/DashboardUserDetails";
import Contacts from "./pages/Landing/Contacts/Contacts";
import MainPage from "./pages/Landing/MainPage/MainPage";
import Subscription from "./pages/Landing/Subscription/Subscription";
import Trial from "./pages/Landing/Trial/Trial";
import Success from "./pages/Landing/PaymentStatusPages/Success/Success";
import Cancel from "./pages/Landing/PaymentStatusPages/Cancel/Cancel";
import { BufferProviderLayout, ResourceProviderLayout } from "resources";
import ResetPassword from "pages/auth/ResetPassword/ResetPassword";
import ForgotPassword from "pages/auth/ForgotPassword/ForgotPassword";
import KnowledgeSoundSetEditor from "pages/SoundSetEditors/KnowledgeSoundSetEditor/KnowledgeSoundSetEditor";
import OtherSoundSetEditor from "pages/SoundSetEditors/OtherSoundSetEditor/OtherSoundSetEditor";
import { GoogleGAProviderLayout, PixelScriptProviderLayout } from "components";
import MarketingStatistic from "pages/MarketingStatistic/MarketingStatistic";
import UserAchievementCard from "pages/AchievementCards/userAchievementCard/userAchievementCard";
import UserAchievementCardKnowledge from "pages/AchievementCards/userAchievementCardKnowledge/userAchievementCardKnowledge";
import UserStatisticCard from "pages/AchievementCards/UserStatisticCard/UserStatisticCard";
import UserAchievementCardStart from "pages/AchievementCards/userAchivementCardStart/userAchievementCardStart";
import { path } from "d3";

function App() {
  const location = useLocation();
  const { pathname: pathName } = location;

  useEffect(() => {
    document.title = "Bulbee";
  }, []);

  const landingPathNames = [
    "/",
    "/trial",
    "/login",
    "/registration",
    "/resetPassword",
    "/forgotPassword",
    "/contacts",
    "/subscription",
    "/profile",
    "/profile/success",
    "/profile/cancel",
  ];

  return (
    <>
      <PixelScriptProviderLayout id={PIXEL_ID} pathName={pathName} pathNames={landingPathNames} />
      <GoogleGAProviderLayout id={GA_ID} pathName={pathName} pathNames={landingPathNames} />
      <Routes>
        <Route element={<BufferProviderLayout />}>
          <>
            <Route path="login" element={<Login />} />
            <Route path="registration" element={<Registration />} />
            <Route path="resetPassword" element={<ResetPassword />} />
            <Route path="forgotPassword" element={<ForgotPassword />} />
            <Route exact path="/" element={<MainPage />} />
            <Route path="/contacts" element={<Contacts />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/trial" element={<Trial />} />

            <Route element={<ProtectedRoute allowedRoles={[ROLE_ADMIN, ROLE_SUPERADMIN, ROLE_TEACHER]} />}>
              <Route element={<ResourceProviderLayout />}>
                <Route path="/dashboard" element={<DashboardUsers />} />
                <Route path="/dashboard/:id" element={<DashboardUserDetails />} />
                <Route path="/dashboard/billing" element={<DashboardBilling />} />
              </Route>
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[ROLE_ADMIN, ROLE_SUPERADMIN, ROLE_CLIENT, ROLE_TEACHER]} />}>
              <Route exact path="/profile" element={<Profile />} />
              <Route exact path="/profile/success" element={<Success />} />
              <Route exact path="/profile/cancel" element={<Cancel />} />
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[ROLE_ADMIN, ROLE_SUPERADMIN]} />}>
              <Route exact path="unity-users" element={<UnityUsers />} />
              <Route exact path="messages" element={<Messages />} />
              <Route exact path="debug" element={<Debug />} />
              <Route exact path="userAchievementCard" element={<UserAchievementCard />} />
              <Route exact path="userAchievementCardStart" element={<UserAchievementCardStart />} />
              <Route exact path="userAchievementCardKnowledge" element={<UserAchievementCardKnowledge />} />
              <Route exact path="userStatisticCard" element={<UserStatisticCard />} />
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[ROLE_ADMIN, ROLE_SUPERADMIN, ROLE_MARKETING]} />}>
              <Route exact path="marketingStatistic" element={<MarketingStatistic />} />
            </Route>

            <Route element={<ProtectedRoute allowedRoles={[ROLE_SUPERADMIN]} />}>
              <Route exact path="admin" element={<Administration />} />
            </Route>
          </>

          <>
            <Route element={<ProtectedRoute allowedRoles={[ROLE_ADMIN, ROLE_SUPERADMIN, ROLE_MARKETING]} />}>
              <Route element={<ResourceProviderLayout />}>
                <Route exact path="menu" element={<MainMenu />} />
                <Route element={<ProtectedRoute allowedRoles={[ROLE_ADMIN, ROLE_SUPERADMIN]} />}>
                  <Route exact path="image-editor" element={<ImageEditor />} />
                  <Route exact path="knowledge-sound-set-editor" element={<KnowledgeSoundSetEditor />} />
                  <Route exact path="other-sound-set-editor" element={<OtherSoundSetEditor />} />
                  <Route exact path="knowledgeGroup-editor" element={<KnowledgeGroupEditor />} />
                  <Route exact path="assetBundles" element={<AssetBundles />} />
                  <Route exact path="assetBundle-editor/:id" element={<AssetBundleEditor />} />
                  <Route exact path="game-editor-universal/:gameId" element={<GameEditorUniversal />} />
                  <Route exact path="games" element={<Games />} />
                  <Route exact path="unity-user-editor/:id" element={<UnityUserEditor />} />
                </Route>
              </Route>
            </Route>
          </>
        </Route>
      </Routes>
    </>
  );
}

export default App;
