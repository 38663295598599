import "./UserChoice.scss";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { ReactComponent as IconRefresh } from "assets/icons/cancel.svg";

const UserChoice = ({ className = "", unityUser }) => {
  const [fetchingStatus, setFetchingStatus] = useState({});

  const [choiceStatistic, setChoiceStatistic] = useState([]);

  const updateFetchingStatus = (action, value) => {
    let statusName = "";
    switch (action) {
      case "getChoiceStatistic":
        statusName = "fetchingChoiceStatistic";
        break;

      default:
        break;
    }

    statusName && setFetchingStatus({ ...fetchingStatus, [statusName]: value });
  };

  const fetchingStatusActive = fetchingStatus.fetchingChoiceStatistic;

  const getChoiceStatistic = async (unityUserId) => {
    if (!unityUserId) {
      setChoiceStatistic([]);
      return;
    }

    updateFetchingStatus("getChoiceStatistic", true);
    axios
      .get(`/api/statistic/userChoiceStatistic?unityUserId=${unityUserId}`)
      .then((res) => {
        setChoiceStatistic(res.data);
        updateFetchingStatus("getChoiceStatistic", false);
      })
      .catch((error) => {
        console.log("error", error);
        setChoiceStatistic([]);
        updateFetchingStatus("getChoiceStatistic", false);
      });
  };

  useEffect(() => {
    getChoiceStatistic(unityUser?._id);
  }, [unityUser]);

  return (
    <div className={`userChoice-tab ${className}`}>
      <div className="userChoice-itemsList">
        {choiceStatistic.map((item, index) => {
          return (
            <div key={index} className="userChoice-item">
              <div className="userChoice-item-image">
                <img src={item.imagePath} alt="item" />
              </div>
              <div className="userChoice-item-count">{item.count}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UserChoice;
