import "./Sucess.scss";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useI18N } from "i18n";
import Layout from "pages/Landing/Layout/Layout";

const Success = () => {
  const { I18N } = useI18N();
  const navigate = useNavigate();
  const navigationTimeout = 1500;

  useEffect(() => {
    setTimeout(() => {
      navigate("/profile");
    }, navigationTimeout);
  }, []);

  return (
    <Layout>
      <div className="success-page">
        <div className="success_wrapper">
          <h1>
            <I18N id={"payment_successfull_header"} />
          </h1>
          <p>
            <I18N id={"payment_successfull_text"} />
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Success;
