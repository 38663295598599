import React, { useEffect, useMemo, useState } from "react";
import { ReactComponent as IconRemove } from "assets/icons/remove.svg";
import TransformIcon from "@mui/icons-material/Transform";
import CompressIcon from "@mui/icons-material/Compress";
import { ReactComponent as IconUpload } from "assets/icons/upload.svg";
import { StyledInput, CardStyled, StyledButton, Tags } from "components";
import { getFileSizeKb, getImageSize } from "helpers/images";
import { TooltipTitleList } from "components";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { useResourcesContext } from "resources";
import * as API from "../../API/API";

export default function Image({ fetchStatus, selectedImage, onChange, onDelete, onTransform, imageUsedInData }) {
  const [name, setName] = useState(selectedImage.name);
  const [selectedTags, setSelectedTags] = useState([...selectedImage.tags]);
  const [imageFileObject, setImageFileObject] = useState(selectedImage.path);
  const [imageFileSize, setImageFileSize] = useState(0);
  const [filteredTagList, setFilteredTagList] = useState([]);
  const [searchTagString, setSearchTagString] = useState("");
  const [newTagString, setNewTagString] = useState("");

  const navigate = useNavigate();

  const preparedImage = useMemo(() => {
    return { ...selectedImage };
  }, [selectedImage]);

  useEffect(() => {
    setImageFileObject(selectedImage.path);
  }, [selectedImage]);

  const {
    imageListStore: { imageList },
    tagListStore: { tagList, getTagList },
  } = useResourcesContext();

  const canBeDelated = useMemo(() => {
    const imageUsed =
      imageUsedInData.gamesWithImage.length ||
      imageUsedInData.bundlesWithImage.length ||
      imageUsedInData.stageTemplatesWithImage.length ||
      imageUsedInData.itemTemplatesWithImage.length;

    return selectedImage?._id && !imageUsed;
  }, [selectedImage, imageUsedInData]);

  const addTag = (value, callback) => {
    if (!tagList.some((tag) => tag.name === value)) {
      API.createOne("tags", { name: value }).then((tag) => {
        getTagList();
        callback(tag);
      });
    }
  };

  const deleteTag = (value, callback) => {
    let filteredTagList = tagList.filter((tag) => tag.name === value);
    filteredTagList.forEach((item) => {
      if (
        !imageList.some((image) => image._id !== selectedImage._id && image.tags.some((tagId) => tagId === item._id))
      ) {
        API.deleteOne("tags", item._id).then((tag) => {
          callback(tag);
        });
      }
    });
    getTagList();
  };

  const save = () => {
    onChange(preparedImage);
  };

  useEffect(() => {
    setFilteredTagList(tagList?.filter((tag) => tag.name.includes(searchTagString)));
  }, [searchTagString, tagList]);

  useEffect(() => {
    if (selectedImage) {
      setName(selectedImage.name ? selectedImage.name : "");
      setSelectedTags(selectedImage.tags ? [...selectedImage.tags] : []);
      setImageFileObject(selectedImage.path ? selectedImage.path : "");
      setNewTagString("");
      getFileSizeKb(selectedImage.path ? selectedImage.path : "").then((res) => setImageFileSize(res));
    }
  }, [selectedImage]);

  const handleImageFileChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      let file = event.target.files[0];
      setImageFileObject(file);
      preparedImage.path = file;
      const fileObjectUrl = URL.createObjectURL(file);
      const imageSize = await getImageSize(fileObjectUrl);
      preparedImage.height = imageSize.height;
      preparedImage.width = imageSize.width;
      save();
    }
  };

  const getFileObjectUrl = (fileObject) => {
    if (fileObject && typeof fileObject === "object") {
      return URL.createObjectURL(fileObject);
    }
    if (fileObject && typeof fileObject === "string") {
      return fileObject;
    }
    if (!fileObject) return "";
  };

  const handleAddTag = () => {
    if (newTagString) {
      addTag(newTagString, (createdTag) => {
        setSelectedTags([...selectedTags, createdTag._id]);
        preparedImage.tags = [...selectedTags, createdTag._id];
        save();
      });
      setNewTagString("");
    }
  };

  const handleDeleteTag = () => {
    if (newTagString) {
      deleteTag(newTagString, (deletedTag) => {
        if (deletedTag) {
          selectedTags.splice(selectedTags.indexOf(deletedTag._id), 1);
          setSelectedTags([...selectedTags]);
          preparedImage.tags = [...selectedTags];
          save();
        }
      });
      setNewTagString("");
    }
  };

  const handleMultiSelect = (tag) => {
    const newSelected = selectedTags.includes(tag._id)
      ? selectedTags.filter((id) => id !== tag._id)
      : selectedTags.concat(tag._id);

    setSelectedTags(newSelected);
    preparedImage.tags = newSelected;

    save();
  };

  const getDeleteImageButtonTooltipProps = () => {
    if (!selectedImage?._id) {
      return { title: `Зображення не обрано` };
    }

    if (canBeDelated) {
      return { title: `Видалити зображення з бібліотеки` };
    }

    const goToGameEditor = (element) => {
      element._id && element.gameType === "universal" && navigate(`/game-editor-universal/${element._id}`);
      element._id && element.gameType !== "universal" && navigate(`/game-editor/${element._id}`);
    };
    const goToBundleEditor = (element) => {
      element._id && navigate(`/assetBundle-editor/${element._id}`);
    };

    return {
      arrow: true,
      className: "disabled-delete-tooltip",
      title: (
        <>
          {(imageUsedInData.gamesWithImage.length || null) && (
            <TooltipTitleList
              title="Використовується в іграх:"
              list={imageUsedInData.gamesWithImage}
              onClick={(element) => {
                goToGameEditor(element);
              }}
            />
          )}
          {(imageUsedInData.bundlesWithImage.length || null) && (
            <TooltipTitleList
              title="Використовується в бандлах:"
              list={imageUsedInData.bundlesWithImage}
              onClick={(element) => {
                goToBundleEditor(element);
              }}
            />
          )}
          {(imageUsedInData.itemTemplatesWithImage.length || null) && (
            <TooltipTitleList
              title="Використовується в шаблонах сцен:"
              list={imageUsedInData.itemTemplatesWithImage}
              onClick={(element) => {}}
            />
          )}
          {(imageUsedInData.stageTemplatesWithImage.length || null) && (
            <TooltipTitleList
              title="Використовується в шаблонах пропсів:"
              list={imageUsedInData.stageTemplatesWithImage}
              onClick={(element) => {}}
            />
          )}
        </>
      ),
    };
  };

  const handleRemoveBackground = () => {
    onTransform(selectedImage._id, {
      removeBackground: true,
      trim: true,
      resize: true,
      compress: true,
    });
  };

  const handleTrimResizeCompress = () => {
    onTransform(selectedImage._id, {
      trim: true,
      resize: true,
      compress: true,
    });
  };

  const handleCompress = () => {
    onTransform(selectedImage._id, {
      compress: true,
    });
  };

  return (
    <>
      <div className="top-wrapper">
        <div className="title">
          {selectedImage?._id ? (selectedImage.name ? selectedImage.name : `no name`) : "New image"}
        </div>

        <div className="remove-button">
          <div className="button-box">
            <label className="file-upload">
              <input accept="image/*" type="file" onChange={handleImageFileChange} />
              <IconUpload className="button-icon" />
              Змінити зображення
            </label>
          </div>
          &nbsp;
          <StyledButton
            title="Видалити зображення"
            tooltipProps={getDeleteImageButtonTooltipProps()}
            disabled={!canBeDelated}
            onClick={onDelete}
            className="customize-button"
          >
            {fetchStatus === "DELETE_INIT" ? (
              <CircularProgress size={15} style={{ color: "#fff" }} />
            ) : (
              <IconRemove className="button-icon" />
            )}
          </StyledButton>
        </div>
      </div>

      <div className="new-name-wrapper">
        <CardStyled className="new-name-box">
          <StyledInput
            title="Name"
            value={name}
            placeholder="Enter name here"
            onChange={(event) => setName(event.target.value)}
            onBlur={() => {
              preparedImage.name = name;
              save();
            }}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                preparedImage.name = name;
                save();
              }
            }}
          />
        </CardStyled>
        <CardStyled className="id-and-resolution">
          <div className="item-id-wrap">
            <div className="item-id-title">ID:</div>
            <div className="item-id">{selectedImage?._id ? selectedImage._id : ""}</div>
          </div>
          <div className="item-resolution-wrap">
            <div className="item-resolution-title">Resolution:</div>
            <div className="item-resolution">{`${selectedImage.width}x${selectedImage.height}`}</div>
          </div>
          <div className="item-resolution-wrap">
            <div className="item-resolution-title">File size (Kb):</div>
            <div className="item-resolution">{`${imageFileSize.toFixed(1)}`}</div>
          </div>
        </CardStyled>
      </div>
      <CardStyled className="tags-wrapper">
        <div className="tags-inputs">
          <div className="tag-search">
            <StyledInput
              title="Tag search"
              disabled={newTagString ? false : true}
              placeholder="Enter text to search tags"
              onChange={(event) => setSearchTagString(event.target.value)}
            />
          </div>
          <div className="tag-new">
            <StyledInput
              title="Add/delete tag"
              value={newTagString}
              placeholder="Enter tag name"
              onChange={(event) => setNewTagString(event.target.value)}
              onKeyPress={(event) => (event.key === "Enter" ? handleAddTag() : "")}
            />
          </div>
          <StyledButton
            title="delete tag"
            disabled={newTagString ? false : true}
            onClick={() => handleDeleteTag()}
            className="customize-button"
          >
            <IconRemove className="button-icon" />
          </StyledButton>
        </div>
        <Tags tagList={filteredTagList} selectedTags={selectedTags} onTagClick={(tag) => handleMultiSelect(tag)} />
      </CardStyled>

      <CardStyled className="added-image-wrapper">
        <div className="added-image-content">
          {imageFileObject && (
            <div className="image">
              <img src={getFileObjectUrl(imageFileObject)} alt="" />
            </div>
          )}
        </div>
        <div className="buttons">
          <div className="button-box">
            <StyledButton title="RemoveBackground +" className="customize-button" onClick={handleRemoveBackground}>
              {fetchStatus === "TRANSFORM_INIT" ? (
                <CircularProgress size={15} style={{ color: "#fff" }} />
              ) : (
                <IconRemove className="button-icon" />
              )}
            </StyledButton>
          </div>
          <div className="button-box">
            <StyledButton title="Compress" className="customize-button" onClick={handleCompress}>
              {fetchStatus === "TRANSFORM_INIT" ? (
                <CircularProgress size={15} style={{ color: "#fff" }} />
              ) : (
                <CompressIcon />
              )}
            </StyledButton>
          </div>
          <div className="button-box">
            <StyledButton title="Trim-resize-compress" className="customize-button" onClick={handleTrimResizeCompress}>
              {fetchStatus === "TRANSFORM_INIT" ? (
                <CircularProgress size={15} style={{ color: "#fff" }} />
              ) : (
                <TransformIcon />
              )}
            </StyledButton>
          </div>
        </div>
      </CardStyled>
    </>
  );
}
