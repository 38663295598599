import "./PlanPlus.scss";
import { useResourcesContext } from "resources";
import PlanElementTile from "./PlanElementTile/PlanElementTile";
import React, { useEffect, useState } from "react";
import { DragDropWrapper, StyledButton, StyledInput, StyledSwitch } from "components";
import { v4 as newUuid } from "uuid";
import axios from "axios";
import { ReactComponent as IconRefresh } from "assets/icons/cancel.svg";
import _ from "lodash";
import GameDataTile from "./GameDataTile/GameDataTile";
import Batteries from "./Batteries/Batteries";
import OpenInBrowserIcon from "@mui/icons-material/OpenInBrowser";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

const Delimiter = ({ onDropDownElementMounted, dragOver }) => {
  return (
    <div ref={onDropDownElementMounted} className={`dragDrop-delimiter ${dragOver ? "color-dragOver" : ""}`}></div>
  );
};

const PlanPlus = ({ className = "", unityUser, knResultsRateBased, onChange }) => {
  const [futureList, setFutureList] = useState([]);

  const [fetchingStatus, setFetchingStatus] = useState({
    fetchingCheckPlanResults: false,
  });

  const [checkPlanResults, setCheckPlanResults] = useState([]);
  const [checkGamesCreationResults, setCheckGamesCreationResults] = useState([]);

  const [batteriesStatistic, setBatteriesStatistic] = useState([]);
  const [startDaysFromToday, setStartDaysFromToday] = useState(7);
  const [endDaysFromToday, setEndDaysFromToday] = useState(0);

  const toDoList = unityUser?.planPlus?.toDo || [];

  const [tagsWithoutSoundSet, setTagsWithoutSoundSet] = useState([]);

  const {
    knowledgeGroupListStore: { knowledgeGroupList },
    soundSetListStore: { getUsedBaseTags },
    knowledgeTagListStore: { knowledgeTagList },
  } = useResourcesContext();

  useEffect(() => {
    const allBaseKnTagsUsedInSoundSets = getUsedBaseTags();
    const allNonBaseKnTagsUsedInGroups = knowledgeGroupList.reduce((result, knowledgeGroup) => {
      if (knowledgeGroup.studiesAtGroups0?.length || knowledgeGroup.studiesAtGroups1?.length) {
        return [...result, ...knowledgeGroup.knowledgeTags];
      }
      return [...result];
    }, []);

    setTagsWithoutSoundSet(
      knowledgeTagList
        .filter(
          (tag) => !allBaseKnTagsUsedInSoundSets.includes(tag._id) && !allNonBaseKnTagsUsedInGroups.includes(tag._id)
        )
        .map((tag) => tag._id)
    );
  }, [getUsedBaseTags, knowledgeGroupList]);

  const updateFetchingStatus = (action, value) => {
    let statusName = "";
    switch (action) {
      case "checkGamesCreation":
        statusName = "fetchingGamesCreationData";
        break;

      case "checkPlanResults":
        statusName = "fetchingCheckPlanResults";
        break;

      case "getParentsStatistic":
        statusName = "fetchingParentsStatistic";
        break;

      default:
        break;
    }

    statusName && setFetchingStatus({ ...fetchingStatus, [statusName]: value });
  };

  const fetchingStatusActive =
    fetchingStatus.fetchingCheckPlanResults ||
    fetchingStatus.fetchingGamesCreationData ||
    fetchingStatus.fetchingParentsStatistic;

  const getStartAddEndDates = (startDaysFromToday, endDaysFromToday) => {
    if (startDaysFromToday === endDaysFromToday) {
      let startDate = new Date();
      startDate.setDate(startDate.getDate() - startDaysFromToday);
      startDate = startDate.getTime();

      return { startDate, endDate: startDate };
    }

    let endDate = new Date();
    endDate.setDate(endDate.getDate() - endDaysFromToday);
    endDate = endDate.getTime();

    let startDate = new Date();
    startDate.setDate(startDate.getDate() - startDaysFromToday);
    startDate = startDate.getTime();

    return { startDate, endDate };
  };

  const getBatteriesStatistic = async (unityUserId, startDaysFromToday, endDaysFromToday) => {
    if (!unityUserId) {
      setBatteriesStatistic([]);
      return;
    }

    const { startDate, endDate } = getStartAddEndDates(startDaysFromToday, endDaysFromToday);

    updateFetchingStatus("getParentsStatistic", true);
    axios
      .get(`/api/statistic/batteriesStatistic?unityUserId=${unityUserId}&startDate=${startDate}&endDate=${endDate}`)
      .then((res) => {
        setBatteriesStatistic(res.data);
        updateFetchingStatus("getParentsStatistic", false);
      })
      .catch((error) => {
        console.log("error", error);
        setBatteriesStatistic([]);
        updateFetchingStatus("getParentsStatistic", false);
      });
  };

  const checkPlan = async (unityUserId) => {
    if (!unityUserId) {
      setCheckPlanResults([]);
      return;
    }

    updateFetchingStatus("checkPlanResults", true);
    axios
      .get(`/api/statistic/checkPlan?unityUserId=${unityUserId}`)
      .then((res) => {
        setCheckPlanResults(res.data);
        updateFetchingStatus("checkPlanResults", false);
      })
      .catch((error) => {
        console.log("error", error);
        setCheckPlanResults([]);
        updateFetchingStatus("checkPlanResults", false);
      });

    //const checkPlanResultsStructure = {
    //  toDo: [{ planElement: {...originalPlanElement}, controlFormula:"ab_cb.ab", combinations: [[idA, idB],[idC, idB],[idA, idB]] }]}
  };

  const checkGamesCreation = async (unityUserId) => {
    if (!unityUserId) {
      setCheckGamesCreationResults([]);
      return;
    }

    updateFetchingStatus("checkGamesCreation", true);
    axios
      .get(`/api/statistic/checkGamesCreation?unityUserId=${unityUserId}`)
      .then((res) => {
        setCheckGamesCreationResults(res.data);
        updateFetchingStatus("checkGamesCreation", false);
        console.log("checkGamesCreation res.data", res.data);
      })
      .catch((error) => {
        console.log("error", error);
        setCheckGamesCreationResults([]);
        updateFetchingStatus("checkGamesCreation", false);
      });
  };

  useEffect(() => {
    setFutureList(
      knowledgeGroupList
        .filter((group) => group.knowledgeTags?.length)
        .reduce((prev, group) => {
          const studiesAtGroups0 = group.studiesAtGroups0 || [];
          const studiesAtGroups1 = group.studiesAtGroups1 || [];

          if (!studiesAtGroups0.length && !studiesAtGroups1.length) {
            return [...prev, { id: group._id, knowledgeGroup: group, elements: [] }];
          }

          let studiesAtCombunations = [];

          if (!studiesAtGroups0.length) {
            studiesAtCombunations = studiesAtGroups1.map((group1Id) => {
              return [group1Id];
            });
          }

          if (!studiesAtGroups1.length) {
            studiesAtCombunations = studiesAtGroups0.map((group0Id) => {
              return [group0Id];
            });
          }

          if (studiesAtGroups0.length && studiesAtGroups1.length) {
            studiesAtCombunations = studiesAtGroups0.reduce((prev, group0Id) => {
              return [
                ...prev,
                ...studiesAtGroups1.map((group1Id) => {
                  return [group0Id, group1Id];
                }),
              ];
            }, []);
          }

          const likePlanElements = studiesAtCombunations.map((combination, index) => {
            return {
              id: prev.length + index + 1,
              knowledgeGroup: group,
              elements: combination.map((groupId) => {
                return {
                  id: groupId,
                  knowledgeGroup: knowledgeGroupList.find((elem) => elem._id === groupId),
                  elements: [],
                };
              }),
            };
          });

          return [...prev, ...likePlanElements];
        }, [])
    );
  }, [knowledgeGroupList]);

  const updatePlanPlus = (planListsToUpdate) => {
    const newPlanPlus = {
      ...unityUser.planPlus,
      ...planListsToUpdate,
    };
    onChange(newPlanPlus);
  };

  const getListByType = (listType) => {
    if (listType === "toDo") return toDoList;
    if (listType === "future") return futureList;
    return [];
  };

  const handleElementChange = (listType, newValue) => {
    if (listType === "toDo") {
      updatePlanPlus({ toDo: toDoList.map((elem) => (elem.id === newValue.id ? newValue : elem)) });
    }
  };

  const deleteElement = (listType, element) => {
    if (listType === "toDo") {
      updatePlanPlus({ toDo: toDoList.filter((elem) => elem.id !== element.id) });
    }
  };

  const handleDropToTodoList = (dropedData, destIndex) => {
    const { listType, elementId } = dropedData;

    //from toDo to ToDo
    if (listType === "toDo") {
      const toDoElement = toDoList.find((elem) => elem.id === elementId);
      const newToDoList = [
        ...toDoList.slice(0, destIndex).filter((elem) => elem.id !== elementId),
        _.cloneDeep(toDoElement),
        ...toDoList.slice(destIndex).filter((elem) => elem.id !== elementId),
      ];
      updatePlanPlus({ toDo: newToDoList });
    }

    //editing only toDoList. FutureList is not changing because it is a basic list of knowledgeGroups
    if (listType === "future") {
      const futureListElement = futureList.find((elem) => elem.id === elementId);
      const newToDoList = [
        ...toDoList.slice(0, destIndex),
        { ..._.cloneDeep(futureListElement), id: newUuid() },
        ...toDoList.slice(destIndex),
      ];
      updatePlanPlus({ toDo: newToDoList });
    }
  };

  const openUserStatisticCardForPeriod = async (unityUserId, startDaysFromToday, endDaysFromToday) => {
    const { startDate, endDate } = getStartAddEndDates(startDaysFromToday, endDaysFromToday);
    window.open(`/userStatisticCard?unityUserId=${unityUserId}&startDate=${startDate}&endDate=${endDate}`);
  };

  const openUserStatisticCard = async (unityUserId) => {
    window.open(`/userStatisticCard?unityUserId=${unityUserId}`);
  };

  const getUserStatisticCardFileForPeriod = async (unityUserId, startDaysFromToday, endDaysFromToday) => {
    const { startDate, endDate } = getStartAddEndDates(startDaysFromToday, endDaysFromToday);

    axios
      .get(
        `/api/statistic/getUserStatisticCardFile?unityUserId=${unityUserId}&startDate=${startDate}&endDate=${endDate}`,
        { responseType: "blob" }
      )
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${unityUser.name}-statisticCard.pdf`);
        document.body.appendChild(link);
        link.click();
      });
  };

  const getUserStatisticCardFile = async (unityUserId) => {
    axios
      .get(`/api/statistic/getUserStatisticCardFile?unityUserId=${unityUserId}`, { responseType: "blob" })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${unityUser.name}-statisticCard.pdf`);
        document.body.appendChild(link);
        link.click();
      });
  };

  return (
    <div className={`planPlus-component ${className}`}>
      <div className="done-column">
        <div className="column-header">Done</div>
        <div className="params">
          <div className="inputWithTitle">
            <div className="param-title">Днів з/по від сьогодні</div>
            <StyledInput
              className="param-input"
              tooltipProps={{ title: "end", placement: "bottom" }}
              type={"number"}
              value={endDaysFromToday}
              placeholder={7}
              onChange={(event) => setEndDaysFromToday(event.target.value)}
            />
            <StyledInput
              className="param-input"
              tooltipProps={{ title: "start", placement: "bottom" }}
              type={"number"}
              value={startDaysFromToday}
              placeholder={7}
              onChange={(event) => setStartDaysFromToday(event.target.value)}
            />
          </div>
          <div className="buttons">
            <StyledButton
              className="button"
              onClick={() => {
                getBatteriesStatistic(unityUser?._id, startDaysFromToday, endDaysFromToday);
              }}
              disabled={!unityUser || fetchingStatusActive}
              circularProgressSize={fetchingStatusActive && 25}
              tooltipProps={{
                title: fetchingStatusActive ? "Отримання даних" : "Отримати данні за період",
                placement: "bottom",
                arrow: true,
              }}
            >
              <IconRefresh className="icon" />
            </StyledButton>
            <StyledButton
              className="button"
              onClick={() => {
                getUserStatisticCardFileForPeriod(unityUser?._id, startDaysFromToday, endDaysFromToday);
              }}
              disabled={!unityUser || fetchingStatusActive}
              circularProgressSize={fetchingStatusActive && 25}
              tooltipProps={{
                title: fetchingStatusActive ? "Отримання даних" : "Зформувати pdf за обраний період",
                placement: "top",
                arrow: true,
              }}
            >
              <PictureAsPdfIcon className="icon" />
            </StyledButton>
            <StyledButton
              className="button"
              onClick={() => {
                openUserStatisticCardForPeriod(unityUser?._id, startDaysFromToday, endDaysFromToday);
              }}
              disabled={!unityUser || fetchingStatusActive}
              circularProgressSize={fetchingStatusActive && 25}
              tooltipProps={{
                title: fetchingStatusActive ? "Отримання даних" : "Відкрити картку за період в новому вікні",
                placement: "bottom",
                arrow: true,
              }}
            >
              <OpenInBrowserIcon className="icon" />
            </StyledButton>
            <StyledButton
              className="button"
              onClick={() => {
                getUserStatisticCardFile(unityUser?._id);
              }}
              disabled={!unityUser || fetchingStatusActive}
              circularProgressSize={fetchingStatusActive && 25}
              tooltipProps={{
                title: fetchingStatusActive ? "Отримання даних" : "Зформувати pdf з поточними результатами",
                placement: "top",
                arrow: true,
              }}
            >
              <PictureAsPdfIcon className="icon" />
            </StyledButton>
            <StyledButton
              className="button"
              onClick={() => {
                openUserStatisticCard(unityUser?._id);
              }}
              disabled={!unityUser || fetchingStatusActive}
              circularProgressSize={fetchingStatusActive && 25}
              tooltipProps={{
                title: fetchingStatusActive
                  ? "Отримання даних"
                  : "Відкрити картку з поточними результатами в новому вікні",
                placement: "bottom",
                arrow: true,
              }}
            >
              <OpenInBrowserIcon className="icon" />
            </StyledButton>
          </div>
        </div>
        <div className="done-list">
          <Batteries unityUser={unityUser} knowledgesData={batteriesStatistic?.knowledgesData} />
        </div>
      </div>

      <div className="inProgress-column">
        <div className="column-header">In progress</div>
        <div className="inProgress-list">
          {checkGamesCreationResults.map((gameData) => (
            <GameDataTile className="GameDataTile-box" gameData={gameData} knResults={knResultsRateBased} />
          ))}
        </div>
      </div>
      <div className="toDo-column">
        <div className="column-header">To do</div>

        <div className="toDo-list">
          <DragDropWrapper onDrop={(dropedData) => handleDropToTodoList(dropedData, 0)}>
            <Delimiter />
          </DragDropWrapper>
          {toDoList.map((toDoElement, index) => (
            <>
              <DragDropWrapper
                draggable={true}
                data={{ listType: "toDo", elementId: toDoElement.id }}
                // onDrop={(dropedData) =>
                //   handleDropToElement(dropedData, { listType: "toDo", elementId: toDoElement.id })
                // }
              >
                <PlanElementTile
                  className="planElementTile-box"
                  planElement={toDoElement}
                  onButtonClick={() => deleteElement("toDo", toDoElement)}
                  buttonType="minus"
                  buttonTooltip="Видалити"
                  onChange={(newValue) => handleElementChange("toDo", newValue)}
                  knResults={knResultsRateBased}
                  planElementCheckResults={checkPlanResults.find((elem) => elem.planElement.id === toDoElement.id)}
                  showArrowDownIcon={true}
                  tagsWithoutSoundSet={tagsWithoutSoundSet}
                />
              </DragDropWrapper>
              <DragDropWrapper onDrop={(dropedData) => handleDropToTodoList(dropedData, index + 1)}>
                <Delimiter />
              </DragDropWrapper>
            </>
          ))}
        </div>
      </div>
      <div className="future-column">
        <div className="checkPlanButton-block">
          <StyledButton
            className="button"
            onClick={() => {
              checkPlan(unityUser?._id);
              checkGamesCreation(unityUser?._id);
              getBatteriesStatistic(unityUser?._id, startDaysFromToday, endDaysFromToday);
            }}
            disabled={!unityUser || fetchingStatusActive}
            circularProgressSize={fetchingStatusActive && 25}
            tooltipProps={{
              title: fetchingStatusActive ? "Отримання результатів" : "Отримати результати",
              placement: "left",
              arrow: true,
            }}
          >
            <IconRefresh className="icon" />
          </StyledButton>
        </div>
        <div className="column-header">Future</div>
        <div className="future-list">
          {futureList.map((futureElement) => (
            <DragDropWrapper draggable={true} data={{ listType: "future", elementId: futureElement.id || "" }}>
              <PlanElementTile
                className="planElementTile-box"
                planElement={futureElement}
                knResults={knResultsRateBased}
                tagsWithoutSoundSet={tagsWithoutSoundSet}
              />
            </DragDropWrapper>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PlanPlus;
