import "./AssetBundleMainScreen.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useResourcesContext } from "resources";

import ImageExitButtonBase from "assets/images/exit_button_base.png";
import ImageExitButton from "assets/images/exit_button.png";
import { ItemUniversal } from "components";
import ItemPlaceholder from "../ItemPlaceholder/ItemPlaceholder";
import { findParentAndItem, getItemsWithChildrenPlain } from "helpers/stageItems";
import _ from "lodash";

const ExitButton = ({ scale }) => {
  return (
    <div
      className="exit-button-wrapper"
      style={{
        transform: `scale(${1 / scale})`,
        top: `${47 / scale}px`,
        left: `${38 / scale}px`,
      }}
    >
      <img src={ImageExitButtonBase} className="exit-button-base" alt="" />
      <img src={ImageExitButton} className="exit-button" alt="" />
    </div>
  );
};

const AssetBundleMainScreen = ({
  className = "",
  scene,
  allKnowledgesItems,
  selectedKnowledgeItemStageItemId,
  knowledgeItemPlaceholderIdx,
  activeItemData,
  onSceneChange,

  onPressDelete = () => {},
  onPressCopy = () => {},
  onPressPaste = () => {},
  onItemClick = () => {},
}) => {
  const [backgroundSize, setBackgroundSize] = useState(null);
  const backgroundRef = useRef();
  const mousePrevPosition = useRef({});
  const movingStageItemId = useRef(null);

  const resizeObserverRef = useRef();

  const [selectedStageItemId, setSelectedStageItemId] = useState("");

  const {
    imageListStore: { getImagePathById, getImageById },
  } = useResourcesContext();

  const [localScene, setLocalScene] = useState(scene);
  useEffect(() => {
    setLocalScene(scene);
  }, [scene]);

  const sceneItems = useMemo(() => localScene?.items || [], [localScene]);

  const sceneBackground = useMemo(
    () => (sceneItems.length > 0 ? getImagePathById(sceneItems[0]._id) : ""),
    [getImagePathById, sceneItems]
  );

  useEffect(() => {
    return () => {
      resizeObserverRef.current && resizeObserverRef.current.disconnect();
    };
  }, []);

  const sceneItemsFlat = useMemo(() => {
    return getItemsWithChildrenPlain(sceneItems);
  }, [sceneItems]);

  const activePlaceholder = useMemo(() => {
    const placeholder = sceneItems.filter((item) => item.type === "placeholder")[knowledgeItemPlaceholderIdx];
    if (!placeholder) {
      return null;
    }
    return placeholder;
  }, [sceneItems, knowledgeItemPlaceholderIdx]);

  const knowledgeItemWithPosition = useMemo(() => {
    if (selectedKnowledgeItemStageItemId && activePlaceholder) {
      const knowledgeItem = allKnowledgesItems.find((item) => item.stageItemId === selectedKnowledgeItemStageItemId);
      if (!knowledgeItem) {
        return null;
      }

      const itemPosition = {
        valueX: activePlaceholder.valueX,
        valueY: activePlaceholder.valueY,
        flipHorizontal: activePlaceholder.flipHorizontal ? !knowledgeItem.flipHorizontal : knowledgeItem.flipHorizontal,
        flipVertical: activePlaceholder.flipVertical ? !knowledgeItem.flipVertical : knowledgeItem.flipVertical,
      };

      if (activePlaceholder.bottomAnchorPoint) {
        const itemImage = getImageById(knowledgeItem._id);
        if (itemImage) itemPosition.valueY = itemPosition.valueY + ((itemImage.height / 2) * knowledgeItem.scale) / 100;
      }

      return {
        ...knowledgeItem,
        ...itemPosition,
      };
    }
    return null;
  }, [allKnowledgesItems, activePlaceholder, selectedKnowledgeItemStageItemId, getImageById]);

  const updateBackgroundSize = () => {
    backgroundRef.current &&
      setBackgroundSize({
        clientHeight: backgroundRef.current.clientHeight,
        clientWidth: backgroundRef.current.clientWidth,
        scale1920: 1920 / backgroundRef.current.clientWidth,
      });
  };

  const handleOnBackgroundLoad = () => {
    resizeObserverRef.current = new ResizeObserver(updateBackgroundSize).observe(backgroundRef.current);
  };

  const handleBackgroundMouseMove = (mouseEvent) => {
    if (movingStageItemId.current) {
      handleItemMouseMove(mouseEvent, movingStageItemId.current);
    } else {
      mousePrevPosition.current.x = mouseEvent.clientX;
      mousePrevPosition.current.y = mouseEvent.clientY;
    }
  };

  const handleMainScreenKeyDown = (event) => {
    let deltaX = 0;
    let deltaY = 0;
    if (event.key === "ArrowUp") deltaY = -2;
    if (event.key === "ArrowDown") deltaY = 2;
    if (event.key === "ArrowLeft") deltaX = -2;
    if (event.key === "ArrowRight") deltaX = 2;

    if ((deltaX || deltaY) && selectedStageItemId) {
      const { item } = findParentAndItem({ items: sceneItems }, selectedStageItemId);
      const newItem = _.cloneDeep(item);
      const scaledDeltaX = deltaX * backgroundSize.scale1920;
      const scaledDeltaY = deltaY * backgroundSize.scale1920;
      newItem.valueX = Math.round(newItem.valueX + scaledDeltaX);
      newItem.valueY = Math.round(newItem.valueY - scaledDeltaY);
      getItemsWithChildrenPlain(newItem.items || []).forEach((item) => {
        item.valueX = Math.round(item.valueX + scaledDeltaX);
        item.valueY = Math.round(item.valueY - scaledDeltaY);
      });
      sceneItemChange(newItem);
    }
  };

  const handleMainScreenKeyUp = (event) => {
    if (
      selectedStageItemId &&
      (event.key === "ArrowUp" || event.key === "ArrowDown" || event.key === "ArrowLeft" || event.key === "ArrowRight")
    ) {
      onSceneChange(localScene);
    }

    (event.key === "Delete" || event.key === "Backspace") && onPressDelete();

    const copyKeys = ["c", "C", "с", "С"];
    const pasteKeys = ["v", "V", "м", "М"];
    (((event.ctrlKey || event.metaKey) && copyKeys.includes(event.key)) || (event.key === "Insert" && event.ctrlKey)) &&
      onPressCopy();
    (((event.ctrlKey || event.metaKey) && pasteKeys.includes(event.key)) ||
      (event.key === "Insert" && event.shiftKey)) &&
      onPressPaste();
  };

  const handleItemMouseMove = (mouseEvent, stageItemId) => {
    if (!movingStageItemId.current || movingStageItemId.current !== stageItemId) {
      return;
    }

    mouseEvent.stopPropagation();

    if (mouseEvent.buttons === 1) {
      const { item } = findParentAndItem({ items: sceneItems }, movingStageItemId.current);
      const newItem = _.cloneDeep(item);
      const deltaX = (mouseEvent.clientX - mousePrevPosition.current.x) * backgroundSize.scale1920;
      const deltaY = (mouseEvent.clientY - mousePrevPosition.current.y) * backgroundSize.scale1920;
      newItem.valueX = Math.round(newItem.valueX + deltaX);
      newItem.valueY = Math.round(newItem.valueY - deltaY);
      getItemsWithChildrenPlain(newItem.items || []).forEach((item) => {
        item.valueX = Math.round(item.valueX + deltaX);
        item.valueY = Math.round(item.valueY - deltaY);
      });
      sceneItemChange(newItem);
    }
    mousePrevPosition.current.x = mouseEvent.clientX;
    mousePrevPosition.current.y = mouseEvent.clientY;
  };

  const handleItemPointerLeave = (event, stageItemId) => {
    movingStageItemId.current === stageItemId &&
      !(event.relatedTarget.id === "stageMainScreenBackgroundImage") &&
      !event.relatedTarget.id.includes(knowledgeItemWithPosition.stageItemId) &&
      !sceneItems.some((item, index) => {
        return event.relatedTarget.id && event.relatedTarget.id.includes(item.stageItemId);
      }) &&
      handleItemMouseClick();
  };

  const handleItemMouseClick = () => {
    if (movingStageItemId.current) {
      onSceneChange(localScene);
      movingStageItemId.current = null;
    }
  };

  const handlePlaceholderMouseDown = (event, stageItemId) => {
    movingStageItemId.current = stageItemId;
    onItemClick({ type: "placeholder", stageItemId: stageItemId });
  };

  const handleItemMouseDown = (event, stageItemId) => {
    movingStageItemId.current = stageItemId;
    onItemClick({ type: "", stageItemId: stageItemId });
  };

  const handleKnowledgeItemMouseDown = (event, placeholderStageItemId, knowledgeStageItemId) => {
    movingStageItemId.current = placeholderStageItemId;
    onItemClick({ type: "knowledgeItem", stageItemId: knowledgeStageItemId });
  };

  const handleMouseWheel = (event, stageItemId) => {
    if (event.shiftKey && event.deltaY) {
      const newItem = { ...sceneItems.find((sceneItem) => sceneItem.stageItemId === stageItemId) };
      if (!newItem.angle) newItem.angle = 0;
      newItem.angle = Math.floor(newItem.angle + event.deltaY / 15);
      if (newItem.angle > 360) newItem.angle = newItem.angle - 360;
      if (newItem.angle < 0) newItem.angle = newItem.angle + 360;
      sceneItemChange(newItem);
    }
  };

  const sceneItemChange = (newItemObject) => {
    if (!localScene) {
      return;
    }

    const mapItemsAndUpdateOne = (items, newItem) => {
      return items.map((sceneItem) => {
        if (sceneItem.stageItemId === newItem.stageItemId) {
          return newItem;
        } else {
          const newSceneItem = { ...sceneItem };
          newSceneItem.items = mapItemsAndUpdateOne(newSceneItem.items || [], newItem);
          return newSceneItem;
        }
      });
    };

    const newSceneItems = mapItemsAndUpdateOne(localScene.items || [], newItemObject);

    setLocalScene({ ...localScene, items: newSceneItems });
  };

  return (
    <div className={`assetBundleMainScreen-component ${className}`}>
      {sceneBackground && (
        <>
          <div
            id="stageMainScreen"
            className="main-screen"
            onMouseMoveCapture={(e) => e.preventDefault()}
            onKeyUp={handleMainScreenKeyUp}
            onKeyDown={handleMainScreenKeyDown}
            tabIndex="-1"
            onMouseMove={handleBackgroundMouseMove}
          >
            <img
              ref={backgroundRef}
              id={`stageMainScreenBackgroundImage`}
              className="main-screen-bg"
              src={sceneBackground}
              alt="Empty background"
              onLoad={handleOnBackgroundLoad}
              onMouseMoveCapture={(e) => e.preventDefault()}
            />
            :
            {sceneItemsFlat?.length > 0 &&
              backgroundSize &&
              sceneItemsFlat.map((item, index) =>
                index === 0 ? null : (
                  <div key={index} style={{ position: "absolute" }}>
                    {item.type === "placeholder" &&
                      knowledgeItemWithPosition &&
                      item.stageItemId === activePlaceholder.stageItemId && (
                        <ItemUniversal
                          imageClassName={
                            activeItemData.type === "knowledgeItem" &&
                            activeItemData.stageItemId === knowledgeItemWithPosition.stageItemId
                              ? "img-active"
                              : ""
                          }
                          item={knowledgeItemWithPosition}
                          backgroundSize={backgroundSize}
                          onMouseDown={(event) =>
                            handleKnowledgeItemMouseDown(
                              event,
                              activePlaceholder.stageItemId,
                              knowledgeItemWithPosition.stageItemId
                            )
                          }
                          onMouseMove={(event) => handleItemMouseMove(event, activePlaceholder.stageItemId)}
                          onPointerLeave={(event) => handleItemPointerLeave(event, activePlaceholder.stageItemId)}
                          onClick={(event) => {
                            handleItemMouseClick();
                            setSelectedStageItemId(activePlaceholder.stageItemId);
                          }}
                          moving={activePlaceholder.stageItemId === movingStageItemId.current}
                        />
                      )}
                    {item.type === "placeholder" &&
                      !(knowledgeItemWithPosition && item.stageItemId === activePlaceholder.stageItemId) && (
                        <ItemPlaceholder
                          key={item.stageItemId}
                          selected={
                            activeItemData.type === "placeholder" && activeItemData.stageItemId === item.stageItemId
                          }
                          active={activePlaceholder?.stageItemId === item.stageItemId}
                          item={item}
                          backgroundSize={backgroundSize}
                          onMouseDown={(event) => handlePlaceholderMouseDown(event, item.stageItemId)}
                          onMouseMove={(event) => handleItemMouseMove(event, item.stageItemId)}
                          onPointerLeave={(event) => handleItemPointerLeave(event, item.stageItemId)}
                          onClick={(event) => {
                            handleItemMouseClick();
                            setSelectedStageItemId(activePlaceholder.stageItemId);
                          }}
                          moving={item.stageItemId === movingStageItemId.current}
                        />
                      )}
                    {item.type !== "placeholder" && (
                      <ItemUniversal
                        key={item.stageItemId}
                        imageClassName={
                          activeItemData.type !== "placeholder" &&
                          activeItemData.type !== "knowledgeItem" &&
                          activeItemData.stageItemId === item.stageItemId
                            ? "img-active"
                            : ""
                        }
                        item={item}
                        backgroundSize={backgroundSize}
                        onMouseWheel={(event) => handleMouseWheel(event, item.stageItemId)}
                        onMouseDown={(event) => handleItemMouseDown(event, item.stageItemId)}
                        onMouseMove={(event) => handleItemMouseMove(event, item.stageItemId)}
                        onPointerLeave={(event) => handleItemPointerLeave(event, item.stageItemId)}
                        onClick={(event) => {
                          handleItemMouseClick();
                          setSelectedStageItemId(item.stageItemId);
                        }}
                        moving={item.stageItemId === movingStageItemId.current}
                      />
                    )}
                  </div>
                )
              )}
          </div>
          {backgroundSize && <ExitButton scale={backgroundSize.scale1920} />}
        </>
      )}
      {scene && !sceneBackground && <div className="emptyBackround-text">Оберіть фон</div>}
      {!scene && <div className="emptyBackround-text">Додайте сцену до бандла</div>}
    </div>
  );
};
export default AssetBundleMainScreen;
