import "./ImageEditor.scss";
import axios from "axios";
import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import {
  Tabs,
  Header,
  ImageList,
  CardStyled,
  FetchStatus,
  AlertDialog,
  StyledInput,
  StyledButton,
  ImageLibrary,
  UploadProgress,
  StageItemLibrary,
  StageTemplateLibrary,
} from "components";
import { ReactComponent as IconCancel } from "assets/icons/cancel.svg";
import { ReactComponent as IconUpload } from "assets/icons/upload.svg";
import { ReactComponent as IconEdit } from "assets/icons/EditGameIcon.svg";
import { ReactComponent as IconSave } from "assets/icons/changes.svg";
import Image from "./Image";

import { useResourcesContext } from "resources";
import { getImageSize } from "helpers/images";
import { set } from "lodash";

const ImageEditor = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [prevSelectedId, setPrevSelectedId] = useState(null);
  const [alertDialogData, setAlertDialogData] = useState(null);

  const [editPrompt, setEditPrompt] = useState(false);
  const [imagePrompt, setImagePrompt] = useState("");
  const [settingsImagePrompt, setSettingsImagePrompt] = useState("");

  const [uploadProgress, setUploadProgress] = useState(null);
  const [fetchStatus, setFetchStatus] = useState();
  const [activeTab, setActiveTab] = useState("Картинки");
  const navigate = useNavigate();
  const location = useLocation();
  const imageId = location.state?.imageId;

  const [imageUsedInData, setImageUsedInData] = useState({
    gamesWithImage: [],
    bundlesWithImage: [],
    stageTemplatesWithImage: [],
    itemTemplatesWithImage: [],
  });

  const {
    tagListStore: { getTagList },
    settingsStore: { settings, getSettings },
    itemTemplatesStore: { getItemTemplatesList, getItemTemplatesWithImage },
    imageListStore: { getImageList, imageList, createImage, updateImage, deleteImage, transformImage, getImageById },
    stageTemplatesStore: { getStageTemplatesList, getStageTemplatesWithImage },
    gameListStore: { getGameList, getGamesWithImage },
    assetBundleListStore: { getAssetBundleList, getBundlesWithImage },
  } = useResourcesContext();

  useEffect(() => {
    getTagList();
    getSettings();
    getGameList();
    getImageList();
    getAssetBundleList();
    getItemTemplatesList();
    getStageTemplatesList();
  }, []);

  useEffect(() => {
    if (selectedImage?._id) {
      const gamesWithImage = getGamesWithImage(selectedImage._id);
      setImageUsedInData((prev) => ({ ...prev, gamesWithImage }));
    }
  }, [selectedImage, getGamesWithImage]);
  useEffect(() => {
    if (selectedImage?._id) {
      const bundlesWithImage = getBundlesWithImage(selectedImage._id);
      setImageUsedInData((prev) => ({ ...prev, bundlesWithImage }));
    }
  }, [selectedImage, getBundlesWithImage]);
  useEffect(() => {
    if (selectedImage?._id) {
      const stageTemplatesWithImage = getStageTemplatesWithImage(selectedImage._id);
      setImageUsedInData((prev) => ({ ...prev, stageTemplatesWithImage }));
    }
  }, [selectedImage, getStageTemplatesWithImage]);
  useEffect(() => {
    if (selectedImage?._id) {
      const itemTemplatesWithImage = getItemTemplatesWithImage(selectedImage._id);
      setImageUsedInData((prev) => ({ ...prev, itemTemplatesWithImage }));
    }
  }, [selectedImage, getItemTemplatesWithImage]);

  const selectedImageCanBeDelated = useMemo(() => {
    const imageUsed =
      imageUsedInData.gamesWithImage.length ||
      imageUsedInData.bundlesWithImage.length ||
      imageUsedInData.stageTemplatesWithImage.length ||
      imageUsedInData.itemTemplatesWithImage.length;

    return selectedImage?._id && !imageUsed;
  }, [selectedImage, imageUsedInData]);

  useEffect(async () => {
    if (imageId) {
      setPrevSelectedId(null);
      setSelectedImage(getImageById(imageId));
      navigate("/image-editor", { replace: true });
    }
    if (editPrompt) {
      setSettingsImagePrompt(settings.imagePrompt);
    }
  }, [imageId, getImageById, navigate, editPrompt, settings]);

  useEffect(() => {
    if (selectedImage) {
      if (!(selectedImage._id === prevSelectedId)) {
        setFetchStatus(null);
      }
    }
  }, [selectedImage, prevSelectedId]);

  const save = (image) => {
    if (image) {
      setFetchStatus("SAVE_INIT");
      updateImage(image)
        .then((updatedImage) => {
          setSelectedImage(updatedImage);
          setPrevSelectedId(updatedImage._id);

          setFetchStatus("SAVE_SUCCESS");
        })
        .catch((error) => {
          setFetchStatus("SAVE_FAILURE");
          console.log(error);
        });
    }
  };

  const handleUploadProgress = (total, current) => {
    current ? setUploadProgress((100 / total) * current) : setUploadProgress(100 / total / 5);
  };

  const handleUploadInputChange = async (event) => {
    const files = [...event.target.files];

    for (let index = 0; index < files.length; index++) {
      let image = {
        _id: "",
        name: "",
        path: files[index],
        tags: [],
      };
      const fileObjectUrl = URL.createObjectURL(files[index]);
      const imageSize = await getImageSize(fileObjectUrl);
      image.height = imageSize.height;
      image.width = imageSize.width;

      handleUploadProgress(files.length, index);
      await createImage(image).catch((error) => {
        console.log(error);
      });
    }
    setUploadProgress(null);
  };

  const handleImageSelect = (selectedLibraryItem) => {
    setPrevSelectedId(selectedImage?._id);
    setSelectedImage(selectedLibraryItem);
  };

  const handleImageGenerate = async () => {
    try {
      if (imagePrompt.length !== 0) {
        setFetchStatus("SAVE_INIT");
        await axios.post(`/api/images/generate`, { imagePrompt });
        setFetchStatus("SAVE_SUCCESS");
        setImagePrompt("");
      }
    } catch (error) {
      console.log(error);
      setFetchStatus("SAVE_FAILURE");
    }
  };

  const handleImagePrompt = async () => {
    setEditPrompt(!editPrompt);

    try {
      if (settingsImagePrompt.length !== 0 && editPrompt) {
        setFetchStatus("SAVE_INIT");
        await axios.post("/api/settings", { payload: { imagePrompt: settingsImagePrompt } });
        setFetchStatus("SAVE_SUCCESS");
        setEditPrompt(false);
        getSettings();
      }
    } catch (error) {
      console.log(error);
      setFetchStatus("SAVE_FAILURE");
    }
  };

  const handleDeleteOkImage = () => {
    setAlertDialogData(null);
    setFetchStatus("DELETE_INIT");
    deleteImage(selectedImage._id)
      .then(() => {
        setFetchStatus("DELETE_SUCCESS");
        setSelectedImage(null);
        setPrevSelectedId(null);
      })
      .catch((error) => {
        setFetchStatus("DELETE_FAILURE");
        console.log(error);
      });
  };

  const handleTransformImage = (imageId, params) => {
    setFetchStatus("TRANSFORM_INIT");
    transformImage(imageId, params)
      .then((image) => {
        setFetchStatus("TRANSFORM_SUCCESS");
        setSelectedImage(image);
        setPrevSelectedId(null);
      })
      .catch((error) => {
        setFetchStatus("TRANSFORM_FAILURE");
        console.log(error);
      });
  };

  const openDeleteImageDialog = () => {
    setAlertDialogData({
      onOk: handleDeleteOkImage,
      onCancel: () => setAlertDialogData(null),
      title: "Deleting image",
      message: "Press Ok to delete Image from library, or Cancel to cancel operation.",
    });
  };

  const handleReloadImages = async () => {
    try {
      setFetchStatus("SAVE_INIT");
      await getImageList();
      setFetchStatus("SAVE_SUCCESS");
    } catch (error) {
      setFetchStatus("SAVE_FAILURE");
    }
  };

  return (
    <div className="image-editor-wrapper">
      <Header className="image-editor-header" />
      <div className="content-wrapper">
        <div className="left-side">
          <div className="edit-images">
            <StyledButton className="edit-images-btn" onClick={handleImagePrompt}>
              {!editPrompt ? <IconEdit className="button-icon" /> : <IconSave className="button-icon" />}
            </StyledButton>
          </div>

          <div className="title">Нові зображення</div>

          <div className="reload-images">
            <StyledButton
              className="reload-images-btn"
              onClick={handleReloadImages}
              disabled={fetchStatus === "SAVE_INIT"}
            >
              {fetchStatus === "SAVE_INIT" ? (
                <CircularProgress size={15} style={{ color: "#fff" }} />
              ) : (
                <IconCancel className="button-icon" />
              )}
            </StyledButton>
          </div>

          {editPrompt ? (
            <>
              <StyledInput
                value={settingsImagePrompt}
                className="generate-image"
                title="Редагувати промпт"
                placeholder="Редагувати промпт"
                disabled={fetchStatus === "SAVE_INIT"}
                tooltipProps={{ style: { width: "100%" } }}
                onChange={(event) => {
                  setSettingsImagePrompt(event.target.value);
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleImagePrompt();
                  }
                }}
              />
            </>
          ) : (
            <>
              <label className="file-upload">
                <input accept="image/*" type="file" multiple onChange={handleUploadInputChange} />
                <IconUpload className="button-icon" />
                Завантажити зображення
              </label>

              <StyledInput
                value={imagePrompt}
                className="generate-image"
                placeholder="Згенерувати картинку"
                disabled={fetchStatus === "SAVE_INIT"}
                tooltipProps={{ style: { width: "100%" } }}
                onChange={(event) => {
                  setImagePrompt(event.target.value);
                }}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleImageGenerate();
                  }
                }}
              />
            </>
          )}

          {!(uploadProgress == null) && (
            <CardStyled className="fetch-progress">
              <UploadProgress className="progress" value={uploadProgress} />
            </CardStyled>
          )}

          <ImageList
            imageList={
              imageList
                ? imageList.filter(
                    (image) => !["gameIcon", "stageTemplateIcon", "assetBundleIcon"].includes(image.type)
                  )
                : []
            }
            selectedImageId={selectedImage?._id}
            onImageSelect={handleImageSelect}
            onImageDelete={selectedImageCanBeDelated ? () => selectedImage?._id && handleDeleteOkImage() : null}
            untagged
          />
        </div>
        <div className="middle">
          {selectedImage && (
            <>
              <Image
                fetchStatus={fetchStatus}
                selectedImage={selectedImage}
                imageUsedInData={imageUsedInData}
                onChange={save}
                onDelete={openDeleteImageDialog}
                onTransform={handleTransformImage}
              />
              <div className="">
                <FetchStatus status={fetchStatus} />
              </div>
            </>
          )}
        </div>

        <div className="right-side">
          <Tabs
            className="tabs-block"
            tabs={["Картинки", "Групи", "Етапи"]}
            activeTab={activeTab}
            onChange={setActiveTab}
          />
          <div className="tabs-content-block">
            {activeTab === "Картинки" && (
              <ImageLibrary selectedImageId={selectedImage?._id} onImageSelect={handleImageSelect} imagesOnPage={100} />
            )}
            {activeTab === "Групи" && <StageItemLibrary deleteButton />}
            {activeTab === "Етапи" && <StageTemplateLibrary deleteButton />}
          </div>
        </div>
      </div>

      <AlertDialog alertDialogData={alertDialogData} />
    </div>
  );
};

export default ImageEditor;
