import "./CustomerTemplate.scss";
import React from "react";
import { CardStyled } from "components";

const CustomerTemplate = ({ header, content, isTransparent = false }) => {
  return (
    <>
      <div className="customer-template-wrapper">
        <div className="user-editor-left-header">{header}</div>

        <div className="content-wrapper">
          <CardStyled className={`middle-wrapper ${isTransparent ? 'transparent-bg' : ''}`}>{content}</CardStyled>
        </div>
      </div>
    </>
  );
};
export default CustomerTemplate;