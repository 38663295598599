import { useResourcesContext } from "resources";
import { ReactComponent as NewGameBtnIcon } from "assets/icons/NewGameBtn.svg";
import { useNavigate } from "react-router-dom";

const GameAddButton = () => {
  const navigate = useNavigate();

  const {
    gameListStore: { copyGameFromTemplate }
  } = useResourcesContext();

  const handleCreateGame = async () => {
    const newGame = await copyGameFromTemplate({ name: "universal", label: "Universal" });
    navigate(`/game-editor-universal/${newGame._id}`)
  };

  return (
    <div
      className="add-new-game-btn"
      title="Додати нову гру"
      onClick={() => handleCreateGame()}
    >
      <NewGameBtnIcon />
    </div>
  )
}

export default GameAddButton
