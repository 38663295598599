import WaveformData from "waveform-data";

const getSoundWaveformData = (url, scale = 128) => {
  if (!url) {
    return new Promise((resolve) => resolve({ upperHalf: [], lowerHalf: [], length: 0 }));
  }

  const audioContext = new AudioContext();

  return fetch(url)
    .then((response) => {
      if (response.ok) {
        return response.arrayBuffer();
      } else {
        return null;
      }
    })
    .then((buffer) => {
      if (!buffer) {
        return null;
      }

      const options = {
        audio_context: audioContext,
        array_buffer: buffer,
        scale: scale,
      };

      return new Promise((resolve, reject) => {
        WaveformData.createFromAudio(options, (err, waveform) => {
          if (err) {
            reject(err);
          } else {
            resolve(waveform);
          }
        });
      });
    })
    .then((waveform) => {
      if (!waveform) {
        return { upperHalf: [], lowerHalf: [], length: 0 };
      }
      const channel0 = waveform.channel(0);
      const upperHalf = new Array(waveform.length).fill("").map((elem, index) => channel0.max_sample(index));
      const lowerHalf = new Array(waveform.length).fill("").map((elem, index) => channel0.min_sample(index));
      return { upperHalf, lowerHalf, length: waveform.length };
    })
    .catch(() => {
      return { upperHalf: [], lowerHalf: [], length: 0 };
    });
};

export { getSoundWaveformData };
