import { Box, CircularProgress, Tooltip } from "@mui/material";
import React from "react";
import "./StyledButton.scss";

const StyledButton = ({
  iconHeader,
  circularProgressSize,
  progressPosition="center",
  tooltipProps = { title: "" },
  title,
  disabled,
  className,
  onClick,
  onMouseDown,
  onMouseUp,
  children,
}) => {
  return (
    <Tooltip {...tooltipProps}>
      <button
        className={`button ${className}`}
        disabled={disabled}
        onClick={onClick ? onClick : null}
        onMouseDown={onMouseDown ? onMouseDown : null}
        onMouseUp={onMouseUp ? onMouseUp : null}
      >
        <span>{children}</span>
        {circularProgressSize && (
          <div className={`progress ${progressPosition}`}>
            <Box sx={{ display: "flex" }}>
              <CircularProgress size={circularProgressSize} />
            </Box>
          </div>
        )}
        <div className="icon-header">{iconHeader}</div>
        <div className="header-title">{title}</div>
      </button>
    </Tooltip>
  );
};
export default StyledButton;
