import "./KnowledgePairsEditor.scss";
import { KnowledgeTagList, KnowledgeTagSelector } from "components";
import React, { useEffect, useState } from "react";
import { useResourcesContext } from "resources";

function KnowledgePairsEditor({ knowledgeTags = [], onChangeKnowledgeTags, knowledgeSounds = [] }) {
  const [similarKnowledgeTags, setSimilarKnowledgeTags] = useState({ percent100: [], percent75: [], percent50: [] });

  const {
    knowledgeTagListStore: { getSimilarKnowledgeTags },
  } = useResourcesContext();

  useEffect(() => {
    setSimilarKnowledgeTags({ percent100: [], percent75: [], percent50: [] });
    if (knowledgeSounds) {
      const ukSoundName = knowledgeSounds.find((sound) => sound && sound.language === "uk")?.name || "";
      if (ukSoundName) {
        const knowledgeTags100 = getSimilarKnowledgeTags(ukSoundName, 100);

        const knowledgeTags100Ids = knowledgeTags100.map((tag) => tag._id);

        const knowledgeTags75 = getSimilarKnowledgeTags(ukSoundName, 75).filter(
          (tag) => !knowledgeTags100Ids.includes(tag._id)
        );
        const knowledgeTags75Ids = knowledgeTags75.map((tag) => tag._id);

        const knowledgeTags50 = getSimilarKnowledgeTags(ukSoundName, 50).filter(
          (tag) => !knowledgeTags100Ids.includes(tag._id) && !knowledgeTags75Ids.includes(tag._id)
        );
        setSimilarKnowledgeTags({
          percent100: knowledgeTags100,
          percent75: knowledgeTags75,
          percent50: knowledgeTags50,
        });
      }
      return;
    }
  }, [knowledgeSounds, getSimilarKnowledgeTags]);

  return (
    <div className="knowledgesPairsEditor-component">
      <div className="knowledgeTags-block">
        <div className="header-label">Тегі знань:</div>
        <KnowledgeTagSelector
          inputClassName={`${!knowledgeTags.length ? "alarm-color" : ""}`}
          value={knowledgeTags.map((elem) => elem._id) || []}
          onChange={(value) => onChangeKnowledgeTags(value)}
          multiple
        />
      </div>
      <div className="similarKnowledgeTags-block">
        <div className="header-label">Схожі теги:</div>
        <div className="percentageTags-blocks">
          <div className="percentageTags-block">
            <KnowledgeTagList
              plusButton
              selectedTagsIds={knowledgeTags}
              knowledgeTags={[
                ...similarKnowledgeTags.percent100,
                ...similarKnowledgeTags.percent75,
                ...similarKnowledgeTags.percent50,
              ]}
              onClick={(knowledgeTag) => {
                onChangeKnowledgeTags([...knowledgeTags, knowledgeTag._id]);
              }}
              showSelectedTags={false}
            />
          </div>

          {!similarKnowledgeTags.percent100.length &&
          !similarKnowledgeTags.percent75.length &&
          !similarKnowledgeTags.percent50.length
            ? "не знайдено схожих тегів"
            : null}
        </div>
      </div>
    </div>
  );
}

export default KnowledgePairsEditor;
