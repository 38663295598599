import "./ItemUniversal.scss";
import { useImageSize } from "helpers/images";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { ReactComponent as HasASoundIcon } from "assets/icons/HasASound.svg";
import { useResourcesContext } from "resources";
import { Tooltip } from "@mui/material";

const ItemUniversalOld = ({
  item,
  backgroundSize,
  onMouseDown,
  onMouseMove,
  onPointerLeave,
  onClick,
  onMouseWheel,
  imageClassName,
  freeze,
  moving,
  showSoundIcon = true,
  showUnderlayer = false,
}) => {
  const [itemSize, processItemSize] = useImageSize();
  const [itemImage, setItemImage] = useState("");
  const imageRef = useRef();

  const {
    imageListStore: { getImagePathById },
  } = useResourcesContext();

  const getItemPosition = (itemImageSize, backgroundSize, item) => {
    if (itemImageSize && backgroundSize) {
      const itemHeight = ((itemImageSize.height / 100) * (item.scale || 100)) / backgroundSize.scale1920;
      const itemWidth = ((itemImageSize.width / 100) * (item.scale || 100)) / backgroundSize.scale1920;
      const position = {
        height: Math.round(itemHeight),
        width: Math.round(itemWidth),
        top: Math.round(
          backgroundSize.clientHeight / 2 - (item.valueY || 0) / backgroundSize.scale1920 - itemHeight / 2
        ),
        left: Math.round(
          (item.valueX || 0) / backgroundSize.scale1920 + backgroundSize.clientWidth / 2 - itemWidth / 2
        ),
      };
      return position;
    }
    return null;
  };

  const itemPosition = useMemo(() => getItemPosition(itemSize, backgroundSize, item), [itemSize, backgroundSize, item]);

  useEffect(() => {
    if (item?.stageItemId) {
      imageRef.current && imageRef.current.style.setProperty("--stroke-color", item.outline);
    }
  }, [item.outline, item.stageItemId]);

  useEffect(() => {
    if (item) {
      const itemPath = getImagePathById(item._id || item.imageId);
      setItemImage(itemPath);
      processItemSize(itemPath);
    }
  }, [getImagePathById, item, processItemSize]);

  

  return (
    <>
      {itemPosition && (
        <>
          <div
            id={"item " + item.stageItemId}
            className="item-universal-wrapper"
            onWheel={(event) => !freeze && onMouseWheel && onMouseWheel(event)}
            onPointerLeave={onPointerLeave}
            onMouseDown={(event) => !freeze && onMouseDown(event)}
            onMouseMoveCapture={(e) => e.preventDefault()}
            onMouseMove={(event) => !freeze && onMouseMove(event)}
            onClick={(e) => (onClick ? onClick(item) : "")}
            style={{
              position: "absolute",
              zIndex: moving ? 2000 : "auto",
              top: itemPosition ? itemPosition.top + "px" : "0px",
              left: itemPosition ? itemPosition.left + "px" : "0px",
              width: itemPosition ? itemPosition.width : "",
              height: itemPosition ? itemPosition.height : "",
              transform: `rotate(${item.angle ? item.angle : 0}deg) scaleX(${item.flipHorizontal ? -1 : 1}) scaleY(${
                item.flipVertical ? -1 : 1
              })`,
            }}
          >
            <div
              className={imageClassName}
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
              }}
            />
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
              }}
            >
              

              <img
                draggable={false}
                ref={imageRef}
                id={"image " + item.stageItemId}
                className={item.outline ? "outlined" : ""}
                style={{
                  filter: `blur(${item.blur / 4 / backgroundSize?.scale1920 / 5}px)`,
                  opacity: item.opacity / 100,
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  height: itemPosition ? itemPosition.height + "px" : "0px",

                  cursor: "pointer",
                }}
                onLoad={() => {
                  const element = document.getElementById(item.stageItemId);
                  element && element.style.setProperty("--stroke-color", item.outline);
                }}
                src={itemImage}
                alt="item"
              />
            </div>
            {showSoundIcon && item?.sounds?.main && (
              <Tooltip title="Об'єкт має звук" placement="right" arrow>
                <HasASoundIcon name="itemSoundIcon" className="sound-icon" />
              </Tooltip>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ItemUniversalOld;
