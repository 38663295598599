import "./UnityUsersPlot.scss";
import React, { useState, useMemo } from "react";
import { useEffect } from "react";
import { plot, ruleY, lineY, text } from "@observablehq/plot";
import axios from "axios";
import { set } from "lodash";
import { line } from "d3";

const UnityUsersPlot = () => {
  // Your component logic goes here
  const [plotData, setPlotData] = useState({ all: [], withStat: [], createdEarlier: [], activeUsersCountByDate: [] });
  const [period, setPeriod] = useState(0);
  const [usersCreatedInPeriod, setUsersCreatedInPeriod] = useState([]);
  const [activeUsersCreatedEarlier, setActiveUsersCreatedEarlier] = useState([]);
  const [activeUsersCountByDate, setActiveUsersCountByDate] = useState([]);

  const endDate = useMemo(() => {
    let endDate = new Date();
    endDate.setDate(endDate.getDate() - period * 28);
    endDate.setHours(23, 59, 59, 999);
    return endDate;
  }, [period]);

  const startDate = useMemo(() => {
    let startDate = new Date();
    startDate.setDate(startDate.getDate() - period * 28 - 28);
    startDate.setHours(23, 59, 59, 999);
    return startDate;
  }, [period]);

  const startDateTimestamp = startDate.getTime();
  const endDateTimestamp = endDate.getTime();

  useEffect(() => {
    startDateTimestamp &&
      endDateTimestamp &&
      axios
        .get(
          `/api/unityUsers/listForMarketing?startDateTimestamp=${startDateTimestamp}&endDateTimestamp=${endDateTimestamp}`
        )
        .then((res) => {
          setUsersCreatedInPeriod(res.data.usersCreatedInPeriod);
          setActiveUsersCreatedEarlier(res.data.activeUsersCreatedEarlier);
          setActiveUsersCountByDate(res.data.activeUsersCountByDate);
        })
        .catch((err) => {
          console.log(err);
          setUsersCreatedInPeriod([]);
          setActiveUsersCreatedEarlier([]);
          setActiveUsersCountByDate([]);
        });
  }, [startDateTimestamp, endDateTimestamp]);

  const [allUsersCount, setAllUsersCount] = useState(0);
  const [usersWithUniqDeviceIdCount, setUsersWithUniqDeviceIdCount] = useState(0);
  const [usersWithWebUserIdCount, setUsersWithWebUserIdCount] = useState(0);

  useEffect(() => {
    const allUsersCount = usersCreatedInPeriod.length;
    const usersWithUniqDeviceIdCount = new Set(usersCreatedInPeriod.map((elem) => elem.deviceId)).size;
    const usersWithWebUserIdCount = usersCreatedInPeriod.filter((elem) => elem.webUserId).length;

    setAllUsersCount(allUsersCount);
    setUsersWithUniqDeviceIdCount(usersWithUniqDeviceIdCount);
    setUsersWithWebUserIdCount(usersWithWebUserIdCount);
  }, [usersCreatedInPeriod]);

  useEffect(() => {
    const localUnityUserList = usersCreatedInPeriod.filter((unityUser) => {
      const createdAtDate = new Date(unityUser.createdAt);
      return createdAtDate >= startDate && createdAtDate <= endDate;
    });

    const usersGroupedByCreatedAt_all = localUnityUserList.reduce((acc, unityUser) => {
      const createdAtString = unityUser.createdAt.split("T")[0];
      const createdAtDate = new Date(createdAtString);

      if (!acc[createdAtString]) {
        acc[createdAtString] = { createdAtDate, count: 0 };
      }
      acc[createdAtString].count += 1;
      return acc;
    }, {});

    const usersGroupedByCreatedAt_withStat = localUnityUserList
      .filter((elem) => elem.lastActivityDate)
      .reduce((acc, unityUser) => {
        const createdAtString = unityUser.createdAt.split("T")[0];
        const createdAtDate = new Date(createdAtString);

        if (!acc[createdAtString]) {
          acc[createdAtString] = { createdAtDate, count: 0 };
        }
        acc[createdAtString].count += 1;
        return acc;
      }, {});

    const all = Object.values(usersGroupedByCreatedAt_all).sort((a, b) => a.createdAtDate - b.createdAtDate);
    const withStat = Object.values(usersGroupedByCreatedAt_withStat).sort((a, b) => a.createdAtDate - b.createdAtDate);

    setPlotData((prev) => ({ ...prev, all, withStat }));
  }, [usersCreatedInPeriod, startDate, endDate]);

  useEffect(() => {
    const localUnityUserList = activeUsersCreatedEarlier;

    const usersGroupedByLastActivityDate = localUnityUserList.reduce((acc, unityUser) => {
      const lastActivityDateString = unityUser.lastActivityDate.split("T")[0];
      const createdAtDateString = unityUser.createdAt.split("T")[0];

      const createdAtDate = new Date(createdAtDateString);
      const lastActivityDate = new Date(lastActivityDateString);

      if (createdAtDate < lastActivityDate) {
        if (!acc[lastActivityDateString]) {
          acc[lastActivityDateString] = { lastActivityDate, count: 0 };
        }
        acc[lastActivityDateString].count += 1;
      }
      return acc;
    }, {});

    const createdEarlier = Object.values(usersGroupedByLastActivityDate).sort(
      (a, b) => a.lastActivityDate - b.lastActivityDate
    );

    setPlotData((prev) => ({ ...prev, createdEarlier }));
  }, [activeUsersCreatedEarlier, startDate, endDate]);

  useEffect(() => {
    setPlotData((prev) => ({ ...prev, activeUsersCountByDate }));
  }, [activeUsersCountByDate]);

  useEffect(() => {
    const container = document.getElementById("unityUsersPot-container");
    const containerWidth = container.offsetWidth;

    const maxY = Math.max(
      10,
      plotData.all.length ? Math.max(...plotData.all.map((elem) => elem.count)) : 0,
      plotData.withStat.length ? Math.max(...plotData.withStat.map((elem) => elem.count)) : 0,
      plotData.createdEarlier.length ? Math.max(...plotData.createdEarlier.map((elem) => elem.count)) : 0,
      plotData.activeUsersCountByDate.length
        ? Math.max(...plotData.activeUsersCountByDate.map((elem) => elem.activeUsersCount))
        : 0
    );

    const myPlot = plot({
      width: containerWidth,
      x: {
        label: "",
        domain: [startDate, endDate],
      },
      y: {
        label: "",
        domain: [0, maxY],
      },

      marks: [
        ruleY([0]),
        lineY(plotData.all, {
          x: "createdAtDate",
          y: "count",
          stroke: "blue",
          strokeWidth: 4,
        }),
        text(plotData.all, {
          x: "createdAtDate",
          y: "count",
          text: "count",
          stroke: "white",
          fill: "currentColor",
          strokeWidth: 4,
        }),
        lineY(plotData.withStat, {
          x: "createdAtDate",
          y: "count",
          stroke: "green",
          strokeWidth: 4,
        }),
        text(plotData.withStat, {
          x: "createdAtDate",
          y: "count",
          text: "count",
          stroke: "white",
          fill: "currentColor",
          strokeWidth: 4,
        }),
        lineY(plotData.createdEarlier, {
          x: "lastActivityDate",
          y: "count",
          stroke: "red",
          strokeWidth: 4,
        }),
        text(plotData.createdEarlier, {
          x: "lastActivityDate",
          y: "count",
          text: "count",
          stroke: "white",
          fill: "currentColor",
          strokeWidth: 4,
        }),
        lineY(plotData.activeUsersCountByDate, {
          x: "activityDate",
          y: "activeUsersCount",
          stroke: "black",
          strokeWidth: 4,
        }),
        text(plotData.activeUsersCountByDate, {
          x: "activityDate",
          y: "activeUsersCount",
          text: "activeUsersCount",
          stroke: "white",
          fill: "currentColor",
          strokeWidth: 4,
        }),
      ],
    });

    container.appendChild(myPlot);

    return () => {
      container.removeChild(myPlot);
    };
  }, [plotData]);

  const getUTCDateString = (date) => {
    return date.getUTCDate() + "." + (date.getUTCMonth() + 1) + "." + date.getUTCFullYear();
  };

  return (
    <div className="unityUsersPlot-component">
      <div className="periodSelector">
        <button onClick={() => setPeriod((prev) => prev + 1)}>-</button>
        <div className="period">
          {getUTCDateString(startDate)} - {getUTCDateString(endDate)}
        </div>
        <button onClick={() => setPeriod((prev) => (!prev ? prev : prev - 1))}>+</button>
      </div>
      <div id="unityUsersPot-container"></div>
      <div className="legend">
        <div className="legend-item">
          <div className="legend-line legend-color-all"> </div>
          <div className="legend-text">Всі</div>
        </div>
        <div className="legend-item">
          <div className="legend-line legend-color-withStat"> </div>
          <div className="legend-text">Є статистика</div>
        </div>
        <div className="legend-item">
          <div className="legend-line legend-color-activeCreatedEarlie"> </div>
          <div className="legend-text">Активні (останій день активності, створені раніше)</div>
        </div>
        <div className="legend-item">
          <div className="legend-line legend-color-active"> </div>
          <div className="legend-text">Активні</div>
        </div>
      </div>

      <div className="additional-statistics">
        <div className="statistic-item">
          <div className="statistic-title">Всього користувачів</div>
          <div className="statistic-value">{allUsersCount}</div>
        </div>
        <div className="statistic-item">
          <div className="statistic-title">Користувачів з унікальним deviceId</div>
          <div className="statistic-value">{usersWithUniqDeviceIdCount}</div>
        </div>
        <div className="statistic-item">
          <div className="statistic-title">Зареєстровані</div>
          <div className="statistic-value">{usersWithWebUserIdCount}</div>
        </div>
      </div>
    </div>
  );
};

export default UnityUsersPlot;
