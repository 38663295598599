import "./AssetBundleActiveItem.scss";
import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import { RoundButton, SliderWithInput, CardStyled, ColorPickerButton } from "components";
import { ReactComponent as IconRemove } from "assets/icons/remove.svg";
import IconEmptyIcon from "assets/icons/emptyIcon.svg";
import { useResourcesContext } from "resources";
import { useMemo } from "react";

const AssetBundleActiveItem = ({
  className = "",
  item,
  isKnowlegeItem,
  isPlaceholder,
  onChange,
  onDelete,
  disabled,
}) => {
  const [valueX, setValueX] = useState(0);
  const [valueY, setValueY] = useState(0);

  const {
    imageListStore: { getImagePathById, getImageById },
  } = useResourcesContext();

  useEffect(() => {
    if (item) {
      setValueX(item.valueX);
      setValueY(item.valueY);
    }
  }, [item]);

  const aplyValueYtoItem = (min = -540, max = 540) => {
    if (valueY < min) {
      onChange({ ...item, valueY: min });
      return;
    }
    if (valueY > max) {
      onChange({ ...item, valueY: max });
      return;
    }
    onChange({ ...item, valueY: valueY });
  };

  const aplyValueXtoItem = (min = -960, max = 960) => {
    if (valueX < min) {
      onChange({ ...item, valueX: min });
      return;
    }
    if (valueX > max) {
      onChange({ ...item, valueX: max });
      return;
    }
    onChange({ ...item, valueX: valueX });
  };

  const itemImagePath = useMemo(() => getImagePathById(item?._id), [item, getImagePathById]);

  const handleUnderlayerChange = async (event) => {
    let underlayer = null;
    if (event.target.checked) {
      const underlayerImageId = "61a8c1da7e8004349409454e";

      const underlayerImage = getImageById(underlayerImageId);
      const itemImage = getImageById(item._id);
      !underlayerImage && console.log("can't find underlayer image");
      if (underlayerImage) {
        underlayer = {
          _id: underlayerImageId,
          scaleHeight: itemImage.height / underlayerImage.height,
          scaleWidth: itemImage.width / underlayerImage.width,
        };
      }
      onChange({ ...item, underlayer });
    } else {
      onChange({ ...item, underlayer: null, shadowlayer: null });
    }
  };

  const handleShadowlayerChange = async (event) => {
    let shadowlayer = null;
    let underlayer = null;
    if (event.target.checked) {
      const itemImage = getImageById(item._id);
      const shadowlayerImageId = "64725ee4149b9303b23d1b89";
      const shadowlayerImage = getImageById(shadowlayerImageId);
      !shadowlayerImage && console.log("can't find shadowlayer image ");
      shadowlayer = {
        _id: shadowlayerImageId,
        scaleHeight: itemImage.height / shadowlayerImage.height,
        scaleWidth: itemImage.width / shadowlayerImage.width,
      };

      const underlayerImageId = "61a8c1da7e8004349409454e";
      const underlayerImage = getImageById(underlayerImageId);
      !underlayerImage && console.log("can't find underlayer image");
      if (underlayerImage) {
        underlayer = {
          _id: underlayerImageId,
          scaleHeight: itemImage.height / underlayerImage.height,
          scaleWidth: itemImage.width / underlayerImage.width,
        };
      }
    }

    onChange({ ...item, shadowlayer, underlayer });
  };

  const handleBottomAnchorPointChange = async (event) => {
    onChange({ ...item, bottomAnchorPoint: Boolean(event.target.checked) });
  };

  return (
    <CardStyled className={`assetBundleActiveItem-component ${className}`}>
      <div className="item-active-content">
        <div className="item-left-content">
          <div className="item-image">
            {!isPlaceholder && <img src={itemImagePath || IconEmptyIcon} alt="" />}
            {isPlaceholder && <div className="placeholder"></div>}
          </div>
          <div className="buttons-wrapper">
            <RoundButton
              disabled={disabled}
              icon={IconRemove}
              className="remove-btn"
              onClick={onDelete}
              tooltipTitle="Удалить пропс"
            />
          </div>
        </div>

        <div className="item-ranges">
          <div className="top-row">
            <div className="activeItem-color-picker">
              <Checkbox
                disabled={disabled || isPlaceholder}
                checked={Boolean(item?.outline)}
                onChange={(event) => {
                  onChange({ ...item, outline: event.target.checked ? "#ff0000ff" : "" });
                }}
                className="outline-check"
              />
              Outline:
              <ColorPickerButton
                className="color-picker-button"
                disabled={!item?.outline || disabled}
                color={item?.outline}
                onChange={(color) => {
                  onChange({ ...item, outline: color });
                }}
              />
              <input
                disabled={!item?.outline || disabled}
                value={item?.outline || ""}
                onChange={(event) => {
                  onChange({ ...item, outline: event.target.value });
                }}
                className="custom-color"
              />
            </div>
            <div className="activeItem-color-picker">
              <Checkbox
                disabled={disabled || isPlaceholder}
                checked={Boolean(item?.fill)}
                onChange={(event) => {
                  onChange({ ...item, fill: event.target.checked ? "#ff0000ff" : undefined });
                }}
                className="outline-check"
              />
              Fill:
              <ColorPickerButton
                className="color-picker-button"
                disabled={!item?.fill || disabled}
                color={item?.fill}
                onChange={(color) => {
                  onChange({ ...item, fill: color });
                }}
              />
              <input
                disabled={!item?.fill || disabled}
                value={item?.fill || ""}
                onChange={(event) => {
                  onChange({ ...item, fill: event.target.value });
                }}
                className="custom-color"
              />
            </div>
          </div>

          <div className="two-columns">
            <div className="column1">
              <div className="checkbox-with-label">
                <Checkbox
                  disabled={disabled || isPlaceholder}
                  checked={Boolean(item?.magnetism)}
                  onChange={(event) => {
                    onChange({ ...item, magnetism: event.target.checked });
                  }}
                  className="checkbox"
                />
                Магнетизм
              </div>
              <div className="checkbox-with-label">
                <Checkbox
                  disabled={disabled || isPlaceholder}
                  checked={Boolean(item?.underlayer)}
                  onChange={handleUnderlayerChange}
                  className="checkbox"
                />
                Підложка
              </div>
              <div className="checkbox-with-label">
                <Checkbox
                  disabled={disabled || !isPlaceholder}
                  checked={Boolean(item?.bottomAnchorPoint)}
                  onChange={handleBottomAnchorPointChange}
                  className="checkbox"
                />
                Чіпляти за низ
              </div>
              <div className="checkbox-with-label">
                <Checkbox
                  disabled={disabled || isPlaceholder}
                  checked={Boolean(item?.shadowlayer)}
                  onChange={handleShadowlayerChange}
                  className="checkbox"
                />
                Тінь
              </div>
            </div>

            <div className="column2">
              <SliderWithInput
                className={"input-with-label"}
                disabled={disabled || isKnowlegeItem}
                onlyInput
                title="Y"
                min={-540}
                max={540}
                value={valueY}
                onChange={(value) => {
                  setValueY(value);
                  if (typeof value === "number" && Math.abs(Math.round(Number(value) - Number(valueY))) === 1) {
                    onChange({ ...item, valueY: value });
                  }
                }}
                onKeyDown={(event) => {
                  event.key === "+" && event.preventDefault();
                  if (event.key === "Enter" && typeof valueY === "number") {
                    aplyValueYtoItem();
                  }
                }}
                onBlur={() => {
                  if (typeof valueY === "number") {
                    aplyValueYtoItem();
                  }
                }}
              />
              <SliderWithInput
                className={"input-with-label"}
                disabled={disabled || isKnowlegeItem}
                onlyInput
                title="X"
                min={-960}
                max={960}
                value={valueX}
                onChange={(value) => {
                  setValueX(value);
                  if (typeof value === "number" && Math.abs(Math.round(Number(value) - Number(valueX))) === 1) {
                    onChange({ ...item, valueX: value });
                  }
                }}
                onKeyDown={(event) => {
                  event.key === "+" && event.preventDefault();
                  if (event.key === "Enter" && typeof valueX === "number") {
                    aplyValueXtoItem();
                  }
                }}
                onBlur={() => {
                  if (typeof valueX === "number") {
                    aplyValueXtoItem();
                  }
                }}
              />
            </div>
          </div>
          <div className="scale-and-flippers">
            <Tooltip arrow className="disabled-delete-tooltip" title="Перевернуть по горизонтали">
              <div className="checkbox-with-label">
                <Checkbox
                  disabled={disabled}
                  checked={Boolean(item?.flipHorizontal)}
                  onChange={(event) => {
                    !isPlaceholder &&
                      onChange({
                        ...item,
                        flipHorizontal: !item.flipHorizontal,
                        scaleX: item.scale * (!item.flipHorizontal ? -1 : 1),
                      });
                    isPlaceholder &&
                      onChange({
                        ...item,
                        flipHorizontal: !item.flipHorizontal,
                      });
                  }}
                  className="checkbox"
                />
                HF
              </div>
            </Tooltip>
            <Tooltip arrow className="disabled-delete-tooltip" title="Перевернуть по вертикали">
              <div className="checkbox-with-label vflipper">
                <Checkbox
                  disabled={disabled}
                  checked={Boolean(item?.flipVertical)}
                  onChange={(event) => {
                    !isPlaceholder &&
                      onChange({
                        ...item,
                        flipVertical: !item.flipVertical,
                        scaleY: item.scale * (!item.flipVertical ? -1 : 1),
                      });
                    isPlaceholder &&
                      onChange({
                        ...item,
                        flipVertical: !item.flipVertical,
                      });
                  }}
                  className="checkbox"
                />
                VF
              </div>
            </Tooltip>
            <SliderWithInput
              className={"slider scale"}
              disabled={disabled || isPlaceholder}
              title="Scale"
              value={item?.scale >= 0 ? item.scale : 100}
              min={0}
              max={200}
              onChange={(value) => {
                onChange({
                  ...item,
                  scale: value,
                  scaleX: value * (item.flipHorizontal ? -1 : 1),
                  scaleY: value * (item.flipVertical ? -1 : 1),
                });
              }}
              onKeyDown={(event) => {
                (event.key === "-" || event.key === "+") && event.preventDefault();
              }}
            />
          </div>

          <SliderWithInput
            className={"slider"}
            disabled={disabled || isPlaceholder}
            title="Rotate"
            value={item?.angle}
            min={0}
            max={360}
            onChange={(value) => {
              onChange({
                ...item,
                angle: value,
              });
            }}
            onKeyDown={(event) => {
              (event.key === "+" || event.key === "-") && event.preventDefault();
            }}
          />

          <SliderWithInput
            className={"slider"}
            disabled={disabled || isPlaceholder}
            title="Opacity"
            value={item?.opacity >= 0 ? item.opacity : 100}
            min={0}
            max={100}
            onChange={(value) => {
              onChange({
                ...item,
                opacity: value,
              });
            }}
            onKeyDown={(event) => {
              (event.key === "-" || event.key === "+") && event.preventDefault();
            }}
          />
        </div>
      </div>
    </CardStyled>
  );
};

export default AssetBundleActiveItem;
