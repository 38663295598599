import "./Stage.scss";
import React, { useState } from "react";
import { Tabs } from "components";

const StageEmpty = ({ externalTabs = [] }) => {
  const [activeTab, setActiveTab] = useState("Етапи");

  return (
    <div className="universal-game-stage-component">
      <div className="left-box-wrapper"></div>
      <div className="right-box-wrapper">
        <Tabs
          className="tabs-block"
          tabs={[...externalTabs.map((extTab) => extTab.tabLabel)]}
          activeTab={activeTab}
          onChange={setActiveTab}
        />
        <div className="tabs-content-block">
          {externalTabs.map((extTab) => activeTab === extTab.tabLabel && extTab.tabJsx)}
        </div>
      </div>
    </div>
  );
};

export default StageEmpty;
