import "./UserDetailsForm.scss";
import { Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { StyledInput, StyledButton, DropdownSelector } from "components";
import { languages, genders, monthes, years } from "constants/constants";
import unityUserListApi from "API/unityUserListApi";

const UserDetailsForm = ({ unityUser = null, handleSubmit, children }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("male");
  const [language, setLanguage] = useState("uk");
  const [aboutChild, setAboutChild] = useState("");
  const [webUserEmail, setWebUserEmail] = useState("");
  const [birthdayYear, setBirthdayYear] = useState("");
  const [birthdayMonth, setBirthdayMonth] = useState("");
  const [webUserPassword, setWebUserPassword] = useState("");

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (unityUser) {
      if (unityUser.name.length > 0 && unityUser.name.split(" ").length > 0) {
        setFirstName(unityUser.name.split(" ")[0] || "");
        setLastName(unityUser.name.split(" ")[1] || "");
      } else {
        setFirstName(unityUser.name || "");
      }
      setGender(unityUser.gender || "male");
      setLanguage(unityUser.language || "uk");
      setAboutChild(unityUser.aboutChild || "");
      setWebUserEmail(unityUser.webUserEmail || "");
      setBirthdayYear(unityUser.birthdayYear || "2015");
      setBirthdayMonth(unityUser.birthdayMonth || "1");
    }
  }, [unityUser]);

  const validateForm = () => {
    const newErrors = {};
    if (!firstName) newErrors.firstName = "Ім'я є обов'язковим";
    if (!webUserEmail) {
      newErrors.webUserEmail = "Email є обов'язковим";
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(webUserEmail)) newErrors.webUserEmail = "Невірний формат email";
    }
    if (!unityUser && !webUserPassword) {
      newErrors.webUserPassword = "Пароль є обов'язковим";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (!validateForm()) return;

    if (unityUser) {
      unityUser.name = `${firstName} ${lastName}`;
      unityUser.gender = gender;
      unityUser.language = language;
      unityUser.aboutChild = aboutChild;
      unityUser.webUserEmail = webUserEmail;
      unityUser.birthdayYear = birthdayYear;
      unityUser.birthdayMonth = birthdayMonth;

      unityUserListApi
        .updateUnityUser(unityUser._id, unityUser)
        .then(() => {
          handleSubmit();
          toast.success("Збережено успішно.");
        })
        .catch(() => {
          toast.error("Помилка при збереженні.");
        });
    } else {
      unityUserListApi
        .createUnityUserCustomer({
          name: `${firstName} ${lastName}`,
          gender: gender,
          language: language,
          aboutChild: aboutChild,
          webUserEmail: webUserEmail,
          birthdayYear: birthdayYear,
          birthdayMonth: birthdayMonth,
          webUserPassword: webUserPassword,
        })
        .then(() => {
          handleSubmit();
          toast.success("Збережено успішно.");
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            setErrors({ webUserEmail: error.response.data });
          } else {
            toast.error("Помилка при збереженні.");
          }
        });
    }
  };

  return (
    <div className="userDetailsForm-component">
      <Grid container spacing={1}>
        <Grid item md={6}>
          <StyledInput
            className="input"
            title="Ім'я:"
            placeholder={"Введіть імʼя"}
            value={firstName}
            onChange={(event) => {
              setFirstName(event.target.value);
            }}
            error={errors.firstName}
            helperText={errors.firstName}
          />
        </Grid>
        <Grid item md={6}>
          <StyledInput
            className="input"
            title="Прізвище:"
            placeholder={"Введіть Прізвище"}
            value={lastName}
            onChange={(event) => {
              setLastName(event.target.value);
            }}
            error={errors.lastName}
            helperText={errors.lastName}
          />
        </Grid>
        <Grid item md={12}>
          <StyledInput
            className="input"
            title="Email:"
            disabled={unityUser && unityUser.webUserEmail}
            placeholder={"Введіть Email"}
            value={webUserEmail}
            onChange={(event) => {
              setWebUserEmail(event.target.value);
            }}
            error={errors.webUserEmail}
            helperText={errors.webUserEmail}
          />
        </Grid>
        {!unityUser && (
          <Grid item md={12}>
            <StyledInput
              className="input"
              title="Пароль:"
              placeholder={"Введіть Пароль"}
              value={webUserPassword}
              onChange={(event) => {
                setWebUserPassword(event.target.value);
              }}
              error={errors.webUserPassword}
              helperText={errors.webUserPassword}
            />
          </Grid>
        )}
        <Grid item md={6}>
          <DropdownSelector
            title="Місяць"
            placeholder="Місяць"
            withNone={false}
            options={monthes}
            selectedOption={monthes.find((elem) => elem.name === birthdayMonth.toString()) || ""}
            onChange={(option) => setBirthdayMonth(option.name)}
            error={errors.birthdayMonth}
          />
        </Grid>
        <Grid item md={6}>
          <DropdownSelector
            title="Рік"
            placeholder="Рік"
            withNone={false}
            options={years}
            selectedOption={years.find((elem) => elem.name === birthdayYear.toString()) || ""}
            onChange={(option) => setBirthdayYear(option.name)}
            error={errors.birthdayYear}
          />
        </Grid>
        <Grid item md={12}>
          <DropdownSelector
            title="Стать дитини"
            placeholder="Стать дитини"
            withNone={false}
            options={genders}
            selectedOption={genders.find((elem) => elem.name === gender) || ""}
            onChange={(option) => setGender(option.name)}
          />
        </Grid>
        <Grid item md={12}>
          <DropdownSelector
            title="Мова озвучки"
            placeholder="Мова озвучки"
            withNone={false}
            options={languages}
            selectedOption={languages.find((elem) => elem.name === language) || ""}
            onChange={(option) => setLanguage(option.name)}
          />
        </Grid>
        <Grid item md={12}>
          <StyledInput
            multiRow
            className="input"
            title="Додаткова інформація:"
            placeholder={"Введіть додаткову інформацію"}
            value={aboutChild}
            onChange={(event) => {
              setAboutChild(event.target.value);
            }}
          />
        </Grid>
        <Grid container justify="flex-end" item md={12}>
          {children}
          <StyledButton onClick={handleSave} className="userDetailsForm-submitButton">
            Зберегти
          </StyledButton>
        </Grid>
      </Grid>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="lig}ht"
      />
    </div>
  );
};

export default UserDetailsForm;
