import { Locales } from "./locales";
import { enUk } from "./messages";
import { ukEn } from "./messages";

export const messages = {
  [Locales.ENGLISH]: enUk,
  [Locales.UKRAINIAN]: ukEn,
};

export * from "./locales";
export * from "./useI18N";
