import "./KnowledgeCard.scss";
import React, {  useMemo, useState } from "react";
import { AlertDialog, SoundSelector, StyledButton } from "components";
import { ReactComponent as IconRemove } from "assets/icons/remove.svg";
import ShowWrongKnowledgesIcon from "@mui/icons-material/MenuOutlined";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import ShowKnowledgeIcon from "@mui/icons-material/AssignmentReturnOutlined";
import { CircularProgress } from "@mui/material";

import { getSoundBySoundSetId } from "helpers/soundLanguage";
import Item from "./Item";


const KnowledgeCard = ({
  className = "",
  selected,
  knowledge,
  usedKnowledgeSounds = [],
  usedWrongSounds = [],
  onClick,
  onDoubleClick,
  onDelete,
  onPressDeleteKey,
  onChange,
  locked,
  controls = true,
  showCircularProgress,
  onItemClick,
  selectedItemStageItemId,
}) => {
  const [alertDialogData, setAlertDialogData] = useState(null);
  const [showWrongKnowledges, setShowWrongKnowledges] = useState(Boolean(knowledge.wrongKnowledges?.length));
  const [plaingSound, setPlaingSound] = useState(false);

  const knSound = useMemo(
    () => usedKnowledgeSounds.find((sound) => sound.soundSetId === knowledge.soundSet),
    [usedKnowledgeSounds, knowledge.soundSet]
  );
  
  const knSoundInfoString = useMemo(() => {
    if (!knSound) {
      return "Звук не залінкован";
    }
    return `${knSound.name} (${knSound.language})`;
  }, [knSound]);

  const openDeleteDialog = () => {
    setAlertDialogData({
      onOk: () => {
        onDelete();
        setAlertDialogData(null);
      },
      onCancel: () => setAlertDialogData(null),
      title: "Deleting knowledge",
      message: "Press Ok to delete knowledge, or Cancel to cancel operation.",
    });
  };

  const handleWrongKnowledgesChange = async (values) => {
    onChange({ ...knowledge, wrongSoundSets: values.map((value) => value.soundSetId) });
  };

  const handleKeyUp = (event) => {
    (event.key === "Delete" || event.key === "Backspace") && onPressDeleteKey();
  };

  const playSound = (knSound) => {
    if (!knSound?.path || plaingSound) {
      return;
    }

    setPlaingSound(true);
    const audio = new Audio(knSound.path);
    audio.onended = () => {
      setPlaingSound(false);
    };
    audio.play();
  };

  return (
    <>
      <div
        className={`knowledge-card ${className} ${selected ? "active-knowledge-card" : ""}`}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
        onKeyUp={handleKeyUp}
        tabIndex={"-1"}
      >
        <div className="knowledgeCommonData">
          <div className="knSound">
            <div className={`knSound-playButton ${knSound?.path ? "" : "disabled"}`} onClick={() => playSound(knSound)}>
              {plaingSound ? (
                <StopIcon className="stopIcon" />
              ) : (
                <PlayArrowIcon className={knSound?.path ? `playIcon` : `playIcon disabled`} />
              )}
            </div>
            <div className="knSound-soundInfo">{knSoundInfoString}</div>
          </div>

          <div className="knowledge-items-list">
            {knowledge.items.map((knowledgeItem, index) => (
              <Item
                key={index}
                imageId={knowledgeItem._id}
                onClick={() => onItemClick(knowledgeItem.stageItemId)}
                selected={selectedItemStageItemId === knowledgeItem.stageItemId}
              />
            ))}
          </div>
        </div>
        {showWrongKnowledges && (
          <div className="wrongKnowledges">
            <div className="knowledgesSelector-multiple">
              <SoundSelector
                label={"Неправильні знання"}
                multiple={true}
                sounds={usedKnowledgeSounds}
                value={(knowledge.wrongSoundSets || []).map((soundSetId) =>
                  getSoundBySoundSetId(soundSetId, usedWrongSounds)
                )}
                onChangeValue={handleWrongKnowledgesChange}
              />
            </div>
          </div>
        )}
        <div className="footer"></div>
        {controls && (
          <>
            {!showWrongKnowledges ? (
              <StyledButton
                tooltipProps={{
                  title: showWrongKnowledges ? "Сховати неправильні варіанти" : "Неправильні варіанти",
                  placement: "right",
                  arrow: true,
                }}
                className="customize-button showWrongKnowledges"
                onClick={(e) => {
                  setShowWrongKnowledges((prev) => !prev);
                  e.stopPropagation();
                }}
              >
                {showWrongKnowledges ? (
                  <ShowKnowledgeIcon className="button-mui-icon" />
                ) : (
                  <ShowWrongKnowledgesIcon className="button-mui-icon" />
                )}
              </StyledButton>
            ) : null}
            <StyledButton
              tooltipProps={{ title: "Видалити", placement: "right", arrow: true }}
              disabled={locked}
              className="customize-button remove"
              onClick={(e) => {
                openDeleteDialog();
                e.stopPropagation();
              }}
            >
              <IconRemove className="button-icon" />
            </StyledButton>
          </>
        )}

        {showCircularProgress && (
          <CircularProgress className="circular-progress" size={100} style={{ color: "#FAA400" }} />
        )}
      </div>
      <AlertDialog alertDialogData={alertDialogData} />
    </>
  );
};
export default KnowledgeCard;
