import { getFilteredList, getOptionsWithCounters, replaceFilterOptions } from "helpers/filters/filters";
import {
  filterGamesByCreators,
  filterGamesByKnowledgeTypes,
  filterGamesByKnowledges,
  filterGamesByName,
  filterGamesByTypes,
} from "./gameFilteringMethods";
import {
  getGameTypesOptions,
  getCreatorsOptions,
  getKnowledgeTypesOptions,
  getKnowledgesOptions,
} from "./gameFilterOptions";

const getGameFilteringMethods = () => {
  return {
    gameTypes: filterGamesByTypes,
    name: filterGamesByName,
    creators: filterGamesByCreators,
    knowledgeTypes: filterGamesByKnowledgeTypes,
    knowledges: filterGamesByKnowledges,
  };
};

const getFilterOptionsMethods = () => {
  return {
    gameTypes: getGameTypesOptions,
    creators: getCreatorsOptions,
    knowledgeTypes: getKnowledgeTypesOptions,
    knowledges: getKnowledgesOptions,
  };
};

export const getGameOptionsWithCounters = (games, filters, filter) => {
  const filteringMethods = getGameFilteringMethods();
  return getOptionsWithCounters(games, filters, filter, filteringMethods);
};

export const getFilteredGames = (games, filters) => {
  const filteringMethods = getGameFilteringMethods();
  return getFilteredList(games, filters, filteringMethods);
};

const addOptions = (filters, games) => {
  const filterOptions = getFilterOptionsMethods();

  return filters?.map((filter) => {
    if (filter.name in filterOptions) {
      return replaceFilterOptions(filter, filterOptions[filter.name](games));
    }

    return filter;
  });
};

const addValues = (filters, selectedFilters) =>
  filters?.map((filter) => {
    const value = selectedFilters.getSearchParam(filter.name);
    return value ? { ...filter, value: value } : filter;
  });

const addCounters = (filters, games) =>
  filters?.map((filter) => (filter.type === "text" ? filter : getGameOptionsWithCounters(games, filters, filter)));

export const fulfillGameData = (filterStructure, selectedFilters, games) => {
  let filters = filterStructure;

  filters = addOptions(filters, games);
  filters = addValues(filters, selectedFilters);
  filters = addCounters(filters, games);

  return filters;
};
