import "./InputWithPopup.scss";
import React from "react";
import { TextField, Tooltip } from "@mui/material/";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

const filter = createFilterOptions();

const InputWithPopup = ({
  className = "",
  optionLabelField = "",
  placeholder="",
  label,
  options = [],
  value = null,
  onChange,
  noOptionsText = "no options",
  disabled,
}) => {
  const handleFilterOptions = (options, params) => {
    let filtered = filter(options, params).sort((a, b) =>
      a[optionLabelField].toLowerCase() < b[optionLabelField].toLowerCase() ? -1 : 1
    );
    if (!params.inputValue) {
      if (filtered.length > 10) {
        filtered.splice(10, filtered.length - 10);
      }
    }

    if (params.inputValue) {
      filtered.sort((a, b) => {
        const aPos = a[optionLabelField].toLowerCase().indexOf(params.inputValue.toLowerCase());
        const bPos = b[optionLabelField].toLowerCase().indexOf(params.inputValue.toLowerCase());

        if (aPos > bPos) {
          return 1;
        }
        if (aPos < bPos) {
          return -1;
        }
        if (a[optionLabelField].length > b[optionLabelField].length) {
          return 1;
        }

        return -1;
      });
    }

    return filtered;
  };

  const renderInput = (params) => {
    const textField = (
      <TextField
        {...params}
        placeholder={placeholder}
        label={label}
        InputProps={{
          ...params.InputProps,
          
          startAdornment: null,
        }}
      />
    );

    if (value && value[optionLabelField]) {
      return (
        <Tooltip title={value[optionLabelField]} placement="top" arrow>
          {textField}
        </Tooltip>
      );
    } else {
      return textField;
    }
  };

  return (
    <div className={`inputWithPopup-component ${className}`}>
      <Autocomplete
        onChange={onChange}
        value={value}
        autoHighlight
        options={options}
        noOptionsText={noOptionsText}
        filterOptions={handleFilterOptions}
        getOptionLabel={(option) => option[optionLabelField] || ""}
        isOptionEqualToValue={(option, value) => option._id === value?._id}
        renderInput={renderInput}
      />
    </div>
  );
};
export default InputWithPopup;
