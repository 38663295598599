import { getAllKnowledges, getAllGameTypes } from "./gameFiltersSubHelper";

export const getGameTypesOptions = (games) => {
  const allGameTypes = getAllGameTypes(games)
  const allGameTypesUnique = [...new Set(allGameTypes)];

  return allGameTypesUnique.map((gameType) => {
    return { value: gameType };
  });
};

export const getCreatorsOptions = (games) => {
  const createdBy = games?.map((game) => game.createdBy).filter(Boolean) || [];

  return Array.from(new Set([...createdBy])).map((email) => {
    return { value: email };
  });
};

export const getKnowledgeTypesOptions = () => {
  return [{ value: "Навчальні" }, { value: "Контролюючі" }];
};

export const getKnowledgesOptions = (games) => {
  const allKnowledges = getAllKnowledges(games)
  const allKnowledgesUnique = [...new Set(allKnowledges)];

  return allKnowledgesUnique.map((knowledge) => {
    return { value: knowledge };
  });
};
