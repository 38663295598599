import "./KnowledgesRateSetup.scss";
import React, { useEffect, useState, useMemo } from "react";

import { StyledButton, StyledInput } from "components";

import { useResourcesContext } from "resources";
import { debounce } from "lodash";

const KnowledgesRateSetup = ({ className = "", unityUser, knResultsRateBased, onLearningProgressChange }) => {
  const [populatedGroups, setPopulatedGroups] = useState([]);
  const [groupLevels, setGroupLevels] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const {
    knowledgeGroupListStore: { knowledgeGroupList },
    knowledgeTagListStore: { knowledgeTagList },
  } = useResourcesContext();

  useEffect(() => {
    const populatedGroups = knowledgeGroupList
      .map((knGroup) => {
        const knowledgeTags = knowledgeTagList.filter((knTag) => knGroup.knowledgeTags.includes(knTag._id));

        return { ...knGroup, knowledgeTags };
      })
      .sort((a, b) => {
        const aName = a.name.toLowerCase();
        const bName = b.name.toLowerCase();
        return aName.localeCompare(bName);
      });

    const usedTagsIds = knowledgeGroupList.reduce((result, knGroup) => {
      return [...result, ...knGroup.knowledgeTags];
    }, []);

    const emptyGroupknowledgeTags = knowledgeTagList.filter((knTag) => !usedTagsIds.includes(knTag._id));
    populatedGroups.push({ name: "без груп", knowledgeTags: emptyGroupknowledgeTags });

    const filteredGroups = populatedGroups.filter((group) => {
      if (!searchValue) {
        return true;
      }
      if (group.name.toLowerCase().includes(searchValue.toLowerCase())) {
        return true;
      }
      if (group.knowledgeTags.some((knTag) => knTag.name.toLowerCase().includes(searchValue.toLowerCase()))) {
        return true;
      }
      return false;
    });

    setGroupLevels(
      Array.from(
        new Set(
          populatedGroups.map((group) => {
            return group.level;
          })
        )
      ).sort((a, b) => a - b)
    );

    setPopulatedGroups(filteredGroups);
  }, [knowledgeGroupList, knowledgeTagList, searchValue]);

  const getKnowledgeStateClassName = (knowledgeTagId) => {
    if (knResultsRateBased.knownList.includes(knowledgeTagId)) {
      return "known-color";
    }
    if (knResultsRateBased.knownListForMinorKnowledges.includes(knowledgeTagId)) {
      return "knownMinor-color";
    }

    if (knResultsRateBased.readyToControlList.includes(knowledgeTagId)) {
      return "control-color";
    }
    if (knResultsRateBased.readyToLearnList.includes(knowledgeTagId)) {
      return "learn-color";
    }
  };

  const getKnowledgeNameExtraInfo = (knowledge, actualLearningProgress = [], learningProgress = []) => {
    const knLearningProgress = learningProgress?.find((kn) => kn.knowledge === knowledge._id);
    const knowledgeStrength = Math.round(knLearningProgress?.strength || 0);

    const actualKnLearningProgress = actualLearningProgress?.find((kn) => kn.knowledge === knowledge._id);

    const actualKnowledgeStrength = Math.round(actualKnLearningProgress?.strength || 0);
    const forgetRate = knLearningProgress?.forgetRate || 0;
    const showStrength = Math.round(knLearningProgress?.showStrength || 0);
    const know = Boolean(knLearningProgress?.know);

    return `${knowledgeStrength}, ${forgetRate.toFixed(2)}, (${actualKnowledgeStrength}, ${showStrength}${
      know ? ", " : ""
    }${
      know && knLearningProgress?.knowTimestamp ? new Date(knLearningProgress?.knowTimestamp).toLocaleDateString() : ""
    })`;
  };

  const handleSetStrengthToGroup = (group, strength, forgetRate) => {
    const groupKnTagsIds = group.knowledgeTags.map((knTag) => knTag._id);

    const oldLearningProgress = unityUser.learningProgress
      .filter((progressData) => !groupKnTagsIds.includes(progressData.knowledge))
      .map((progressData) => ({ ...progressData }));

    //we unset timestamp, and it will be set to current time in backend
    const newLearningProgress = group.knowledgeTags.map((knTag) => {
      const progressElementToUpdate = unityUser.learningProgress.find(
        (progressData) => progressData.knowledge === knTag._id
      );

      return {
        knowledge: knTag._id,
        strength: Number(strength),
        forgetRate: Number(forgetRate),
        showStrength: progressElementToUpdate?.showStrength || 0,
        know: Boolean(progressElementToUpdate?.know),
        knowTimestamp: progressElementToUpdate?.knowTimestamp,
      };
    });
    const learningProgress = [...oldLearningProgress, ...newLearningProgress];

    onLearningProgressChange(learningProgress, newLearningProgress);
  };

  const handleSetStrengthToKnTag = (knTag, strength, forgetRate) => {
    const oldLearningProgress = unityUser.learningProgress
      .filter((progressData) => progressData.knowledge !== knTag._id)
      .map((progressData) => ({ ...progressData }));

    //we unset timestamp, and it will be set to current time in backend
    const progressElementToUpdate = unityUser.learningProgress.find(
      (progressData) => progressData.knowledge === knTag._id
    );
    const newLearningProgress = [
      {
        knowledge: knTag._id,
        strength: Number(strength),
        forgetRate: Number(forgetRate),
        showStrength: progressElementToUpdate?.showStrength || 0,
        know: Boolean(progressElementToUpdate?.know),
        knowTimestamp: progressElementToUpdate?.knowTimestamp,
      },
    ];
    const learningProgress = [...oldLearningProgress, ...newLearningProgress];

    onLearningProgressChange(learningProgress, newLearningProgress);
  };

  const getGroupKnowStatusClassName = (group, learningProgress) => {
    if (!learningProgress) {
      return "";
    }

    if (group.knowledgeTags.every((knTag) => learningProgress.find((kn) => kn.knowledge === knTag._id)?.know)) {
      return "known-color";
    }
    return "";
  };

  const getGroupKnowDate = (group, learningProgress) => {
    if (!learningProgress) {
      return "";
    }

    const knTags = group.knowledgeTags;

    const knowGroup = knTags.every((knTag) => {
      const knTagProgress = learningProgress.find((kn) => kn.knowledge === knTag._id);
      return knTagProgress?.know;
    });

    if (!knowGroup) return "";

    const knTagsKnowDates = knTags
      .map((knTag) => {
        const knTagProgress = learningProgress.find((kn) => kn.knowledge === knTag._id);
        if (knTagProgress?.know && knTagProgress?.knowTimestamp) {
          return new Date(knTagProgress.knowTimestamp).getTime();
        }
        return null;
      })
      .filter(Boolean);

    const maxKnowDate = Math.max(...knTagsKnowDates);
    return maxKnowDate ? new Date(maxKnowDate).toLocaleDateString() : 0;
  };

  const getGroupHeaderLabel = (group) => {
    const knowDate = getGroupKnowDate(group, unityUser?.learningProgress);
    if (knowDate) {
      return `${group.name} (знає з ${knowDate})`;
    }
    return group.name;
  };

  const filterDebouced = useMemo(() => {
    return debounce((value) => {
      setSearchValue(value);
    }, 500);
  });

  return (
    <div className={`knowledgeStrengthSetup-component ${className}`}>
      <div className="filter-row">
        <div className="timestampScale-block">
          <div className="timestampScale-title">Пошук</div>
          <StyledInput
            onChange={(e) => {
              filterDebouced(e.target.value);
            }}
          />
        </div>
      </div>
      <div className="groups">
        {groupLevels.map((level) => (
          <div className="level-block" key={level}>
            <div className="level-header">{`${level} Рівень`}</div>
            {populatedGroups
              .filter((group) => group.level === level)
              .map((group) => (
                <div className="group-block" key={group._id}>
                  <div className={`group-header ${getGroupKnowStatusClassName(group, unityUser?.learningProgress)}`}>
                    <div className="group-name">{getGroupHeaderLabel(group)}</div>
                    <StyledButton
                      className="setStrength-button know"
                      onClick={() => {
                        handleSetStrengthToGroup(group, 92, 0.3);
                      }}
                      tooltipProps={{ title: "strength 92, forgetRate 0.3", followCursor: true }}
                    >
                      Знає
                    </StyledButton>
                    <StyledButton
                      className="setStrength-button know-almost"
                      onClick={() => {
                        handleSetStrengthToGroup(group, 88, 0.5);
                      }}
                      tooltipProps={{ title: "strength 88, forgetRate 0.5", followCursor: true }}
                    >
                      Майже
                    </StyledButton>
                    <StyledButton
                      className="setStrength-button dont-know"
                      onClick={() => {
                        handleSetStrengthToGroup(group, 50, 1.5);
                      }}
                      tooltipProps={{ title: "strength 50, forgetRate 1.5", followCursor: true }}
                    >
                      Не знає
                    </StyledButton>
                  </div>
                  {group.knowledgeTags.map((knTag) => (
                    <div className="knowledge-row" key={knTag._id}>
                      <div className={`knowledge-name-block ${getKnowledgeStateClassName(knTag._id)}`}>
                        <div className="knowledge-name">{knTag.name}</div>
                        <div className="knowledge-progress">
                          {getKnowledgeNameExtraInfo(
                            knTag,
                            knResultsRateBased.actualLearningProgress,
                            unityUser.learningProgress
                          )}
                        </div>
                      </div>
                      <StyledButton
                        className="setStrength-button know"
                        onClick={() => {
                          handleSetStrengthToKnTag(knTag, 92, 0.3);
                        }}
                        //tooltipProps={{ title: "strength 92, forgetRate 0.3", followCursor: true }}
                      >
                        Знає
                      </StyledButton>
                      <StyledButton
                        className="setStrength-button know-almost"
                        onClick={() => {
                          handleSetStrengthToKnTag(knTag, 88, 0.5);
                        }}
                        //tooltipProps={{ title: "strength 88, forgetRate 0.5", followCursor: true }}
                      >
                        Майже
                      </StyledButton>
                      <StyledButton
                        className="setStrength-button dont-know"
                        onClick={() => {
                          handleSetStrengthToKnTag(knTag, 50, 1.5);
                        }}
                        //tooltipProps={{ title: "strength 50, forgetRate 1.5", followCursor: true }}
                      >
                        Не знає
                      </StyledButton>
                    </div>
                  ))}
                </div>
              ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default KnowledgesRateSetup;
