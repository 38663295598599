import "./Debug.scss";
import { AlertDialog, DeleteRoundButton, Header, ReloadButton } from "components";
import { CardStyled } from "components";
import { ReactComponent as ArrowCollapse } from "assets/icons/Arrow.svg";
import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { ReactComponent as BackIcon } from "assets/icons/back.svg";
import { getDateTimeUa, getDateTimeUaShort, getDateUaShort } from "helpers/utils";
import useSearchParamWorker from "helpers/useSearchParam";
import { useAuthz } from "auth/AuthzProvider";
import { ROLE_SUPERADMIN } from "constants/constants";
import { Chip } from "@mui/material";

import { get } from "lodash";

const Debug = () => {
  const [debugList, setDebugList] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [prevDisabled, setPrevDisabled] = useState(false);
  const [unityId, setUnityId] = useState("");
  const [deviceId, setDeviceId] = useState("");
  const [alertDialogData, setAlertDialogData] = useState(null);

  const [unityUsersList, setUnityUsersList] = useState([]);

  const { user } = useAuthz();

  const searchParamWorker = useSearchParamWorker();
  const [initialDocId] = useState(searchParamWorker.getSearchParam("id"));

  const getUnityUsersList = useCallback(() => {
    return axios
      .get("/api/debug/users")
      .then((res) => res.data)
      .then((unityUsers) => {
        return unityUsers;
      })
      .catch((error) => {
        return [];
      });
  }, []);

  useEffect(() => {
    getUnityUsersList().then((unityUsers) => setUnityUsersList(unityUsers));
  }, [getUnityUsersList]);

  const openDeleteAllDialog = () => {
    setAlertDialogData({
      onOk: async () => {
        setAlertDialogData(null);
        deleteAll();
      },
      onCancel: () => setAlertDialogData(null),
      title: "Видалити всі дебаг данні",
      message: "Натиснить Ok для видалення, або Cancel для відміни операції.",
    });
  };

  const openDeleteOneDialog = (unityId) => {
    setAlertDialogData({
      onOk: async () => {
        setAlertDialogData(null);
        deleteOneUser(unityId);
      },
      onCancel: () => setAlertDialogData(null),
      title: "Видалити дебаг данні обраного користувача",
      message: "Натиснить Ok для видалення, або Cancel для відміни операції.",
    });
  };

  const handleNextPage = () => {
    const docId = debugList.length ? debugList[debugList.length - 1]._id : "";

    axios
      .get(`/api/debug?unityid=${unityId}&docId=${docId}&portionSize=${rowsPerPage}&direction=${"next"}`)
      .then((res) => res.data)
      .then((data) => {
        data.length && setDebugList(data);
        data.length && checkNext(data[data.length - 1]._id, unityId);
        data.length && checkPrev(data[0]._id, unityId);
      })
      .catch(() => setDebugList([]));
  };

  const handlePrevPage = () => {
    const docId = debugList.length ? debugList[0]._id : "";
    axios
      .get(`/api/debug?unityid=${unityId}&docId=${docId}&portionSize=${rowsPerPage}&direction=${"prev"}`)
      .then((res) => res.data)
      .then((data) => {
        data.length && setDebugList(data);
        data.length && checkNext(data[data.length - 1]._id, unityId);
        data.length && checkPrev(data[0]._id, unityId);
      })
      .catch(() => setDebugList([]));
  };

  const checkNext = (docId, unityId) => {
    axios
      .get(`/api/debug?unityid=${unityId}&deviceid=${deviceId}&docId=${docId}&portionSize=${1}&direction=next`)
      .then((res) => res.data)
      .then((data) => {
        setNextDisabled(!data.length);
      })
      .catch();
  };
  const checkPrev = (docId, unityId) => {
    axios
      .get(`/api/debug?unityid=${unityId}&deviceid=${deviceId}&docId=${docId}&portionSize=${1}&direction=prev`)
      .then((res) => res.data)
      .then((data) => {
        setPrevDisabled(!data.length);
      })
      .catch();
  };

  const deleteAll = () => {
    axios
      .delete(`/api/debug/deleteAll`)
      .then(() => {
        setDebugList([]);
        setActiveIndex(-1);
        setRowsPerPage(100);
        setNextDisabled(false);
        setPrevDisabled(false);
        getUnityUsersList().then((unityUsers) => {
          setUnityUsersList(unityUsers);
          setUnityId("");
        });
      })
      .catch();
  };

  const deleteOneUser = (unityIdToDelete) => {
    axios
      .delete(`/api/debug/deleteBy?unityid=${unityIdToDelete}`)
      .then(() => {
        setActiveIndex(-1);
        setRowsPerPage(100);
        setNextDisabled(false);
        setPrevDisabled(false);
        getUnityUsersList().then((unityUsers) => {
          const unityIds = unityUsers.map((elem) => elem.unityId);
          if (!unityIds.includes(unityId)) {
            setUnityId("");
            setDebugList([]);
          }
          setUnityUsersList(unityUsers);
        });
      })
      .catch();
  };

  const reloadDebugData = useCallback(
    (docId = "", include = "") => {
      axios
        .get(
          `/api/debug?unityid=${unityId}&deviceid=${deviceId}&docId=${docId}&portionSize=${rowsPerPage}&direction=${"next"}&include=${include}`
        )
        .then((res) => res.data)
        .then((data) => {
          setDebugList(data);
          if (include) {
            setActiveIndex(data.findIndex((elem) => elem._id === docId));
          }
          !data.length && setNextDisabled(true);
          !data.length && setPrevDisabled(true);
          data.length && checkNext(data[data.length - 1]._id, unityId);
          data.length && checkPrev(data[0]._id, unityId);
        })
        .catch(() => setDebugList([]));
    },
    [unityId, deviceId, rowsPerPage]
  );

  useEffect(() => {
    if (initialDocId) {
      reloadDebugData(initialDocId, "include");
    } else {
      reloadDebugData();
    }
  }, [reloadDebugData, initialDocId]);

  const getJson = (data) => {
    try {
      return JSON.stringify(JSON.parse(data || "{}"), null, 4);
    } catch (error) {
      console.log(error);
      return "{}";
    }
  };

  return (
    <div className="debugPage-component">
      <Header className="main-header" />

      <div className="content-wrapper">
        <CardStyled className="left-content-wrapper">
          <div className="unityUsers-list">
            {unityUsersList.map((unityUser) => (
              <div
                className={`unityUser-row ${unityUser.unityId === unityId ? "active" : ""}`}
                onClick={() => {
                  searchParamWorker.setSearchParam("id", null);
                  setUnityId(unityUser.unityId === unityId ? "" : unityUser.unityId);
                  unityUser.unityId && setDeviceId("");
                }}
              >
                <div className="userName">{unityUser.name}</div>
                <div className="userUnityId">{unityUser.unityId}</div>
                <div className="userCreatedAt">{`creted at: ${getDateUaShort(unityUser.createdAt)}`}</div>
                <div className="lastActivity">{`last actіvity: ${getDateTimeUaShort(unityUser.lastActivityDate)}`}</div>
                <DeleteRoundButton
                  className="deleteOneUserDebugButton"
                  title="Видалити debug дані"
                  onClick={(event) => {
                    event.stopPropagation();
                    openDeleteOneDialog(unityUser.unityId);
                  }}
                />
              </div>
            ))}
          </div>
        </CardStyled>
        <CardStyled className="middle-content-wrapper">
          <div className="debugList-area">
            <div className="title-box">
              <div className="title">Debugging</div>
              <button disabled={prevDisabled} className={`prev-button`} title={"Назад"} onClick={handlePrevPage}>
                <BackIcon />
              </button>
              <button disabled={nextDisabled} className={`next-button `} title={"Вперед"} onClick={handleNextPage}>
                <BackIcon />
              </button>
              <ReloadButton title="Перезавантажити debug дані" onClick={() => reloadDebugData()} />
              <DeleteRoundButton
                className="deleteAllButton"
                title={`Видалити всі debug дані ${!user.roles.includes(ROLE_SUPERADMIN) ? "(тільки для адміна)" : ""}`}
                onClick={() => openDeleteAllDialog()}
                disabled={!user.roles.includes(ROLE_SUPERADMIN)}
              />
              {deviceId && (
                <Chip variant="" className="deviceIdChip" label={`${deviceId}`} onDelete={() => setDeviceId("")} />
              )}
            </div>
            <div className="debugList">
              {debugList.map((debugData, index) => (
                <CardStyled className="debug-card">
                  <div
                    className={`debug-card-header ${activeIndex === index ? "active" : ""}`}
                    onClick={() => {
                      const newActiveIndex = activeIndex === index ? -1 : index;
                      searchParamWorker.setSearchParam("id", debugList[newActiveIndex]?._id);
                      setActiveIndex(newActiveIndex);
                    }}
                  >
                    <div className="debug-date">
                      <p>{`${getDateTimeUa(debugData.createdAt)}`}</p>
                    </div>
                    <div className="app-version">
                      <p>{`App: ${debugData.appVersion || ""}`}</p>
                    </div>
                    <div
                      className="device-id"
                      onClick={(event) => {
                        event.stopPropagation();
                        setDeviceId(debugData.deviceId || "");
                        debugData.deviceId && setUnityId("");
                      }}
                    >
                      <p>{`DeviceId: ${debugData.deviceId || ""}`}</p>
                    </div>
                    <div
                      className="debug-unityId"
                      onClick={(event) => {
                        event.stopPropagation();
                        searchParamWorker.setSearchParam("id", null);
                        setUnityId(debugData.userid);
                        setDeviceId("");
                      }}
                    >
                      <p>{`UnityId: ${debugData.userid}`}</p>
                    </div>
                    <div
                      className={`collapser ${activeIndex === index ? "flip" : ""}`}
                      onClick={() => {
                        const newActiveIndex = activeIndex === index ? -1 : index;
                        searchParamWorker.setSearchParam("id", debugList[newActiveIndex]?._id);
                        setActiveIndex(newActiveIndex);
                      }}
                    >
                      <ArrowCollapse />
                    </div>
                  </div>
                  {activeIndex === index ? (
                    <div className="content">
                      <div className="firstLine">
                        <div className="debug-device">
                          <div className="header">Device:</div>
                          <pre>{debugData.device || ""}</pre>
                        </div>
                        <div className="debug-device">
                          <div className="header">DeviceModel:</div>
                          <div className="deviceModel">{debugData.deviceModel || ""}</div>
                        </div>
                      </div>
                      <div className="secondLine">
                        <div className="debug-debug">
                          <div className="header">Debug info:</div>
                          <div className="content">{debugData.debug || ""}</div>
                        </div>
                        <div className="debug-config">
                          <div className="header">Game unity config:</div>
                          <pre>{getJson(debugData.config)}</pre>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </CardStyled>
              ))}
            </div>
            <div className="footer"></div>
          </div>
        </CardStyled>
      </div>
      <AlertDialog alertDialogData={alertDialogData} />
    </div>
  );
};

export default Debug;
