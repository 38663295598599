import "./Contacts.scss";
import React from "react";
import Socials from "components/Socials/Socials";
import { useI18N } from "i18n";
import Layout from "pages/Landing/Layout/Layout";

const Contacts = () => {
  const { I18N } = useI18N();

  return (
    <Layout>
      <div className="contacts-page">
        <div className={"contacts"}>
          <h1>
            <I18N id={"contacts_header"} />
          </h1>
          <Socials />
        </div>
      </div>
    </Layout>
  );
};

export default Contacts;
