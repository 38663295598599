import "./ScenesBar.scss";
import React from "react";
import SceneCard from "./SceneCard/SceneCard";
import { ReactComponent as BigPlusIcon } from "assets/icons/bigPlus.svg";
import { Tooltip } from "@material-ui/core";

const ScenesBar = ({
  className = "",
  scenes,
  selectedSceneIdx,
  showIconProgress,
  onSelectScene,
  onDeleteScene,
  onAddScene,
  onAddToLibrary,
  onSceneDoubleClick,
}) => {
  return (
    <div className={`scenesBar-component ${className}`}>
      <div className={`sceneCard-list`}>
        {scenes?.map((scene, index) => (
          <SceneCard
            key={index}
            selected={index === selectedSceneIdx}
            showCircularProgress={index === selectedSceneIdx && showIconProgress}
            className="sceneCard-wrapper"
            scene={scene}
            onClick={() => onSelectScene(index)}
            onDoubleClick={() => onSceneDoubleClick(index)}
            onDelete={() => onDeleteScene(index)}
            onAddToLibrary={() => onAddToLibrary(index)}
          />
        ))}

        <div className="plusButton-wrapper">
          <Tooltip title="Створити сцену" placement="right" arrow>
            <button disabled={false} className={`plus-button`} onClick={onAddScene}>
              <BigPlusIcon />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
export default ScenesBar;
