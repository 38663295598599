import { useCallback, useState } from "react";

const useSoundSetList = () => {
  const [soundSetList, setSoundSetList] = useState([]);

  const getSoundSetList = () => {
    return fetch(`/api/soundSets`, { method: "GET" })
      .then((res) => res.json())
      .then((res) => {
        setSoundSetList(res.reverse());
        return res;
      })
      .catch((error) => {
        setSoundSetList([]);
        console.log(error);
        return [];
      });
  };

  const createSoundSet = (soundSet) => {
    return fetch("/api/soundSets", {
      method: "POST",
      body: JSON.stringify(soundSet),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        setSoundSetList([res, ...soundSetList]);
        return res;
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
  };

  const createSoundSetMany = (soundSets) => {
    if (!Array.isArray(soundSets)) return Promise.reject(null);

    return fetch("/api/soundSets/many", {
      method: "POST",
      body: JSON.stringify(soundSets),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        setSoundSetList([...res, ...soundSetList]);
        return res;
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
  };

  const updateSoundSet = (id, soundSet) => {
    return fetch(`/api/soundSets/${id}`, {
      method: "PUT",
      body: JSON.stringify(soundSet),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        setSoundSetList(soundSetList.map((elem) => (elem._id === id ? res : elem)));
        return res;
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
  };

  const deleteSoundSet = (id) => {
    return fetch(`/api/soundSets/${id}`, { method: "DELETE", headers: {} })
      .then((res) => res.json())
      .then((res) => {
        setSoundSetList(soundSetList.filter((elem) => elem._id !== id));
        return res;
      })
      .catch((error) => {
        console.log(error);
        return null;
      });
  };

  const getUsedBaseTags = useCallback(() => {
    return soundSetList
      .filter((soundSet) => soundSet.knowledgeTags.length === 1)
      .map((soundSet) => soundSet.knowledgeTags[0]._id);
  }, [soundSetList]);

  return {
    soundSetList,
    createSoundSet,
    createSoundSetMany,
    updateSoundSet,
    deleteSoundSet,
    getSoundSetList,
    getUsedBaseTags,
  };
};

export default useSoundSetList;
