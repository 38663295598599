import { useCallback, useState } from "react";

const useSettings = () => {
  const [settings, setSettings] = useState({});

  const getSettings = () => {
    return fetch(`/api/settings`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => setSettings(res))
      .catch((error) => {
        setSettings({});
        console.log(error);
      });
  };
  const isFieldDefined = (field) => {
    return typeof field !== "undefined";
  };
  
  const updateSettings = useCallback((payload) => {
    const formData = new FormData();
    isFieldDefined(payload.imagePrompt) && formData.append("imagePrompt", payload.imagePrompt);
    return fetch(`/api/settings`, {
      method: "POST",
      body: formData,
      headers: {},
    }).then((res) => {
      setSettings({});
      return res.json();
    });
  }, []);

  return {
    settings,
    getSettings,
    updateSettings,
  };
};

export default useSettings;
