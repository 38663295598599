import { gameTypes } from "constants/constants";

export const getGameTypes = () => {
  return gameTypes;
};

export const getGameNameFromLabel = (label, allGameTypes) => {
  return allGameTypes.find((gameType) => {
    return gameType.label === label;
  }).name;
};

export const getGameKnowledges = (game) => {
  if (!game?.knowledges) {
    return [];
  }

  const knowledges = [...game.knowledges.controlled, ...game.knowledges.uncontrolled];

  return knowledges.map((knowledge) => knowledge.knowledge);
};

export const knowledgesIncludeString = (knowledges, string) => {
  return knowledges.filter((knowledge) => knowledge.toUpperCase().includes(string.toUpperCase())).length;
};

export const getGameTypeLabelFromName = (name) => {
  const gameTypes = getGameTypes();

  return gameTypes.find((gameType) => {
    return gameType.name === name;
  }).label;
};

export const getUpperCasedFilterValues = (filter) => {
  return filter.value.map((value) => {
    return value.toUpperCase();
  });
};

export const getAllGameTypes = (games) => {
  return games?.reduce((gameTypes, game) => {
    return gameTypes.concat(getGameTypeLabelFromName(game.gameType));
  }, []);
};

export const getAllKnowledges = (games) => {
  return games?.reduce((knowledges, game) => {
    return knowledges.concat(getGameKnowledges(game));
  }, []);
};
