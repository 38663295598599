import "./GameKnowledges.scss";
import React from "react";
import { Tooltip } from "@mui/material";

// game.knowledges = {
//   controlled: getControlledKnowledges(game, gameTasks, sounds),
//   uncontrolled: getUncontrolledKnowledges(game, gameTasks, sounds),
// };
// { knowledge: uniqSoundName, itemsQuantity: quantity };
//{ knowledge: task.controledKnowledge, itemsQuantity: uniqItemsIdsQuatity }

const GameKnowledges = ({ gameKnowledges }) => {
  const maxUncontrolled =
    gameKnowledges?.uncontrolled?.reduce((max, gameKnowledge) => {
      if (gameKnowledge.quantity >= max) {
        return gameKnowledge.quantity;
      } else {
        return max;
      }
    }, 0) || 0;

  return (
    <div className={`game-knowledges-component`}>
      <div className="game-knowledges">
        {gameKnowledges?.uncontrolled
          ?.sort((a, b) => b.quantity - a.quantity)
          .map((uncontrolledKnowledge, i) => (
            <Tooltip
              key={uncontrolledKnowledge.knowledge}
              title={uncontrolledKnowledge.knowledge}
              followCursor={true}
              arrow={true}
            >
              <div className="game-knowledge-wrapper" key={i}>
                <div className="game-knowledge-label">{uncontrolledKnowledge.knowledge}</div>
                <div className="game-knowledge-progress">
                  <div
                    className="game-knowledge-progress-element"
                    style={{ width: `${(uncontrolledKnowledge.quantity * 100) / maxUncontrolled}%` }}
                  ></div>
                </div>
                <div className="game-knowledge-param">{uncontrolledKnowledge.quantity}</div>
              </div>
            </Tooltip>
          ))}
        {gameKnowledges?.controlled
          ?.sort((a, b) => b.quantity - a.quantity)
          .map((controlledKnowledge, i) => (
            <Tooltip
              key={controlledKnowledge.knowledge}
              title={controlledKnowledge.knowledge}
              followCursor={true}
              arrow={true}
            >
              <div className="game-knowledge-wrapper" key={i}>
                <div className="game-knowledge-label">{controlledKnowledge.knowledge}</div>
                <div className="game-knowledge-progress controlled-knowledge-progress-color"></div>
                <div className="game-knowledge-param">{controlledKnowledge.quantity}</div>
              </div>
            </Tooltip>
          ))}
      </div>
    </div>
  );
};

export default GameKnowledges;
