export const states = {
  STATE_DEMO: {
    value: "demo",
    label: "Демо",
    count: 0,
  },
  STATE_NOT_DEMO: {
    value: "not-demo",
    label: "Не демо",
    count: 0,
  },
  STATE_LINKED: {
    value: "linked",
    label: "Залінковані",
    count: 0,
  },
  STATE_NOT_LINKED: {
    value: "not-linked",
    label: "Не залінковані",
    count: 0,
  },
  STATE_STAT: {
    value: "stat",
    label: "Є статистика",
    count: 0,
  },
  STATE_NOT_STAT: {
    value: "not-stat",
    label: "Нема статистики",
    count: 0,
  },
  STATE_SUBSCRIPTION: {
    value: "subscription",
    label: "Є підписка",
    count: 0,
  },
};

export const filterStructure = [
  {
    type: "text",
    label: "Пошук за іменем",
    name: "name",
    value: "",
  },
  {
    type: "text",
    label: "Пошук за unityId",
    name: "unityId",
    value: "",
  },
  {
    type: "text",
    label: "Пошук за email",
    name: "email",
    value: "",
  },
  {
    type: "multi",
    label: "Стани",
    name: "states",
    value: [],
    options: [
      states.STATE_DEMO,
      states.STATE_NOT_DEMO,
      states.STATE_LINKED,
      states.STATE_NOT_LINKED,
      states.STATE_STAT,
      states.STATE_NOT_STAT,
      states.STATE_SUBSCRIPTION,
    ],
    settings: ["checkBox"],
  },
];
