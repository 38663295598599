import "./GameIcon.scss";
import React, { useEffect, useState } from "react";
import IconEmptyIcon from "assets/icons/emptyIcon.svg";
import { Box, CircularProgress } from "@material-ui/core";

const GameIcon = ({ className = "", gameIcon, showProgress }) => {
  const [imageUrl, setImageUrl] = useState();

  useEffect(() => {
    if (!gameIcon) {
      setImageUrl(IconEmptyIcon);
      return;
    }

    if (typeof gameIcon === "string") {
      setImageUrl(gameIcon);
      return;
    }

    URL.revokeObjectURL(imageUrl);
    setImageUrl(URL.createObjectURL(gameIcon));

    return () => {
      URL.revokeObjectURL(imageUrl);
    };
  }, [gameIcon]);

  return (
    <div className={`game-icon ${className}`}>
      <img src={imageUrl} alt="" className={`${!gameIcon ? "empty-icon" : ""}`} />
      {showProgress && (
        <div className="icon-progress">
          <Box sx={{ display: "flex" }}>
            <CircularProgress size={60} />
          </Box>
        </div>
      )}
    </div>
  );
};

export default GameIcon;
