import "./NewUserTile.scss";
import React, { useState } from "react";
import { StyledButton, StyledInput } from "components";

const NewUserTile = ({ className = "", onCreateUser }) => {
  const [unityId, setUnityId] = useState("");
  const [unityUserName, setUnityUserName] = useState("");

  const handleCreateUser = () => {
    onCreateUser({ name: unityUserName, unityId: unityId });
  };

  return (
    <>
      <div className={`new-user-tile-wrapper ${className}`}>
        <div className="new-user-tile-content">
          <div className="footer"></div>
          <div className="body">
            <div className="body-left-content">
              <div className="name-block">
                <div className="label">Ім'я користувача:</div>
                <StyledInput
                  className="input"
                  placeholder={"Введіть імʼя користувача"}
                  value={unityUserName}
                  onChange={(event) => setUnityUserName(event.target.value)}
                />
              </div>
              <div className="unity-id-block">
                <div className="label">Unity ID:</div>
                <StyledInput
                  className="input"
                  placeholder={"Введіть unity id"}
                  value={unityId}
                  onChange={(event) => setUnityId(event.target.value)}
                />
              </div>
            </div>
            <div className="body-right-content">
              <StyledButton
                className=" create-button"
                disabled={!unityUserName}
                title={"Створити"}
                onClick={handleCreateUser}
              />
            </div>
          </div>
          <div className="footer"></div>
        </div>
      </div>
    </>
  );
};

export default NewUserTile;
