import "./AudioRecorder.scss";
import React, { useState } from "react";
import { CardStyled, LanguageSwitch } from "components";
import RoundButton from "components/RoundButton/RoundButton";
import { useAudioRecorder } from "helpers/sounds";
import { ReactComponent as CancelIcon } from "assets/icons/cancel.svg";
import { ReactComponent as RecordIcon } from "assets/icons/RecorderMic.svg";
import { ReactComponent as StopRecordIcon } from "assets/icons/RecorderStop.svg";
import { ReactComponent as PlayIcon } from "assets/icons/RecorderPlay.svg";
import { ReactComponent as StopPlayIcon } from "assets/icons/RecorderStop.svg";
import { ReactComponent as ConfirmIcon } from "assets/icons/Confirm.svg";

import { useRef } from "react";
import { useEffect } from "react";
import { useResourcesContext } from "resources";

const AudioRecorder = ({ onConfirm, onCancel, soundName = "", soundLanguage }) => {
  const {
    audioBlob,
    audioUrl,
    audioDuration,
    startRecord,
    stopRecord,
    isRecording,
    onTimeChange,
    startPlay,
    stopPlay,
    isPlaying,
    //reset,
  } = useAudioRecorder();

  const currentTimeRef = useRef();

  const {
    cookiedStatesStorage: { language: globalLanguage, setLanguage: setGlobalLanguage },
  } = useResourcesContext();

  const [localLanguage, setLocalLanguage] = useState(soundLanguage || globalLanguage);

  const [name, setName] = useState(soundName);

  const handleLanguageChange = (value) => {
    setLocalLanguage(value);
    setGlobalLanguage(value);
  };

  useEffect(
    () =>
      onTimeChange((time) => {
        if (currentTimeRef.current) {
          currentTimeRef.current.value = `${time / 1000} s`;
        }
      }),
    [onTimeChange]
  );

  const handleRecordClick = () => {
    isRecording ? stopRecord() : startRecord();
  };

  const handlePlayClick = () => {
    isPlaying ? stopPlay() : startPlay();
  };

  const handleConfirm = () => {
    onConfirm && onConfirm(audioBlob, audioUrl, name, audioDuration, localLanguage);
  };

  return (
    <CardStyled className="audio-recorder-container">
      <input
        className="sound-recorder-input"
        type="text"
        value={name}
        placeholder="Введите имя записываемого звука"
        onChange={(e) => setName(e.target.value)}
      />
      <div className="language-switch-box">
        <div className="label">Мова озвучування:</div>
        <LanguageSwitch value={localLanguage} onChange={handleLanguageChange} useLabel disableNoneOption={true} />
      </div>
      <div className="recorder-buttons">
        {!audioBlob || isRecording ? (
          <>
            <div className="substrate" />
            <RoundButton
              icon={isRecording ? StopRecordIcon : RecordIcon}
              tooltipTitle={isRecording ? "Stop recording" : "Record"}
              onClick={handleRecordClick}
              className={isRecording ? "record-btn recording" : "record-btn"}
            />
          </>
        ) : (
          <>
            <div className="substrate" />
            <RoundButton
              icon={isPlaying ? StopPlayIcon : PlayIcon}
              tooltipTitle={isPlaying ? "Stop" : "Play"}
              disabled={!audioBlob || isRecording}
              onClick={audioBlob ? handlePlayClick : null}
              className={isPlaying ? "playing play-btn-recorder" : "play-btn-recorder"}
            />
          </>
        )}
        <div className="btn-footer">
          <RoundButton
            icon={CancelIcon}
            tooltipTitle={"Cancel"}
            // disabled={!audioBlob || isRecording}
            onClick={onCancel}
            className="cancel-record"
          />
          <span id="record-time" className="record-time">
            <input disabled ref={currentTimeRef}></input>
          </span>
          <RoundButton
            icon={ConfirmIcon}
            tooltipTitle={"Confirm"}
            disabled={!audioBlob}
            onClick={handleConfirm}
            className="confirm-record"
          />
        </div>
      </div>
    </CardStyled>
  );
};
export default AudioRecorder;
