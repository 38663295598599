import "./StyledInput.scss";
import React from "react";
import { Tooltip } from "@mui/material";

const StyledInput = ({
  title,
  tooltipProps = {},
  placeholder,
  value,
  onChange,
  className = "",
  onKeyPress,
  onBlur,
  type,
  defaultValue,
  disabled = false,
  multiRow = false,
  error,
}) => {
  return (
    <Tooltip title="" placement="right" arrow {...tooltipProps}>
      <div className="input-container">
        {title && <div className="input-title">{title}</div>}
        {multiRow ? (
          <textarea
            disabled={disabled}
            defaultValue={defaultValue}
            value={value}
            className={`input ${className} ${error ? "input-error" : ""}`}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={onChange}
            onKeyPress={onKeyPress}
          >
            {value}
          </textarea>
        ) : (
          <input
            disabled={disabled}
            type={type}
            value={value}
            className={`input ${className} ${error ? "input-error" : ""}`}
            placeholder={placeholder}
            onBlur={onBlur}
            onChange={onChange}
            onKeyPress={onKeyPress}
          />
        )}
        {error && <div className="input-error-message">{error}</div>}
      </div>
    </Tooltip>
  );
};

export default StyledInput;
