import "./TaskItems.scss";
import React from "react";
import { useResourcesContext } from "resources";

const TaskItems = ({
  taskType,
  taskItems,
  stageItemsPlain,
  selectedItemsBlockIndex,
  selectedStageItem,
  onItemBlockClick,
  onItemClick,
  onAddItemsBlock,
  onDeleteItemsBlock,
}) => {
  const {
    imageListStore: { getImagePathById },
  } = useResourcesContext();

  const getTaskItemImage = (stageItemId) => {
    return getImagePathById(stageItemsPlain.find((stageItem) => stageItem.stageItemId === stageItemId)._id);
  };

  return (
    <div className="task-items-wrapper">
      <div className="items-blocks-list">
        {taskItems &&
          taskItems.map((itemsBlock, itemsBlockIndex) => (
            <div
              className={`items-block  ${
                itemsBlock.passive?.length || itemsBlock.active?.length ? "min-height-fit-content" : ""
              }`}
              onClick={() => onItemBlockClick(itemsBlockIndex)}
              key={itemsBlockIndex}
            >
              <div
                className={`active-part-wrapper ${taskType === "tap" && !itemsBlock.passive?.length ? "width100" : ""}`}
              >
                {itemsBlock.active && (
                  <div className="active-items-list">
                    {itemsBlock.active.map((taskItem, taskItemIndex) => (
                      <div
                        key={taskItemIndex}
                        className={`active-item ${taskItem === selectedStageItem?.stageItemId ? "item-selected" : ""}`}
                        onClick={() => onItemClick && onItemClick(taskItem)}
                      >
                        <img src={getTaskItemImage(taskItem)} alt="" />
                      </div>
                    ))}
                  </div>
                )}
              </div>

              {!(taskType === "tap" && !itemsBlock.passive?.length) && (
                <>
                  <div className="separator"></div>

                  <div className="passive-part-wrapper">
                    {itemsBlock.passive && (
                      <div className="passive-items-list">
                        {itemsBlock.passive.map((taskItem, taskItemIndex) => (
                          <div
                            key={taskItemIndex}
                            className={`passive-item ${
                              taskItem === selectedStageItem?.stageItemId ? "item-selected" : ""
                            }`}
                            onClick={() => onItemClick && onItemClick(taskItem)}
                          >
                            <img src={getTaskItemImage(taskItem)} alt="" />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </>
              )}

              <div className="separator"></div>
              <div
                className={"delete-block-button"}
                onClick={(e) => {
                  e.stopPropagation();
                  onDeleteItemsBlock(itemsBlockIndex);
                }}
              >
                -
              </div>
            </div>
          ))}
      </div>
      <div className="bottom-buttons-row">
        {taskType === "drag" && (
          <div
            className={"add-block-button"}
            onClick={onAddItemsBlock}
            disabled={typeof selectedItemsBlockIndex === "number"}
          >
            +
          </div>
        )}
      </div>
    </div>
  );
};
export default TaskItems;
