import React from "react";
import { useI18N } from "i18n";
import "./AboutBulbee.scss";

const AboutBulbee = () => {
  const { I18N } = useI18N();

  return (
    <article className="aboutBulbee">
      <div className="aboutBulbee-header">
        <div className="mainheader">
          <I18N id="about_bulbee_header" />
        </div>
        <div className="subheader">
          <I18N id="about_bulbee_subheader" />
        </div>
      </div>
      <div className="cards">
        <div className="card first">
          <div className="card-content">
            <h4>
              <I18N id="about_bulbee_first_header" />
            </h4>

            <p>
              <I18N id="about_bulbee_first_text" />
            </p>
          </div>
        </div>
        <div className="card sec">
          <div className="card-content">
            <h4>
              <I18N id="about_bulbee_sec_header" />
            </h4>
            <p>
              <I18N id="about_bulbee_sec_text" />
            </p>
          </div>
        </div>
        <div className="card third">
          <div className="card-content">
            <div>
              <h4>
                <I18N id="about_bulbee_third_header" />
              </h4>
              <p>
                <I18N id="about_bulbee_third_text" />
              </p>
            </div>
          </div>
        </div>
        <div className="card fourth">
          <div className="card-content">
            <h4>
              <I18N id="about_bulbee_fourth_header" />
            </h4>
            <p>
              <I18N id="about_bulbee_fourth_text" />
            </p>
          </div>
        </div>
      </div>
    </article>
  );
};

export default AboutBulbee;
