import CardStyled from "components/CardStyled/CardStyled";

import { useMemo } from "react";
import { useResourcesContext } from "resources";
import StageTemplateCard from "./StageTemplateCard";
import StageTemplateCardSimple from "./StageTemplateCardSimple";
import "./StageTemplateLibrary.scss";

const StageTemplateLibrary = ({ className = "", onStageTemplateClick, deleteButton }) => {
  const {
    stageTemplatesStore: { stageTemplatesList, deleteStageTemplate },
  } = useResourcesContext();

  const sortedList = useMemo(() => {
    return (
      stageTemplatesList
        .map((element) => element)
        .filter((element) => Boolean(element.template))
        .sort((a, b) => {
          const idA = a.template.items?.length ? a.template.items[0]._id : 0;
          const idB = b.template.items?.length ? b.template.items[0]._id : 0;
          return idA > idB ? 1 : idA < idB ? -1 : 0;
        }) || []
    );
  }, [stageTemplatesList]);

  const wrongTemplatesList = useMemo(() => {
    return stageTemplatesList.map((element) => element).filter((element) => Boolean(!element.template)) || [];
  }, [stageTemplatesList]);

  return (
    <CardStyled className={`stageLibrary-component ${className}`}>
      <div className="stageLibrary-content">
        <div className="stageLibrary-list">
          {sortedList.map((stageTemplate, index) =>
            !stageTemplate.icon ? (
              <StageTemplateCard
                className="stageLibrary-element"
                stageTemplate={stageTemplate}
                onClick={() => stageTemplate.template && onStageTemplateClick(stageTemplate.template)}
                onStageTemplateDelete={deleteButton && (() => deleteStageTemplate(stageTemplate._id))}
                key={index}
              />
            ) : (
              <StageTemplateCardSimple
                className="stageLibrary-element"
                stageTemplate={stageTemplate}
                onClick={() => stageTemplate.template && onStageTemplateClick(stageTemplate.template)}
                onStageTemplateDelete={deleteButton && (() => deleteStageTemplate(stageTemplate._id))}
                key={index}
              />
            )
          )}
          {wrongTemplatesList.map((stageTemplate, index) => (
            <StageTemplateCardSimple
              className="stageLibrary-element"
              stageTemplate={stageTemplate}
              onClick={() => {}}
              onStageTemplateDelete={deleteButton && (() => deleteStageTemplate(stageTemplate._id))}
              key={index}
              wrong
            />
          ))}
        </div>
      </div>
    </CardStyled>
  );
};

export default StageTemplateLibrary;
