import React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import "./TooltipTitleList.scss";

const TooltipTitleList = ({ title, onClick, list }) => {
  return (
    <div className="list-wrapper">
      <h2 className="label">{title}</h2>
      <Box sx={{ width: "100%", maxWidth: 500 }}>
        <nav aria-label="simple-list">
          <List className="list">
            {list?.map((element) => (
              <ListItem key={"ListItem" + element.id} disablePadding>
                <ListItemButton>
                  <ListItemText
                    primary={`${element?.name || "Назва не вказана"}`}
                    onClick={() => {
                      onClick && onClick(element);
                    }}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </nav>
      </Box>
    </div>
  );
};

export default TooltipTitleList;
