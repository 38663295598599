import "./ItemPlaceholder.scss";
import React, { useMemo } from "react";

const ItemPlaceholder = ({
  item,
  selected,
  active,
  backgroundSize,
  onMouseDown,
  onMouseMove,
  onPointerLeave,
  onClick,
  moving,
}) => {
  const itemPosition = useMemo(() => {
    const height = 100 / backgroundSize.scale1920;
    const width = 100 / backgroundSize.scale1920;
    return {
      height,
      width,
      top: Math.round(backgroundSize.clientHeight / 2 - (item.valueY || 0) / backgroundSize.scale1920 - height / 2),
      left: Math.round((item.valueX || 0) / backgroundSize.scale1920 + backgroundSize.clientWidth / 2 - width / 2),
      borderSize: 10 / backgroundSize.scale1920,
    };
  }, [backgroundSize, item]);

  return (
    <>
      {itemPosition && (
        <div
          id={"item " + item.stageItemId}
          className={`itemPlaceholder-component ${active ? "active" : ""}`}
          onPointerLeave={onPointerLeave}
          onMouseDown={(event) => onMouseDown(event)}
          onMouseMoveCapture={(e) => e.preventDefault()}
          onMouseMove={(event) => onMouseMove && onMouseMove(event)}
          onClick={(e) => (onClick ? onClick(item) : "")}
          style={{
            zIndex: moving ? 2000 : "auto",
            height: itemPosition.height + "px",
            width: itemPosition.width + "px",
            top: itemPosition.top + "px",
            left: itemPosition.left + "px",
            border: `${itemPosition.borderSize}px solid gray`,
          }}
        >
          {item.bottomAnchorPoint && (
            <>
              <div className="top-box"></div>
              <div className="bottom-box"></div>
            </>
          )}
          <div
            className={`border-box ${selected ? "border-box-selected" : ""}`}
            id={"itemBorder " + item.stageItemId}
          ></div>
        </div>
      )}
    </>
  );
};

export default ItemPlaceholder;
