import React, { useEffect } from "react";

const GoogleGAScript = ({ id = "" }) => {
  useEffect(() => {
    // Paste the GA script here
    const script1 = document.createElement("script");
    script1.innerHTML = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${id}');`;
    document.head.insertBefore(script1, document.head.firstChild);

    const script2 = document.createElement("script");
    script2.async = true;
    script2.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
    document.head.insertBefore(script2, document.head.firstChild);

    // Cleanup when the component unmounts
    return () => {
      document.head.removeChild(script1);
      document.head.removeChild(script2);
    };
  }, []); // Empty dependency array to run the effect only once

  return null; // This component doesn't render anything
};

export default GoogleGAScript;
