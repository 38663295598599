import React from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import { useAuthz } from "./AuthzProvider";

const ProtectedRoute = ({ allowedRoles }) => {
  const { user, isLoading } = useAuthz();
  const location = useLocation();

  if (isLoading) {
    return null;
  }

  if (!user) return <Navigate to="/login" state={{ from: location }} replace />;
  if (user.roles.find((role) => allowedRoles.includes(role))) return <Outlet />;
};

export default ProtectedRoute;
