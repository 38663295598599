export const ukEn = {
  about_bulbee_header: "Про Bulbee",
  about_bulbee_subheader: "Це унікальна освітня платформа, яка поєднує ",
  about_bulbee_first_header: "Індивідуальний підхід ",
  about_bulbee_first_text: "кожна ігра стоврена під конкретну дитину",
  about_bulbee_sec_header: "Всебічний розвиток дитини в процессі гри",
  about_bulbee_sec_text:
    "Розвинути пам'ять, увагу, мовленнєвий розвиток, класифікувати і узагальнювати поняття, розвинути дрібну моторику і зорово-моторну координацію, посидючість і цілеспрямованість.",
  about_bulbee_third_header: "Взаємодія батьків зі спеціалістами",
  about_bulbee_third_text: "Mожливість для батьків отримати фахову допомогу і підтримку",
  about_bulbee_fourth_header: "Перенос навичок з планшета в реальне життя",
  about_bulbee_fourth_text: "Cистема, яка залучає батьків, щоб допомогти перенести навички з планшета в реальне життя",

  active_subscription_text: "Ваша підписка активна",

  benefit_1_header: "Здобудьте реальні скілли для життя",
  benefit_1_text: "Також дитина зможе отримати розвиток самостійності,інтелекту І усвідомленості.",
  benefit_2_header: " Ефективне та цікаве навчання",
  benefit_2_text: "Приділяємо увагу тому, чи розуміють діти процес навчання",
  benefit_3_header: "Закріплення знань",
  benefit_3_text: "Чергування нових знань і навичок із вже здобутими,щоб дитина відчувала свою успішність.",
  benefit_4_header: "Під контролем спеціалістів",
  benefit_4_text: "Вас хвилює прогрес  у розвитку дитини?",
  benefit_5_header: "Психологічна підтримка батьків",
  benefit_5_text: "Вас хвилює прогрес  у розвитку дитини?",
  benefit_6_header: "Заохочення за малі досягнення",
  benefit_6_text: "Вас хвилює прогрес  у розвитку дитини?",

  benefits_header: "Не гайте час",
  benefits_subheader: "Bulbee в захопливій, цікавій і простій формі допоможе підготувати дитину до дитсадка,школи.",

  created_for_header: "Проект створений батьками для батьків",
  created_for_subheader:
    "Ми вас розуміємо, адже ми самі батьки, які пройшли такий же шлях і як ніхто інший розуміємо, що потрібно робити і як діяти, щоб досягти успіху",
  created_for_brain: "Чи хвилюєтеся ви за успіхи вашої дитини?",
  created_for_skills: "Бажаєте вдосконалити знання та навички?",
  created_for_book: "Мрієте розкрити потенціал своєї дитини?",
  created_for_heart: "Успіхи вашої дитини зроблять вас щасливими",

  confirm_yes: "Так",
  confirm_no: "Ні",
  contacts_header: "Контакти",

  form_lock_text:
    "Натискаючи на кнопку, я погоджуюся на обробку  даних для певноїі  дії, з правилами користування платформою. Всі данні захищені та не передаються третім особам",

  join_us_header: "Долучайтесь до нас",
  join_us_subheader: "Ми бажаємо і знаємо як допомогти!",
  join_family_header: "Долучайся до родини",

  header_button_login: "Увійти",
  header_button_join: "Зв'язатися",

  how_to_join_header: "Як долучитись?",
  how_to_join_subheader: "Ми бажаємо допомогти і знаємо як",

  how_to_join_step_1: "Залиште контакт ",
  how_to_join_step_1_text: "Вас хвилює прогрес у розвитку дитини?",

  how_to_join_step_2: "Поспілкуйтесь  із спеціалістом",
  how_to_join_step_2_text: "Вас хвилює прогрес  у розвитку дитини?",

  how_to_join_step_3: "Прийміть участь у проекті",
  how_to_join_step_3_text: "Вас хвилює прогрес  у розвитку дитини?",

  input_placeholder_address: "Адреса електронної пошти",
  input_placeholder_name: "Ім'я",
  input_placeholder_id: "ID дитини",

  history_header: "Історія батьків - засновників ",
  history_bulbee: "Bulbee",

  history_paragraph_1: "Привіт! Нас звати Юля і Вова. Ми сім’я, яка створила Bulbee.",
  history_paragraph_21: "А починалося все з нашого власного досвіду. ",
  history_paragraph_22:
    "Коли ріс наш старший синочок ми зіткнулися з певними труднощами, діагнози затримка розвитку мовлення, дитячий аутизм та ін. ми чули неодноразово. А от шо це таке і що з цим робити, ото була загадка. Ми реально відвідали купу центрів та приватних спеціалістів, і всі  рекомендували різні методики та підходи, для однієї і тієї дитини. Якісь методики працювали, якісь зовсім ні. Але не було якогось одного злагодженого плану дій. Сказати, що ми були розгублені - нічого не сказати. Ми перечитали купу інформації, ознайомилися з купою методик, але це нас заплутало ще більше… В решті решт нам пощастило і ми знайшли гарного спеціаліста, і далі все пішло вперед і вгору, але ж пошуки зайняли  4.5 роки! І тоді ми почали думати про те що в наш час високих технологій та штучного інтелекту повинен бути розумний комплекс для розвитку дітей.",
  history_paragraph_3:
    "Забігаючи наперед скажу, що зараз наш син навчається в звичайній школі в Англії і всі його попередні діагнози ніяк йому не заважають.",
  history_paragraph_4:
    "Пізніше ми зустріли ще одну сімейну пару, Наташу та Арсенія, з такими ж проблемами і бажанням якось це виправити.",
  history_paragraph_5:
    "Вони так само на прийомі у невролога вперше почули  про червоні прапорці у дитини і ще багато чого про аутизм. Були шоковані, багато чого не зрозуміли. Навіть не уявляли яки довгий та складний шлях їм доведеться пройти. Той день змінив їхнє життя.",
  history_paragraph_6:
    "А потім Наташа зустріла мій пост у Facebook, де я розповідала про успіхи свого сина. Наташа зв'язалася з нами і ми почали спілкуватися та ділитися своїм досвідом.",
  history_paragraph_7:
    "Потім ми вирішили, що хочемо допомагати батькам, які опинилися в такій же ситуації, разом робити цінний продукт для розвитку особливих дітей.",
  read_more: "Читати далі",
  link_child_header: "Підключення дитини",
  link_child_how_to: "Заповніть форму з ID дитини, яку ви хочете підключити до свого облікового запису",
  link_child_btn: "Підключити",

  logout: "Вийти",
  link_team: "Команда",
  link_about_us: "Про нас",
  link_about: "Про підписку",
  link_contacts: "Контакти",
  link_account: "Кабінет фахівця",
  link_profile: "Особистий кабінет",
  link_menu: "Меню",

  overview_link: "Ви в потрібному місці",
  overview_header: "Створено, щоб допомогти вам створити найщасливіше майбутнє для вашої",
  overview_child: "дитини",
  overview_subheader: "Найбільше щастя батьків підтримувати успіх своєї дитини",

  profile_content_link_child: "Підключити дитину",
  profile_content_link_header: "Навігація",
  profile_content_link_subscribe: "Керування підпискою",

  problems_header: "Які проблеми батьків ми ",
  problems_header_span: "можемо допомогти ",
  problems_header_solve: "вирішити",
  problems_subheader: "Ми бажаємо допомогти і знаємо як",
  problems_card_1: "Він такий неслухняний. зовсім не хоче займатися ",
  problems_card_2: "Я хочу щоб  він проводив  час у планшеті з користю",
  problems_card_3: "Не реагує на мої прохання",
  problems_card_4: "Мені потрібні  чіткі інструкціі  як діяти  в різних ситуаціях",
  problems_card_5: "Дуже неуважний",
  problems_card_6: "Не розуміє що я їй говорю",
  policy_description: "Політика конфіденційності",
  payment_successfull_header: "Оплата пройшла успішно",
  payment_successfull_text: "Дякуємо за оплату. Ваш платіж успішно оброблено.",
  payment_cancelled_header: "Оплата скасована",
  payment_cancelled_text: "Ваш платіж був скасований. Ви будете перенаправлені на свій профіль через 3 секунди.",

  rights_description: "Усі права захищені 2023",
  terms_and_conditions: "Умови та правила",

  subscription_confirm_cancel_text: "Ви впевнені що хочете відмінити підписку?",
  subscription_confirm_uncancel_text: "Ви впевнені що хочете відновити підписку?",

  subscription_header: "Керування підпискою",
  subscription_create_btn: "Підписатися",
  subscription_uncancel_btn: "Поновити підписку",
  subscription_cancel_btn: "Відмінити підписку",

  subscription_period: "Період",
  subscription_canceled_end_date: "Підписка дійсна до ",
  subscription_last_payment: "Останній платіж",
  manage_subscriptiption_btn: "Керування підпискою",
  subscription_end_date: "Наступний платіж буде здійснено ",
  subscription_status: "Статус",
  subscription_status_active: "Активна",
  subscription_status_inactive: "Неактивна",
  subscription_status_canceled: "Відмінена",
  subscription_per_month: "місяць",
  subscription_per_monthly: "Щомісяця",
  subscription_next_payment: "Дата наступного платежу",
  subscription_page_header: "Що ви отримаєте за підписку?",
  subscription_uncancel_tooltip: "Автоматичне продовження наразі відмінено. Поновити зараз.",
  subscription_no_prev_payment: "Немає попередніх платежів",
  subscription_after_action:"Після оплати звʼяжіться з нами для отримання первинної консультації та інших можливостей платного плану",

  consultation: "Первинна консультація ",
  consultation_description: "з корекційним педагогом та автором навчальної платформи",
  testing: "Первинне тестування ",
  testing_description: " дитини для визначення рівня її розвитку та навчальних потреб",
  analysis: "Безперервний аналіз та тестування ",
  analysis_description: " дитини протягом навчання",
  games: "Безлімітна кількість ігор ",
  games_description: " на платформі, адаптованих під розвиток вашої дитини",
  games_stack: "Індивідуальний набір ігор ",
  games_stack_description: " для дитини в додатку",
  exercises: "Регулярні ігрові вправи ",
  exercises_description: " створені для батьків для зв'язку отриманих в додатку знань з реальним світом",
  video: "Аналіз відео ",
  video_description: " взаємодії батьків і дитини під час спільної гри",
  adaptation: "Постійна адаптація ",
  adaptation_description: " навчального процесу в залежності від успіхів дитини та ії особливостей",
  language: "Можливість вибору мови ",
  language_description: " навчання",
  price: "Ціна підписки ",
};
