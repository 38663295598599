import "./CreatedFor.scss";
import React from "react";
import { useI18N } from "i18n";
import heart from "assets/landing/createdFor/Heart.svg";
import book from "assets/landing/createdFor/Book.svg";
import brain from "assets/landing/createdFor/brainImage.svg";
import improveSkills from "assets/landing/createdFor/improveSkills.svg";

const CreatedFor = () => {
  const { I18N } = useI18N();

  return (
    <aside className="createdfor">
      <div className="createdfor-header">
        <h2>
          <I18N id="created_for_header" />
        </h2>
        <p>
          <I18N id="created_for_subheader" />
        </p>
      </div>
      <div className="cards">
        <div className="card">
          <img src={brain} alt="brain" />
          <p>
            <I18N id="created_for_brain" />
          </p>
        </div>
        <div className="card">
          <img src={improveSkills} alt="improveSkills" />
          <p>
            <I18N id="created_for_skills" />
          </p>
        </div>
        <div className="card">
          <img src={book} alt="book" />
          <p>
            <I18N id="created_for_book" />
          </p>
        </div>
        <div className="card">
          <img src={heart} alt="heart" />
          <p>
            <I18N id="created_for_heart" />
          </p>
        </div>
      </div>
    </aside>
  );
};

export default CreatedFor;
