import React from "react";
import "./ModalWindow.scss";

const ModalWindow = ({ children, onCancel }) => {
  return (
    <div className="modal-wrapper">
      <div className="modal-cancel" onClick={onCancel} />
      <div className="modal">{children}</div>
    </div>
  );
};

export default ModalWindow;
