import "./Waveform.scss";
import { useEffect, useMemo } from "react";

import { getSoundWaveformData } from "./soundWaveform";
import { v4 as newUuid } from "uuid";

const Waveform = ({ className = "", canvasClassName = "", soundPath, duration }) => {
  const canvasId = useMemo(() => `canvas-${newUuid()}`, []);

  useEffect(() => {
    const canvas = document.getElementById(canvasId);
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (typeof soundPath === "object") {
      return;
    }

    getSoundWaveformData(soundPath, Math.round((100 * (duration || 1) * 486) / canvas.width / 1000)).then((data) => {
      const scaleY = (amplitude, height) => {
        const range = 256;
        const offset = 128;
        return height - ((amplitude + offset) * height) / range;
      };

      ctx.beginPath();
      ctx.strokeStyle = "#77a5c3";
      data.upperHalf.forEach((value, index) => {
        ctx.lineTo(index, scaleY(0, canvas.height));
        ctx.lineTo(index, scaleY(value, canvas.height));
        ctx.lineTo(index, scaleY(data.lowerHalf[index], canvas.height));
        ctx.lineTo(index, scaleY(0, canvas.height));
      });

      ctx.closePath();
      ctx.stroke();
      ctx.fill();
    });
  }, [soundPath, canvasId, duration]);

  return (
    <div className={`waveForm-component ${className}`}>
      <canvas id={canvasId} className={canvasClassName} />
    </div>
  );
};

export default Waveform;
