import "./GameEditorUniversal.scss";
import React, { useState, useEffect, useMemo } from "react";
import Stage from "./Stage/Stage";

import {
  GameCard,
  CommonGameConfig,
  AlertDialog,
  VerticalHeader,
  StyledButton,
  StageCardUniversal,
  CardStyled,
  StageTemplateLibrary,
} from "components";
import _ from "lodash";
import { v4 as newStageItemId } from "uuid";
import { useNavigate, useParams } from "react-router-dom";
import { useResourcesContext } from "resources";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import { gameTypes } from "constants/constants";
import { getItemsWithChildrenPlain } from "helpers/stageItems";
import { CircularProgress } from "@mui/material";
import useUndoRedoBuffer from "helpers/useUndoRedoBuffer";
import StageEmpty from "./Stage/StageEmpty";
import { createIconFromDomElement } from "helpers/createIconFromDomElement";

const GameEditorUniversal = () => {
  const [game, setGame] = useState();
  const [gameExtraData, setGameExtraData] = useState();
  const [gameIsEdited, setGameIsEdited] = useState();
  const [gameIsNew, setGameIsNew] = useState();
  const [selectedStageIdx, setSelectedStageIdx] = useState(null);
  const [showCommonGameConfig, setShowCommonGameConfig] = useState(false);
  const [alertDialogData, setAlertDialogData] = useState(null);

  const [creatingGameIcon, setCreatingGameIcon] = useState(false);
  const [creatingStageTemplateIcon, setCreatingStageTemplateIcon] = useState(false);

  const {
    addToUndoBuffer: addGameToUndoBuffer,
    undo: getUndoGame,
    redo: getRedoGame,
    init: initGamesUndoBuffer,
  } = useUndoRedoBuffer();

  const [getingGame, setGetingGame] = useState();
  const navigate = useNavigate();

  let { gameId } = useParams();

  const {
    gameListStore: {
      getGameList,
      getGameForEditing,
      updateEditedGame,
      deleteGame,
      saveGame,
      undoGame,
      copyGame,
      copyGameFromTemplate,
      fetchingGame,
    },
    imageListStore: {
      getImageList,
      getImagePathById,
    },
    soundListStore: {
      getSoundList,
    },
    tagListStore: { getTagList },
    stageTemplatesStore: { getStageTemplatesList, createStageTemplate },
    itemTemplatesStore: { getItemTemplatesList },
  } = useResourcesContext();

  useEffect(() => {
    getTagList();
    getImageList();
    getGameList();
    getSoundList();
    getItemTemplatesList();
    getStageTemplatesList();
  }, []);

  const selectedStage = useMemo(() => {
    return selectedStageIdx >= 0 && game?.stages?.length > selectedStageIdx ? game.stages[selectedStageIdx] : null;
  }, [selectedStageIdx, game]);

  const gameLocked = Boolean(
    !(game?.gameType === "template") &&
      ((!gameExtraData?.tracking && !gameIsNew) ||
        (Boolean(gameExtraData?.tracking?.trackingIds?.length) && game?.lock))
  );

  useEffect(() => {
    const getGame = async (gameId) => {
      const { game: gameFromList, gameExtraData: newExtraData, edited, isNew } = await getGameForEditing(gameId);
      setGame(gameFromList);
      addGameToUndoBuffer(gameFromList);
      newExtraData && setGameExtraData(newExtraData);
      setSelectedStageIdx(0);
      setGameIsEdited(edited);
      setGameIsNew(isNew);
      setGetingGame(false);
    };

    if (!game && gameId && getGameForEditing && !getingGame) {
      setGetingGame(true);
      getGame(gameId);
    }
  }, [gameId, game, getGameForEditing, getingGame, addGameToUndoBuffer]);

  const handleGameEditorKeyUp = (event) => {
    const redoKeys = ["y", "Y", "н", "Н"];
    (event.ctrlKey || event.metaKey) && redoKeys.includes(event.key) && handleRedoGame();

    const undoKeys = ["z", "Z", "я", "Я"];
    (event.ctrlKey || event.metaKey) && undoKeys.includes(event.key) && handleUndoGame();
  };

  const handleUndoGame = () => {
    if (showCommonGameConfig) {
      return;
    }

    const { data: undoGame, index } = getUndoGame();

    if (!gameIsNew && index === 0) {
      handleUndoGameToOriginal();
      return;
    }

    if (!undoGame) {
      return;
    }

    setGame(undoGame);
    setGameIsEdited(true);
    typeof selectedStageIdx !== "number" && undoGame?.stages?.length && setSelectedStageIdx(0);
    updateEditedGame(undoGame);
  };

  const handleRedoGame = () => {
    if (showCommonGameConfig) {
      return;
    }

    const { data: redoGame } = getRedoGame();

    if (!redoGame) {
      return;
    }

    setGame(redoGame);
    setGameIsEdited(true);
    updateEditedGame(redoGame);
  };

  const handleUndoGameToOriginal = async () => {
    if (!gameIsNew) {
      const { game: restoredGame, gameExtraData } = await undoGame(game._id);
      setGame(restoredGame);
      initGamesUndoBuffer(restoredGame);
      setGameExtraData(gameExtraData);
      setGameIsEdited(false);
      setSelectedStageIdx(restoredGame?.stages?.length ? 0 : null);
    }
  };

  const handleChangeGame = (options = {}) => {
    if (!gameLocked) {
      setGame({ ...game });
      setGameIsEdited(true);
      !options.passCtrlzBuffer && addGameToUndoBuffer(game);
      updateEditedGame(game);
    }
  };

  const openDeleteGameDialog = () => {
    setAlertDialogData({
      onOk: async () => {
        setAlertDialogData(null);
        await deleteGame(game._id);
        navigate("/games");
      },
      onCancel: () => setAlertDialogData(null),
      title: "Deleting game",
      message: "Press Ok to delete game, or Cancel to cancel operation.",
    });
  };

  const handleSaveGame = async () => {
    const { game: savedGame, gameExtraData: newExtraData } = await saveGame(game);
    await getImageList();
    setGameIsEdited(false);
    setGameIsNew(false);
    setGame({
      ...game,
      _id: savedGame._id,
    });
    setGameExtraData(newExtraData);
    initGamesUndoBuffer(savedGame);
    if (savedGame.gameType === "universal") {
      navigate(`/game-editor-universal/${savedGame._id}`, { replace: true });
    } else {
      navigate(`/game-editor/${savedGame._id}`, { replace: true });
    }
  };

  const handleGameNameChange = (name) => {
    game.name = name;
    handleChangeGame();
  };

  const handleGameDescriptionChange = (description) => {
    game.description = description;
    handleChangeGame();
  };

  const handleCopyGame = async () => {
    const copyedGame = await copyGame(game);
    const newIcon =
      typeof game.icon === "object"
        ? game.icon
        : game.icon
        ? await fetch(getImagePathById(game.icon)).then((res) => res.blob())
        : "";
    copyedGame.icon = newIcon;

    setGameIsEdited(true);
    setGameIsNew(true);
    setGame(copyedGame);
    setGameExtraData(null);
    initGamesUndoBuffer(copyedGame);

    if (copyedGame.gameType === "universal") {
      navigate(`/game-editor-universal/${copyedGame._id}`);
    } else {
      navigate(`/game-editor/${copyedGame._id}`);
    }
  };

  const handleCreateGameFromTemplate = async (gameTypeName, template) => {
    const gameType = gameTypes.find((gameType) => gameType.name === gameTypeName);
    if (gameType && template) {
      const newIcon =
        typeof template.icon === "object"
          ? template.icon
          : template.icon
          ? await fetch(getImagePathById(template.icon)).then((res) => res.blob())
          : "";

      const newGame = await copyGameFromTemplate(gameType, template);
      newGame.icon = newIcon;
      gameTypeName === "universal"
        ? navigate(`/game-editor-universal/${newGame._id}`)
        : navigate(`/game-editor/${newGame._id}`);
    }
  };

  const handleLockGame = () => {};

  const handleDeleteStage = (stageIndex) => {
    if (stageIndex < selectedStageIdx) {
      setSelectedStageIdx(selectedStageIdx - 1);
    }
    if (stageIndex === selectedStageIdx) {
      setSelectedStageIdx(game?.stages?.length ? 0 : null);
    }

    game.stages.splice(stageIndex, 1);
    handleChangeGame();
  };

  const handleAddStage = () => {
    const background = selectedStage?.items?.length ? selectedStage.items[0] : null;
    game.stages.push({
      items: background ? [_.cloneDeep(background)] : [],
    });
    setSelectedStageIdx(game.stages.length - 1);
    handleChangeGame();
  };

  const handleAddLibraryStageToGame = (libraryStage) => {
    const newStage = _.cloneDeep(libraryStage);
    newStage.items = (newStage.items || []).filter((item) => item.type !== "placeholder");
    const stageItemsPlain = getItemsWithChildrenPlain(newStage.items);
    stageItemsPlain.forEach((item, index) => {
      item.stageItemId = newStageItemId();
      if (index > 0) {
        item.sounds = item.sounds || { main: "" };
        item.startAction = item.startAction || "";
      }
    });
    game.stages.push(newStage);
    setSelectedStageIdx(game.stages.length - 1);
    handleChangeGame();
  };

  const handleCopyStage = (stage) => {
    let stageClone = _.cloneDeep(stage);
    if (stageClone.tutorial) stageClone.tutorial = false;
    if (stageClone.usedAsGameIcon) stageClone.usedAsGameIcon = false;
    game.stages.push(stageClone);
    setSelectedStageIdx(game.stages.length - 1);
    handleChangeGame();
  };

  const moveStageUp = (stageIndex) => {
    if (stageIndex === 0) return;
    const splicedStage = game.stages.splice(stageIndex, 1)[0];
    game.stages.splice(stageIndex - 1, 0, splicedStage);
    handleChangeGame();
  };

  const moveStageDown = (stageIndex) => {
    if (stageIndex === game.stages.length - 1) return;
    const splicedStage = game.stages.splice(stageIndex, 1)[0];
    game.stages.splice(stageIndex + 1, 0, splicedStage);
    handleChangeGame();
  };

  const handleAddStageToLibrary = () => {
    if (selectedStage?.items?.length) {
      setCreatingStageTemplateIcon(true);
      const stageCopy = _.cloneDeep(selectedStage);
      const stageCopyItemsPlain = getItemsWithChildrenPlain(stageCopy.items || []);
      stageCopyItemsPlain.forEach((item, index) => {
        item.stageItemId = "";
        if (index > 0) {
          item.sounds = { main: "" };
          item.startAction = "";
        }
      });

      const stageMainScreen = document.getElementById(`stageMainScreen`);
      const backgroundImage = document.getElementById("stageMainScreenBackgroundImage");
      const scale1920 = backgroundImage && backgroundImage.clientWidth ? 1920 / backgroundImage.clientWidth : 1;
      const soundIcons = document.getElementsByName("itemSoundIcon");
      soundIcons.forEach((icon) => (icon.style.display = "none"));
      createIconFromDomElement(stageMainScreen, 0.15 * scale1920).then((iconBlob) => {
        soundIcons.forEach((icon) => (icon.style.display = ""));
        iconBlob &&
          createStageTemplate({ template: { items: stageCopy.items }, icon: iconBlob }).then(() => {
            getImageList();
            setCreatingStageTemplateIcon(false);
          });
        !iconBlob && setCreatingStageTemplateIcon(false);
      });
    }
  };

  const handleStageCardDoubleClick = (stage) => {
    if (stage === selectedStage) {
      setCreatingGameIcon(true);
      const stageMainScreen = document.getElementById(`stageMainScreen`);
      const backgroundImage = document.getElementById("stageMainScreenBackgroundImage");
      const scale1920 = backgroundImage && backgroundImage.clientWidth ? 1920 / backgroundImage.clientWidth : 1;
      const soundIcons = document.getElementsByName("itemSoundIcon");
      soundIcons.forEach((icon) => (icon.style.display = "none"));
      createIconFromDomElement(stageMainScreen, 0.23 * scale1920).then((iconBlob) => {
        soundIcons.forEach((icon) => (icon.style.display = ""));
        if (iconBlob) {
          game.icon = iconBlob;
          setCreatingGameIcon(false);
          handleChangeGame();
        }
      });
    }
  };

  return (
    <>
      <div className="game-editor-universal-wrapper" tabIndex={-1} onKeyUp={handleGameEditorKeyUp}>
        <CardStyled className="game-editor-universal-header">
          <VerticalHeader backPath="/games" />
        </CardStyled>
        {!game && (
          <div className="no-game-content-wrapper">
            <CardStyled className="no-game-content-left" />
            <CardStyled className="no-game-content-right">
              <div className="progressFetchingGame">
                <span className="progressFetchingGameLabel">{"Завантаження ..."}</span>
                <CircularProgress size={400} style={{ color: "#FAA400" }} />
              </div>
            </CardStyled>
          </div>
        )}
        {game && (
          <div className="content-wrapper">
            <div className="left-side">
              <CardStyled className="stages-box-wrapper">
                <div className="game-card-wrapper">
                  <GameCard
                    game={game}
                    gameExtraData={gameExtraData}
                    selected={showCommonGameConfig}
                    onClick={() => setShowCommonGameConfig(!showCommonGameConfig)}
                    onSave={handleSaveGame}
                    onUndo={handleUndoGameToOriginal}
                    showIconProgress={creatingGameIcon}
                    locked={gameLocked}
                    edited={gameIsEdited}
                    isNew={gameIsNew}
                    showFetchingGameProgress={fetchingGame === game._id}
                  />
                </div>
                <StyledButton
                  title="Додати етап"
                  className="customize-button stage-add-btn"
                  onClick={handleAddStage}
                  disabled={gameLocked}
                >
                  <PlusIcon className="plus-icon" />
                </StyledButton>
                <div className="stages-wrapper">
                  {game.stages.map((stage, index) => (
                    <div key={index} className="stage-card-with-updown">
                      {!showCommonGameConfig && (
                        <>
                          {index !== 0 && (
                            <StyledButton
                              disabled={gameLocked}
                              className="customize-button moveUp"
                              onClick={(e) => {
                                moveStageUp(index);
                                e.stopPropagation();
                              }}
                            >
                              <KeyboardDoubleArrowUpIcon />
                            </StyledButton>
                          )}
                          {index !== game.stages.length - 1 && (
                            <StyledButton
                              disabled={gameLocked}
                              className="customize-button moveDown"
                              onClick={(e) => {
                                moveStageDown(index);
                                e.stopPropagation();
                              }}
                            >
                              <KeyboardDoubleArrowDownIcon />
                            </StyledButton>
                          )}
                        </>
                      )}
                      <StageCardUniversal
                        locked={gameLocked}
                        selected={stage === selectedStage && !showCommonGameConfig}
                        key={index}
                        stage={stage}
                        stageIndex={index}
                        onClick={() => {
                          setSelectedStageIdx(index);
                          setShowCommonGameConfig(false);
                        }}
                        onAddToLibrary={() => handleAddStageToLibrary()}
                        onDelete={handleDeleteStage}
                        onCopy={handleCopyStage}
                        onDoubleClick={() => handleStageCardDoubleClick(stage)}
                        controls={!showCommonGameConfig}
                        showCircularProgress={selectedStageIdx === index && creatingStageTemplateIcon}
                      />
                    </div>
                  ))}
                </div>
              </CardStyled>
            </div>

            {!showCommonGameConfig && !selectedStage && (
              <StageEmpty
                externalTabs={[
                  {
                    tabLabel: "Етапи",
                    tabJsx: (
                      <StageTemplateLibrary
                        key={"StageTemplateLibraryTabContent"}
                        onStageTemplateClick={handleAddLibraryStageToGame}
                      />
                    ),
                  },
                ]}
              />
            )}

            {!showCommonGameConfig && selectedStage && (
              <Stage
                key={"stage"}
                stage={selectedStage}
                gameType={game.gameType}
                onChange={handleChangeGame}
                locked={gameLocked}
                externalTabs={[
                  {
                    tabLabel: "Етапи",
                    tabJsx: (
                      <StageTemplateLibrary
                        key={"StageTemplateLibraryTabContent"}
                        onStageTemplateClick={handleAddLibraryStageToGame}
                      />
                    ),
                  },
                ]}
              />
            )}

            {showCommonGameConfig && (
              <CommonGameConfig
                game={game}
                gameExtraData={gameExtraData}
                onChange={handleChangeGame}
                onNameChange={handleGameNameChange}
                onDescriptionChange={handleGameDescriptionChange}
                onDelete={openDeleteGameDialog}
                onCopy={handleCopyGame}
                onCreateGameFromTemplate={handleCreateGameFromTemplate}
                onLock={handleLockGame}
                creatingGameIcon={creatingGameIcon}
                locked={gameLocked}
              />
            )}
          </div>
        )}
      </div>
      <AlertDialog alertDialogData={alertDialogData} />
    </>
  );
};
export default GameEditorUniversal;
