import "./MarketingStatistic.scss";
import { CardStyled, Header } from "components";
import { useState, useEffect } from "react";
import axios from "axios";
import UnityUsersPlot from "components/UnityUsersPlot/UnityUsersPlot";

const MarketingStatistic = () => {
  const [unityUserList, setUnityUserList] = useState([]);

  useEffect(() => {
    axios
      .get("/api/unityUsers/listForMarketing")
      .then((res) => {
        setUnityUserList(res.data);
      })
      .catch((err) => {
        console.log(err);
        setUnityUserList([]);
      });
  }, []);

  return (
    <div className="marketingStatistic-page">
      <Header className="main-header" />

      <div className="content-wrapper">
        <CardStyled className="left-content-wrapper"></CardStyled>
        <CardStyled className="middle-content-wrapper">
          <div className="newUsersStatistic">
            <div className="newUsersPlot-header">Статистика нових користувачів</div>
            <UnityUsersPlot unityUserList={unityUserList} />
          </div>
        </CardStyled>
      </div>
    </div>
  );
};

export default MarketingStatistic;
