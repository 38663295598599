import React from "react";
import "./WebUserCard.scss";
import { CardStyled, SwitchLabels } from "components";
import { ROLE_SUPERADMIN, ROLE_CLIENT, ROLE_ADMIN, ROLE_MARKETING, ROLE_TEACHER } from "constants/constants";

const WebUserCard = ({ webUser, updateWebUserRole }) => {
  const isAdmin = webUser.roles?.includes(ROLE_ADMIN);
  const isSuperAdmin = webUser.roles?.includes(ROLE_SUPERADMIN);
  const isClient = webUser.roles?.includes(ROLE_CLIENT);
  const isMarketing = webUser.roles?.includes(ROLE_MARKETING);
  const isTeacher = webUser.roles?.includes(ROLE_TEACHER);

  return (
    <CardStyled className="web-user-wrapper">
      <div className="user">
        <div className="web-user-email">{webUser.email}</div>
      </div>
      <div className="button-box">
        <div className="role-switch">
          <SwitchLabels
            label={ROLE_ADMIN}
            checked={isAdmin}
            onChange={(e, checked) => updateWebUserRole(webUser, checked ? [ROLE_ADMIN] : [])}
          />
        </div>
        <div className="role-switch">
          <SwitchLabels
            label={ROLE_SUPERADMIN}
            checked={isSuperAdmin}
            onChange={(e, checked) => updateWebUserRole(webUser, checked ? [ROLE_SUPERADMIN] : [])}
          />
        </div>
        <div className="role-switch">
          <SwitchLabels
            label={ROLE_CLIENT}
            checked={isClient}
            onChange={(e, checked) => updateWebUserRole(webUser, checked ? [ROLE_CLIENT] : [])}
          />
        </div>
        <div className="role-switch">
          <SwitchLabels
            label={ROLE_MARKETING}
            checked={isMarketing}
            onChange={(e, checked) => updateWebUserRole(webUser, checked ? [ROLE_MARKETING] : [])}
          />
        </div>
        <div className="role-switch">
          <SwitchLabels
            label={ROLE_TEACHER}
            checked={isTeacher}
            onChange={(e, checked) => updateWebUserRole(webUser, checked ? [ROLE_TEACHER] : [])}
          />
        </div>
      </div>
    </CardStyled>
  );
};

export default WebUserCard;
