import "./Footer.scss";
import React from "react";
import appLogo from "assets/app-logo.png";
import line from "assets/landing/footer/line.svg";
import { Link } from "react-router-dom";
import { useI18N } from "i18n";
import AppMarketLinkButton from "components/AppMarketLinkButton/AppMarketLinkButton.js";

const Footer = () => {
  const { I18N } = useI18N();

  return (
    <footer className="footer">
      <div className="footer_top">
        <div className="footer_top__logo-with-download-buttons">
          <img src={appLogo} alt="application logo" />
          <ul>
            <AppMarketLinkButton appMarket={"play-market"} />
            <AppMarketLinkButton appMarket={"app-store"} />
          </ul>
        </div>
        <ul className="footer_top__links">
          <a href={"/#about_us"}>
            <I18N id="link_about_us" />
          </a>
          <Link to={"/subscription"}>
            <I18N id="link_about" />
          </Link>
          <Link to={"/contacts"}>
            <I18N id="link_contacts" />
          </Link>
        </ul>
      </div>

      <img src={line} alt="line" />
      <div className="footer_bottom">
        <p>
          <I18N id="rights_description" />
        </p>
        <div className="footer_bottom__docs">
          <Link to={"#"}>
            <I18N id="policy_description" />
          </Link>
          <Link to={"#"}>
            <I18N id="terms_and_conditions" />
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
