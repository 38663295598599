import "./Socials.scss";
import React from "react";
import telegram from "assets/landing/socials/telegram.svg";
import instagram from "assets/landing/socials/instagram-logo-thin.svg";
import whatsapp from "assets/landing/socials/whatsapp.svg";
import { TELEGRAM_INVITE_LINK, INSTAGRAM_INVITE_LINK, WHATSAPP_INVITE_LINK } from "constants/constants";

const Socials = () => {
  return (
    <div className="socials-component">
      <div className="icons">
        <a href={INSTAGRAM_INVITE_LINK}>
          Instagram
          <img src={instagram} alt="instagram" />
        </a>
        <a href={WHATSAPP_INVITE_LINK}>
          WhatsApp
          <img src={whatsapp} alt="whatsapp" />
        </a>
        <a href={TELEGRAM_INVITE_LINK}>
          Telegram
          <img src={telegram} alt="telegram" />
        </a>
      </div>
    </div>
  );
};

export default Socials;
