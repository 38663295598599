import "./OtherSoundSetEditor.scss";
import React, { useMemo, useState, useEffect } from "react";
import SoundsBox from "../SoundsBox/SoundsBox";
import { DropdownSelector, Header, SoundTypes } from "components";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useResourcesContext } from "resources";
import SoundSetList from "./SoundSetList/SoundSetList";
import { openAlertDialog } from "components/AlertDialog/AlertDialog";
import { Tooltip } from "@mui/material";
import { soundSetTypes } from "constants/constants";

const OtherSoundSetEditor = () => {
  const [selectedSoundSet, setSelectedSoundSet] = useState(null);

  const {
    soundListStore: { getSoundList },
    assetBundleListStore: { assetBundleList, getAssetBundleList, getAssetBundleExtraDataList },
    soundSetListStore: { soundSetList, createSoundSet, updateSoundSet, deleteSoundSet, getSoundSetList },
  } = useResourcesContext();

  useEffect(() => {
    getSoundList();
    getSoundSetList();
    getAssetBundleList();
    getAssetBundleExtraDataList();
  }, []);

  const canBeDeleted = (soundSet) => {
    return !assetBundleList.some(
      (bundle) =>
        (bundle.knowledges || []).some(
          (knowledge) => knowledge.soundSet === soundSet._id || (knowledge.wrongSoundSets || []).includes(soundSet._id)
        ) ||
        [
          bundle.sounds?.startSoundSet,
          bundle.sounds?.endSoundSet,
          ...(bundle.sound?.correctSoundSets || []),
          ...(bundle.sound?.incorrectSoundSets || []),
        ]
          .filter(Boolean)
          .includes(soundSet._id)
    );
  };

  const otherSoundSets = useMemo(() => {
    return soundSetList.filter((soundSet) => soundSet.type !== "knowledge");
  }, [soundSetList]);

  const getSoundSetById = (id) => {
    if (!id) {
      selectedSoundSet(null);
    }

    id &&
      axios
        .get("/api/soundSets/" + id)
        .then((res) => {
          setSelectedSoundSet(res.data);
        })
        .catch((err) => console.log(err));
  };

  const handleChangeSounds = (value) => {
    const newSounds = value.map((elem) => elem._id);
    updateSoundSet(selectedSoundSet._id, { sounds: newSounds }).then(setSelectedSoundSet);
  };

  const handleChangeType = (soundSetType) => {
    updateSoundSet(selectedSoundSet._id, { type: soundSetType.name }).then((soundSet) =>
      soundSet.type === "knowledge" ? setSelectedSoundSet(null) : setSelectedSoundSet(soundSet)
    );
  };

  const handleCreateSoundSet = () => {
    createSoundSet({ type: "other" }).then((newSet) => setSelectedSoundSet(newSet));
  };

  const handleDeleteSoundSet = (id) => {
    deleteSoundSet(id).then(() => setSelectedSoundSet(null));
  };

  const openDeleteSoundSetDialog = (soundSetId) => {
    openAlertDialog("Видалення набору звуків", "Натисніть Ok для видалення, або Cancel для відміни операції.", () =>
      handleDeleteSoundSet(soundSetId)
    );
  };

  return (
    <div className="otherSoundSetEditor-page">
      <Header className="header" />
      <div className="content-wrapper">
        <div className="left-side">
          <AddIcon className="addButton" onClick={handleCreateSoundSet} />
          <div className="soundSetsListWrapper">
            {soundSetTypes
              .filter((type) => otherSoundSets.some((soundSet) => soundSet.type === type.name))
              .map((type) => (
                <div key={type.name} className="typedSoundSetsList">
                  <div key={type.name} className="soundSetTypeTitle">
                    {type.label}
                  </div>
                  <SoundSetList
                    soundSetList={otherSoundSets.filter((soundSet) => soundSet.type === type.name)}
                    onClick={getSoundSetById}
                    selectedSoundSet={selectedSoundSet}
                  />
                </div>
              ))}
          </div>
        </div>
        <div className="middle-block">
          {selectedSoundSet && (
            <>
              <div className="header">
                <div className="title">Інші звуки</div>
              </div>

              {canBeDeleted(selectedSoundSet) && (
                <Tooltip title="Видалити набір звуків">
                  <DeleteForeverIcon
                    className="deleteButton"
                    onClick={() => openDeleteSoundSetDialog(selectedSoundSet._id)}
                  />
                </Tooltip>
              )}
              {/* <SoundTypes
                className={"soundSetTypes"}
                label={"Тип набору звуків:"}
                typesList={soundSetTypes}
                selectedTypes={soundSetTypes.filter((type) => selectedSoundSet.type === type.name)}
                onChangeSelectedTypes={handleChangeTypes}
              /> */}
              <DropdownSelector
                className="soundSetTypeSelector"
                title="Тип набору звуків:"
                options={soundSetTypes}
                selectedOption={soundSetTypes.find((type) => selectedSoundSet.type === type.name)}
                onChange={(value) => handleChangeType(value)}
                withNone={false}
              />
              <SoundsBox
                className="soundBox"
                sounds={selectedSoundSet.sounds || []}
                onChangeSounds={handleChangeSounds}
              />
            </>
          )}
        </div>
        <div className="right-side">{selectedSoundSet && <></>}</div>
      </div>
    </div>
  );
};

export default OtherSoundSetEditor;
