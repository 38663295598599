import {  useState } from "react";

const getCookie = (name) =>
  document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${name}=`))
    ?.split("=")[1];

const setCookie = (name, value) => {
  document.cookie = `${name}=${value};path='/'`;
};

const useCookiedStates = () => {
  const [language, setLanguage] = useState(getCookie("language") || "uk");

  const setLanguageCookie = (value) => {
    setCookie("language", value);
    setLanguage(value);
  };

  //useEffect(() => {
  //  setLanguage(getCookie("language") || "uk");
  //}, []);

  return { language, setLanguage: setLanguageCookie };
};

export default useCookiedStates;
