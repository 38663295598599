import "./GameTile.scss";
import React from "react";
import { GameIcon, GroupTile } from "components";
import { useResourcesContext } from "resources";
import { useNavigate } from 'react-router-dom';
import GameKnowledges from "components/GameKnowledges/GameKnowledges";
import GameTileButton from "components/GameTile/GameTileButton/GameTileButton";

const GameTile = ({ game, gameTileButtonProps, defaultGroupMode = "single" }) => {
  const {
    imageListStore: { getImagePathById },
  } = useResourcesContext();
  
  const navigate = useNavigate();

  const isSelectorTile = !!gameTileButtonProps;

  const handleOnClick = (e) => {
    e.stopPropagation();
    switch (game.gameType) {
      case "universal":
        navigate(`/game-editor-universal/${game._id}`);
        break;
      default:
        navigate(`/game-editor/${game._id}`);
        break;
    }
  };

  const getGameIcon = (game) =>
    typeof game?.icon === "string"
      ? getImagePathById(game.icon)
      : game.icon

  const renderGameTitle = (game) => (
    <div className="sgt-game-name">
      {game?.name}
      {!isSelectorTile && (
        <div className="sgt-game-type-block">
          <div className="sgt-game-createdBy">{game?.createdBy}</div>
        </div>
      )}
    </div>
  )

  return (
    <GroupTile mode={defaultGroupMode}>
      <div className={isSelectorTile ? "selector-game-tile-component" : "game-tile-component"} onClick={handleOnClick}>
          {isSelectorTile && renderGameTitle(game)}
        <div className="sgt-middle">
          <div className="sgt-main-block">
            {!isSelectorTile && renderGameTitle(game)}
            <div className="sgt-game-icon-and-type">
              <div className="sgt-game-icon">
                <GameIcon gameIcon={getGameIcon(game)} />
              </div>
            </div>
          </div>
          <div className="sgt-knowledges-block">
            <GameKnowledges gameKnowledges={game.knowledges} />
          </div>
          {isSelectorTile && <GameTileButton {...gameTileButtonProps} />}
        </div>
        <div className="sgt-footer"></div>
      </div>
    </GroupTile>
  );
};

export default GameTile;
