import "./KnowledgeSoundSetEditor.scss";
import React, { useMemo, useState, useEffect } from "react";
import KnowledgeTagsPairs from "./KnowledgePairs/KnowledgePairs";
import SoundsBox from "../SoundsBox/SoundsBox";
import { Header } from "components";
import KnowledgeTagsPairsEditor from "./KnowledgePairsEditor/KnowledgePairsEditor";
import axios from "axios";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import { useResourcesContext } from "resources";
import BindedBundleKnowledges from "./BindedBundleKnowledges/BindedBundleKnowledges";
import { openAlertDialog } from "components/AlertDialog/AlertDialog";
import { Tooltip } from "@mui/material";
import { update } from "lodash";

const KnowledgeSoundSetEditor = () => {
  const [selectedSoundSet, setSelectedSoundSet] = useState(null);
  const [soundSetsUsedInBundles, setSoundSetsUsedInBundles] = useState([]);
  const [bundlesKnowledges, setBundlesKnowledges] = useState([]);

  const {
    imageListStore: { getImageList },
    soundListStore: { getSoundList },
    knowledgeTagListStore: { getKnowledgeTagList },
    assetBundleListStore: { getAssetBundleList, getAssetBundleExtraDataList, assetBundleList },
    soundSetListStore: { getSoundSetList, soundSetList, createSoundSet, updateSoundSet, deleteSoundSet },
    imageListStore: { getImagePathById },
  } = useResourcesContext();

  useEffect(() => {
    getImageList();
    getSoundList();
    getSoundSetList();
    getAssetBundleList();
    getKnowledgeTagList();
    getAssetBundleExtraDataList();
  }, []);

  useEffect(() => {
    const newBundlesKnowledges = assetBundleList.reduce((result, assetBundle) => {
      const bundleKnowledges = (assetBundle.knowledges || []).map((bundleKnowledge, index) => {
        const itemsWithImagePath = bundleKnowledge.items.map((item) => ({
          ...item,
          imagePath: getImagePathById(item._id),
        }));
        return {
          knowledgeIndex: index,
          items: itemsWithImagePath || [],
          soundSet: bundleKnowledge.soundSet,
          wrongSoundSets: bundleKnowledge.wrongSoundSets,
          bundleId: assetBundle._id,
          bundleName: assetBundle.name,
        };
      });

      return [...bundleKnowledges, ...result];
    }, []);

    const soundSetsUsedInBundles = newBundlesKnowledges.map((bundleKnowledge) => bundleKnowledge.soundSet);
    setSoundSetsUsedInBundles(soundSetsUsedInBundles);
    setBundlesKnowledges(newBundlesKnowledges);
  }, [assetBundleList, getImagePathById]);

  const canBeDeleted = (soundSet) => {
    return !soundSetsUsedInBundles.includes(soundSet._id);
  };

  const knowlewdgeSoundSets = useMemo(() => {
    return soundSetList.filter((soundSet) => soundSet.type === "knowledge");
  }, [soundSetList]);

  const getSoundSetById = (id) => {
    if (!id) {
      selectedSoundSet(null);
    }

    id &&
      axios
        .get("/api/soundSets/" + id)
        .then((res) => {
          setSelectedSoundSet(res.data);
        })
        .catch((err) => console.log(err));
  };

  const handleChangeKnowledgeTags = (value) => {
    updateSoundSet(selectedSoundSet._id, { knowledgeTags: value }).then(setSelectedSoundSet);
  };

  const handleChangeKnowledgeSounds = (value) => {
    const newKnowledgeSounds = value.map((elem) => elem._id);
    updateSoundSet(selectedSoundSet._id, { sounds: newKnowledgeSounds }).then(setSelectedSoundSet);
  };

  const handleChangeQuestionSounds = (value) => {
    const newQuestionSounds = value.map((elem) => elem._id);
    updateSoundSet(selectedSoundSet._id, { questionSounds: newQuestionSounds }).then(setSelectedSoundSet);
  };

  const handleCreateSoundSet = () => {
    createSoundSet({ type: "knowledge" }).then((newSet) => setSelectedSoundSet(newSet));
  };

  const handleDeleteSoundSet = (id) => {
    id && deleteSoundSet(id).then(() => setSelectedSoundSet(null));
  };

  const openDeleteSoundSetDialog = (soundSetId) => {
    openAlertDialog("Видалення набору звуків", "Натисніть Ok для видалення, або Cancel для відміни операції.", () =>
      handleDeleteSoundSet(soundSetId)
    );
  };

  const openMoveToOtherDialog = (soundSetId) => {
    openAlertDialog(
      "Перенесення набору звуків до інших",
      "Натисніть Ok для перенесення, або Cancel для відміни операції.",
      () => {
        updateSoundSet(soundSetId, { type: "other" }).then(() => setSelectedSoundSet(null));
      }
    );
  };

  return (
    <div className="knowledgeSoundSetEditor-page">
      <Header className="knowledgeSoundSetEditor-header" />
      <div className="content-wrapper">
        <div className="left-side">
          <AddIcon className="addButton" onClick={handleCreateSoundSet} />
          <KnowledgeTagsPairs
            soundSets={knowlewdgeSoundSets}
            selectedSoundSet={selectedSoundSet}
            onClick={(soundSet) => getSoundSetById(soundSet._id)}
            soundSetsUsedInBundles={soundSetsUsedInBundles}
            onDelete={handleDeleteSoundSet}
          />
        </div>
        <div className="middle-block">
          {selectedSoundSet && (
            <>
              {canBeDeleted(selectedSoundSet) && (
                <Tooltip title="Видалити набір звуків">
                  <DeleteForeverIcon
                    className="deleteButton"
                    onClick={() => openDeleteSoundSetDialog(selectedSoundSet._id)}
                  />
                </Tooltip>
              )}
              {true && (
                <Tooltip title="Перенести до інших звуків">
                  <MoveUpIcon
                    className="moveToOtherButton"
                    onClick={() => openMoveToOtherDialog(selectedSoundSet._id)}
                  />
                </Tooltip>
              )}
              <SoundsBox
                title="Знання"
                sounds={selectedSoundSet.sounds || []}
                onChangeSounds={handleChangeKnowledgeSounds}
              />
              <SoundsBox
                title="Питання"
                sounds={selectedSoundSet.questionSounds || []}
                onChangeSounds={handleChangeQuestionSounds}
              />
            </>
          )}
        </div>
        <div className="right-side">
          {selectedSoundSet && (
            <>
              <KnowledgeTagsPairsEditor
                knowledgeTags={selectedSoundSet.knowledgeTags || []}
                onChangeKnowledgeTags={handleChangeKnowledgeTags}
                knowledgeSounds={selectedSoundSet.sounds || []}
              />
              <BindedBundleKnowledges soundSetId={selectedSoundSet._id} bundlesKnowledges={bundlesKnowledges} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default KnowledgeSoundSetEditor;
