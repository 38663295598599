import React, { useMemo, useRef } from "react";
import { getDateTimeUaWithoutYear, getDateAndTimeUa } from "helpers/utils";
import calculateAge from "helpers/calculateAge";
import { ReactComponent as IconClock } from "assets/icons/clock.svg";
import { ReactComponent as IconCalendar } from "assets/icons/calendar.svg";
import Tooltip from "@mui/material/Tooltip";

import "./Table.scss";

const TableRow = ({ unityUser, onClick, onClickDemoStatus }) => {
  const singleClickTimeoutIdRef = useRef();

  const handleOnClick = (e) => {
    e.stopPropagation();
    singleClickTimeoutIdRef.current = setTimeout(onClick, 200);
  };

  const age = useMemo(() => {
    if (!unityUser) {
      return "";
    }

    return calculateAge(unityUser.birthdayYear, unityUser.birthdayMonth);
  }, [unityUser]);

  const handleDemoStatusClick = (e) => {
    e.stopPropagation();
    onClickDemoStatus && onClickDemoStatus(unityUser);
  };

  return (
    <div className="customer-tile-wrapper" onClick={handleOnClick}>
      <div className="tile-content">
        <div className="tile-row">
          <div className="tile-cell">
            <span>{unityUser.name}</span>
          </div>
          <div className="tile-cell">
            <span>{age}</span>
          </div>
          <div className="tile-cell">
            <span className="tile-icon">
              <IconClock />
            </span>
            <span>{getDateAndTimeUa(unityUser.createdAt)}</span>
          </div>
          <div className="tile-cell">
            {unityUser.quickTest ? (
              <div className="yellow-badge">Тестування</div>
            ) : (
              <div className="green-badge">Навчання</div>
            )}
          </div>
          <div className="tile-cell">
            {!unityUser.demo ? (
              <Tooltip title={"Деактивувати"} placement="bottom">
                <div className="green-badge badgeHover" onClick={handleDemoStatusClick}>
                  Активно
                </div>
              </Tooltip>
            ) : (
              <Tooltip title={"Активувати"} placement="bottom">
                <div className="gray-badge badgeHover" onClick={handleDemoStatusClick}>
                  Неактивно
                </div>
              </Tooltip>
            )}
          </div>
          <div className="tile-cell">
            {unityUser.lastActivityDate && (
              <>
                <span className="tile-icon">
                  <IconCalendar />
                </span>
                <span>{getDateTimeUaWithoutYear(unityUser.lastActivityDate)}</span>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableRow;
