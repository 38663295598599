import "./SubscriptionCard.scss";
import React, { useEffect, useState } from "react";
import { useI18N } from "i18n";
import { Tooltip } from "@mui/material";
import { RefreshOutlined } from "@mui/icons-material";
import { CustomTooltip } from "components";
import { CircularProgress } from "@mui/material";

const SubscriptionCard = ({
  product,
  isLoading,
  onRefresh,
  onSubscribe,
  onCancel,
  onUncancel,
  subscriptionActive,
  endDate,
  isCancelled,
}) => {
  const { I18N } = useI18N();
  const [locale, setLocale] = useState("uk");

  useEffect(() => {
    const currentLocale = localStorage.getItem("locale") || "uk";
    if (currentLocale === "uk") {
      setLocale("uk");
    } else {
      setLocale("en-Gb");
    }
  }, []);

  const renderDates = (date) => {
    const options =
      locale === "en-Gb"
        ? { day: "numeric", month: "long", year: "numeric" }
        : { day: "numeric", month: "long", year: "numeric" };

    return new Date(date).toLocaleDateString(locale, options);
  };

  const defineExpired = () => {
    const endDateObj = new Date(endDate);
    return endDateObj < new Date();
  };

  const renderSubscribeButton = () => {
    return (
      <button
        className="subscription_create_btn"
        disabled={subscriptionActive}
        onClick={() => onSubscribe(product.price)}
      >
        <span>
          <I18N id={"subscription_create_btn"} />
        </span>
      </button>
    );
  };

  const renderCancelButton = () => (
    <button className="subscription_cancel_btn" onClick={() => onCancel()}>
      <p>
        <I18N id={"subscription_cancel_btn"} />
      </p>
    </button>
  );

  const renderUncancelButton = () => (
    <button className="subscription_uncancel_btn" disabled={!isCancelled} onClick={() => onUncancel(product.price)}>
      <Tooltip title={<I18N id={"subscription_uncancel_tooltip"} />}>
        <span>
          <I18N id={"subscription_uncancel_btn"} />
        </span>
      </Tooltip>
    </button>
  );

  function renderPriceColumn() {
    return (
      <div className="subscription_card__columns">
        <p>
          <I18N id={"price"} />
        </p>
        <p className="subscription_card__price">
          {product.price} {product.currency}
        </p>
      </div>
    );
  }

  function renderPeriodColumn() {
    return (
      <div className="subscription_card__columns">
        <p>
          <I18N id={"subscription_period"} />
        </p>
        <p>
          <I18N id={"subscription_per_monthly"} />
        </p>
      </div>
    );
  }

  function renderNextPaymentColumn() {
    return (
      <div className="subscription_card__columns">
        <p>
          <I18N id={"subscription_next_payment"} />
        </p>
        <p>{endDate && !isCancelled ? renderDates(endDate) : null}</p>
      </div>
    );
  }

  function renderCanceledEndDateColumn() {
    return defineExpired() ? renderExpiredStatus() : renderActiveStatus();
  }

  function renderExpiredStatus() {
    return (
      <>
        <div className="subscription_card__columns">
          <p>
            <I18N id={"subscription_last_payment"} />
          </p>
          <p>{endDate ? renderDates(endDate) : null}</p>
        </div>
        <div className="subscription_card__columns">
          <p>
            <I18N id={"subscription_status"} />
          </p>
          <p>
            <I18N id={"subscription_status_inactive"} />
          </p>
        </div>
      </>
    );
  }

  function renderActiveStatus() {
    return (
      <>
        <div className="subscription_card__columns">
          <p>
            <I18N id={"subscription_canceled_end_date"} />
          </p>
          <p>{endDate ? renderDates(endDate) : null}</p>
        </div>
        <div className="subscription_card__columns">
          <p>
            <I18N id={"subscription_status"} />
          </p>
          <p className={isCancelled ? "subscription_status_canceled" : "subscription_status_active"}>
            <I18N id={isCancelled ? "subscription_status_canceled" : "subscription_status_active"} />
          </p>
        </div>
      </>
    );
  }

  const renderCircularProgress = () => {
    return isLoading ? (
      <div className="circularProgress">
        <CircularProgress size={100} />
      </div>
    ) : null;
  };

  return (
    <div className="subscription_card">
      <div className="subscription_card__wrapper">
        <div className="refreshButtonBlock">
          <CustomTooltip tooltipText={isLoading ? "Отримання даних" : "Оновити данні"}>
            <div className={`header-refreshDataButton ${isLoading ? "spin-animation" : ""}`} onClick={onRefresh}>
              <RefreshOutlined />
            </div>
          </CustomTooltip>
        </div>
        <div className="subscription_card__wrapper-content">
          <div className="subscription_card__columns">
            <h2 className="subscription_card__name">{product.name}</h2>
          </div>
          <div className="subscription_card__details">
            {renderCircularProgress()}
            {renderPriceColumn()}
            {renderPeriodColumn()}
            {subscriptionActive && renderNextPaymentColumn()}
            {(isCancelled || subscriptionActive) && renderCanceledEndDateColumn()}
          </div>
        </div>
        {subscriptionActive && !isCancelled && renderCancelButton()}
        {subscriptionActive && isCancelled && renderUncancelButton()}
        {!subscriptionActive && renderSubscribeButton()}
        {!subscriptionActive && (
          <div className="subscription-after-action-text">
            <p>
              <I18N id={"subscription_after_action"} />
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionCard;
