import Badge from "../Badge/Badge";
import React from "react";

const OptionElement = ({ option, isCheckBox, isSelected, onClick, withBadge = true }) => {
  const getElementClassName = (isCheckBox, isSelected) => {
    let className = "optionElement";

    className += isCheckBox ? " with-checkbox" : "";
    className += isSelected ? " optionElement-selected" : "";

    return className;
  };

  const getCheckBoxClassName = (isSelected) => {
    return "checkbox" + (isSelected ? " checkbox-selected" : "");
  };

  return (
    <div className="optionElement-wrapper" onClick={onClick}>
      <div className={getElementClassName(isCheckBox, isSelected)}>
        {isCheckBox && <div className={getCheckBoxClassName(isSelected)} />}
        <div>{option.label}</div>
        {withBadge && <Badge content={option.count} />}
      </div>
    </div>
  );
};

export default OptionElement;
