import React from "react";
import { CustomerHeader, CustomerTemplate } from "components";
import SubscriptionList from "../../Landing/Profile/SubscriptionList/SubscriptionList";

const DashboardBilling = () => {
  return (
    <>
      <CustomerTemplate
        isTransparent={true}
        header={<CustomerHeader backPath={"/"} />}
        content={<SubscriptionList />}
      />
    </>
  )
};

export default DashboardBilling;
