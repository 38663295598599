import { useAuthz } from "auth/AuthzProvider";
import { useState } from "react";

const useSoundList = () => {
  const { user } = useAuthz();
  const [soundList, setSoundList] = useState([]);

  const getSoundList = () => {
    return fetch(`/api/sounds`, { method: "GET" })
      .then((res) => res.json())
      .then((res) => {
        setSoundList(res);

        return res;
      })
      .catch((error) => {
        setSoundList([]);

        console.log(error);
        return [];
      });
  };

  const createSoundFormData = (sound) => {
    const formData = new FormData();
    typeof sound.name !== "undefined" && formData.append("name", sound.name);
    typeof sound.type !== "undefined" && formData.append("type", sound.type);
    typeof sound.comment !== "undefined" && formData.append("comment", sound.comment);
    typeof sound.duration !== "undefined" && formData.append("duration", sound.duration);
    typeof sound.path === "object" && sound.isOggSound && formData.append("oggSoundFile", sound.path);
    typeof sound.path === "object" && !sound.isOggSound && formData.append("draftSoundFile", sound.path);
    typeof sound.user !== "undefined" && formData.append("user", sound.user);
    typeof sound.language !== "undefined" && formData.append("language", sound.language);
    return formData;
  };

  const createSound = (sound, options = {}) => {
    if (options.assignUser) sound.user = user.email;
    const formData = createSoundFormData(sound);
    return new Promise((resolve, reject) => {
      fetch("/api/sounds", { method: "POST", body: formData, headers: {} })
        .then((res) => res.json())
        .then((res) => {
          !options.withoutListUpdate && getSoundList();
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
          !options.withoutListUpdate && getSoundList();
          resolve("");
        });
    });
  };

  const updateSound = (sound, options = {}) => {
    if (options.assignUser) sound.user = user.email;
    const formData = createSoundFormData(sound);
    return new Promise((resolve, reject) => {
      fetch(`/api/sounds/${sound._id}`, { method: "PUT", body: formData, headers: {} })
        .then((res) => res.json())
        .then((res) => {
          !options.withoutListUpdate && getSoundList();
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
          !options.withoutListUpdate && getSoundList();
          resolve("");
        });
    });
  };

  const deleteSound = (sound, options) => {
    return new Promise((resolve, reject) => {
      fetch(`/api/sounds/${sound._id}`, { method: "DELETE", headers: {} })
        .then((res) => res.json())
        .then((res) => {
          !options.withoutListUpdate && getSoundList();
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
          !options.withoutListUpdate && getSoundList();
          resolve("");
        });
    });
  };

  const deleteSoundWithoutListUpdate = (soundId) => {
    return new Promise((resolve, reject) => {
      fetch(`/api/sounds/${soundId}`, { method: "DELETE", headers: {} })
        .then((res) => res.json())
        .then((res) => {
          resolve(res);
        })
        .catch(reject);
    });
  };

  const deleteSounds = async (soundIdsToDelete) => {
    return Promise.all(soundIdsToDelete.map((soundId) => deleteSoundWithoutListUpdate(soundId))).then(getList);
  };

  return {
    soundList,
    createSound,
    updateSound,
    deleteSound,
    deleteSounds,
    getSoundList,
  };
};

export default useSoundList;
