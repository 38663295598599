import React from "react";
import "./CommonGameConfig.scss";
import { ErrorBoard, SoundSelector, GameIcon } from "components";
import Checkbox from "@mui/material/Checkbox";
import { ReactComponent as RemoveBtnIcon } from "assets/icons/remove.svg";
import { ReactComponent as CopyBtnIcon } from "assets/icons/copy.svg";
import { ReactComponent as CreateBtnIcon } from "assets/icons/NewGameBtn.svg";
import { useResourcesContext } from "resources";
import { gameTypes } from "constants/constants";

const CommonGameConfig = ({
  game,
  gameExtraData,
  onChange,
  onNameChange,
  onDescriptionChange,
  onDelete,
  onCopy,
  onCreateGameFromTemplate,
  onLock,
  creatingGameIcon,
  locked,
}) => {
  const {
    soundListStore: { soundList },
    imageListStore: { getImagePathById },
  } = useResourcesContext();

  return (
    <div className="common-game-config-component">
      <div className="common-game-config-content">
        <div className="main-game-config">
          <div className="mgc-one-label gc-one-width">Основні:</div>

          <div className="mgc-two">
            <div className="game-icon-block">
              <div className="mgc-game-icon">
                <GameIcon
                  gameIcon={typeof game?.icon === "string" ? getImagePathById(game.icon) : game.icon}
                  showProgress={creatingGameIcon}
                />
              </div>
              <div className="type-and-name">
                <input
                  className="mgc-game-name"
                  placeholder="Назва ігри"
                  value={game.name}
                  onChange={(event) => onNameChange(event.target.value)}
                />
                <div className="mgc-game-type">
                  <div className="mgc-game-type-label">Тип ігри:</div>
                  <div className="cg-game-type">
                    {gameTypes.find((gameType) => gameType.name === game?.gameType)?.label || ""}
                  </div>
                </div>
                <div className="mgc-game-created-updated">
                  <div className="mgc-game-createdBy">{`Автор:  ${game?.createdBy} `}</div>
                  <div className="mgc-game-updatedBy">{`Збережено:  ${game?.updatedBy}`}</div>
                </div>
              </div>
            </div>

            <textarea
              className="mgc-game-description"
              placeholder="Опис ігри"
              value={game.description}
              onChange={(event) => onDescriptionChange(event.target.value)}
            ></textarea>

            <div className="mgc-lock-on-status-wrapper">
              <Checkbox disabled={true} checked={Boolean(game?.lock)} onChange={onLock} />
              <div className="mgc-lock-on-status-label">Блокувати при наявності статистики </div>
              <div className="mgc-locked-status-wrapper">
                <div className="mgc-locked-label">{"(Поточний стан:"}</div>
                <div className={`mgc-locked-text ${locked ? "locked" : ""}`}>{`${
                  locked ? "заблоковано" : "разблоковано"
                }`}</div>
                <div>{")"}</div>
              </div>
            </div>
          </div>
          <div className="mgc-three">
            <div className="top">
              <button className=" gc-game-btn-style" onClick={() => onCopy()}>
                <div className=" gc-btn-icon-style">
                  <CopyBtnIcon />
                </div>
                {game?.gameType === "template" ? "Копіювати шаблон" : "Копіювати ігру"}
              </button>
              <button className=" gc-game-btn-style" onClick={() => onDelete()}>
                <div className=" gc-btn-icon-style background-color-red">
                  <RemoveBtnIcon />
                </div>
                {game?.gameType === "template" ? "Видалити шаблон" : "Видалити ігру"}
              </button>
            </div>
            <div className="middle">
              {game?.gameType === "template" && (
                <div className="creator-buttons-block">
                  <button className=" gc-game-btn-style" onClick={() => onCreateGameFromTemplate("universal", game)}>
                    <div className=" gc-btn-icon-style">
                      <CreateBtnIcon />
                    </div>
                    Universal
                  </button>
                </div>
              )}
            </div>
            <div className="bottom"></div>
          </div>
        </div>
        <div className="sounds-game-config">
          <div className="sgc-one-label gc-one-width">Звуки:</div>
          <div className="sgc-two">
            <div className="sgc-sound-selector-wrapper">
              <div className="sgc-sound-selector-label">Початок ігри:</div>
              <div className="sgc-game-sound-selector">
                <div className="sgc-single">
                  <SoundSelector
                    soundType={"gameStart"}
                    value={soundList.find((sound) => game.sounds?.gameStart === sound._id)}
                    onChangeValue={(value) => {
                      game.sounds = { ...game.sounds, gameStart: value ? value._id : "" };
                      onChange();
                    }}
                    onSoundRecorded={(createdSound) => {
                      game.sounds = { ...game.sounds, gameStart: createdSound._id };
                      onChange();
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="sgc-sound-selector-wrapper">
              <div className="sgc-sound-selector-label">Кінець ігри:</div>
              <div className="sgc-game-sound-selector">
                <div className="sgc-single">
                  <SoundSelector
                    soundType={"gameEnd"}
                    value={soundList.find((sound) => game.sounds?.gameEnd === sound._id)}
                    onChangeValue={(value) => {
                      game.sounds = { ...game.sounds, gameEnd: value ? value._id : "" };
                      onChange();
                    }}
                    onSoundRecorded={(createdSound) => {
                      game.sounds = { ...game.sounds, gameEnd: createdSound._id };
                      onChange();
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="sgc-sound-selector-wrapper">
              <div className="sgc-sound-selector-label">Звук туторіала:</div>
              <div className="sgc-game-sound-selector">
                <div className="sgc-single">
                  <SoundSelector
                    soundType={"tutorial"}
                    value={soundList.find((sound) => game.sounds?.tutorial === sound._id)}
                    onChangeValue={(value) => {
                      game.sounds = { ...game.sounds, tutorial: value ? value._id : "" };
                      onChange();
                    }}
                    onSoundRecorded={(createdSound) => {
                      game.sounds = { ...game.sounds, tutorial: createdSound._id };
                      onChange();
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="sgc-sound-selector-wrapper">
              <div className="sgc-sound-selector-label">Вірно:</div>
              <div className="sgc-game-sound-selector">
                <div className="sgc-multiple">
                  <SoundSelector
                    multiple={true}
                    soundType={"correct"}
                    value={soundList.filter((sound) => game.sounds?.correct?.includes(sound._id))}
                    onChangeValue={(value) => {
                      game.sounds = { ...game.sounds, correct: value.map((sound) => sound._id) };
                      onChange();
                    }}
                    onSoundRecorded={(createdSound) => {
                      game.sounds = { ...game.sounds, correct: [...(game.sounds?.correct || []), createdSound._id] };
                      onChange();
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="sgc-sound-selector-wrapper">
              <div className="sgc-sound-selector-label">Не вірно:</div>
              <div className="sgc-game-sound-selector">
                <div className="sgc-multiple">
                  <SoundSelector
                    multiple={true}
                    soundType={"incorrect"}
                    value={soundList.filter((sound) => game.sounds?.incorrect?.includes(sound._id))}
                    onChangeValue={(value) => {
                      game.sounds = { ...game.sounds, incorrect: value.map((sound) => sound._id) };
                      onChange();
                    }}
                    onSoundRecorded={(createdSound) => {
                      game.sounds = { ...game.sounds, incorrect: [...(game.sounds?.incorrect || []), createdSound._id] };
                      onChange();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="errors-game-config">
          <div className="egc-one-label gc-one-width">Помилки:</div>

          <div className="egc-two">
            {gameExtraData && !gameExtraData.validation?.valid && (
              <ErrorBoard errors={gameExtraData.validation.errors} />
            )}
          </div>
          <div className="egc-three"></div>
        </div>
      </div>
    </div>
  );
};

export default CommonGameConfig;
