import React, { useState, useEffect } from "react";
import { errorTable } from "../../constants/constants";

const ErrorBoard = ({ errors }) => {
  const [errorsToDisplay, setErrorsToDisplay] = useState([]);

  const formatErrorMessages = (errors) => {
    const allErrorMessages = errors.map((error) => {
      const activeError = errorTable.find((message) => message.typeOfError === error.typeOfError);
      if (!activeError)
        return {
          message: `Невідомий код помилки: ${error.typeOfError}. ${
            error.stage ? "Етап " + String(error.stage) + "." : ""
          }`,
        };
      if (error.stage) return { message: activeError.message, stage: error.stage };
      return { message: activeError.message };
    });
    return allErrorMessages;
  };

  useEffect(() => {
    if (errors) {
      const errorMessages = formatErrorMessages(errors);
      errorMessages.length > 0 ? setErrorsToDisplay(errorMessages) : setErrorsToDisplay([]);
    }
  }, [errors]);

  return (
    <div className="error-messages-wrapper">
      {errorsToDisplay.map((error, index) =>
        error.stage ? (
          <div className="error-message" key={"error" + index}>{`${error.message} ${error.stage}`}</div>
        ) : (
          <div className="error-message" key={"error" + index}>{`${error.message}`}</div>
        )
      )}
    </div>
  );
};

export default ErrorBoard;
