import "./Header.scss";
import React, { useState } from "react";
import appLogo from "assets/app-logo.png";
import line from "assets/landing/line.svg";
import { Link } from "react-router-dom";
import { useI18N } from "i18n";
import { useAuthz } from "auth/AuthzProvider";

const Header = () => {
  const { user, logout } = useAuthz();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { I18N } = useI18N();
  const isClient = user && user.roles.includes("client");
  const isAdmin = user && user.roles.includes("admin");
  const isSuperadmin = user && user.roles.includes("superadmin");
  const isMarketing = user && user.roles.includes("marketing");
  const isTeacher = user && user.roles.includes("teacher");

  const changeLanguage = (locale) => {
    localStorage.setItem("locale", locale);
    window.location.reload();
  };

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <header className={`landing-header ${isMenuOpen ? "menu-open" : ""}`}>
      <Link className="userEmail" to={"/profile"}>
        {user?.email || ""}
      </Link>
      <Link to={"/"}>
        <img src={appLogo} alt="application logo" />
      </Link>
      <div id={"header-toggle"} className={`burger-menu ${isMenuOpen ? "active" : ""}`} onClick={toggleMenu}>
        <span></span>
      </div>
      <ul className={`landing-header_links ${isMenuOpen ? "menu-open" : ""}`}>
        <a href={"/#about_us"}>
          <I18N id="link_about_us" />
        </a>
        <Link to={"/subscription"}>
          <I18N id="link_about" />
        </Link>
        <Link to={"/contacts"}>
          <I18N id="link_contacts" />
        </Link>
        {isClient && (
          <Link to={"/profile"}>
            <I18N id="link_profile" />
          </Link>
        )}
        {(isAdmin || isSuperadmin || isMarketing || isTeacher) && (
          <Link to={"/dashboard"}>
            <I18N id="link_account" />
          </Link>
        )}
        {(isAdmin || isSuperadmin || isMarketing) && (
          <a href="/menu">
            <I18N id="link_menu" />
          </a>
        )}
      </ul>
      <div className={`landing-header_features ${isMenuOpen ? "menu-open" : ""}`}>
        <div className="landing-header_features__translation">
          <button className="ua" onClick={() => changeLanguage("uk")}>
            Ua
          </button>
          <img src={line} alt="line" />
          <button className="eng" onClick={() => changeLanguage("en")}>
            Eng
          </button>
        </div>
        {isAdmin || isSuperadmin || isClient || isMarketing || isTeacher ? (
          <div className="logoutButtonAndEmail">
            <button className="landing-header_features__feedback" onClick={() => logout()}>
              <I18N id={"logout"} />
            </button>
          </div>
        ) : (
          <Link to={"/login"} className="landing-header_features__feedback">
            <I18N id={"header_button_login"} />
          </Link>
        )}
      </div>
    </header>
  );
};

export default Header;
