import { getDigitalOceanUrl } from "helpers/getDigitalOceanUrl";
import React from "react";
import { Outlet } from "react-router-dom";

const openGraphTrialImageUrl = getDigitalOceanUrl(window.location.host) + "/video/open_graph_trial.png";
const openGraphMainImageUrl = getDigitalOceanUrl(window.location.host) + "/video/open_graph_main.png";

const OpenGraphProviderLayout = ({ pathName }) => {
  const addTags = (url, title, description, image) => {
    const meta = document.querySelector('meta[property="og:url"]') || document.createElement("meta");
    meta.setAttribute("property", "og:url");
    meta.content = url;
    document.head.appendChild(meta);

    const meta2 = document.querySelector('meta[property="og:type"]') || document.createElement("meta");
    meta2.setAttribute("property", "og:type");
    meta2.content = "website";
    document.head.appendChild(meta2);

    const meta3 = document.querySelector('meta[property="og:title"]') || document.createElement("meta");
    meta3.setAttribute("property", "og:title");
    meta3.content = title;
    document.head.appendChild(meta3);
    const meta31 = document.querySelector('meta[property="twitter:title"]') || document.createElement("meta");
    meta31.setAttribute("property", "twitter:title");
    meta31.content = title;
    document.head.appendChild(meta31);

    const meta4 = document.querySelector('meta[property="og:description"]') || document.createElement("meta");
    meta4.setAttribute("property", "og:description");
    meta4.content = description;
    document.head.appendChild(meta4);
    const meta41 = document.querySelector('meta[name="description"]') || document.createElement("meta");
    meta41.setAttribute("name", "description");
    meta41.content = description;
    document.head.appendChild(meta41);
    const meta42 = document.querySelector('meta[property="twitter:description"]') || document.createElement("meta");
    meta42.setAttribute("property", "twitter:description");
    meta42.content = description;
    document.head.appendChild(meta42);

    const meta5 = document.querySelector('meta[property="og:image"]') || document.createElement("meta");
    meta5.setAttribute("property", "og:image");
    meta5.content = image;
    document.head.appendChild(meta5);
    const meta51 = document.querySelector('meta[property="twitter:image"]') || document.createElement("meta");
    meta51.setAttribute("property", "twitter:image");
    meta51.content = image;
    document.head.appendChild(meta51);

    const meta6 = document.querySelector('meta[name="twitter:card"]') || document.createElement("meta");
    meta6.setAttribute("name", "twitter:card");
    meta6.content = "summary_large_image";
    document.head.appendChild(meta6);
  };

  if (pathName !== "/trial") {
    const url = window.location.origin;
    const title = "BulBee — високотехнологічне навчання";
    const description = "Забезпечте вашій дитині найкращі умови для розвитку";
    const image = openGraphMainImageUrl;
    addTags(url, title, description, image);
  }

  if (pathName === "/trial") {
    const url = window.location.origin + "/trial";
    const title = "BulBee — високотехнологічне навчання";
    const description = "Забезпечте вашій дитині найкращі умови для розвитку";
    const image = openGraphTrialImageUrl;
    addTags(url, title, description, image);
  }

  return <Outlet />;
};

export default OpenGraphProviderLayout;
