import React, { createContext, useContext, useState, useEffect } from "react";
import Cookies from "universal-cookie";
import * as API from "../API/API";
import axios from "axios";

const AuthzContext = createContext(null);

const useAuthz = () => {
  return useContext(AuthzContext);
};
const AuthzProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const cookies = new Cookies();

  const logout = async () => {
    await axios.post("/api/auth/logout");
    const cookies = new Cookies();
    cookies.remove("accessToken");
    setUser(() => null);
  };

  const login = async (email, password) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.post("/api/auth/login", { email, password });
        setUser(response.data);
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  };

  const getMe = async () => {
    setIsLoading(true);
    API.getOne("auth/getMe").then((webUser) => {
      if (webUser) {
        setUser(webUser);
        setIsLoading(false);
      } else {
        cookies.remove("accessToken");
        setUser(null);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    setIsLoading(true);
    API.getOne("auth/getMe").then((webUser) => {
      if (webUser) {
        setUser(webUser);
        setIsLoading(false);
      } else {
        cookies.remove("accessToken");
        setUser(null);
        setIsLoading(false);
      }
    });
  }, []);

  return (
    <AuthzContext.Provider value={{ user, setUser, isLoading, logout, login, getMe }}>{children}</AuthzContext.Provider>
  );
};

export { useAuthz, AuthzProvider };
