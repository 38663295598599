import "./StageTemplateCard.scss";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { AlertDialog, StyledButton, ItemUniversal } from "components";
import { ReactComponent as IconAddToLibrary } from "assets/icons/plus2.svg";
import { ReactComponent as AttentionIcon } from "assets/icons/ExclamationCircleRed.svg";

import IconEmptyIcon from "assets/icons/emptyIcon.svg";
import { useResourcesContext } from "resources";
import { getItemsWithChildrenPlain } from "helpers/stageItems";
import { Tooltip } from "@mui/material";

const StageTemplateCard = ({ className = "", stageTemplate, onClick, onDoubleClick, onStageTemplateDelete }) => {
  const [backgroundSize, setBackgroundSize] = useState(null);
  const backgroundRef = useRef();
  const [alertDialogData, setAlertDialogData] = useState(null);

  const {
    imageListStore: { getImagePathById },
  } = useResourcesContext();

  const stageItems = useMemo(() => getItemsWithChildrenPlain(stageTemplate.template?.items || []), [stageTemplate]);
  const stageBackground = useMemo(
    () => (stageTemplate.template?.items?.length > 0 ? getImagePathById(stageTemplate.template.items[0]._id) : null),
    [stageTemplate, getImagePathById]
  );

  const updateBackgroundSize = () => {
    setBackgroundSize({
      clientHeight: backgroundRef.current?.clientHeight,
      clientWidth: backgroundRef.current?.clientWidth,
      scale1920: 1920 / backgroundRef.current?.clientWidth,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", updateBackgroundSize);
    return () => {
      window.removeEventListener("resize", updateBackgroundSize);
    };
  }, []);

  const handleBackgroundOnLoad = () => {
    updateBackgroundSize();
  };

  const openDeleteStageDialog = () => {
    setAlertDialogData({
      onOk: () => {
        onStageTemplateDelete(stageTemplate);
        setAlertDialogData(null);
      },
      onCancel: () => setAlertDialogData(null),
      title: "Видалення шаблону етапу",
      message: "Натисніть Ok для видалення, або Cancel для відміни операції.",
    });
  };

  return (
    <>
      <div className={`stage-template-card ${className}`} onClick={onClick} onDoubleClick={onDoubleClick}>
        <div id={`stageCardTemplate`}>
          <img
            ref={backgroundRef}
            onLoad={handleBackgroundOnLoad}
            className={`stage-bg ${stageTemplate.template?.items?.length > 0 ? "" : "srcSvg"}`}
            src={stageBackground || IconEmptyIcon}
            alt="Empty background"
          />

          {backgroundSize &&
            stageItems.map((item, index) =>
              index === 0 ? null : (
                <ItemUniversal
                  key={index}
                  item={item}
                  backgroundSize={backgroundSize}
                  freeze
                  showSoundIcon={false}
                />
              )
            )}
        </div>

        {onStageTemplateDelete && (
          <StyledButton
            tooltipProps={{ title: "Видалити з бібліотеки", placement: "right", arrow: true }}
            className="customize-button deleteStageFromLibrary"
            onClick={(e) => {
              openDeleteStageDialog();
              e.stopPropagation();
            }}
          >
            <IconAddToLibrary className="deleteStageFromLibrary-button-icon" />
          </StyledButton>
        )}
        <Tooltip title="Відсутня іконка" >
          <div className="attentionIcon">
            <AttentionIcon />
          </div>
        </Tooltip>
      </div>

      <AlertDialog alertDialogData={alertDialogData} />
    </>
  );
};
export default StageTemplateCard;
