import "./FilterBox.scss";
import React, { useState } from "react";
import { ReactComponent as SwitcherIcon } from "assets/icons/Arrow.svg";
import { List, ListItemButton, ListItemText, Collapse } from "@mui/material";

const FilterBox = ({ children, label, defaultOpened }) => {
  const [opened, setOpened] = useState(defaultOpened);

  const handleClick = () => {
    setOpened(!opened);
  };

  return (
    <div className="filter-box-wrapper">
      <List>
        <ListItemButton onClick={handleClick} disableRipple>
          {opened ? (
            <div className="switcher">
              <SwitcherIcon />
            </div>
          ) : (
            <div className="switcher-open">
              <SwitcherIcon />
            </div>
          )}
          <ListItemText primary={label} />
        </ListItemButton>
        <Collapse in={Boolean(opened)} timeout="auto" unmountOnExit>
          <List component="div">{children}</List>
        </Collapse>
      </List>
    </div>
  );
};

export default FilterBox;
