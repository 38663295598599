import "./VerticalHeader.scss";
import React from "react";
import { useNavigate } from "react-router-dom";
import HouseSidingIcon from "@mui/icons-material/HouseSiding";
import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import SchoolIcon from "@mui/icons-material/School";
import LibraryMusicIcon from "@mui/icons-material/LibraryMusic";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { Tooltip } from "@mui/material";

const VerticalHeader = ({
  className = "",
  alwaysShow = true,
  buttons = ["landing", "unityUsers", "knowledgeGroupEditor", "knowledgeSoundSetEditor", "assetBundles", "dashboard"],
  children,
}) => {
  const navigate = useNavigate();

  const showUnityUsersButton = buttons.includes("unityUsers") && window.location.pathname !== "/unity-users";
  const showAssetBundlesButton = buttons.includes("assetBundles") && window.location.pathname !== "/assetBundles";
  const showKnowledgeGroupsButton =
    buttons.includes("knowledgeGroupEditor") && window.location.pathname !== "/knowledgeGroup-editor";
  const showKnowledgeSoundSetsButton =
    buttons.includes("knowledgeSoundSetEditor") && window.location.pathname !== "/knowledge-sound-set-editor";

  const showDashboardButton = buttons.includes("dashboard") && window.location.pathname !== "/dashboard";
  const showLandingButton = buttons.includes("landing") && window.location.pathname !== "/";

  return (
    <div className={`vertical-header-wrapper ${className}`}>
      <Tooltip title="Головне меню" arrow placement="right">
        <div className={`header-usersButton }`} onClick={() => navigate("/menu")}>
          <HomeIcon />
        </div>
      </Tooltip>

      {(showUnityUsersButton || alwaysShow) && (
        <Tooltip title="Користувачі" arrow placement="right">
          <div
            className={`header-usersButton ${alwaysShow && !showUnityUsersButton ? "active" : ""}`}
            onClick={() => showUnityUsersButton && navigate("/unity-users")}
          >
            <PeopleIcon />
          </div>
        </Tooltip>
      )}

      {(showAssetBundlesButton || alwaysShow) && (
        <Tooltip title="Бандли" arrow placement="right">
          <div
            className={`header-usersButton ${alwaysShow && !showAssetBundlesButton ? "active" : ""}`}
            onClick={() => showAssetBundlesButton && navigate("/assetBundles")}
          >
            <SportsEsportsIcon />
          </div>
        </Tooltip>
      )}

      {(showKnowledgeGroupsButton || alwaysShow) && (
        <Tooltip title="Знання" arrow placement="right">
          <div
            className={`header-usersButton ${alwaysShow && !showKnowledgeGroupsButton ? "active" : ""}`}
            onClick={() => showKnowledgeGroupsButton && navigate("/knowledgeGroup-editor")}
          >
            <SchoolIcon />
          </div>
        </Tooltip>
      )}

      {(showKnowledgeSoundSetsButton || alwaysShow) && (
        <Tooltip title="Звуки знань" arrow placement="right">
          <div
            className={`header-usersButton ${alwaysShow && !showKnowledgeSoundSetsButton ? "active" : ""}`}
            onClick={() => showKnowledgeSoundSetsButton && navigate("/knowledge-sound-set-editor")}
          >
            <LibraryMusicIcon />
          </div>
        </Tooltip>
      )}

      {(showDashboardButton || alwaysShow) && (
        <Tooltip title="Дашборд" arrow placement="right">
          <div
            className={`header-usersButton ${alwaysShow && !showDashboardButton ? "active" : ""}`}
            onClick={() => showDashboardButton && navigate("/dashboard")}
          >
            <DashboardIcon />
          </div>
        </Tooltip>
      )}
      {(showLandingButton || alwaysShow) && (
        <Tooltip title="Лендінг" arrow placement="right">
          <div
            className={`header-usersButton ${alwaysShow && !showLandingButton ? "active" : ""}`}
            onClick={() => showLandingButton && navigate("/")}
          >
            <HouseSidingIcon />
          </div>
        </Tooltip>
      )}

      {children}
    </div>
  );
};
export default VerticalHeader;
