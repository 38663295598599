import "./ReloadButton.scss";
import React from "react";
import { ReactComponent as IconUpdate } from "../../assets/icons/cancel.svg";

const ReloadButton = ({ className, title, onClick }) => {
  return (
    <button className={`reloadButton-component ${className}`} title={title} onClick={onClick ? onClick : null}>
      <IconUpdate />
    </button>
  );
};
export default ReloadButton;
