import { CircularProgress } from '@mui/material';
import React from 'react';

const FetchingProgressWrapper = ({
  fetchingGameList,
  fetchingExtraData,
  children
}) => {
  return (
    <>
      {fetchingGameList ? (
        <div className="progress-box">
          <div className="progressFetchingGameList">
            <span className="progressFetchingGameListLabel">{"Завантаження ігор"}</span>
            <CircularProgress size={400} style={{ color: "#FAA400" }} />
          </div>
        </div>
      ) : (
        <>
          {fetchingExtraData && !fetchingGameList && (
            <div className="progressExtraData">
              <CircularProgress size={20} style={{ color: "#FAA400" }} />
              <span className="progressExtraDataLabel">{"Завантаження екстра даних"}</span>
            </div>
          )}
          {children}
        </>
      )}
    </>
  )
}

export default FetchingProgressWrapper
