import React from "react";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

const SwitchLabels = ({ label, checked, onChange }) => {
  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch checked={checked} onChange={onChange ? onChange : null} />}
        label={`${label}`}
      />
    </FormGroup>
  );
};
export default SwitchLabels;
