import "./Profile.scss";
import React from "react";
import SubscriptionList from "./SubscriptionList/SubscriptionList";
import Layout from "pages/Landing/Layout/Layout";

const Profile = () => {
  return (
    <Layout>
      <div className="profile-page">
        <div className="profile">
          <div className="profile__content">
            <SubscriptionList />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
