import "./DashboardUserDetails.scss";
import { ToastContainer } from "react-toastify";
import React, { useEffect, useState, useMemo } from "react";
import { Link, useParams } from "react-router-dom";

import withIdParam from "helpers/withIdParam";

import Editor from "pages/UnityUserEditor/Editor";

import unityUserListApi from "API/unityUserListApi";

import calculateAge from "helpers/calculateAge";

import { useResourcesContext } from "resources";

import { ReactComponent as IconBackOutlined } from "assets/icons/backOutlined.svg";

import { CustomerHeader, CustomerTemplate, CardStyled, Tabs, UserDetailsForm } from "components";

import Badge from "components/Badge/Badge";

const DashboardUserDetails = () => {
  const [unityUser, setUnityUser] = useState(null);
  const [activeTab, setActiveTab] = useState("Моніторинг");
  const { userListStore: { reloadList } } = useResourcesContext();

  const { id } = useParams();

  const loadUnityUser = () => {
    unityUserListApi.getUnityUser(id).then((user) => {
      setUnityUser(user);
    })
  }

  useEffect(() => loadUnityUser(), []);

  const age = useMemo(() => {
    if (!unityUser) {
      return "";
    }

    return calculateAge(unityUser.birthdayYear, unityUser.birthdayMonth);
  }, [unityUser]);

  if (!unityUser) {
    return null;
  }

  const handleSubmit = () => {
    reloadList();
    loadUnityUser();
  }

  return (
    <div className="dashboard-user-details">
      <CustomerTemplate
        isTransparent={true}
        header={<CustomerHeader backPath={"/"} />}
        content={
          <>
            <CardStyled>
              <div className="account-details-container">
                <div className="account-details-label">
                  <Link to={"/dashboard"}>
                    <IconBackOutlined />
                  </Link>
                  <span className="account-details-title">
                    {unityUser.name}
                  </span>
                  {(unityUser.birthdayYear !== null && unityUser.birthdayMonth !== null) && (
                    <span className="account-details-value">
                      <Badge content={`${age} років`} />
                    </span>
                  )}
                </div>
                <Tabs
                  className="account-details-tabs"
                  tabs={["Моніторинг", "Інформація"]}
                  activeTab={activeTab}
                  onChange={setActiveTab}
                />
              </div>
            </CardStyled>
            <br />
            {activeTab === "Моніторинг" && (
              <Editor id={id} className="userEditor" />
            )}
            {activeTab === "Інформація" && (
              <div style={{ width: 390 }}>
                <CardStyled>
                  <UserDetailsForm unityUser={unityUser} handleSubmit={handleSubmit} />
                </CardStyled>
              </div>
            )}
          </>
        }
      />
      <ToastContainer
        className={"toast-position"}
        toastClassName="toastClassName"
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="lig}ht"
      />
    </div>
  )
};

export default withIdParam(DashboardUserDetails);
