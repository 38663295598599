import { useAuthz } from "auth/AuthzProvider";
import { useEffect, useState } from "react";
import * as API from "../API/API";

const useTagList = () => {
  const { user } = useAuthz();
  const [tagList, setTagList] = useState([]);

  const getTagList = () => {
    API.getAll("tags")
      .then((res) => setTagList(res))
      .catch(() => setTagList([]));
  };

  return { tagList, setTagList, getTagList };
};

export default useTagList;
