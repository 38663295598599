import "./PlanElementTile.scss";
import React, { useEffect, useState } from "react";
import { Chip, Tooltip } from "@mui/material/";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useResourcesContext } from "resources";
import PlanElementCheckResults from "./PlanElementCheckResults/PlanElementCheckResults";
import _ from "lodash";

const PlanElementTile = ({
  className = "",
  chipClassName = "",
  planElement,
  onClick,
  onButtonClick,
  onChange,
  buttonDisabled,
  buttonType = "",
  buttonTooltip = "Додати",
  dragging,
  dragOver,
  onDropDownElementMounted,
  knResults,
  planElementCheckResults,
  showArrowDownIcon = false,
  tagsWithoutSoundSet,
}) => {
  const [knTags, setKnTags] = useState([]);
  const [knTagsWithStyles, setKnTagsWithStyles] = useState([]);
  const [isKnownElement, setIsKnownElement] = useState(false);
  const [showCheckElementResults, setShowCheckElementResults] = useState(false);

  const {
    knowledgeTagListStore: { knowledgeTagList },
  } = useResourcesContext();

  useEffect(() => {
    if (!planElement?.knowledgeGroup?.knowledgeTags) {
      setKnTags([]);
      return;
    }
    const filteredKnTags = knowledgeTagList.filter((knTag) =>
      planElement.knowledgeGroup.knowledgeTags.includes(knTag._id)
    );

    setKnTags(
      planElement.knowledgeGroup.knowledgeTags.map(
        (tagId) => filteredKnTags.find((knTag) => knTag._id === tagId) || { _id: tagId, name: "DELETED" }
      )
    );
  }, [knowledgeTagList, planElement]);

  const getTagProps = (knTagId, knResults, tagsWithoutSoundSet = []) => {
    const knLearningProgress = knResults.actualLearningProgress?.find((kn) => kn.knowledge === knTagId);
    const knowledgeStrength = Math.round(knLearningProgress?.strength || 0);
    const knowledgeForgetRate = (knLearningProgress?.forgetRate || 0).toFixed(2);

    const hasSoundSetClassName = tagsWithoutSoundSet.includes(knTagId) ? "doesntHaveSoundSet" : "";

    if (knResults.knownList?.includes(knTagId)) {
      return {
        className: `known-color ${hasSoundSetClassName}`,
        tooltipText: `Знає (${knowledgeStrength}, ${knowledgeForgetRate})`,
      };
    }
    if (knResults.knownListForMinorKnowledges?.includes(knTagId)) {
      return {
        className: `knownMinor-color ${hasSoundSetClassName}`,
        tooltipText: `Знає для випадку допоміжного знання (${knowledgeStrength}, ${knowledgeForgetRate})`,
      };
    }
    if (knResults.readyToControlList?.includes(knTagId)) {
      return {
        className: `control-color ${hasSoundSetClassName}`,
        tooltipText: `Контроль (${knowledgeStrength}, ${knowledgeForgetRate})`,
      };
    }
    if (knResults.readyToLearnList?.includes(knTagId)) {
      return {
        className: `learn-color ${hasSoundSetClassName}`,
        tooltipText: `Вивчення (${knowledgeStrength}, ${knowledgeForgetRate})`,
      };
    }
    return { className: `${hasSoundSetClassName}`, tooltipText: "" };
  };

  useEffect(() => {
    knResults
      ? setKnTagsWithStyles(
          knTags.map((knTag) => {
            if (knTag.name === "DELETED") {
              return { ...knTag, className: "deleted", tooltipText: `Тег не існує, можливо видалений` };
            }
            return { ...knTag, ...getTagProps(knTag._id, knResults, tagsWithoutSoundSet) };
          })
        )
      : setKnTagsWithStyles(knTags);

    setIsKnownElement(
      !planElement.knowledgeGroup.knowledgeTags.some((tagId) => !knResults?.knownList?.includes(tagId))
    );
  }, [knTags, tagsWithoutSoundSet, knResults, planElement]);

  const handleDeleteTag = (tagId) => {
    const newKnowwledgeGroupTags = planElement.knowledgeGroup.knowledgeTags.filter((knTagId) => knTagId !== tagId);
    const newKnowledgeGroup = { ...planElement.knowledgeGroup, knowledgeTags: newKnowwledgeGroupTags };
    const newPlanElement = { id: planElement.id, knowledgeGroup: newKnowledgeGroup, elements: planElement.elements };
    onChange(newPlanElement);
  };

  const handleChangeSubElement = (subElement) => {
    const newSubElements = planElement.elements.map((element) => {
      if (element.id === subElement.id) {
        return subElement;
      }
      return element;
    });
    const newPlanElement = { id: planElement.id, knowledgeGroup: planElement.knowledgeGroup, elements: newSubElements };
    onChange(newPlanElement);
  };

  return (
    <div
      ref={onDropDownElementMounted}
      className={`planElementTile-component ${className} ${isKnownElement ? "color-knownElement" : ""}`}
    >
      <div className="tile-header">{planElement.knowledgeGroup?.name || "no name"}</div>

      <div className="middle">
        <div className={`tagList`}>
          {knTagsWithStyles.map((knTag) => (
            <Tooltip followCursor={true} title={knTag.tooltipText}>
              <Chip
                variant=""
                label={<div className={"chip-label"}>{`${knTag.name}`}</div>}
                className={`chip ${chipClassName} ${knTag.className}`}
                onDelete={onChange ? () => handleDeleteTag(knTag._id) : null}
              />
            </Tooltip>
          ))}
        </div>
        <div className="subElementList">
          {planElement.elements?.map((subElement) => (
            <PlanElementTile
              className="subElement"
              planElement={subElement}
              onChange={onChange && handleChangeSubElement}
              knResults={knResults}
              tagsWithoutSoundSet={tagsWithoutSoundSet}
            />
          ))}
        </div>
        {planElementCheckResults && showArrowDownIcon && (
          <Tooltip
            title={`${showCheckElementResults ? "Згорнути деталі" : "Разгорнути деталі"}`}
            placement="right"
            arrow
          >
            <button
              className={`arrowDownIcon ${showCheckElementResults && "rotate180"}`}
              onClick={() => setShowCheckElementResults(!showCheckElementResults)}
            >
              <ArrowDownIcon />
            </button>
          </Tooltip>
        )}
        {showCheckElementResults && planElementCheckResults && (
          <div className="checkResultsBlock">
            <PlanElementCheckResults planElementCheckResults={planElementCheckResults} />
          </div>
        )}
      </div>

      <div className="rightButtonBlock">
        {buttonType === "plus" ? (
          <Tooltip title={buttonTooltip} placement="right" arrow>
            <button className={`plusButton`} disabled={buttonDisabled} onClick={onButtonClick}>
              <AddIcon />
            </button>
          </Tooltip>
        ) : null}
        {buttonType === "minus" ? (
          <Tooltip title={buttonTooltip} placement="right" arrow>
            <button className={`minusButton`} disabled={buttonDisabled} onClick={onButtonClick}>
              <RemoveIcon />
            </button>
          </Tooltip>
        ) : null}
        {buttonType === "clear" ? (
          <Tooltip title={buttonTooltip} placement="right" arrow>
            <button className={`minusButton`} disabled={buttonDisabled} onClick={onButtonClick}>
              <ClearIcon />
            </button>
          </Tooltip>
        ) : null}
        {buttonType === "check" ? (
          <Tooltip title={buttonTooltip} placement="right" arrow>
            <button className={`checkButton`} disabled={buttonDisabled} onClick={onButtonClick}>
              <CheckIcon />
            </button>
          </Tooltip>
        ) : null}
      </div>
    </div>
  );
};
export default PlanElementTile;
