import "./AppButtons.scss";
import React from "react";
import { useI18N } from "i18n";
import AppMarketLinkButton from "components/AppMarketLinkButton/AppMarketLinkButton";

const AppButtons = ({ theme = "dark", blinking, usePng }) => {
  const { I18N } = useI18N();

  return (
    <div className="app-market-buttons">
      <AppMarketLinkButton appMarket={"play-market"} theme={theme} blinking={blinking} usePng={usePng} />
      <AppMarketLinkButton appMarket={"app-store"} theme={theme} blinking={blinking} usePng={usePng} />
    </div>
  );
};

export default AppButtons;
