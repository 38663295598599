const getItemsWithChildrenPlain = (items) => {
  return (items||[]).reduce((resArr, child) => {
    const itemChildren = getItemsWithChildrenPlain(child.items || []);
    return [...resArr, child, ...itemChildren];
  }, []);
};


const findParentAndItem = (rootItem, stageItemId) => {
  const result = (rootItem.items || [])
    .map((item, index) => {
      return (
        (item.stageItemId === stageItemId && { parent: rootItem, item, index }) || findParentAndItem(item, stageItemId)
      );
    })
    .filter(Boolean);

  return (result.length && result[0]) || null;
};


const findRootParentItem = (items, stageItemId) => {
  const isRootItemFor = (itemToCheck, stageItemId) =>
    itemToCheck.stageItemId === stageItemId ||
    itemToCheck.items?.some((childItem) => isRootItemFor(childItem, stageItemId));

  return items.find((item) => isRootItemFor(item, stageItemId));
};


export { getItemsWithChildrenPlain, findParentAndItem, findRootParentItem };
