import { useCallback, useState } from "react";

const useImageList = () => {
  const [imageList, setImageList] = useState([]);

  const getImageList = () => {
    return fetch(`/api/images`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((res) => setImageList(res.reverse()))
      .catch((error) => {
        setImageList([]);
        console.log(error);
      });
  };

  const getImagePathById = useCallback(
    (imageId) => {
      return imageList.find((image) => image._id === imageId)?.path;
    },
    [imageList]
  );

  const getImageById = useCallback(
    (imageId) => {
      return imageList.find((image) => image._id === imageId);
    },
    [imageList]
  );

  const isFieldDefined = (field) => {
    return typeof field !== "undefined";
  };

  const createImage = useCallback((image) => {
    const formData = new FormData();
    isFieldDefined(image.name) && formData.append("name", image.name);
    isFieldDefined(image.type) && formData.append("type", image.type);
    isFieldDefined(image.height) && formData.append("height", image.height);
    isFieldDefined(image.width) && formData.append("width", image.width);
    isFieldDefined(image.tags) && formData.append("tags", JSON.stringify(image.tags));
    formData.append("imageFile", typeof image.path === "object" ? image.path : "");
    return fetch(`/api/images`, {
      method: "POST",
      body: formData,
      headers: {},
    }).then((res) => {
      getImageList();
      return res.json();
    });
  }, []);

  const updateImage = useCallback((image) => {
    const formData = new FormData();
    isFieldDefined(image.name) && formData.append("name", image.name);
    isFieldDefined(image.type) && formData.append("type", image.type);
    isFieldDefined(image.height) && formData.append("height", image.height);
    isFieldDefined(image.width) && formData.append("width", image.width);
    isFieldDefined(image.tags) && formData.append("tags", JSON.stringify(image.tags));
    formData.append("imageFile", typeof image.path === "object" ? image.path : "");
    return fetch(`/api/images/${image._id}`, {
      method: "PUT",
      body: formData,
      headers: {},
    }).then((res) => {
      getImageList();
      return res.json();
    });
  }, []);

  const deleteImage = useCallback((imageId) => {
    return fetch(`/api/images/${imageId}`, {
      method: "DELETE",
    }).then((res) => {
      getImageList();
      return res.json();
    });
  }, []);

  const transformImage = useCallback((imageId, params = {}) => {
    const searchParams = new URLSearchParams(params);

    return fetch(`/api/images/${imageId}/transform?${searchParams.toString()}`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((updatedImage) => {
        setImageList((prevImageList) => {
          return prevImageList.map((image) => {
            if (image._id === imageId) {
              return {
                ...image,
                ...updatedImage,
              };
            }
            return image;
          });
        });

        return updatedImage;
      });
  }, []);

  return {
    imageList,
    setImageList,
    getImagePathById,
    getImageById,
    getImageList,
    createImage,
    updateImage,
    deleteImage,
    transformImage,
  };
};

export default useImageList;
