import "./AssetBundles.scss";

import React, { useMemo, useEffect } from "react";
import { CardStyled, Header, VerticalHeader } from "components";
import { ReactComponent as NewAssetBundleBtnIcon } from "assets/icons/NewGameBtn.svg";
import AssetBundleTile from "./AssetBundleTile/AssetBundleTile";
import { useResourcesContext } from "resources";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

const AssetBundles = () => {
  const navigate = useNavigate();

  const {
    assetBundleListStore: {
      assetBundleList,
      editedAssetBundleList,
      fetchingList,
      fetchingExtraDataList,
      createNewAssetBundle,
      getAssetBundleList,
      getAssetBundleExtraDataList,
    },
    imageListStore: { getImageList },
  } = useResourcesContext();

  useEffect(() => {
    getImageList();
    getAssetBundleList();
    getAssetBundleExtraDataList();
  }, []);

  const agregatedAndReversedList = useMemo(() => {
    return [
      ...assetBundleList,
      ...editedAssetBundleList.filter(
        (editedAssetBundle) => !assetBundleList.some((assetBundle) => assetBundle._id === editedAssetBundle._id)
      ),
    ].reverse();
  }, [assetBundleList, editedAssetBundleList]);

  const handleCreateAssetBundle = async () => {
    const newEditedAssetBandle = createNewAssetBundle();
    navigate(`/assetBundle-editor/${newEditedAssetBandle._id}`);
  };

  const handleAssetBundleTileClick = (assetBundleId) => {
    navigate(`/assetBundle-editor/${assetBundleId}`);
  };

  const handleAssetBundleTileDoubleClick = (assetBundleId) => {
    handleAssetBundleTileClick(assetBundleId);
  };

  return (
    <div className="assetBundles-component">
      <CardStyled className="left-header">
        <VerticalHeader></VerticalHeader>
      </CardStyled>
      <CardStyled className="assetBundles-container">
        <div className="left-side"></div>
        <div className="right-side">
          <div className="right-side-content">
            {fetchingList ? (
              <div className="progress-box">
                <div className="progressFetchingList">
                  <span className="progressFetchingListLabel">{"Завантаження ..."}</span>
                  <CircularProgress size={400} style={{ color: "var(--main-accent-color)" }} />
                </div>
              </div>
            ) : (
              <>
                {fetchingExtraDataList && !fetchingList && (
                  <div className="progressExtraData">
                    <CircularProgress size={20} style={{ color: "var(--main-accent-color)" }} />
                    <span className="progressExtraDataLabel">{"Завантаження екстра даних"}</span>
                  </div>
                )}
                <button className="add-new-assetBundle-btn" onClick={handleCreateAssetBundle}>
                  <div className="new-assetBundle-btn" title="Створити asset bundle">
                    <NewAssetBundleBtnIcon />
                  </div>
                </button>

                <div className="assetBundles-list">
                  {agregatedAndReversedList.map((assetBundle, index) => {
                    return (
                      <AssetBundleTile
                        key={index}
                        assetBundle={assetBundle}
                        onClick={() => handleAssetBundleTileClick(assetBundle._id)}
                        onDoubleClick={() => handleAssetBundleTileDoubleClick(assetBundle._id)}
                        edited={editedAssetBundleList.some(
                          (editedAssetBundle) => editedAssetBundle._id === assetBundle._id
                        )}
                      />
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </div>
      </CardStyled>
    </div>
  );
};

export default AssetBundles;
