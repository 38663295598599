import "./ModalDialog.scss";
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@mui/material';

import { ReactComponent as IconClose } from "assets/icons/close.svg";

const ModalDialog = ({
  open,
  onClose,
  title,
  children,
}) => {
  return (
    <Dialog open={open} onClose={onClose} sx={{
      '& .MuiDialog-paper': {
        width: '400px',
        maxHeight: 'initial',
        borderRadius: '10px',
        background: '#FFF',
        boxShadow: '30px 122px 35px 0px rgba(131, 156, 180, 0.00), 20px 78px 32px 0px rgba(131, 156, 180, 0.01), 11px 44px 27px 0px rgba(131, 156, 180, 0.05), 5px 20px 20px 0px rgba(131, 156, 180, 0.09), 1px 5px 11px 0px rgba(131, 156, 180, 0.10)'
      }
    }}>
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <IconClose />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );
};

export default ModalDialog;
