import React, { useMemo } from "react";
import { useResourcesContext } from "resources";
import Layers from "./Layers";
import { ReactComponent as IconAddToLibrary } from "assets/icons/plus2.svg";
import StyledButton from "components/StyledButton/StyledButton";
import { getItemsWithChildrenPlain } from "helpers/stageItems";
import _ from "lodash";
import { Tooltip } from "@mui/material";

const Layer = ({
  item,
  selectedStageItemId,
  onItemClick,
  onItemCopy,
  onPressPaste,
  onDropDownElementMounted,
  dragging,
  dragOver,
  onDropItem,
}) => {
  const {
    imageListStore: { getImagePathById },
    itemTemplatesStore: { createItemTemplate },
    soundListStore: { soundList },
  } = useResourcesContext();

  const imageSrc = useMemo(() => getImagePathById(item._id), [getImagePathById, item._id]);
  const soundName = useMemo(
    () => (!item?.sounds?.main ? "" : soundList.find((sound) => sound._id === item.sounds.main)?.name || ""),
    [soundList, item?.sounds?.main]
  );

  const handleAddToItemLibrary = (item) => {
    const itemCopy = _.cloneDeep(item);
    const childItemsPlain = getItemsWithChildrenPlain(itemCopy.items || []);
    itemCopy.stageItemId = "";
    itemCopy.startAction = "";
    itemCopy.sounds = { main: "" };

    childItemsPlain.forEach((childItem) => {
      childItem.stageItemId = "";
      childItem.startAction = "";
      childItem.sounds = { main: "" };
    });
    createItemTemplate(itemCopy);
  };

  const handleKeyUp = (event) => {
    const copyKeys = ["c", "C", "с", "С"];
    const pasteKeys = ["v", "V", "м", "М"];
    (((event.ctrlKey || event.metaKey) && copyKeys.includes(event.key)) || (event.key === "Insert" && event.ctrlKey)) &&
      onItemCopy(item);
    (((event.ctrlKey || event.metaKey) && pasteKeys.includes(event.key)) ||
      (event.key === "Insert" && event.shiftKey)) &&
      onPressPaste();
  };

  return (
    <div className="layer-component" tabIndex="-1" onKeyUp={handleKeyUp}>
      <div
        ref={onDropDownElementMounted}
        onClick={(e) => {
          onItemClick(item.stageItemId);
        }}
        className={`box-layer ${item.stageItemId === selectedStageItemId ? "active-layer" : ""} ${
          dragOver && !dragging ? "layer-drag-over" : ""
        }`}
        key={item._id}
      >
        {item?.type !== "placeholder" && (
          <Tooltip title={item.stageItemId}>
            <img className="picture" src={imageSrc} alt="" />
          </Tooltip>
        )}

        {item?.type === "placeholder" && (
          <Tooltip title={"Плейсхолдер"}>
            <div className="item-placeholder"></div>
          </Tooltip>
        )}

        <div className="sound-name">{soundName}</div>
        {item?.items?.length ? (
          <StyledButton
            tooltipProps={{ title: "Додати до бібліотеки", placement: "right", arrow: true }}
            //icon={IconCopy}
            className="customize-button addToLibrary"
            onClick={(e) => {
              handleAddToItemLibrary(item);
              e.stopPropagation();
            }}
          >
            <IconAddToLibrary className="addToLibrary-button-icon" />
          </StyledButton>
        ) : null}
      </div>
      <div className="sublayers-wrapper">
        <div className="sublayers-left-box"></div>
        <div className="sublayers-list">
          <Layers
            cardStyled={false}
            items={item.items?.length ? item.items.map((element) => element).reverse() : []}
            parentId={item.stageItemId}
            selectedStageItemId={selectedStageItemId}
            onDropItem={onDropItem}
            onItemClick={onItemClick}
          />
        </div>
      </div>
    </div>
  );
};

export default Layer;
