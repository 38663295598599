import StyledButton from 'components/StyledButton/StyledButton'
import { ReactComponent as IconRemove } from "assets/icons/minus.svg";
import { ReactComponent as IconConfirm } from "assets/icons/Confirm.svg";
import React from 'react'

const GameTileButton = ({
  onAddClick,
  onRemoveClick,
  selected = false,
  buttonType = '',
}) => {
  const isUnselectedCheckButton = () =>
    !selected && (buttonType === 'check')

  const getTooltipTitle = () =>
    isUnselectedCheckButton()
      ? "Додати до плану"
      : "Видалити з плану"

  const handleOnClick = (e) => {
    e.stopPropagation();
    isUnselectedCheckButton()
      ? onAddClick()
      : onRemoveClick();
  }

  const getIcon = () => {
    if (selected) {
      return <IconConfirm />
    }

    if (buttonType === 'remove') {
      return <IconRemove />
    }
  }
  
  return (
    <div className="sgt-buttons-block">
        <StyledButton
          tooltipProps={{
            title: getTooltipTitle(),
            placement: "right",
            arrow: true,
          }}
          className={`${buttonType}-button`}
          onClick={handleOnClick}
        >
          {getIcon()}
        </StyledButton>
    </div>
  )
}

export default GameTileButton
