import "./Subscription.scss";
import React from "react";
import { useI18N } from "i18n";
import { IoPricetagOutline } from "react-icons/io5";
import { planPrice, planPriceCurrency } from "constants/constants";
import { useAuthz } from "auth/AuthzProvider";
import Layout from "pages/Landing/Layout/Layout";

const Subscription = () => {
  const { I18N } = useI18N();
  const { user } = useAuthz();

  const subscriptionActive = user?.subscription?.active || user?.subscriptionAutoActive;

  return (
    <Layout>
      <div className="aboutSubscription-page">
        <div className={"aboutSubscription"}>
          <h2>
            <I18N id={"subscription_page_header"} />
          </h2>
          <ul>
            <li>
              <i>
                <I18N id={"consultation"} />
              </i>
              <I18N id={"consultation_description"} />
            </li>
            <li>
              <i>
                <I18N id={"testing"} />
              </i>
              <I18N id={"testing_description"} />
            </li>
            <li>
              <i>
                <I18N id={"analysis"} />
              </i>
              <I18N id={"analysis_description"} />
            </li>
            <li>
              <i>
                <I18N id={"games"} />
              </i>
              <I18N id={"games_description"} />
            </li>
            <li>
              <i>
                <I18N id={"games_stack"} />
              </i>
              <I18N id={"games_stack_description"} />
            </li>
            <li>
              <i>
                <I18N id={"exercises"} />
              </i>
              <I18N id={"exercises_description"} />
            </li>
            <li>
              <i>
                <I18N id={"video"} />
              </i>
              <I18N id={"video_description"} />
            </li>
            <li>
              <i>
                <I18N id={"adaptation"} />
              </i>
              <I18N id={"adaptation_description"} />
            </li>
            <li>
              <i>
                <I18N id={"language"} />
              </i>
              <I18N id={"language_description"} />
            </li>
            <li className="aboutSubscription__price">
              <IoPricetagOutline />
              <I18N id={"price"} /> - {planPrice} {planPriceCurrency} / <I18N id={"subscription_per_month"} />
            </li>
            <li className="aboutSubscription__link">
              <a href="/profile">
                <I18N id={subscriptionActive ? "manage_subscriptiption_btn" : "subscription_create_btn"} />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default Subscription;
