import "./userAchievementCardKnowledge.scss";
import React, { useEffect, useMemo } from "react";

import app_logo2 from "../assets/app-logo2.png";
import corona_level1 from "../assets/corona-level1.png";
import group_img from "../assets/group-img.png";
import useSearchParamWorker from "helpers/useSearchParam";

function UserAchievementCardKnowledge() {
  const { getSearchParam } = useSearchParamWorker();

  const [unityUser, setUnityUser] = React.useState(null);
  const [knowledgeTag, setKnowledgeTag] = React.useState(null);
  const [mainImageSrc, setMainImageSrc] = React.useState("");

  const unityUserId = useMemo(() => getSearchParam("unityUserId"), []);
  const knowledgeId = useMemo(() => getSearchParam("knowledgeId"));

  const nameEqualEmail = useMemo(() => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isEmail = unityUser?.name && emailRegex.test(unityUser.name);
    return Boolean(isEmail);
  }, unityUser);

  useEffect(() => {
    unityUserId &&
      fetch(`/api/unityUsers/${unityUserId}`)
        .then((res) => res.json())
        .then((data) => {
          setUnityUser(data);
        })
        .catch((err) => {
          console.error(err);
        });

    knowledgeId &&
      fetch(`/api/knowledgeTags/${knowledgeId}`)
        .then((res) => res.json())
        .then((data) => {
          setKnowledgeTag(data);
        })
        .catch((err) => {
          console.error(err);
        });

    knowledgeId &&
      fetch(`/api/statistic/getKnowledgeRandomImagePath?knowledgeId=${knowledgeId}`)
        .then((res) => res.json())
        .then((data) => {
          setMainImageSrc(data.imagePath || group_img);
        })
        .catch((err) => {
          console.error(err);
        });
  }, [unityUserId, knowledgeId]);

  const getUserAction = (unityUser) => {
    if (!unityUser?.gender || unityUser?.gender === "male") {
      return "вивчив";
    }
    return "вивчила";
  };

  return (
    <div className="userAchievementCardKnowledge">
      <div className="top">
        <img src={app_logo2} alt="logo" />
      </div>

      <div className="user-block">
        <div className="user-block-header">{nameEqualEmail && "Користувач"}</div>
        <div id="userName" className="user-name">
          {unityUser?.name}
        </div>
        <div id="userAction" className="user-action">
          {getUserAction(unityUser)}
        </div>
      </div>
      <div className="image-block">
        <div className="image-container">
          <img id="knowledgeImage" src={mainImageSrc} alt="knowledge image" />
        </div>
      </div>

      <div id="knowledgeName" className="knowledge-name">
        {knowledgeTag?.name || "Знання не знайдено"}
      </div>
    </div>
  );
}

export default UserAchievementCardKnowledge;
