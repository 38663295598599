import "./EditorTemplate.scss";
import React from "react";
import { CardStyled } from "components";

const EditorTemplate = ({ header, leftContent, middleContent, rightContent }) => {
  return (
    <>
      <div className="editor-template-wrapper">
        <CardStyled className="user-editor-left-header">{header}</CardStyled>

        <div className="content-wrapper">
          <CardStyled className="left-wrapper">{leftContent}</CardStyled>

          <CardStyled className="middle-wrapper">{middleContent}</CardStyled>

          {rightContent && <CardStyled className="right-wrapper">{rightContent}</CardStyled>}
        </div>
      </div>
    </>
  );
};
export default EditorTemplate;
