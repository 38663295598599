import "./Editor.scss";
import React, { useCallback, useEffect, useState } from "react";
import { CardStyled } from "components";
import Tabs from "./Tabs/Tabs";
import GameSessions from "./GamesSessions/GamesSessions";
import UserProfile from "./UserProfile/UserProfile";
import UserCard from "./UserCard/UserCard";
import { useNavigate } from "react-router-dom";
import useSearchParamWorker from "helpers/useSearchParam";

import axios from "axios";
import PlanPlus from "./PlanPlus/PlanPlus";
import unityUserListApi from "API/unityUserListApi";
import { openAlertDialog } from "components/AlertDialog/AlertDialog";
import StatisticResultsView from "./StatisticResultsView/StatisticResultsView";
import { RefreshOutlined } from "@mui/icons-material";

import KnowledgesRateSetup from "./KnowledgesRateSetup/KnowledgesRateSetup";
import UserChoice from "./UserChoice/UserChoice";

import { useResourcesContext } from "resources";
import DepsGraph from "../../components/DepsGraph/DepsGraph";

const getEmptyKnResults = () => {
  return {
    knownList: [],
    knownListForMinorKnowledges: [],
    readyToControlList: [],
    readyToLearnList: [],
  };
};

const Editor = ({ id, className = "" }) => {
  const navigate = useNavigate();
  const searchParamWorker = useSearchParamWorker();
  const [unityUser, setUnityUser] = useState();

  const [leftColumn, setLeftColumn] = useState(searchParamWorker.getSearchParam("leftColumn") || "");
  const [rightColumn, setRightColumn] = useState(searchParamWorker.getSearchParam("rightColumn") || "");

  const [showUserDetails, setShowUserDetails] = useState(searchParamWorker.getSearchParam("showUserDetails"));

  const [knResultsRateBased, setKnResultsRateBased] = useState(getEmptyKnResults());
  const [trackingKnowledges, setTrackingKnowledges] = useState([]);

  const [gamesSets, setGamesSets] = useState([]); //{dateDDMMYYYY: "", gamesSets: []}
  const [gameSessions, setGameSessions] = useState([]); //{dateDDMMYYYY: "", gameSessions: []}

  const {
    imageListStore: { getImageList },
    knowledgeGroupListStore: { getKnowledgeGroupList },
    knowledgeTagListStore: { getKnowledgeTagList },
    soundSetListStore: { getSoundSetList },
    assetBundleListStore: { getAssetBundleList, getAssetBundleExtraDataList },
  } = useResourcesContext();

  useEffect(() => {
    getImageList();
    getSoundSetList();
    getAssetBundleList();
    getKnowledgeTagList();
    getKnowledgeGroupList();
    getAssetBundleExtraDataList();
  }, []);

  const [fetchingStatus, setFetchingStatus] = useState({
    fetchingUser: false,
    fetchingKnResultsRateBased: false,
    fetchingGameSets: false,
    fetchingGameSessionsDays: false,
    fetchingTrackingKnowledges: false,
  });

  const [tabs, setTabs] = useState([]);

  const updateFetchingStatus = useCallback((action, value) => {
    let statusName = "";
    switch (action) {
      case "user":
        statusName = "fetchingUser";
        break;
      case "resultsRateBased":
        statusName = "fetchingKnResultsRateBased";
        break;
      case "gameSessions":
        statusName = "fetchingGameSessions";
        break;
      case "gameSets":
        statusName = "fetchingGameSets";
        break;
      case "trackingKnowledges":
        statusName = "fetchingTrackingKnowledges";
        break;
      default:
        break;
    }

    statusName &&
      setFetchingStatus((prev) => {
        return { ...prev, [statusName]: value };
      });
  }, []);

  const fetchingStatusActive =
    fetchingStatus.fetchingUser || fetchingStatus.fetchingKnResultsRateBased || fetchingStatus.fetchingGameSets;

  const getGameSessionsDays = useCallback(
    (unityUser) => {
      if (!unityUser || fetchingStatus.fetchingGameSessionsDays) {
        return;
      }

      updateFetchingStatus("gameSessionsDays", true);
      axios
        .get(`/api/statistic/gameSessionsDays?unityUserId=${unityUser._id}`)
        .then((res) => res.data)
        .then((gameSessionsDays) => {
          updateFetchingStatus("gameSessionsDays", false);

          const datesTabs = gameSessionsDays.map((day) => {
            return {
              label: day,
              tabType: "gameSessions",
              gamesSessions: [],
            };
          });
          const newTabs = [
            ...datesTabs,
            { label: "План+", tabType: "planPlus" },
            { label: "Граф", tabType: "depsGraph" },
            { label: "Статистика", tabType: "statView" },
            { label: "Знання", tabType: "strengthSetup" },
            { label: "Вподобання", tabType: "userChoice" },
          ];

          setTabs(newTabs);
          setRightColumn((prev) => prev || "Граф");
        })
        .catch((error) => {
          updateFetchingStatus("gameSessionsDays", false);
          console.log(error);
        });
    },
    [fetchingStatus, updateFetchingStatus]
  );

  const getTrackingKnowledges = useCallback(
    (unityUser) => {
      if (!unityUser || fetchingStatus.fetchingTrackingKnowledges) {
        return;
      }

      updateFetchingStatus("trackingKnowledges", true);
      axios
        .post(`/api/statistic/trackingKnowledges?unityUserId=${unityUser._id}`, { knTagsIds: [] })
        .then((res) => res.data)
        .then((trackingKnowledges) => {
          updateFetchingStatus("trackingKnowledges", false);
          setTrackingKnowledges(trackingKnowledges);
        })
        .catch((error) => {
          updateFetchingStatus("trackingKnowledges", false);
          setTrackingKnowledges([]);
          console.log(error);
        });
    },
    [fetchingStatus, updateFetchingStatus]
  );

  const getKnResultsRateBased = useCallback(
    async (unityUser) => {
      if (!unityUser || fetchingStatus.fetchingKnResultsRateBased) {
        return;
      }

      updateFetchingStatus("resultsRateBased", true);
      axios
        .get(`/api/statistic/resultsRateBased?unityUserId=${unityUser._id}`)
        .then((res) => {
          const knLists = res.data;
          setKnResultsRateBased(knLists);
          updateFetchingStatus("resultsRateBased", false);
        })
        .catch((error) => {
          setKnResultsRateBased(getEmptyKnResults());
          updateFetchingStatus("resultsRateBased", false);
          console.log(error);
        });
    },
    [fetchingStatus, updateFetchingStatus]
  );

  const getGamesSets = async (unityUser, dateDDMMYYYY) => {
    if (unityUser && dateDDMMYYYY) {
      updateFetchingStatus("gameSets", true);
      axios
        .get(`/api/statistic/gamesSets?unityUserId=${unityUser._id}&dateDDMMYYYY=${dateDDMMYYYY}`)
        .then((res) => {
          const gamesSets = res.data;
          setGamesSets((prev) => [...prev, { dateDDMMYYYY, gamesSets }]);
          updateFetchingStatus("gameSets", false);
        })
        .catch((error) => {
          console.log(error);
          updateFetchingStatus("gameSets", false);
        });
    }
  };

  const getGameSessions = async (unityUser, dateDDMMYYYY) => {
    if (unityUser && dateDDMMYYYY) {
      updateFetchingStatus("gameSessions", true);
      axios
        .get(`/api/statistic/gameSessions?unityUserId=${unityUser._id}&dateDDMMYYYY=${dateDDMMYYYY}`)
        .then((res) => {
          const gameSessions = res.data;
          setGameSessions((prev) => [...prev, { dateDDMMYYYY, gameSessions }]);
          updateFetchingStatus("gameSessions", false);
        })
        .catch((error) => {
          console.log(error);
          updateFetchingStatus("gameSessions", false);
        });
    }
  };
  const getDayGameSets = (dateDDMMYYYY) => {
    const localGamesSets = gamesSets.find((elem) => elem.dateDDMMYYYY === dateDDMMYYYY);
    if (localGamesSets) {
      return localGamesSets.gamesSets;
    }
  };

  const getDayGameSessions = (dateDDMMYYYY) => {
    const localGameSessions = gameSessions.find((elem) => elem.dateDDMMYYYY === dateDDMMYYYY);
    if (localGameSessions) {
      return localGameSessions.gameSessions;
    }
  };

  useEffect(() => {
    if (leftColumn && tabs.find((tab) => tab.label === leftColumn)?.tabType === "gameSessions") {
      const localGameSessions = gameSessions.find((elem) => elem.dateDDMMYYYY === leftColumn);
      !localGameSessions && getGameSessions(unityUser, leftColumn);

      const localGamesSets = gamesSets.find((elem) => elem.dateDDMMYYYY === leftColumn);
      !localGamesSets && getGamesSets(unityUser, leftColumn);
    }
  }, [leftColumn, tabs]);

  useEffect(() => {
    if (rightColumn && tabs.find((tab) => tab.label === rightColumn)?.tabType === "gameSessions") {
      const localGameSessions = gameSessions.find((elem) => elem.dateDDMMYYYY === rightColumn);
      !localGameSessions && getGameSessions(unityUser, rightColumn);

      const localGamesSets = gamesSets.find((elem) => elem.dateDDMMYYYY === rightColumn);
      !localGamesSets && getGamesSets(unityUser, rightColumn);
    }
  }, [rightColumn, tabs]);

  const refreshStatistic = useCallback(
    (id) => {
      if (!id || fetchingStatusActive) return;
      updateFetchingStatus("user", true);
      unityUserListApi.getUnityUser(id).then((user) => {
        setUnityUser(user);
        setGameSessions([]);
        setGamesSets([]);
        if (user) {
          getKnResultsRateBased(user);
          getGameSessionsDays(user);
          getTrackingKnowledges(user);
        }
        setGameSessions([]);
        setGamesSets([]);
        updateFetchingStatus("user", false);
      });
    },
    [fetchingStatusActive, updateFetchingStatus, getGameSessionsDays, getKnResultsRateBased, getTrackingKnowledges]
  );

  useEffect(() => {
    if (id && !unityUser && !fetchingStatusActive) {
      refreshStatistic(id);
    }
  }, [id, unityUser, fetchingStatusActive, refreshStatistic]);

  const getTabLabel = (tabs, tabIndex) => tabs[tabIndex]?.label || "";
  const getTabByLabel = (tabs, tabLabel) => tabs.find((tab) => tab.label === tabLabel);

  const leftColumnTypeEqual = (tabs, typeName) => {
    return typeName && tabs.find((tab) => tab.label === leftColumn)?.tabType === typeName;
  };
  const rightColumnTypeEqual = (tabs, typeName) => {
    return typeName && tabs.find((tab) => tab.label === rightColumn)?.tabType === typeName;
  };

  const handleTabClick = (tab) => {
    if (!tab) {
      return;
    }

    if (
      tab.tabType === "statView" ||
      tab.tabType === "planPlus" ||
      tab.tabType === "strengthSetup" ||
      tab.tabType === "userChoice" ||
      tab.tabType === "depsGraph"
    ) {
      setLeftColumn("");
      setRightColumn(tab.label);
      searchParamWorker.setSearchParam("leftColumn", "");
      searchParamWorker.setSearchParam("rightColumn", tab.label);
      return;
    }

    const tabIndex = tabs.findIndex((elem) => elem.label === tab.label);

    if (tabIndex === 0 && tabs[1].tabType !== "gameSessions") {
      setLeftColumn(getTabLabel(tabs, 0));
      setRightColumn("");
      searchParamWorker.setSearchParam("leftColumn", getTabLabel(tabs, 0));
      searchParamWorker.setSearchParam("rightColumn", "");
      return;
    }

    if (tabIndex === 0) {
      setLeftColumn(getTabLabel(tabs, 0));
      setRightColumn(getTabLabel(tabs, 1));
      searchParamWorker.setSearchParam("leftColumn", getTabLabel(tabs, tabIndex));
      searchParamWorker.setSearchParam("rightColumn", getTabLabel(tabs, tabIndex + 1));
    } else {
      setLeftColumn(getTabLabel(tabs, tabIndex - 1));
      setRightColumn(getTabLabel(tabs, tabIndex));
      searchParamWorker.setSearchParam("leftColumn", getTabLabel(tabs, tabIndex - 1));
      searchParamWorker.setSearchParam("rightColumn", getTabLabel(tabs, tabIndex));
    }
  };

  const handleSaveLearningProgress = async (learningProgress, updatedElements) => {
    const newUnityUser = { ...unityUser, learningProgress };
    setUnityUser(newUnityUser);
    await unityUserListApi.updateUnityUserProgress(unityUser._id, learningProgress).then((user) => {
      if (!user) {
        return;
      }
      setUnityUser(user);
    });

    await axios
      .post(`/api/statistic/addCorrectionStatisticEvents?unityUserId=${unityUser._id}`, {
        updatedLearningProgressElements: updatedElements,
      })
      .catch((error) => {
        console.log(error);
      });

    getKnResultsRateBased(unityUser);
  };

  const handleSave = useCallback(
    (unityUser) => {
      return unityUserListApi.updateUnityUser(unityUser._id, unityUser).then(() => {
        if (rightColumn === "План+") {
          handleTabClick(getTabByLabel(tabs, "План+"));
        }
      });
    },
    [unityUser, rightColumn, tabs]
  );

  const copyUnityUser = async (unityUser) => {
    setLeftColumn("");
    setRightColumn("");

    setTabs([]);
    setUnityUser(null);
    updateFetchingStatus("user", true);
    unityUserListApi
      .cloneUnityUser(unityUser._id)
      .then((userClone) => {
        updateFetchingStatus("user", false);
        navigate("/unity-user-editor/" + userClone._id);
      })
      .catch(() => navigate("/unity-users"));
  };

  const openDeleteUserDialog = (unityUser) => {
    openAlertDialog(
      "Видалення користувача",
      "Натисніть Ok для видалення користувача, або Cancel для відміни операції.",
      async () => {
        unityUser._id && (await unityUserListApi.deleteUnityUser(unityUser._id));
        navigate("/unity-users");
      },
      () => {}
    );
  };

  const openClearUserStatisticDialog = (unityUser) => {
    openAlertDialog(
      "Видалення статистики користувача",
      "Натисніть Ok для видалення  статистики користувача, або Cancel для відміни операції.",
      async () => {
        unityUser._id &&
          fetch(`/api/statistic?unityUserId=${unityUser._id}`, { method: "DELETE" }).then(() => {
            refreshStatistic(unityUser._id);
          });
      },
      () => {}
    );
  };

  const handlePlanPlusChange = (newPlanPlus) => {
    const newUnityUser = { ...unityUser, planPlus: newPlanPlus };
    setUnityUser(newUnityUser);
    handleSave(newUnityUser);
  };

  return (
    <div className={`userEditor-component ${className}`}>
      <UserCard
        className="user-card-block"
        unityUser={unityUser}
        selected={showUserDetails}
        onClick={() => setShowUserDetails(!showUserDetails)}
      />

      <div
        className={`header-refreshDataButton ${fetchingStatusActive ? "spin-animation" : ""}`}
        onClick={() => refreshStatistic(id)}
      >
        <RefreshOutlined />
      </div>

      <div className="middle-content">
        {showUserDetails && (
          <div className="user-profile-block">
            <UserProfile
              unityIdBlocked={gamesSets.length || tabs.length > 3}
              unityUser={unityUser}
              onUserChange={(newUnityUser) => {
                setUnityUser(newUnityUser);
                handleSave(newUnityUser);
              }}
              onDeleteUser={() => openDeleteUserDialog(unityUser)}
              onCopyUser={() => copyUnityUser(unityUser)}
              onClearStatistic={() => openClearUserStatisticDialog(unityUser)}
            />
          </div>
        )}

        {!showUserDetails && (
          <>
            <CardStyled className="tabs-block">
              <Tabs
                tabs={tabs}
                selectedTabs={[getTabByLabel(tabs, leftColumn), getTabByLabel(tabs, rightColumn)]}
                onClick={handleTabClick}
                searchParamWorker={searchParamWorker}
              />
            </CardStyled>

            {rightColumnTypeEqual(tabs, "statView") && (
              <CardStyled className="statView-column">
                <StatisticResultsView
                  unityUser={unityUser}
                  knResultsRateBased={knResultsRateBased}
                  trackingKnowledges={trackingKnowledges}
                />
              </CardStyled>
            )}

            {rightColumnTypeEqual(tabs, "strengthSetup") && (
              <CardStyled className="statView-column">
                <KnowledgesRateSetup
                  unityUser={unityUser}
                  knResultsRateBased={knResultsRateBased}
                  onLearningProgressChange={(newLearningProgress, updatedElements) => {
                    handleSaveLearningProgress(newLearningProgress, updatedElements);
                  }}
                />
              </CardStyled>
            )}
            {rightColumnTypeEqual(tabs, "planPlus") && (
              <CardStyled className="planPlus-column">
                <PlanPlus
                  unityUser={unityUser}
                  onChange={handlePlanPlusChange}
                  knResultsRateBased={knResultsRateBased}
                />
              </CardStyled>
            )}
            {rightColumnTypeEqual(tabs, "userChoice") && (
              <CardStyled className="userChoice-column">
                <UserChoice unityUser={unityUser} />
              </CardStyled>
            )}

            {rightColumnTypeEqual(tabs, "depsGraph") && (
              <CardStyled className="depsGraph-column">
                <DepsGraph unityUser={unityUser} knResultsRateBased={knResultsRateBased} />
              </CardStyled>
            )}

            {!rightColumnTypeEqual(tabs, "planPlus") &&
              !rightColumnTypeEqual(tabs, "statView") &&
              !rightColumnTypeEqual(tabs, "strengthSetup") &&
              !rightColumnTypeEqual(tabs, "userChoice") &&
              !rightColumnTypeEqual(tabs, "depsGraph") && (
                <div className="columns">
                  <CardStyled className={`left-column }`}>
                    <div className="column-header">
                      <div className="left-button-block"></div>
                      <span className="label">{leftColumn}</span>
                      <div className="right-button-block"></div>
                    </div>
                    {leftColumnTypeEqual(tabs, "gameSessions") && (
                      <GameSessions
                        gamesSessions={getDayGameSessions(leftColumn)}
                        gamesSets={getDayGameSets(leftColumn)}
                      />
                    )}
                  </CardStyled>
                  <CardStyled className={`right-column }`}>
                    <div className="column-header">
                      <div className="left-button-block"></div>
                      <span className="label">{rightColumn}</span>
                      <div className="right-button-block"></div>
                    </div>
                    {rightColumnTypeEqual(tabs, "gameSessions") && (
                      <GameSessions
                        gamesSessions={getDayGameSessions(rightColumn)}
                        gamesSets={getDayGameSets(rightColumn)}
                      />
                    )}
                  </CardStyled>
                </div>
              )}
          </>
        )}
      </div>
    </div>
  );
};
export default Editor;
