import axios from "axios";

const unityUserListApi = {
  getList: (searchParams = '', page = 1, limit = 20) => {
    const params = new URLSearchParams(searchParams);

    return axios
      .get(`/api/unityUsers?${params.toString()}`)
      .then((res) => res.data)
      .catch(() => []);
  },
  createUnityUserCustomer: (unityUserFields) => {
    return axios
      .post(`/api/unityUsers/customer`, JSON.stringify(unityUserFields), {
        headers: { "Content-Type": "application/json" },
      })

  },
  createUnityUser: (unityUserFields) => {
    return axios
      .post(`/api/unityUsers`, JSON.stringify(unityUserFields), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => res.data)
      .then((createdUnityUser) => {
        return createdUnityUser;
      })
      .catch(() => {
        return null;
      });
  },

  updateUnityUser: (unityUserId, unityUserFields) => {
    return axios
      .put(`/api/unityUsers/${unityUserId}`, JSON.stringify(unityUserFields), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => res.data)
      .then((updatedUnityUser) => {
        return updatedUnityUser;
      })
      .catch(() => {
        return null;
      });
  },

  updateUnityUserProgress: (unityUserId, learningProgress) => {
    return axios
      .put(`/api/unityUsers/learningProgress/${unityUserId}`, JSON.stringify({learningProgress}), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => res.data)
      .then((updatedUnityUser) => {
        return updatedUnityUser;
      })
      .catch(() => {
        return null;
      });
  },

  cloneUnityUser: (unityUserId) => {
    return axios
      .get(`/api/unityUsers/copy/${unityUserId}`)
      .then((res) => res.data)
      .then((userClone) => {
        return userClone;
      })
      .catch(() => {
        return null;
      });
  },

  deleteUnityUser: (unityUserId) => {
    return axios
      .delete(`/api/unityUsers/${unityUserId}`)
      .then((res) => res.data)
      .then((deletedUnityUser) => {
        return deletedUnityUser;
      })
      .catch(() => {
        return null;
      });
  },

  getUnityUser: (unityUserId) => {
    return axios
      .get(`/api/unityUsers/${unityUserId}`)
      .then((res) => res.data)
      .catch(() => {
        return null;
      });
  },
};

export default unityUserListApi;
