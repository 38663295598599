import "./LogIn.scss";
import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { CardStyled, StyledButton } from "components";
import { useAuthz } from "../../../auth/AuthzProvider";
import logo from "../../../assets/app-logo.png";
import { sendGtagData } from "helpers/sendGtagEvent";
import { ROLE_CLIENT, ROLE_TEACHER } from "constants/constants";

export default function Login() {
  const { user, login } = useAuthz();
  const navigate = useNavigate();
  const location = useLocation();

  const userRole = user?.roles?.[0];
  const redirectedFrom = location.state?.from;

  if (userRole) {
    switch (userRole) {
      case ROLE_CLIENT:
        navigate(redirectedFrom || "/profile");
        break;
      case ROLE_TEACHER:
        navigate(redirectedFrom || "/dashboard");
        break;
      default:
        navigate(redirectedFrom || "/");
        break;
    }
  }

  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [message, setMessage] = useState(null);

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      await login(email, password);
      sendGtagData("event", "user_login_success");
    } catch (error) {
      sendGtagData("event", "user_login_failure");
      setMessage("Невірні email або пароль");
    }
  };

  return (
    <div className="login-page-wrapper">
      <div className="login-wrapper">
        <div className="login-title">
          <img src={logo} alt={"logo"} />
          Долучайся до родини
        </div>
        <CardStyled className="button-box">
          {message && <div className="message">{message}</div>}
          <form className="login-form">
            <label>
              <p className="login-lable">Введіть поштовий адрес</p>
              <input
                className="input"
                type="email"
                value={email}
                onChange={(event) => setEmail(event.target.value.toLowerCase())}
                required
              />
            </label>
            <label>
              <p className="login-lable">Введіть пароль</p>
              <input className="input" type="password" onChange={(event) => setPassword(event.target.value)} required />
            </label>
            <StyledButton title="Увійти" className="customize-button" type="submit" onClick={handleLogin} />
          </form>
          <div className="link-box">
            <Link className="registration-link" to="/forgotPassword">
              Забули пароль?
            </Link>
            <Link className="registration-link" to="/registration">
              Реєстрація
            </Link>
          </div>
        </CardStyled>
      </div>
    </div>
  );
}
