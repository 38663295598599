import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as createId } from "uuid";
import _, { isArray } from "lodash";
import { useAuthz } from "auth/AuthzProvider";

const createEmptyAssetBundle = (name = "") => {
  return {
    _id: createId(),
    name: name,
    description: "",
    icon: "",
    sounds: { correctSoundSets: [], endSoundSet: "", incorrectSoundSets: [], startSoundSet: "" },
    scenes: [{ items: [] }],
    knowledges: [
      {
        soundSet: "",
        wrongSoundSets: [],
        items: [],
      },
    ],
  };
};

const useAssetBundleList = () => {
  const { user } = useAuthz();
  const [list, setList] = useState([]);
  const [editedList, setEditedList] = useState([]);
  const [extraDataList, setExtraDataList] = useState([]);
  const [fetchingList, setFetchingList] = useState(false);
  const [fetchingExtraDataList, setFetchingExtraDataList] = useState(false);
  const [fetchingOne, setFetchingOne] = useState(false);

  const listWithExtraData = useMemo(() => {
    if (list) {
      return list.map((assetBundle) => {
        const extraData = extraDataList.find((extraData) => extraData._id === assetBundle._id);
        return { ...assetBundle, ...extraData };
      });
    } else {
      return [];
    }
  }, [list, extraDataList]);

  const getAssetBundleList = useCallback(() => {
    setFetchingList(true);
    fetch(`/api/assetBundles`, { method: "GET" })
      .then((res) => (res.status !== 200 ? [] : res.json()))
      .then((assetBundles) => {
        setList(isArray(assetBundles) ? assetBundles : []);
        setFetchingList(false);
        return isArray(assetBundles) ? assetBundles : [];
      })
      .catch((error) => {
        setList([]);
        setFetchingList(false);
        return [];
      });
  }, []);

  const getAssetBundleExtraDataList = () => {
    setFetchingExtraDataList(true);

    fetch(`/api/assetBundles-extradata`, { method: "GET" })
      .then((res) => (res.status !== 200 ? [] : res.json()))
      .then((extraDataList) => {
        setExtraDataList(isArray(extraDataList) ? extraDataList : []);
        setFetchingExtraDataList(false);
      })
      .catch((error) => {
        setExtraDataList([]);
        setFetchingExtraDataList(false);
      });
  };

  const isNewAssetBundle = (assetBundleId) => {
    return Boolean(!list.some((assetBundle) => assetBundle._id === assetBundleId));
  };

  const isEditedAssetBundle = useCallback(
    (assetBundleId) => {
      return Boolean(editedList.some((assetBundle) => assetBundle._id === assetBundleId));
    },
    [editedList]
  );

  const getAssetBundleRemote = (assetBundleId) => {
    return fetch(`/api/assetBundles/${assetBundleId}`, { method: "GET" })
      .then((res) => (res.status !== 200 ? null : res.json()))
      .catch(() => null);
  };

  const getAssetBundleExtraDataRemote = (assetBundleId) => {
    return fetch(`/api/assetBundles-extraData/${assetBundleId}`, { method: "GET" })
      .then((res) => (res.status !== 200 ? null : res.json()))
      .catch(() => null);
  };

  const getAssetBundleAndExtraDataRemote = async (assetBundleId) => {
    const assetBundle = await getAssetBundleRemote(assetBundleId);
    const assetBundleExtraData = await getAssetBundleExtraDataRemote(assetBundleId);
    return { assetBundle, extradata: assetBundleExtraData };
  };

  const getAssetBundleForEditing = async (assetBundleId) => {
    const editedAssetBundle = editedList.find((assetBundle) => assetBundle._id === assetBundleId);

    if (editedAssetBundle) {
      let assetBundleExtraData;
      if (!isNewAssetBundle(editedAssetBundle._id)) {
        assetBundleExtraData =
          extraDataList.find((extraData) => extraData._id === assetBundleId) ||
          (await getAssetBundleExtraDataRemote(assetBundleId));
      }
      return { assetBundle: editedAssetBundle, extraData: assetBundleExtraData };
    }

    const assetBundleAndExtraData = await getAssetBundleAndExtraDataRemote(assetBundleId);
    return assetBundleAndExtraData;
  };

  const deleteAssetBundleFromEditedList = (assetBundleId) => {
    setEditedList(editedList.filter((assetBundle) => assetBundle._id !== assetBundleId));
  };

  const deleteAssetBundleFromList = (assetBundleId) => {
    setList(list.filter((assetBundle) => assetBundle._id !== assetBundleId));
  };

  const deleteAssetBundleFromLists = (assetBundleId) => {
    deleteAssetBundleFromEditedList(assetBundleId);
    deleteAssetBundleFromList(assetBundleId);
  };

  const deleteAssetBundle = async (assetBundleId) => {
    return new Promise((resolve, reject) => {
      if (assetBundleId) {
        setFetchingOne(assetBundleId);
        fetch(`/api/assetBundles/${assetBundleId}`, { method: "DELETE" })
          .then(() => {
            setFetchingOne(false);
            deleteAssetBundleFromLists(assetBundleId);
            resolve();
          })
          .catch((error) => {
            setFetchingOne(false);
            console.log(error.message);
            resolve();
          });
      } else {
        resolve();
      }
    });
  };

  const createNewAssetBundle = () => {
    const newAssetBundle = createEmptyAssetBundle();
    setEditedList([...editedList, newAssetBundle]);
    return newAssetBundle;
  };

  const copyAssetBundle = async (assetBundle) => {
    let newAssetBundle = {
      ..._.cloneDeep(assetBundle),
      _id: createId(),
      name: assetBundle.name + " копія",
      icon: "",
    };

    setEditedList([...editedList, newAssetBundle]);
    return newAssetBundle;
  };

  const updateEditedAssetBundle = (editedAssetBundle) => {
    setEditedList([
      ...editedList.filter((assetBundle) => assetBundle._id !== editedAssetBundle._id),
      editedAssetBundle,
    ]);
  };

  const createAssetBundle = async (assetBundle) => {
    setFetchingOne(assetBundle._id);

    const fields = {
      ...assetBundle,
      icon: undefined,
      createdBy: user?.email,
    };

    return fetch(`/api/assetBundles`, {
      method: "POST",
      body: JSON.stringify(fields),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        setFetchingOne(false);
        return res.json();
      })
      .catch(() => {
        setFetchingOne(false);
        return null;
      });
  };

  const updateAssetBundle = useCallback(
    async (assetBundle) => {
      setFetchingOne(assetBundle._id);
      const fields = {
        ...assetBundle,
        icon: undefined,
        updatedBy: user?.email,
      };

      return fetch(`/api/assetBundles/${assetBundle._id}`, {
        method: "PUT",
        body: JSON.stringify(fields),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (res.ok) {
            setFetchingOne(false);
            return res.json();
          } else {
            setFetchingOne(false);
            return null;
          }
        })
        .catch(() => {
          setFetchingOne(false);
          return null;
        });
    },
    [user]
  );

  const updateAssetBundleIcon = async (assetBundleIconBlob, assetBundleId) => {
    if (typeof assetBundleIconBlob === "object" && assetBundleId) {
      setFetchingOne(assetBundleId);
      const files = new FormData();
      files.append("iconFile", assetBundleIconBlob);
      return fetch(`/api/assetBundles/${assetBundleId}`, {
        method: "PUT",
        body: files,
        headers: {},
      })
        .then((res) => {
          setFetchingOne(false);
          return res.json();
        })
        .catch(() => {
          setFetchingOne(false);
          return null;
        });
    } else {
      return null;
    }
  };

  const updateOneAssetBundleExtraData = (assetBundleExtraData) => {
    assetBundleExtraData?._id &&
      setExtraDataList([
        ...extraDataList.filter((extraData) => extraData._id !== assetBundleExtraData._id),
        assetBundleExtraData,
      ]);
  };

  const saveAssetBundle = async (assetBundle) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        let savedAssetBundle = isNewAssetBundle(assetBundle._id)
          ? await createAssetBundle(assetBundle)
          : await updateAssetBundle(assetBundle);

        if (savedAssetBundle) {
          let savedAssetBundleWithIcon = await updateAssetBundleIcon(assetBundle.icon, savedAssetBundle._id);
          deleteAssetBundleFromEditedList(assetBundle._id);
          getAssetBundleList();
          const extraData = await getAssetBundleExtraDataRemote(savedAssetBundle._id)
            .then((res) => {
              setFetchingOne(false);
              if (res.ok) {
                res._id && updateOneAssetBundleExtraData(res);
                return res;
              } else {
                return null;
              }
            })
            .catch(() => {
              setFetchingOne(false);
              return null;
            });
          resolve({
            assetBundle: savedAssetBundleWithIcon || savedAssetBundle,
            extraData: extraData,
          });
        } else {
          setFetchingOne(false);
          resolve({
            assetBundle: null,
            extraData: null,
          });
        }
      } catch (error) {
        setFetchingOne(false);
        resolve({
          assetBundle: null,
          extraData: null,
        });
      }
    });
  };

  const bundleUseSoundSet = useCallback((bundle, soundSetId) => {
    return (
      bundle.sounds?.startSoundSet === soundSetId ||
      bundle.sounds?.endSoundSet === soundSetId ||
      bundle.sounds?.correctSoundSets?.includes(soundSetId) ||
      bundle.sounds?.incorrectSoundSets?.includes(soundSetId) ||
      bundle.knowledges?.some((knowledge) => knowledge.soundSet === soundSetId)
    );
  }, []);

  const getBundlesWithSoundSet = useCallback(
    (soundSetId) => {
      return [...list, ...editedList]
        .filter((bundle) => {
          return bundleUseSoundSet(bundle, soundSetId);
        })
        .reduce((resultBundles, bundle) => {
          if (resultBundles.some((resBundle) => resBundle._id === bundle._id)) {
            return [...resultBundles];
          } else {
            return [...resultBundles, bundle];
          }
        }, []);
    },
    [list, editedList, bundleUseSoundSet]
  );

  const getBundlesWithImage = useCallback(
    (imageId) => {
      const itemUseImageRecursive = (item, imageId) => {
        return item._id === imageId || item.items?.some((item) => itemUseImageRecursive(item, imageId));
      };

      return [...list, ...editedList]
        .filter((bundle) => {
          return (
            bundle.icon === imageId ||
            bundle.knowledges?.some((knowledge) =>
              knowledge.items?.some((item) => itemUseImageRecursive(item, imageId))
            ) ||
            bundle.scenes?.some((scene) => scene.items?.some((item) => itemUseImageRecursive(item, imageId)))
          );
        })
        .reduce((resultBundles, bundle) => {
          if (resultBundles.some((resBundle) => resBundle._id === bundle._id)) {
            return [...resultBundles];
          } else {
            return [...resultBundles, bundle];
          }
        }, []);
    },
    [list, editedList]
  );

  const updateAssetBundleLocal = (assetBundle) => {
    setList(list.map((bundle) => (bundle._id !== assetBundle._id ? bundle : assetBundle)));
  };

  return {
    assetBundleList: listWithExtraData,
    editedAssetBundleList: editedList,
    getAssetBundleForEditing,
    updateAssetBundleLocal,
    updateEditedAssetBundle,
    deleteAssetBundleFromEditedList,
    copyAssetBundle,
    deleteAssetBundle,
    saveAssetBundle,
    updateAssetBundle,
    createNewAssetBundle,
    isNewAssetBundle,
    isEditedAssetBundle,
    getAssetBundleList,
    getAssetBundleExtraDataList, 
    getBundlesWithSoundSet,
    getBundlesWithImage,

    fetchingList: fetchingList,
    fetchingExtraDataList: fetchingExtraDataList,
    fetchingOne: fetchingOne,
  };
};

export default useAssetBundleList;
