import "./SoundsBox.scss";
import {
  AlertDialog,
  AudioRecorderModal,
  DropdownSelector,
  SoundSelector,
  StyledButton,
  StyledInput,
  Waveform,
} from "components";
import { languages, soundSetTypes } from "constants/constants";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useResourcesContext } from "resources";
import { v4 as newUuid } from "uuid";
import FindReplaceOutlinedIcon from "@mui/icons-material/FindReplaceOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { ReactComponent as MicIcon } from "assets/icons/RecorderMic.svg";

const SoundsBox = ({ title, sounds, onChangeSounds }) => {
  const [alertDialogData, setAlertDialogData] = useState(null);
  const [audioRecorderConfig, setAudioRecorderConfig] = useState(null);
  const [soundName0, setSoundName0] = useState(sounds[0]?.name || "");
  const [soundName1, setSoundName1] = useState(sounds[1]?.name || "");
  const [soundName2, setSoundName2] = useState(sounds[2]?.name || "");

  const {
    soundListStore: { soundList, updateSound, createSound, deleteSounds },
  } = useResourcesContext();

  const fullSoundsSet = useMemo(() => {
    return [...sounds, null, null, null].slice(0, 3);
  }, [sounds]);

  useEffect(() => {
    setSoundName0(sounds[0]?.name || "");
    setSoundName1(sounds[1]?.name || "");
    setSoundName2(sounds[2]?.name || "");
  }, [sounds]);

  const handleSoundSelectorChange = useCallback(
    (prevSound, selectedSound) => {
      let newSounds = [...sounds.filter(Boolean)];

      if (!prevSound && selectedSound) {
        newSounds = [...sounds.filter(Boolean), selectedSound];
      }
      if (prevSound && !selectedSound) {
        newSounds = [...sounds.filter(Boolean).filter((sound) => sound._id !== prevSound._id)];
      }
      if (prevSound && selectedSound) {
        newSounds = sounds.filter(Boolean).map((sound) => (sound._id !== prevSound._id ? sound : selectedSound));
      }
      onChangeSounds(newSounds);
    },
    [sounds, onChangeSounds]
  );

  const handleUpdateSound = useCallback(
    (sound) => {
      updateSound(sound, { assignUser: true }).then((updatedSound) => {
        updatedSound && handleSoundSelectorChange(sound, updatedSound);
      });
    },
    [handleSoundSelectorChange, updateSound]
  );

  const updateSoundDebounced = useMemo(() => _.debounce(handleUpdateSound, 1000), [handleUpdateSound]);

  const getNextLanguage = () => {
    const usedLanguages = sounds.map((sound) => sound?.language).filter(Boolean);

    if (usedLanguages.includes("ru") && usedLanguages.includes("uk")) {
      return "en";
    }
    if (usedLanguages.includes("ru")) {
      return "uk";
    }
    if (usedLanguages.includes("uk")) {
      return "ru";
    }
    return "";
  };

  const recordSound = (soundTemplate, callback, language) => {
    setAudioRecorderConfig({
      soundName: soundTemplate?.name || "",
      soundLanguage: language || "uk",
      onConfirm: (soundBlob, soundUrl, soundName, soundDuration, language) => {
        setAudioRecorderConfig(null);
        const fileName = `${newUuid()}`;
        const fullFileName = fileName + "." + soundBlob.type.split("/")[1];
        const newSound = {
          name: soundName || soundTemplate?.name || "Новий звук",
          type: soundTemplate?.type || "",
          path: new File([soundBlob], fullFileName, { type: soundBlob.type }),
          duration: soundDuration,
          language: language,
        };
        callback(newSound);
      },
      onCancel: () => setAudioRecorderConfig(null),
    });
  };

  const handleKnowledgeSoundRecorded = async (prevSound, recordedSound) => {
    if (prevSound) {
      handleUpdateSound({
        ...prevSound,
        name: recordedSound.name || prevSound.name || "",
        path: recordedSound.path,
        duration: recordedSound.duration,
        language: recordedSound.language,
      });
    }

    if (!prevSound) {
      const createdSound = await createSound(recordedSound, { assignUser: true });
      createdSound && handleSoundSelectorChange(prevSound, createdSound);
    }
  };

  const handleDownloadSound = (sound) => {
    if (sound.path) {
      fetch(sound.path)
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = (sound.name || sound.originalFileName || "noNameSound") + ".ogg";
          link.click();
        })
        .catch(console.error);
    }
  };

  const mergeSound = async (sound) => {
    const soundsToReplace = soundList.filter(
      (listSound) =>
        listSound.name.trim().toLowerCase() === sound.name.trim().toLowerCase() &&
        listSound.language === sound.language &&
        listSound._id !== sound._id
    );
    await deleteSounds(soundsToReplace.map((elem) => elem._id));
  };

  const openMergeSoundDialog = (sound) => {
    setAlertDialogData({
      onOk: () => {
        mergeSound(sound);
        setAlertDialogData(null);
      },
      onCancel: () => setAlertDialogData(null),
      title: "Видалення зайвих звуків з бібліотеки",
      message: "Натисніть Ок для видалення, або Cancel для відміни операції.",
    });
  };

  const soundCanBeMerged = (sound) => {
    const res = Boolean(
      soundList.filter(
        (listSound) =>
          listSound._id !== sound._id &&
          listSound.name.trim().toLowerCase() === sound.name.trim().toLowerCase() &&
          listSound.language === sound.language
      ).length
    );

    return res;
  };

  const getSoundSetTypeLabel = (typeName) => {
    if (!typeName) {
      return "";
    }

    return soundSetTypes.find((soundType) => soundType.name === typeName)?.label || "";
  };

  return (
    <div className="soundsBox-component">
      {title ? (
        <div className="header">
          <div className="title">{title}</div>
          <div className="buttons"></div>
        </div>
      ) : null}
      <div className="sounds">
        {fullSoundsSet.map((sound, index) => (
          <div className="sound" key={index}>
            <div className={"first-row"}>
              <DropdownSelector
                className={`soundLanguage-selector ${
                  sound?.language &&
                  sounds.some((knSound) => knSound !== sound && knSound?.language === sound?.language)
                    ? "alarm-color"
                    : ""
                }`}
                disabled={!sound}
                withNone={false}
                options={languages}
                useNameAsLabel
                uppercase
                selectedOption={
                  languages.find((lang) => {
                    return lang.name === sound?.language;
                  }) || ""
                }
                onChange={(value) => {
                  const newValue = value?.name || "";
                  handleUpdateSound({ ...sound, language: newValue });
                }}
              />

              <StyledInput
                className="soundName-input"
                disabled={!sound}
                value={index === 0 ? soundName0 : index === 1 ? soundName1 : soundName2}
                placeholder=""
                onChange={(e) => {
                  index === 0 && setSoundName0(e.target.value);
                  index === 1 && setSoundName1(e.target.value);
                  index === 2 && setSoundName2(e.target.value);
                  updateSoundDebounced({ ...sound, name: e.target.value });
                }}
              />
              <SoundSelector
                key={"knowledge"}
                className="soundSelector"
                value={sound}
                sounds={soundList}
                createSoundInDatabase={false}
                onChangeValue={(selectedSound) =>
                  selectedSound?._id !== sound?._id && handleSoundSelectorChange(sound, selectedSound)
                }
              />

              <StyledButton
                tooltipProps={{ title: `${sound ? "Перезаписати звук" : "Записати звук"}` }}
                title=""
                disabled={false}
                onClick={() => {
                  const language = sound?.language || getNextLanguage();
                  recordSound(sound, (recordedSound) => handleKnowledgeSoundRecorded(sound, recordedSound), language);
                }}
                className="customize-button soundEditor-round-button"
              >
                <MicIcon className="button-icon-mic" />
              </StyledButton>

              <StyledButton
                tooltipProps={sound ? { title: "Зберегти на диск " } : { title: "Звук не обрано" }}
                title=""
                disabled={!sound}
                onClick={() => handleDownloadSound(sound)}
                className="customize-button soundEditor-round-button"
              >
                <FileDownloadOutlinedIcon className="button-icon" />
              </StyledButton>
              <StyledButton
                tooltipProps={sound ? { title: "Видалити повторні" } : { title: "Звук не обрано" }}
                title=""
                disabled={!sound || !soundCanBeMerged(sound)}
                onClick={() => openMergeSoundDialog(sound)}
                className="customize-button soundEditor-round-button"
              >
                <FindReplaceOutlinedIcon className="button-icon" />
              </StyledButton>
            </div>

            <div className={"second-row"}>
              <Waveform className="waveform" soundPath={sound?.path} duration={sound?.duration || 0} />
              <div className="info-col">
                <div className="soundDuration-label">{`Тривалість : ${(sound?.duration || 0) / 1000} сек.`}</div>
                <div className="soundAuthor-label">{`Автор: ${sound?.user || ""}`}</div>
                {getSoundSetTypeLabel(sound?.type) && (
                  <div className="soundType-label">{`Тип звуку: ${getSoundSetTypeLabel(sound?.type)}`}</div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      <AlertDialog alertDialogData={alertDialogData} />
      <AudioRecorderModal audioRecorderConfig={audioRecorderConfig} />
    </div>
  );
};

export default SoundsBox;
