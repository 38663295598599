import "./UserCard.scss";
import PersonIcon from "@mui/icons-material/Person";

const UserCard = ({ unityUser, selected, onClick, className = "" }) => {
  return (
    <div
      className={`user-card-component ${className} ${selected ? "user-card-component-selected" : ""}`}
      onClick={onClick}
    >
      <div className="user-avatar">
        <PersonIcon />
      </div>

      <div className="user-name-block">
        <div className="user-name">{unityUser?.name || "Ім'я не вказано"}</div>
      </div>

      <div className="unity-language-block">

        <div className="language">{unityUser?.language.toUpperCase() || ""}</div>
      </div>
    </div>
  );
};

export default UserCard;
