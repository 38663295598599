import { useResourcesContext } from "resources";
import "./KnowledgeGroupTile.scss";

const KnowledgeGroupTile = ({ knowledgeGroup, selected, onClick }) => {
  const {
    knowledgeGroupListStore: { knowledgeGroupList },
  } = useResourcesContext();
  const dependsOnGroups = knowledgeGroupList.filter((group) => knowledgeGroup.dependsOnGroups.includes(group._id));

  return (
    <div
      key={knowledgeGroup?._id || 0}
      className={`knowledgeGroupTile-component ${selected ? "selected" : ""} ${
        knowledgeGroup.knowledgeTags?.length ? "" : "backgroundAlert"
      }`}
      onClick={() => {
        onClick && onClick(knowledgeGroup);
      }}
    >
      <div className="groupName">{knowledgeGroup.name}</div>
      <div className="deps">
        {dependsOnGroups.map((group) => (
          <div key={group._id} className="dep">
            {group.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default KnowledgeGroupTile;
