import axios from "axios";

const itemTemplatesApi = {
  getAll: () => {
    return axios.get("/api/itemTemplates").then((res) => res.data);
  },

  deleteOne: (id) => {
    return axios.delete(`/api/itemTemplates/${id}`).then((res) => res.data);
  },

  create: (fields) => {
    return axios
      .post(`/api/itemTemplates`, JSON.stringify(fields), {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => res.data);
  },

  
};

export default itemTemplatesApi;
