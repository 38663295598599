import "./ItemUniversal.scss";

import React, { useEffect, useMemo, useRef } from "react";
import { ReactComponent as HasASoundIcon } from "assets/icons/HasASound.svg";
import { useResourcesContext } from "resources";
import { Tooltip } from "@mui/material";

const ItemUniversal = ({
  item,
  backgroundSize,
  onMouseDown,
  onMouseMove,
  onPointerLeave,
  onClick,
  onMouseWheel,
  imageClassName,
  freeze,
  moving,
  showSoundIcon = true,
  showUnderlayer = false,
  showShadowlayer = true,
}) => {
  const imageRef = useRef();

  const {
    imageListStore: { getImagePathById, getImageById },
  } = useResourcesContext();

  const getItemPosition = (itemImage, backgroundSize, item) => {
    if (itemImage && backgroundSize) {
      const itemHeight = ((itemImage.height / 100) * (item.scale || 100)) / backgroundSize.scale1920;
      const itemWidth = ((itemImage.width / 100) * (item.scale || 100)) / backgroundSize.scale1920;
      const position = {
        height: Math.round(itemHeight),
        width: Math.round(itemWidth),
        top: Math.round(
          backgroundSize.clientHeight / 2 - (item.valueY || 0) / backgroundSize.scale1920 - itemHeight / 2
        ),
        left: Math.round(
          (item.valueX || 0) / backgroundSize.scale1920 + backgroundSize.clientWidth / 2 - itemWidth / 2
        ),
      };
      return position;
    }
    return null;
  };

  const itemImage = useMemo(() => {
    return item ? getImageById(item._id) : null;
  }, [item, getImageById]);

  const itemPosition = useMemo(
    () => getItemPosition(itemImage, backgroundSize, item),
    [itemImage, backgroundSize, item]
  );

  useEffect(() => {
    if (item?.stageItemId) {
      imageRef.current && imageRef.current.style.setProperty("--stroke-color", item.outline);
    }
  }, [item.outline, item.stageItemId]);

  const underlayerPath = useMemo(() => getImagePathById(item.underlayer?._id), [getImagePathById, item]);
  const shadowlayerPath = useMemo(() => getImagePathById(item.shadowlayer?._id), [getImagePathById, item]);
  const shadowlayerImage = useMemo(() => getImageById(item.shadowlayer?._id), [getImageById, item]);

  return (
    <>
      {!itemPosition && (
        <div className="item-universal-wrapper">
          <div
            className="itemError"
            style={{
              position: "absolute",
              zIndex: 2000,
              top: backgroundSize?.clientHeight ? backgroundSize.clientHeight / 2 - 25 + "px" : "0px",
              left: backgroundSize?.clientWidth ? backgroundSize.clientWidth / 2 - 70 + "px" : "0px",
            }}
          >
            Item with error
          </div>
        </div>
      )}
      {itemPosition && (
        <>
          <div
            id={"item " + item.stageItemId}
            className="item-universal-wrapper"
            onWheel={(event) => !freeze && onMouseWheel && onMouseWheel(event)}
            onPointerLeave={onPointerLeave}
            onMouseDown={(event) => !freeze && onMouseDown(event)}
            onMouseMoveCapture={(e) => e.preventDefault()}
            onMouseMove={(event) => !freeze && onMouseMove(event)}
            onClick={(e) => (onClick ? onClick(item) : "")}
            style={{
              position: "absolute",
              zIndex: moving ? 2000 : "auto",
              top: itemPosition ? itemPosition.top + "px" : "0px",
              left: itemPosition ? itemPosition.left + "px" : "0px",
              width: itemPosition ? itemPosition.width : "",
              height: itemPosition ? itemPosition.height : "",
              transform: `rotate(${item.angle ? item.angle : 0}deg) scaleX(${item.flipHorizontal ? -1 : 1}) scaleY(${
                item.flipVertical ? -1 : 1
              })`,
            }}
          >
            <div
              className={imageClassName}
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
              }}
            />
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                height: "100%",
              }}
            >
              {showUnderlayer && underlayerPath && (
                <img
                  draggable={false}
                  id={"imageUnderlayer " + item.stageItemId}
                  className={""}
                  style={{
                    opacity: 0.5,
                    position: "absolute",
                    top: "0px",
                    left: "0px",
                    height: itemPosition ? itemPosition.height + "px" : "0px",
                    width: itemPosition ? itemPosition.width + "px" : "0px",
                    //cursor: "pointer",
                  }}
                  src={underlayerPath}
                  alt="item"
                />
              )}

              {showShadowlayer && shadowlayerImage && shadowlayerPath && (
                <img
                  draggable={false}
                  id={"imageShadowlayer " + item.stageItemId}
                  className={""}
                  style={{
                    position: "absolute",
                    bottom:
                      -(((shadowlayerImage.height * item.shadowlayer.scaleWidth) / 100) * (item.scale || 100)) /
                        backgroundSize.scale1920 /
                        2 +
                      "px",

                    height:
                      (((shadowlayerImage.height * item.shadowlayer.scaleWidth) / 100) * (item.scale || 100)) /
                        backgroundSize.scale1920 +
                      "px",
                    width:
                      (((shadowlayerImage.width * item.shadowlayer.scaleWidth) / 100) * (item.scale || 100)) /
                        backgroundSize.scale1920 +
                      "px",
                    //cursor: "pointer",
                  }}
                  src={shadowlayerPath}
                  alt="item"
                />
              )}

              <img
                draggable={false}
                ref={imageRef}
                id={"image " + item.stageItemId}
                className={item.outline ? "outlined" : ""}
                style={{
                  filter: `blur(${item.blur / 4 / backgroundSize?.scale1920 / 5}px)`,
                  opacity: item.opacity / 100,
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  height: itemPosition ? itemPosition.height + "px" : "0px",

                  cursor: "pointer",
                }}
                onLoad={() => {
                  const element = document.getElementById(item.stageItemId);
                  element && element.style.setProperty("--stroke-color", item.outline);
                }}
                src={itemImage.path}
                alt="item"
              />
            </div>
            {showSoundIcon && item?.sounds?.main && (
              <Tooltip title="Об'єкт має звук" placement="right" arrow>
                <HasASoundIcon name="itemSoundIcon" className="sound-icon" />
              </Tooltip>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ItemUniversal;
