import "./GameResults.scss";
import React, { useMemo } from "react";
import { Tooltip } from "@mui/material";

const GameResults = ({ gameResults }) => {
  console.log("GameResults gameResults", gameResults);

  const maxUncontrolled = useMemo(() => {
    if (!gameResults.uncontrolled) {
      return 0;
    }

    return (
      gameResults.uncontrolled.reduce((max, gameKnowledge) => {
        if (gameKnowledge.quantity >= max) {
          return gameKnowledge.quantity;
        } else {
          return max;
        }
      }, 0) || 0
    );
  }, [gameResults]);

  return (
    <div className={`game-results-component`}>
      <div className="game-results">
        {gameResults?.uncontrolled
          ?.sort((a, b) => b.quantity - a.quantity)
          .map((uncontrolledKnowledge, i) => (
            <Tooltip
              key={uncontrolledKnowledge.knowledge}
              title={uncontrolledKnowledge.knowledge}
              followCursor={true}
              arrow={true}
            >
              <div className="game-result-wrapper" key={i}>
                <div className="game-result-label">{uncontrolledKnowledge.knTag?.name}</div>
                <div className="game-result-progress">
                  <div
                    className="game-result-progress-element"
                    style={{ width: `${(uncontrolledKnowledge.quantity * 100) / maxUncontrolled}%` }}
                  ></div>
                </div>
                <div className="game-result-param">{uncontrolledKnowledge.quantity}</div>
              </div>
            </Tooltip>
          ))}
        {gameResults?.controlled
          ?.sort((a, b) => b.correctQuantity / b.incorrectQuantity - a.correctQuantity / a.incorrectQuantity)
          .map((controlledKnowledge, i) => (
            <Tooltip
              key={controlledKnowledge.knowledge}
              title={controlledKnowledge.knowledge}
              followCursor={true}
              arrow={true}
            >
              <div className="game-result-wrapper" key={i}>
                <div className="game-result-label">{controlledKnowledge.knTag?.name}</div>
                <div className="controlled-result-progress">
                  <div
                    className="controlled-result-progress-element-correct"
                    style={{
                      width: `${controlledKnowledge.incorrectQuantity ? 0 : 100}%`,
                    }}
                  ></div>
                </div>
                <div className="controlled-result-param">{`${controlledKnowledge.correctQuantity}/${controlledKnowledge.incorrectQuantity}`}</div>
              </div>
            </Tooltip>
          ))}
      </div>
    </div>
  );
};

export default GameResults;
