import "./KnowledgeGroupEditor.scss";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Header, AlertDialog, StyledButton, KnowledgeTagList, Tabs, DepsGraph } from "components";
import { ReactComponent as IconPlus } from "assets/icons/bigPlus.svg";
import KnowledgeGroup from "./KnowledgeGroup";
import { useResourcesContext } from "resources";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _, { get, set } from "lodash";
import axios from "axios";
import KnowledgeGroupTile from "./KnowlegeGroupTile";

const KnowledgeGroupEditor = () => {
  const [selectedKnowledgeGroup, setSelectedKnowledgeGroup] = useState();
  const [alertDialogData, setAlertDialogData] = useState(null);

  const [activeTab, setSelectedTab] = useState("Знання");

  const [tagsUsageData, setTagsUsageData] = useState({
    freeTags: [],
    usedKnowledgeTags: {
      allGroupsTags: [],
      allLearningProgressTags: [],
      allPlanTags: [],
      allSoundSetsTags: [],
    },
  });
  const [tagsWithoutSoundSet, setTagsWithoutSoundSet] = useState([]);

  const {
    knowledgeGroupListStore: {
      knowledgeGroupList,
      updateKnowledgeGroup,
      createKnowledgeGroup,
      deleteKnowledgeGroup,
      status,
      resetStatus,
      getKnowledgeGroupList,
    },
    knowledgeTagListStore: { knowledgeTagList, getKnowledgeTagList },
    soundSetListStore: { getUsedBaseTags, getSoundSetList },
  } = useResourcesContext();

  useEffect(() => {
    getKnowledgeTagList();
    getKnowledgeGroupList();
    getSoundSetList();
  }, []);

  useEffect(() => {
    const allBaseKnTagsUsedInSoundSets = getUsedBaseTags();
    const allNonBaseKnTagsUsedInGroups = knowledgeGroupList.reduce((result, knowledgeGroup) => {
      if (knowledgeGroup.studiesAtGroups0?.length || knowledgeGroup.studiesAtGroups1?.length) {
        return [...result, ...knowledgeGroup.knowledgeTags];
      }
      return [...result];
    }, []);

    setTagsWithoutSoundSet(
      knowledgeTagList
        .filter(
          (tag) => !allBaseKnTagsUsedInSoundSets.includes(tag._id) && !allNonBaseKnTagsUsedInGroups.includes(tag._id)
        )
        .map((tag) => tag._id)
    );
  }, [getUsedBaseTags, knowledgeGroupList]);

  useEffect(() => {
    return () => resetStatus();
  }, [resetStatus]);

  const getTagsUsageData = useCallback(() => {
    return axios
      .get("/api/knowledgeTags/usageData")
      .then((response) => response.data)
      .then((tagsUsageData) => {
        setTagsUsageData(tagsUsageData);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    getTagsUsageData();
  }, [getTagsUsageData]);

  useEffect(() => {
    status && status.error && status.message && toast.error(status.message, { toastId: "1" });
    status && !status.error && status.message && toast.success(status.message, { toastId: "2" });
  }, [status]);

  const saveKnowledgeGroup = useCallback(
    (knowledgeGroup) => {
      if (!knowledgeGroup._id) {
        createKnowledgeGroup(knowledgeGroup)
          .then((knowledgeGroup) => {
            setSelectedKnowledgeGroup((prev) => {
              return { ...prev, _id: knowledgeGroup._id };
            });

            resetStatus();
            return getTagsUsageData();
          })
          .catch(() => {});
      } else {
        updateKnowledgeGroup(knowledgeGroup)
          .then(() => {
            return getTagsUsageData();
          })
          .catch(() => {});
      }
    },
    [createKnowledgeGroup, updateKnowledgeGroup, resetStatus]
  );

  const saveKnowledgeGroupDebounced = useMemo(() => _.debounce(saveKnowledgeGroup, 2000), [saveKnowledgeGroup]);

  const handleKnowledgeGroupChange = (knowledgeGroup) => {
    if (knowledgeGroup) {
      setSelectedKnowledgeGroup(knowledgeGroup);
      saveKnowledgeGroupDebounced(knowledgeGroup);
    }
  };

  const handleDeleteKnowledgeGroupOk = () => {
    setAlertDialogData(null);
    selectedKnowledgeGroup?._id &&
      deleteKnowledgeGroup(selectedKnowledgeGroup)
        .then(() => {
          setSelectedKnowledgeGroup(null);
        })
        .catch(() => {});
  };

  const openDeleteKnowledgeGroupDialog = () => {
    setAlertDialogData({
      onOk: handleDeleteKnowledgeGroupOk,
      onCancel: () => setAlertDialogData(null),
      title: "Видалення групи знань",
      message: "Натисніть Ok для видалення, або Cancel для відміни операції.",
    });
  };

  const handleNewKnowledgeGroup = () => {
    setSelectedKnowledgeGroup({ _id: "", name: "", knowledgeTags: [] });
  };

  const handleKnowledgeTagListElementClick = (value) => {
    if (selectedKnowledgeGroup) {
      if (!selectedKnowledgeGroup.knowledgeTags.includes(value._id)) {
        handleKnowledgeGroupChange({
          ...selectedKnowledgeGroup,
          knowledgeTags: [...selectedKnowledgeGroup.knowledgeTags, value._id],
        });
      }
    }
  };

  const allGroupedKnowledgeTagsIds = useMemo(
    () =>
      knowledgeGroupList.reduce((result, knowledgeGroup) => {
        return [...result, ...knowledgeGroup.knowledgeTags];
      }, []),
    [knowledgeGroupList]
  );

  const usedLevels = useMemo(() => {
    return knowledgeGroupList.reduce((result, knowledgeGroup) => {
      if (!result.includes(knowledgeGroup.level)) {
        return [...result, knowledgeGroup.level];
      }
      return result.sort((a, b) => a - b);
    }, []);
  }, [knowledgeGroupList]);

  const handleDeleteKnowledgeTag = (knowledgeTag) => {
    knowledgeTag &&
      axios
        .delete(`/api/knowledgeTags/${knowledgeTag._id}`)
        .then(() => {
          getKnowledgeTagList();
        })
        .catch(() => {});
  };

  return (
    <div className="knowledgeGroupEditor-page">
      <Header className="knowledgeGroupEditor-header" />
      <div className="content-wrapper">
        <div className="left-content-wrapper">
          <div className="title">Групи знань</div>
          <div className="buttons-block">
            <StyledButton
              className="newKnowledgeGroup-button"
              tooltipProps={{ title: "Створити групу", placement: "right" }}
              onClick={() => {
                resetStatus();
                handleNewKnowledgeGroup();
              }}
            >
              <IconPlus />
            </StyledButton>
          </div>
          <div className="knowledgeGroupList-block">
            <div className="knowledgeGroupList-list">
              {usedLevels.map((level) => (
                <>
                  <div className="knowledgeGroupList-level" key={level}>
                    Рівень {level}
                  </div>

                  {[...knowledgeGroupList.filter((group) => group.level === level)]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((knowledgeGroup) => (
                      <div key={knowledgeGroup._id} className="knowledgeGroupList-item">
                        <KnowledgeGroupTile
                          knowledgeGroup={knowledgeGroup}
                          selected={selectedKnowledgeGroup?._id === knowledgeGroup._id}
                          onClick={(knowledgeGroup) => setSelectedKnowledgeGroup(knowledgeGroup)}
                        />
                      </div>
                    ))}
                </>
              ))}
            </div>
          </div>
        </div>
        <div className="middle-content-wrapper">
          {selectedKnowledgeGroup && (
            <KnowledgeGroup
              value={selectedKnowledgeGroup}
              onChange={handleKnowledgeGroupChange}
              onDelete={openDeleteKnowledgeGroupDialog}
            />
          )}
          <ToastContainer
            className={"toast-position"}
            toastClassName="toastClassName"
            position="top-center"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>

        <div className="right-content-wrapper">
          <Tabs
            className=""
            tabs={["Знання", "Граф залежностей"]}
            activeTab={activeTab}
            onChange={(tab) => setSelectedTab(tab)}
          />
          {activeTab === "Граф залежностей" && (
            <>
              <DepsGraph
                onClick={(group) =>
                  setSelectedKnowledgeGroup(
                    knowledgeGroupList.find((knowledgeGroup) => knowledgeGroup._id === group._id)
                  )
                }
                selectedGroup={selectedKnowledgeGroup || {}}
              />
            </>
          )}
          {activeTab === "Знання" && (
            <>
              <KnowledgeTagList
                knowledgeTags={knowledgeTagList}
                tagsWithoutSoundSet={tagsWithoutSoundSet}
                highliteSelectedTags
                selectedTagsIds={selectedKnowledgeGroup?.knowledgeTags || []}
                selectedTagsIdsRoot={allGroupedKnowledgeTagsIds}
                tagsUsageData={tagsUsageData}
                showSelectedTags={true}
                onClick={handleKnowledgeTagListElementClick}
                onDeleteKnowledgeTag={handleDeleteKnowledgeTag}
                className="knowledgeTagList"
                chipClassName="chip"
                tagDefaultTooltip=""
                selectedTagDefaultTooltip="Вже додано до групи"
              />
            </>
          )}
        </div>
      </div>

      <AlertDialog alertDialogData={alertDialogData} />
    </div>
  );
};
export default KnowledgeGroupEditor;
