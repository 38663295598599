export const googlePlayUrl = "https://play.google.com/store/apps/details?id=com.dts.bulbee";
export const appStoreUrl = "https://apps.apple.com/us/app/bulbee/id6450890892";

export const PROD_DIGITALOCEAN_URL = "https://fra1.digitaloceanspaces.com/bulbee/bulbee.web.platform";
export const QA_DIGITALOCEAN_URL = "https://fra1.digitaloceanspaces.com/bulbee/qa.bulbee.web.platform";
export const VIBER_INVITE_LINK = "viber://pa?chatURI=380977815913";
export const WHATSAPP_INVITE_LINK =
  "https://api.whatsapp.com/send/?phone=%2B380977815913&text&type=phone_number&app_absent=0";
export const TELEGRAM_INVITE_LINK = "https://t.me/bulbee_bot?start=664797b20a0f76a8790bbae9";
export const INSTAGRAM_INVITE_LINK = "https://www.instagram.com/bulbee_org/";

export const planPrice = "20$";
export const planPriceCurrency = "(~800Грн)";
export const planName = "Bulbee";

export const PIXEL_ID = window.location.host.includes("qa.bulbee")
  ? ""
  : window.location.host.includes("localhost")
  ? ""
  : "179640238561140";

export const GTM_ID = window.location.host.includes("qa.bulbee")
  ? ""
  : window.location.host.includes("localhost")
  ? ""
  : "GTM-T2TFVN5T";

const GA_ID_DEV = "G-9E1B279GT6"; //olegs ga for tests
export const GA_ID = window.location.host.includes("qa.bulbee")
  ? GA_ID_DEV
  : window.location.host.includes("localhost")
  ? GA_ID_DEV
  : "G-HNZ3FMY649";

export const ROLE_ADMIN = "admin";
export const ROLE_SUPERADMIN = "superadmin";
export const ROLE_CLIENT = "client";
export const ROLE_MARKETING = "marketing";
export const ROLE_TEACHER = "teacher";

export const defaultPlanOptions = {
  stagesCount: 5,
  concurentCount: 3,
  concurentPlanElementsCount: 4,
  levelSuccessThreshold: 75,
};

export const defaultKnowledgeStrengthAlgOptions = {
  learnThreshold: 30,
  knowThreshold: 90,
  knowThresholdForMinorKnowledge: 70,
  startKnowledgeStrength: 50,
  questionStep: 35,
  startForgetRate: 1.5,
  forgetRateChangeValueCorrect: 0.1,
  forgetRateChangeValueIncorrect: 0.4,
  useForgetRate: true,
  useForgetRateForMinorKnowledge: true,
  strengthAcceptableDropLevel: 35,
};

export const defaultQuickTestOptions = {
  correctStrength: 80,
  incorrectStrength: 50,
  correctForgetRate: 1.5,
  incorrectForgetRate: 1.5,
  stagesCount: 5,
  unlimitedStagesCount: false,
  testSuccessThresholdForGroup: 75,
};

export const soundSetTypes = [
  { name: "knowledge", label: "Знання" },
  { name: "other", label: "Інше" },
  { name: "correct", label: "Правильно" },
  { name: "incorrect", label: "Неправильно" },
  { name: "gameStart", label: "Початок гри" },
  { name: "gameEnd", label: "Кінець гри" },
];

export const gameTypes = [
  { name: "match", label: "Співставлення" },
  { name: "universal", label: "Universal" },
  { name: "template", label: "Шаблон" },
];

export const languages = [
  { name: "uk", label: "Українська" },
  { name: "ru", label: "Русский" },
  { name: "en", label: "English" },
];

export const genders = [
  { label: "Хлопчик", name: "male" },
  { label: "Дівчинка", name: "female" },
];

export const monthes = [
  { label: "Січень", name: "1" },
  { label: "Лютий", name: "2" },
  { label: "Березень", name: "3" },
  { label: "Квітень", name: "4" },
  { label: "Травень", name: "5" },
  { label: "Червень", name: "6" },
  { label: "Липень", name: "7" },
  { label: "Серпень", name: "8" },
  { label: "Вересень", name: "9" },
  { label: "Жовтень", name: "10" },
  { label: "Листопад", name: "11" },
  { label: "Грудень", name: "12" },
];

export const sortingAlphabeticallyOptions = [
  { label: "За абеткою (А-Я)", name: "name-asc" },
  { label: "За абеткою (Я-А)", name: "name-desc" },
];

export const sortingActivityOptions = [
  { label: "Активність (нові зверху)", name: "lastActivityDate-asc" },
  { label: "Активність (старі зверху)", name: "lastActivityDate-desc" },
];

export const years = [
  { label: "2015", name: "2015" },
  { label: "2016", name: "2016" },
  { label: "2017", name: "2017" },
  { label: "2018", name: "2018" },
  { label: "2019", name: "2019" },
  { label: "2020", name: "2020" },
  { label: "2021", name: "2021" },
  { label: "2022", name: "2022" },
  { label: "2023", name: "2023" },
  { label: "2024", name: "2024" },
];

export const itemStartActions = [{ name: "CLOUD_ANIMATION", label: "CLOUD_ANIMATION" }];

export const taskCorrectReactions = [
  { name: "PARTICLES_AND_DELETE", label: "PARTICLES_AND_DELETE" },
  { name: "PARTICLES", label: "PARTICLES" },
  { name: "BIG_PARTICLES", label: "BIG_PARTICLES" },
  { name: "BIG_PARTICLES_AND_DELETE", label: "BIG_PARTICLES_AND_DELETE" },
];
export const tapDefaultCorrectReaction = "PARTICLES_AND_DELETE";
export const dragDefaultCorrectReaction = "PARTICLES";

export const taskIncorrectReactions = [
  { name: "SHAKE", label: "SHAKE" },
  { name: "SHAKE_AND_DELETE", label: "SHAKE_AND_DELETE" },
  { name: "SHAKE_AND_RETURN", label: "SHAKE_AND_RETURN" },
  { name: "SHAKE_AND_BLOCK", label: "SHAKE_AND_BLOCK" },
  { name: "NONE_AND_BLOCK", label: "NONE_AND_BLOCK" },
  { name: "SHAKE_FADE_BLOCK", label: "SHAKE_FADE_BLOCK" },
  { name: "NONE_FADE_BLOCK", label: "NONE_FADE_BLOCK" },
  { name: "NONE", label: "NONE" },
];

export const tapDefaultIncorrectReaction = "SHAKE";
export const dragDefaultIncorrectReaction = "SHAKE_AND_RETURN";

export const errorTable = [
  { typeOfError: "gameNameError", message: "Для ігри не встановлена назва." },
  { typeOfError: "gameTypeError", message: "Не обрано тип ігри." },
  { typeOfError: "gameStagesError", message: "Ігра не містить етапів." },

  { typeOfError: "correctCombinationsError", message: "Не створені корректні комбінації для етапа:" },
  { typeOfError: "correctItemsError", message: "Не обрані корректні відповіді для етапа:" },

  { typeOfError: "gameIconImage_error", message: "Не обрана іконка для ігри." },
  { typeOfError: "gameIconImage_imageNotFoundError", message: "Обрана іконка ігри відсутня в базі зображень." },
  {
    typeOfError: "gameIconImage_imageFileNotFoundError",
    message: "Для обраної іконки ігри відсутній файл зображення.",
  },

  { typeOfError: "stageBackgroundImage_error", message: "Не встановлен фон для етапу:" },
  {
    typeOfError: "stageBackgroundImage_imageNotFoundError",
    message: "Встановлений фон не знайдений в базі зображень. Етап:",
  },
  {
    typeOfError: "stageBackgroundImage_imageFileNotFoundError",
    message: "Для встановленого фону етапу відсутній файл зображення. Етап:",
  },
  //---------------------------------
  { typeOfError: "itemImage_error", message: "Для одного з пропсів не задане головне зображення в етапі:" },
  {
    typeOfError: "itemImage_imageNotFoundError",
    message: "Головне зображення одного із пропсів відсутнє в базі зображень. Етапі:",
  },
  {
    typeOfError: "itemImage_imageFileNotFoundError",
    message: "Для обраного для одного із пропсів головного зображення відсутній файл зображення. Етап:",
  },
  //---------------------------------
  { typeOfError: "itemFrontImage_error", message: "Для одного з пропсів не заданий передній план в етапі:" },
  {
    typeOfError: "itemFrontImage_imageNotFoundError",
    message: "Передній план одного із пропсів відсутній в базі зображень. Етапі:",
  },
  {
    typeOfError: "itemFrontImage_imageFileNotFoundError",
    message: "Для обраного для одного із пропсів переднього плану відсутній файл зображення. Етап:",
  },
  //---------------------------------
  { typeOfError: "itemBackImage_error", message: "Для одного з пропсів не заданий задній план в етапі:" },
  {
    typeOfError: "itemBackImage_imageNotFoundError",
    message: "Задній план одного із пропсів відсутній в базі зображень. Етапі:",
  },
  {
    typeOfError: "itemBackImage_imageFileNotFoundError",
    message: "Для обраного для одного із пропсів заднього плану відсутній файл зображення. Етап:",
  },
  //---------------------------------
  { typeOfError: "itemForegroundImage_error", message: "Для одної із корзин не обраний передній план в этапі:" },
  {
    typeOfError: "itemForegroundImage_imageNotFoundError",
    message: "Передний план одноі із корзин відсутній в базі зображень. Етап:",
  },
  {
    typeOfError: "itemForegroundImage_imageFileNotFoundError",
    message: "Для обраного для однієї із корзин переднього плану відсутній файл зображення. Етап:",
  },
  //---------------------------------
  {
    typeOfError: "gameSound_startGame_error",
    message: "Для старта ігри не обраний звук",
  },
  {
    typeOfError: "gameSound_startGame_soundNotFoundError",
    message: "Обраний для старта ігри звук відсутній в базі звуків",
  },
  {
    typeOfError: "gameSound_startGame_soundFileNotFoundError",
    message: "На сервері відсутній файл звуку для старта ігри",
  },
  //---------------------------------
  {
    typeOfError: "gameSound_tutorial_error",
    message: "Для туторіала ігри не обраний звук",
  },
  {
    typeOfError: "gameSound_tutorial_soundNotFoundError",
    message: "Обраний для туторіала ігри звук відсутній в базі звуків",
  },
  {
    typeOfError: "gameSound_tutorial_soundFileNotFoundError",
    message: "На сервері відсутній файл звуку для туторіала ігри",
  },
  //---------------------------------
  {
    typeOfError: "gameSound_correct_error",
    message: "Не обрані correct звуки",
  },
  {
    typeOfError: "gameSound_correct_soundNotFoundError",
    message: "Один із correct звуків відсутній в базі звуків:",
  },
  {
    typeOfError: "gameSound_correct_soundFileNotFoundError",
    message: "Для одного із correct звуків відсутній файл звуку",
  },
  //---------------------------------
  {
    typeOfError: "gameSound_incorrect_error",
    message: "Не обрані incorrect звуки",
  },
  {
    typeOfError: "gameSound_incorrect_soundNotFoundError",
    message: "Один із incorrect звуків відсутній в базі звуків:",
  },
  {
    typeOfError: "gameSound_incorrect_soundFileNotFoundError",
    message: "Для одного із incorrect звуків відсутній файл звуку",
  },
  //---------------------------------
  {
    typeOfError: "stageSound_stageStart_error",
    message: "Для старту етапу не обраний звук. Етап:",
  },
  {
    typeOfError: "stageSound_stageStart_soundNotFoundError",
    message: "Обраний для старту етапу звук відсутній в базі звуків.  Етап:",
  },
  {
    typeOfError: "stageSound_stageStart_soundFileNotFoundError",
    message: "На сервері відсутній файл звуку для старта етапу:",
  },
  //---------------------------------
  {
    typeOfError: "itemSound_main_error",
    message: "Для пропса не обрано звук в етапі:",
  },
  {
    typeOfError: "itemSound_main_soundNotFoundError",
    message: "Обраний для пропса звук відсутній в базі звуків.  Етап:",
  },
  {
    typeOfError: "itemSound_main_soundFileNotFoundError",
    message: "На сервері відсутній файл звуку для одного із пропсів.  Етап:",
  },
];
