import "./MainPage.scss";
import React from "react";
import Overview from "./Overview/Overview";
import CreatedFor from "./CreatedFor/CreatedFor";
import AboutBulbee from "./AboutBulbee/AboutBulbee";
import Benefits from "./Benefits/Benefits";
import JoinUsFooterForm from "./JoinUsFooterForm/JoinUsFooterForm";
import JoinUsForm from "./JoinUsForm/JoinUsForm";
import ParentHistory from "./ParentHistory/ParentHistory";
import Layout from "pages/Landing/Layout/Layout";
import AppButtons from "./AppButtons/AppButtons";

const MainPage = () => {
  return (
    <Layout>
      <Overview />
      <AppButtons />
      <JoinUsForm />
      <AboutBulbee />
      <CreatedFor />
      <ParentHistory />
      <Benefits />
      <JoinUsFooterForm />
    </Layout>
  );
};

export default MainPage;
