import { useCallback, useEffect, useState } from "react";
import unityUserListApi from "API/unityUserListApi";
import axios from "axios";

const useUserList = () => {
  const [fetchingList, setFetchingList] = useState(false);
  const [sortingOrder, setSortingOrder] = useState("lastActivityDate-asc");
  const [userList, setUserList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);

  const fetchData = useCallback((page = 1, limit = 0) => {
    setFetchingList(true);

    const searchParams = {
      pinnedUsers: true,
    };

    if (limit) {
      searchParams.limit = limit;
      searchParams.page = page;
    }

    const searchParamString = "?" + new URLSearchParams(searchParams).toString();

    unityUserListApi
      .getList(searchParamString)
      .then((response) => {
        const { data: list, totalPages } = response;
        setFetchingList(false);

        setUserList((prevList) => {
          const newList = page === 1 ? list : [...prevList, ...list];
          setHasMore(page < totalPages);
          return newList;
        });
      })
      .catch((error) => {
        setFetchingList(false);
        console.error("Error fetching user list:", error);
      });
  }, []);

  useEffect(() => {
    fetchData(1);
  }, [fetchData]);

  useEffect(() => {
    if (currentPage > 1) {
      fetchData(currentPage);
    }
  }, [currentPage, fetchData]);

  const reloadList = useCallback(() => {
    setCurrentPage(1);
    fetchData(1);
  }, [fetchData]);

  const loadMore = useCallback(() => {
    if (!fetchingList && hasMore) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  }, [fetchingList, hasMore]);

  const updateSortingOrder = (order) => {
    setSortingOrder(order);
    sortUserList(order);
  };

  const sortUserList = (order) => {
    setUserList((prevList) => {
      const sortedList = [...prevList].sort((a, b) => {
        if (order === "name-asc") {
          return a.name.localeCompare(b.name);
        } else if (order === "name-desc") {
          return b.name.localeCompare(a.name);
        } else if (order === "lastActivityDate-asc") {
          return new Date(b.lastActivityDate) - new Date(a.lastActivityDate);
        } else if (order === "lastActivityDate-desc") {
          return new Date(a.lastActivityDate) - new Date(b.lastActivityDate);
        } else {
          return 0;
        }
      });
      return sortedList;
    });
  };

  const updateUser = useCallback(
    (unityUserId, unityUserFields) => {
      return axios
        .put(`/api/unityUsers/${unityUserId}`, JSON.stringify(unityUserFields), {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => res.data)
        .then((updatedUnityUser) => {
          setUserList((prevList) => {
            const updatedList = prevList.map((user) => {
              if (user._id === unityUserId) {
                return updatedUnityUser;
              }
              return user;
            });
            return updatedList;
          });

          sortUserList(sortingOrder);
          return updatedUnityUser;
        })
        .catch(() => {
          return null;
        });
    },
    [sortingOrder]
  );

  return {
    userList,
    fetchingList,
    sortingOrder,
    setSortingOrder: updateSortingOrder,
    reloadList,
    loadMore,
    hasMore,
    updateUser,
  };
};

export default useUserList;
