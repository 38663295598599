import "./UnityUserEditor.scss";
import React from "react";
import withIdParam from "helpers/withIdParam";
import { VerticalHeader, CardStyled } from "components";

import Editor from "./Editor";

const UnityUserEditor = ({ id }) => {
  return (
    <div className="user-editor-wrapper">
      <CardStyled className="left-header">
        <VerticalHeader></VerticalHeader>
      </CardStyled>

      <Editor id={id} />
    </div>
  );
};
export default withIdParam(UnityUserEditor);
