import "./MainScreen.scss";
import React, { useEffect, useRef, useState } from "react";
import { useResourcesContext } from "resources";
import { Item } from "components";
import ImageExitButtonBase from "assets/images/exit_button_base.png";
import ImageExitButton from "assets/images/exit_button.png";

const ExitButton = ({ scale }) => {
  return (
    <div
      className="exit-button-wrapper"
      style={{
        transform: `scale(${1 / scale})`,
        top: `${8 + 52 / scale}px`,
        left: `${9 + 40 / scale}px`,
      }}
    >
      <img src={ImageExitButtonBase} className="exit-button-base" alt="" />
      <img src={ImageExitButton} className="exit-button" alt="" />
    </div>
  );
};

const MainScreen = ({
  locked,
  stage,
  activeItem,
  passiveItem,
  onPressDelete,
  combinationModeEnabled,
  onSorterClick,
  onChange,
}) => {
  const [capturedItem, setCapturedItem] = useState(null);
  const [itemMoved, setItemMoved] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [skipSorterClick, setSkipSorterClick] = useState(false);
  const [backgroundSize, setBackgroundSize] = useState(null);
  const backgroundRef = useRef();
  const mousePrevPosition = useRef({});

  const {
    imageListStore: { getImagePathById },
  } = useResourcesContext();

  useEffect(() => {
    window.addEventListener("resize", updateBackgroundSize);
    return () => {
      window.removeEventListener("resize", updateBackgroundSize);
    };
  }, []);

  const handleStageBackgroundOnLoad = () => {
    updateBackgroundSize();
  };

  const updateBackgroundSize = () => {
    setBackgroundSize({
      clientHeight: backgroundRef.current.clientHeight,
      clientWidth: backgroundRef.current.clientWidth,
      scale1920: 1920 / backgroundRef.current.clientWidth,
    });
  };

  const handleBackgroundMouseMove = (mouseEvent) => {
    if (capturedItem) {
      handleItemMove(mouseEvent);
    } else {
      mousePrevPosition.current.x = mouseEvent.clientX;
      mousePrevPosition.current.y = mouseEvent.clientY;
    }
  };

  const handleMainScreenKeyUp = (event) => {
    (event.key === "Delete" || event.key === "Backspace") && onPressDelete();
  };

  const handleItemMove = (mouseEvent) => {
    if (mouseEvent.buttons === 1 && capturedItem) {
      setItemMoved(true);
      capturedItem.valueX = Math.round(
        capturedItem.valueX + (mouseEvent.clientX - mousePrevPosition.current.x) * backgroundSize.scale1920
      );
      capturedItem.valueY = Math.round(
        capturedItem.valueY - (mouseEvent.clientY - mousePrevPosition.current.y) * backgroundSize.scale1920
      );
      onChange();
    }
    mousePrevPosition.current.x = mouseEvent.clientX;
    mousePrevPosition.current.y = mouseEvent.clientY;
  };

  const handleItemPointerLeave = (event) => {
    itemMoved &&
      backgroundRef.current !== event.relatedTarget &&
      !stage.items.some((item) => event.relatedTarget.id && event.relatedTarget.id.includes(item.stageItemId)) &&
      handleItemMouseClick(selectedItem);
  };

  const handleItemMouseClick = (item) => {
    setSkipSorterClick(false);
    if (!skipSorterClick && !itemMoved) {
      onSorterClick(item);
    }
    setCapturedItem(null);
    setItemMoved(null);
  };

  const handleItemMouseDown = (item) => {
    setSelectedItem(item);
    setCapturedItem(item);

    if (combinationModeEnabled && (item === activeItem || item === passiveItem)) {
      return;
    }
    setSkipSorterClick(combinationModeEnabled && item !== activeItem && !passiveItem);
    onSorterClick(item);
  };

  const isActiveItem = (item) => item.stageItemId === activeItem?.stageItemId;
  const isPassiveItem = (item) => item.stageItemId === passiveItem?.stageItemId;

  const itemIsReceiver = (item) => {
    return (
      stage.correctCombinations &&
      stage.correctCombinations.findIndex((combination) => combination.stageReceiverId === item.stageItemId) >= 0
    );
  };

  const handleMouseWheel = (event, item) => {
    if (event.shiftKey && event.deltaY) {
      if (!item.angle) item.angle = 0;
      item.angle = Math.floor(item.angle + event.deltaY / 15);

      if (item.angle > 360) item.angle = item.angle - 360;
      if (item.angle < 0) item.angle = item.angle + 360;

      onChange();
    }
  };

  return (
    <>
      <div
        id="stageMainScreen"
        className="main-screen"
        onMouseMoveCapture={(e) => e.preventDefault()}
        onKeyUp={handleMainScreenKeyUp}
        tabIndex="-1"
      >
        <img
          ref={backgroundRef}
          id={`stageMainScreenBackgroundImage`}
          className="main-screen-bg"
          src={stage ? getImagePathById(stage.background) : ""}
          alt="Empty background"
          onLoad={handleStageBackgroundOnLoad}
          onMouseMoveCapture={(e) => e.preventDefault()}
          onMouseMove={handleBackgroundMouseMove}
        />

        {stage &&
          backgroundSize &&
          stage?.items &&
          stage.items.map((item) => (
            <Item
              key={"board item " + item.stageItemId}
              imageClassName={isActiveItem(item) ? "img-active" : isPassiveItem(item) ? "img-passive" : ""}
              item={item}
              front={!itemIsReceiver(item) ? item?.front || stage?.defaultFront : null}
              backgroundSize={backgroundSize}
              onMouseWheel={(event) => handleMouseWheel(event, item)}
              onMouseDown={() => handleItemMouseDown(item)}
              onMouseMove={handleItemMove}
              onPointerLeave={handleItemPointerLeave}
              onClick={handleItemMouseClick}
              freeze={locked}
              moving={capturedItem === item}
            />
          ))}
      </div>
      {stage && backgroundSize && <ExitButton scale={backgroundSize.scale1920} />}
    </>
  );
};
export default MainScreen;
