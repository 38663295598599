import "./ImageLibrary.scss";
import React, { useCallback, useEffect, useState } from "react";
import { ImageList, CardStyled, StyledInput, Tags } from "components";
import { useResourcesContext } from "resources";

const ImageLibrary = ({
  className = "",
  selectedImageId,
  onImageSelect,
  imagesOnPage,
  withoutSelection,
  disableOnImageSelect,
  onImageDelete,
}) => {
  const [filteredImageList, setFilteredImageList] = useState();
  const [selectedTags, setSelectedTags] = useState([]);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [tagListWithCount, setTagListWithCount] = useState([]);

  const {
    tagListStore: { tagList },
    imageListStore: { imageList },
  } = useResourcesContext();

  const getFilteredImageList = useCallback(() => {
    if (imageList) {
      return imageList.filter((image) => {
        const hasTags = image.tags && image.tags.length > 0;

        const nameMatches = image.name.toUpperCase().includes(searchInputValue.toUpperCase());

        const tagsMatch = selectedTags.every((tag) => image.tags.includes(tag));

        const typeExcludes = !["gameIcon", "stageTemplateIcon", "assetBundleIcon"].includes(image.type);

        return hasTags && nameMatches && tagsMatch && typeExcludes;
      });
    }
    return [];
  }, [imageList, selectedTags, searchInputValue]);

  useEffect(() => {
    setFilteredImageList(getFilteredImageList());
  }, [getFilteredImageList]);

  const getTaggedImagesCount = useCallback(
    (tag) => {
      return imageList.filter((image) => image.tags.includes(tag._id)).length;
    },
    [imageList]
  );

  useEffect(() => {
    if (tagList) {
      setTagListWithCount(
        tagList.map((tag) => {
          return { count: getTaggedImagesCount(tag), ...tag };
        })
      );
    }
  }, [tagList, imageList, getTaggedImagesCount]);

  return (
    <div className={`image-library-wrapper ${className}`}>
      <CardStyled className="tags-wrapper">
        <div className="tags-inputs">
          <div className="tag-search">
            <StyledInput
              placeholder="Пошук по назві та тегам"
              onChange={(event) => setSearchInputValue(event.target.value)}
            />
          </div>
        </div>
        <Tags tagList={tagListWithCount} selectedTags={selectedTags} onTagClick={(tag) => setSelectedTags([tag._id])} />
      </CardStyled>

      {filteredImageList && (
        <ImageList
          imageList={filteredImageList}
          selectedImageId={selectedImageId}
          onImageSelect={disableOnImageSelect ? () => {} : onImageSelect}
          withoutSelection={withoutSelection}
          imagesOnPage={selectedTags.length > 0 ? null : imagesOnPage}
          onImageDelete={onImageDelete}
        />
      )}
    </div>
  );
};
export default ImageLibrary;
