import React from "react";
import { LinearProgress } from "@material-ui/core";

const UploadProgress = ({ value, className }) => {
    return (
      <div className={className}>
        <LinearProgress variant="determinate" value={value} />
      </div>
    );
};
export default UploadProgress;