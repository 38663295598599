import "./JoinUsFooterForm.scss";
import React from "react";
import Socials from "components/Socials/Socials";
import { useI18N } from "i18n";

const JoinUsFooterForm = () => {
  const { I18N } = useI18N();

  return (
    <div id={"footercontacts"} className="joinUsFooterForm background">
      <div className="joinUsFooterForm-content">
        <div className="joinUsFooterForm-content__heading">
          <h3>
            <I18N id="join_family_header" />
            <span>
              <I18N id="history_bulbee" />
            </span>
          </h3>
          <p>
            <I18N id="join_us_subheader" />
          </p>
        </div>
        <Socials />
      </div>
    </div>
  );
};

export default JoinUsFooterForm;
