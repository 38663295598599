import "./UserStatisticCard.scss";
import React, { useEffect, useMemo } from "react";
import app_logo2 from "../assets/app-logo2.png";
import battery from "../assets/battery.png";
import useSearchParamWorker from "helpers/useSearchParam";
import KnowledgeKnowSvgIcon from "../assets/knowledgeKnowSvgIcon.svg";
import KnowledgeLearnSvgIcon from "../assets/knowledgeLearnSvgIcon.svg";
import TopicsSvgIcon from "../assets/topicsSvgIcon.svg";
import BatteryFillerLines from "../assets/batteryFillerLines.svg";
import DeltaDot from "../assets/deltaDot.svg";
import DeltaUp from "../assets/deltaUp.svg";
import CoronaLevelOne from "../assets/corona-level1.png";

// const userStatisticStructure = {
//   knowledgesData: [
//     {
//       groupName: "посуд",
//       groupActualKnow: false,
//       groupEndKnow: false,
//       groupStartKnow: false,
//       knowledgesData: [
//         {
//           knowledge: "64e707ab362137a0b8136aff",

//           actualShowStrength: "69.92",
//           actualStrength: "92.02",
//           actualForgetRate: "0.50",
//           actualKnow: false,

//           startShowStrength: "61.42",
//           startStrength: "91.89",
//           startForgetRate: "0.70",
//           startKnow: false,

//           endShowStrength: "69.92",
//           endStrength: "92.02",
//           endForgetRate: "0.50",
//           endKnow: false,

//           knowledgeTag: {
//             _id: "64e707ab362137a0b8136aff",
//             name: "тарілка",
//             __v: 0,
//           },
//         },
//       ],
//     },

//   ],
//   daysWithBulbeeData: {
//     daysWithBulbee: 6,
//     daysWithGameplay: 4,
//     totalPlayTime: 1191,
//     averagePlayTime: 298,
//   },
//   allDaysWithBulbeeData: {
//     daysWithBulbee: 22,
//     daysWithGameplay: 14,
//     totalPlayTime: 5960,
//     averagePlayTime: 426,
//   },
// };

function UserStatisticCard() {
  const { getSearchParam } = useSearchParamWorker();

  const [unityUser, setUnityUser] = React.useState(null);
  const [userStatistic, setUserStatistic] = React.useState(null);
  const unityUserId = useMemo(() => getSearchParam("unityUserId"), []);
  const startTimestamp = useMemo(() => Number(getSearchParam("startDate")) || "");
  const endTimestamp = useMemo(() => Number(getSearchParam("endDate")) || "");

  const showDeltaValues = !(startTimestamp === endTimestamp);

  const nameEqualEmail = useMemo(() => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isEmail = unityUser?.name && emailRegex.test(unityUser.name);
    return Boolean(isEmail);
  }, unityUser);

  useEffect(() => {
    unityUserId &&
      fetch(`/api/unityUsers/${unityUserId}`)
        .then((res) => res.json())
        .then((data) => {
          setUnityUser(data);
        })
        .catch((err) => {
          console.error(err);
        });

    unityUserId &&
      fetch(
        `/api/statistic/userStatisticCardData?unityUserId=${unityUserId}&startDate=${startTimestamp}&endDate=${endTimestamp}`
      )
        .then((res) => res.json())
        .then((data) => {
          setUserStatistic(data);
        })
        .catch((err) => {
          console.error(err);
        });
  }, [unityUserId]);

  const knowledgesKnowEndValue = useMemo(
    () =>
      userStatistic?.knowledgesData.reduce((acc, group) => {
        return (
          acc +
          group.knowledgesData.reduce((acc, knowledge) => {
            if (knowledge.endKnow) {
              return acc + 1;
            }
            return acc;
          }, 0)
        );
      }, 0),
    [userStatistic]
  );

  const knowledgesKnowStartValue = useMemo(
    () =>
      userStatistic?.knowledgesData.reduce((acc, group) => {
        return (
          acc +
          group.knowledgesData.reduce((acc, knowledge) => {
            if (knowledge.startKnow) {
              return acc + 1;
            }
            return acc;
          }, 0)
        );
      }, 0),
    [userStatistic]
  );

  const knowledgesKnowDelta = knowledgesKnowEndValue - knowledgesKnowStartValue;

  const knowledgesLearnEndValue = useMemo(
    () =>
      userStatistic?.knowledgesData.reduce((acc, group) => {
        return (
          acc +
          group.knowledgesData.reduce((acc, knowledge) => {
            if (!knowledge.endKnow) {
              return acc + 1;
            }
            return acc;
          }, 0)
        );
      }, 0),
    [userStatistic]
  );

  const getPlusMinus = (value) => {
    if (value >= 0) {
      return "+";
    }

    return "";
  };

  const allGroupsValue = useMemo(() => userStatistic?.knowledgesData.length, [userStatistic]);
  const knowGroups = useMemo(
    () => userStatistic?.knowledgesData.filter((group) => group.groupEndKnow).length,
    [userStatistic]
  );

  const totalPlayTime = userStatistic?.allDaysWithBulbeeData.totalPlayTime;
  const totalPlayTimeDelta = userStatistic?.daysWithBulbeeData.totalPlayTime;

  const averagePlayTime = userStatistic?.allDaysWithBulbeeData.averagePlayTime;

  const getFormatedTime = (timeSec) => {
    if (timeSec < 60) {
      return `${timeSec} сек`;
    }
    if (timeSec < 3600) {
      // 1 min 05 sec and add 0 if less than 10
      const minutes = Math.floor(timeSec / 60);
      const seconds = timeSec - minutes * 60;

      return `${minutes} хв ${seconds < 10 ? "0" : ""}${seconds} сек`;
    }
    // 1 h 05 min and add 0 if less than 10
    const hours = Math.floor(timeSec / 3600);
    const minutes = Math.floor((timeSec - hours * 3600) / 60);

    return `${hours} год ${minutes < 10 ? "0" : ""}${minutes} хв`;
  };

  const getKnowKnowledges = (knowledgesData) => {
    return knowledgesData.reduce((acc, knowledge) => {
      if (knowledge.endKnow) {
        return acc + 1;
      }
      return acc;
    }, 0);
  };

  const getKnownKnowledges = (knowledgesData) => {
    return knowledgesData.filter((knowledge) => knowledge.actualKnow);
  };

  const getUnknownKnowledges = (knowledgesData) => {
    return knowledgesData.filter((knowledge) => !knowledge.actualKnow);
  };

  const getDeltaColorClassName = (endShowStrength) => {
    if (endShowStrength >= 60) {
      return "colorDelta60";
    }
    if (endShowStrength >= 55) {
      return "colorDelta55";
    }
    if (endShowStrength >= 50) {
      return "colorDelta50";
    }
    if (endShowStrength >= 45) {
      return "colorDelta45";
    }
    if (endShowStrength >= 40) {
      return "colorDelta40";
    }
    if (endShowStrength >= 35) {
      return "colorDelta35";
    }
    if (endShowStrength >= 30) {
      return "colorDelta30";
    }
    if (endShowStrength >= 25) {
      return "colorDelta25";
    }
    if (endShowStrength >= 20) {
      return "colorDelta20";
    }
    if (endShowStrength >= 15) {
      return "colorDelta15";
    }

    return "colorDelta10";
  };

  const getColorClassName = (endShowStrength) => {
    if (endShowStrength >= 60) {
      return "color60";
    }
    if (endShowStrength >= 55) {
      return "color55";
    }
    if (endShowStrength >= 50) {
      return "color50";
    }
    if (endShowStrength >= 45) {
      return "color45";
    }
    if (endShowStrength >= 40) {
      return "color40";
    }
    if (endShowStrength >= 35) {
      return "color35";
    }
    if (endShowStrength >= 30) {
      return "color30";
    }
    if (endShowStrength >= 25) {
      return "color25";
    }
    if (endShowStrength >= 20) {
      return "color20";
    }
    if (endShowStrength >= 15) {
      return "color15";
    }

    return "color10";
  };

  const getPeriod = () => {
    if (!(startTimestamp === endTimestamp)) {
      const startDate = new Date(startTimestamp);
      const endDate = new Date(endTimestamp);
      const startMonth = startDate.getUTCMonth();
      const endMonth = endDate.getUTCMonth();
      const startYear = startDate.getUTCFullYear();
      const endYear = endDate.getUTCFullYear();
      const startDay = startDate.getUTCDate();
      const endDay = endDate.getUTCDate();

      return `${startDay.toString().padStart(2, "0")}.${(startMonth + 1)
        .toString()
        .padStart(2, "0")}.${startYear} - ${endDay.toString().padStart(2, "0")}.${(endMonth + 1)
        .toString()
        .padStart(2, "0")}.${endYear}`;
    }
    return "";
  };

  const getUserAction = () => {
    return `навчання в цифрах`;
  };

  return (
    <div className="userStatisticCard">
      <div className="logo-image-container">
        <img src={app_logo2} alt="logo" />
      </div>
      <div className="userStatisticCard-header">
        <div className="userName">{unityUser?.name}</div>
        <div className="userAction">{getUserAction()}</div>
        <div className="userStatisticCard-period">{getPeriod()}</div>
      </div>

      <div className="summary-block">
        <div className="knowledges">
          <div className="knowledgesKnow">
            <div className="knowledgesKnow-svgIcon">
              <img src={KnowledgeKnowSvgIcon} alt="knowledgeKnowSvgIcon" />
            </div>
            <div className="knowledgesKnowValuAndDelta">
              <div className="knowledgesKnowValue">{knowledgesKnowEndValue}</div>
              {showDeltaValues && (
                <div className="knowledgesKnowDelta">
                  {getPlusMinus(knowledgesKnowDelta)}
                  {knowledgesKnowDelta}
                </div>
              )}
            </div>
            <div className="knowledgesKnowText">Знає</div>
          </div>
          <div className="knowledgesLearn">
            <div className="knowledgesLearn-svgIcon">
              <img src={KnowledgeLearnSvgIcon} alt="knowledgeLearnSvgIcon" />
            </div>
            <div className="knowledgesLearnValueAndDelta">
              <div className="knowledgesLearnValue">{knowledgesLearnEndValue}</div>
            </div>
            <div className="knowledgesLearnText">Вивчає</div>
          </div>
        </div>
        <div className="knowledgeGroups">
          <div className="knowledgeGroups-svgIcon">
            <img src={TopicsSvgIcon} alt="topicsSvgIcon" />
          </div>
          <div className="allGroups">
            <div className="allGroupsValue">{allGroupsValue}</div>
            <div className="allGroupsText">{allGroupsValue === 1 ? "тема" : "тем"}</div>
          </div>
          <div className="deltaGroups">
            <div className="deltaGroupsValue">
              {knowGroups}/{allGroupsValue}
            </div>
            <div className="deltaGroupsBattery">
              <div className="batteryBody">
                {Array.from({ length: knowGroups }).map((_, index) => (
                  <div key={index} className="batteryElement know"></div>
                ))}
                {Array.from({ length: allGroupsValue - knowGroups }).map((_, index) => (
                  <div key={index} className="batteryElement"></div>
                ))}
              </div>
              <div className="batteryPlus"></div>
            </div>
          </div>
        </div>
        <div className="days">
          <div className="daysWithBulbee">
            <div className="daysWithBulbeeValue">{userStatistic?.allDaysWithBulbee}</div>
            {showDeltaValues && <div className="daysWithBulbeeDelta">+{userStatistic?.deltaDaysWithBulbee}</div>}
            <div className="daysWithBulbeeText">Днів з Bulbee</div>
          </div>
          <div className="daysWithGamePlay">
            <div className="daysWithGamePlayValue">{userStatistic?.allDaysWithBulbeeData.daysWithGameplay}</div>
            {showDeltaValues && (
              <div className="daysWithGamePlayDelta">+{userStatistic?.daysWithBulbeeData.daysWithGameplay}</div>
            )}
            <div className="daysWithGamePlayText">днів навчання в додатку</div>
          </div>
        </div>
        <div className="playTime">
          <div className="totalPlayTime">
            <div className="totalPlayTimeValue">{getFormatedTime(totalPlayTime)}</div>
            {showDeltaValues && (
              <div className="totalPlayTimeDelta">
                {getPlusMinus(totalPlayTimeDelta)}
                {getFormatedTime(totalPlayTimeDelta)}
              </div>
            )}
            <div className="totalPlayTimeText"></div>
          </div>
          <div className="averagePlayTime">
            <div className="averagePlayTimeValue">{getFormatedTime(averagePlayTime)}</div>
            <div className="averagePlayTimeText">середній час за день</div>
          </div>
        </div>
      </div>

      <div className="knowledges-block">
        <div className="knowledgesGroups-header">Знання</div>
        <div className="knowledgesGroups">
          {userStatistic?.knowledgesData.map((groupData) => (
            <div className="group" key={groupData.groupName}>
              <div className="groupHeader">
                <div className="groupBattery-block">
                  {
                    <div className="battery">
                      <div className="batteryPlus"></div>
                      <div className="batteryBody">
                        {getUnknownKnowledges(groupData.knowledgesData).map((_, index) => (
                          <div key={index} className="batteryElement"></div>
                        ))}
                        {getKnownKnowledges(groupData.knowledgesData).map((_, index) => (
                          <div key={index} className="batteryElement know"></div>
                        ))}
                      </div>
                    </div>
                  }
                </div>
                <div className="groupInfo-block">
                  <div className="groupKnowledgesStatus">
                    {getKnowKnowledges(groupData.knowledgesData)}/{groupData.knowledgesData.length} знань
                  </div>
                  <div className="groupName">{groupData.groupName}</div>

                  <div className="groupStatusAndCorona">
                    <div className={`groupStatus ${groupData.groupEndKnow ? "know" : ""}`}>
                      {groupData.groupEndKnow ? "Вивчено" : "Вивчення"}
                    </div>
                    {groupData.groupEndKnow && (
                      <div className="level-line">
                        <div className="image-container">
                          <img src={CoronaLevelOne} alt="icon" />
                        </div>
                        <div className="level-label">1-й рівень</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="groupKnowledges">
                {groupData.knowledgesData.map((knowledgeData) => (
                  <div className={`groupKnowledge ${knowledgeData.actualKnow ? "greenBorder" : ""}`}>
                    <div className="groupKnowledgeBattery-block">
                      {!knowledgeData.actualKnow && (
                        <div className="battery">
                          <div className="batteryPlus"></div>
                          <div className="batteryBody">
                            <div
                              className={`batteryFiller delta ${getDeltaColorClassName(knowledgeData.endShowStrength)}`}
                              style={{
                                height: `calc(${
                                  knowledgeData.endShowStrength - knowledgeData.startShowStrength
                                }% + 5px)`,
                              }}
                            ></div>
                            <div
                              className={`batteryFiller ${getColorClassName(knowledgeData.endShowStrength)}`}
                              style={{
                                height: `${knowledgeData.startShowStrength}%`,
                              }}
                            ></div>
                            <img className="fillerLines" src={BatteryFillerLines} alt="batteryFillerLines" />
                          </div>
                        </div>
                      )}
                      {knowledgeData.actualKnow && <img src={battery} alt="battery" />}
                    </div>
                    {knowledgeData.newKnowInPeriod && <div className="knowInPeriod">Нове</div>}
                    <div className="groupKnowledgeInfo-block">
                      <div className="knowledgeName">{knowledgeData.knowledgeTag.name}</div>

                      {knowledgeData.endKnow && <div className="knowStatus"> Вивчено</div>}
                      {!knowledgeData.endKnow && (
                        <div className="knowProgress">
                          <div className="knowProgressValue-block">
                            <div className="knowProgressValue">{Math.round(knowledgeData.endShowStrength)}%</div>
                          </div>
                          {showDeltaValues && (
                            <div
                              className={`knowProgressDelta-Block ${
                                Math.round(knowledgeData.endShowStrength - knowledgeData.startShowStrength) > 0 &&
                                "incraseColor"
                              } ${
                                Math.round(knowledgeData.endShowStrength - knowledgeData.startShowStrength) <= 0 &&
                                "leftPadding"
                              }`}
                            >
                              {Math.round(knowledgeData.endShowStrength - knowledgeData.startShowStrength) > 0 && (
                                <img src={DeltaUp} alt="deltaUp" />
                              )}

                              {Math.round(knowledgeData.endShowStrength - knowledgeData.startShowStrength) <= 0 && (
                                <img src={DeltaDot} alt="deltaDot" />
                              )}

                              <div
                                className={`knowProgressDelta ${
                                  Math.round(knowledgeData.endShowStrength - knowledgeData.startShowStrength) > 0 &&
                                  "incraseColor"
                                }`}
                              >
                                {Math.round(knowledgeData.endShowStrength - knowledgeData.startShowStrength)}%
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="footer-logo-image-container">
        <img src={app_logo2} alt="logo" />
      </div>
    </div>
  );
}

export default UserStatisticCard;
