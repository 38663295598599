export const createAPIOptionsEndData = async (method, data = "", headers = {}) => {
  if (method === "GET") return { method: method };
  return {
    method: method,
    body: data,
    headers: headers,
  };
};

export const sendAPIRequest = async (secondPartOfEndPoint, options, id = "") => {
  const firstPartOfEndPoint = secondPartOfEndPoint === "tracking" ? "unity" : "api";
  return fetch(`/${firstPartOfEndPoint}/${secondPartOfEndPoint}/${id}`, options)
    .then((res) => {
      if (res.status === 400) return res;
      if (!res.ok) throw Error(res.statusText);
      return res;
    })
    .then((res) => res.json())
    .catch((error) => console.log(error));
};

const createJsonStringfyData = (data) => {
  return JSON.stringify(data);
};

export const createData = (endPoint, dataToCreate) => {
  return createJsonStringfyData(dataToCreate);
};

export const createHeaders = (endPoint) => {
  return { "Content-Type": "application/json" };
};
