import { Outlet } from "react-router-dom";
import React from "react";
import GoogleGAScript from "./GoogleGAScript";

const GoogleGAProviderLayout = ({ id = "", pathName = "", pathNames = [] }) => {
  const showScript = id && pathNames.includes(pathName);

  return (
    <>
      {showScript && <GoogleGAScript id={id} />}
      <Outlet />
    </>
  );
};

export default GoogleGAProviderLayout;
