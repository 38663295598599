import React, { useRef, useEffect, useMemo } from "react";
import "./DashboardUsers.scss";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

import { useResourcesContext } from "resources";

import { CustomerHeader, CustomerTemplate, DropdownSelector } from "components";

import { sortingAlphabeticallyOptions, sortingActivityOptions } from "constants/constants";

import TableRow from "./Table/TableRow";
import TableHead from "./Table/TableHead";

import { ReactComponent as IconSortActive } from "assets/icons/sortActive.svg";
import { ReactComponent as IconSortNotActive } from "assets/icons/sortNotActive.svg";

const DashboardUsers = () => {
  const {
    userListStore: { userList, updateUser, fetchingList, sortingOrder, setSortingOrder, loadMore, hasMore },
  } = useResourcesContext();

  const listInnerRef = useRef();
  const navigate = useNavigate();

  const handleTileClick = (unityUser) => {
    navigate(`/dashboard/${unityUser._id}`);
  };

  const handleSortChange = (option) => {
    setSortingOrder(option.name);
  };

  const handleScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5 && hasMore && !fetchingList) {
        loadMore();
      }
    }
  };

  useEffect(() => {
    const listRef = listInnerRef.current;
    if (listRef) {
      listRef.addEventListener("scroll", handleScroll);
      return () => {
        listRef.removeEventListener("scroll", handleScroll);
      };
    }
  }, [hasMore, fetchingList]);

  const activeSortingClass = useMemo(
    () => (option) => sortingOrder === option.name ? "highlighted" : "",
    [sortingOrder]
  );

  const activeSortingIcon = useMemo(
    () => (option) => sortingOrder === option.name ? <IconSortActive /> : <IconSortNotActive />,
    [sortingOrder]
  );

  const handleClickDemoStatus = (unityUser) => {
    const updatedUnityUser = { ...unityUser, demo: !unityUser.demo };
    updateUser(unityUser._id, updatedUnityUser);
  };

  return (
    <CustomerTemplate
      header={<CustomerHeader backPath={"/"} />}
      content={
        <>
          <div className="unity-user-list-head">
            <div>
              <span className="unity-user-list-title">Діти</span>
              <span className="unity-user-list-value">{userList && userList.length}</span>
            </div>
            <div className="unity-user-list-sort">
              <DropdownSelector
                withNone={false}
                showArrow={false}
                disableBackground={true}
                placeholder="За абеткою"
                onChange={handleSortChange}
                options={sortingAlphabeticallyOptions}
                selectedOption={sortingAlphabeticallyOptions.find((elem) => elem.name === sortingOrder) || null}
                icon={activeSortingIcon(
                  sortingAlphabeticallyOptions.find((elem) => elem.name === sortingOrder) ||
                    sortingAlphabeticallyOptions[0]
                )}
                className={activeSortingClass(
                  sortingAlphabeticallyOptions.find((elem) => elem.name === sortingOrder) ||
                    sortingAlphabeticallyOptions[0]
                )}
              />
              &nbsp;
              <DropdownSelector
                withNone={false}
                showArrow={false}
                disableBackground={true}
                onChange={handleSortChange}
                placeholder="За активністю"
                options={sortingActivityOptions}
                selectedOption={sortingActivityOptions.find((elem) => elem.name === sortingOrder) || null}
                icon={activeSortingIcon(
                  sortingActivityOptions.find((elem) => elem.name === sortingOrder) || sortingActivityOptions[0]
                )}
                className={activeSortingClass(
                  sortingActivityOptions.find((elem) => elem.name === sortingOrder) || sortingActivityOptions[0]
                )}
              />
            </div>
          </div>
          <div className="unity-user-list-content">
            <TableHead />
            <div className="users-list-wrapper" ref={listInnerRef}>
              {userList.map((unityUser) => (
                <TableRow
                  key={unityUser._id}
                  unityUser={unityUser}
                  onClick={() => handleTileClick(unityUser)}
                  onClickDemoStatus={handleClickDemoStatus}
                />
              ))}
            </div>
          </div>
        </>
      }
    />
  );
};

export default DashboardUsers;
