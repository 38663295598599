import React from "react";
import "./AddGameButton.scss";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";

const AddGameButton = ({ title, className, onClick }) => {
  return (
    <div className={`add-game ${className}`} onClick={onClick}>
      <PlusIcon className="plus-icon" />
      <div className="add-game-title">{title}</div>
    </div>
  );
};
export default AddGameButton;
