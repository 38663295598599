import "./GameSessionTile.scss";
import React, { useEffect, useRef, useState } from "react";
import { GameIcon, GameResults, GroupTile, StageCardUniversal, StatisticEventsView } from "components";
import { useResourcesContext } from "resources";
import { ReactComponent as ArrowCollapse } from "assets/icons/Arrow.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { Tooltip } from "@mui/material";
import axios from "axios";

const GameSessionTile = ({ gameSessionExtra, expanded, onClick, onDoubleClick }) => {
  const singleClickTimoutIdRef = useRef();
  const [showConfig, setShowConfig] = useState(false);

  const [gameConfig, setGameConfig] = useState();
  const [gameSessionEvents, setGameSessionEvents] = useState();

  const {
    imageListStore: { getImagePathById },
  } = useResourcesContext();

  const [fetchingEvents, setFetchingEvents] = useState(false);
  useEffect(() => {
    if (expanded && !gameSessionEvents && !fetchingEvents) {
      setFetchingEvents(true);
      axios
        .get(`/api/statistic/gameSessionEvents?trackingId=${gameSessionExtra.gameSessionId}`)
        .then((res) => res.data)
        .then((res) => {
          setGameSessionEvents(res);
          setFetchingEvents(false);
        })
        .catch((error) => {
          setFetchingEvents(false);
          console.log(error);
        });
    }
  }, [expanded, gameSessionEvents, gameSessionExtra, fetchingEvents]);

  const [fetchingConfig, setFetchingConfig] = useState(false);
  useEffect(() => {
    if (expanded && !gameConfig && !fetchingConfig) {
      setFetchingConfig(true);
      axios
        .get(`/api/statistic/gameSessionGameConfig?trackingId=${gameSessionExtra.gameSessionId}`)
        .then((res) => res.data)
        .then((res) => {
          setGameConfig(res);
          setFetchingConfig(false);
        })
        .catch((error) => {
          setFetchingConfig(false);
          console.log(error);
        });
    }
  }, [expanded, gameConfig, gameSessionExtra, fetchingConfig]);

  const handleOnClick = (e) => {
    e.stopPropagation();
    singleClickTimoutIdRef.current = setTimeout(onClick, 200);
  };

  const handleOnDoubleClick = (e) => {
    e.stopPropagation();
    clearTimeout(singleClickTimoutIdRef.current);
    onDoubleClick();
  };

  const handleTagsBarClick = (e) => {
    expanded && e.stopPropagation();
  };

  const getTime = (timestamp) => {
    const newDate = new Date(timestamp);
    return `${newDate.getUTCHours()}:${newDate.getUTCMinutes()}`;
  };

  return (
    <GroupTile className="gameSession-border" mode={"single"}>
      <div
        className={`game-session-tile-component ${expanded ? "game-session-tile-component-expanded" : ""}`}
        onDoubleClick={handleOnDoubleClick}
        onClick={handleOnClick}
      >
        <div className="gst-header">
          {gameSessionExtra.gamesSet?.icons?.length && (
            <div className="gst-gamesSet">
              {gameSessionExtra.gamesSet &&
                gameSessionExtra.gamesSet.icons
                  .filter((iconData) => iconData.gameId !== gameSessionExtra.game?._id)
                  .map((iconData) =>
                    iconData.iconConfig ? (
                      <StageCardUniversal
                        className="gst-gamesSet-stageIcon"
                        stage={iconData.iconConfig}
                        controls={false}
                      />
                    ) : (
                      <GameIcon
                        className="gst-gamesSet-icon"
                        gameIcon={iconData.iconSrc || getImagePathById(iconData.icon)}
                      />
                    )
                  )}
            </div>
          )}
          <div className="gst-name-row">
            <div className="gst-game-time">{`${getTime(gameSessionExtra.createdAt)}  (${
              gameSessionExtra.gameTime
            })`}</div>
            <div className="gst-game-name">{`${
              gameSessionExtra.shortGameConfig._id ? gameSessionExtra.shortGameConfig.name : "Гра не знайдена"
            } `}</div>
          </div>
        </div>
        <div className="gst-middle">
          <div className="gst-main-block">
            <div className="gst-game-icon">
              {gameSessionExtra.shortGameConfig.iconConfig ? (
                <StageCardUniversal
                  className="gst-game-icon-stageIcon"
                  stage={gameSessionExtra.shortGameConfig.iconConfig}
                  controls={false}
                />
              ) : (
                <GameIcon
                  gameIcon={
                    gameSessionExtra.shortGameConfig.iconSrc || getImagePathById(gameSessionExtra.shortGameConfig.icon)
                  }
                />
              )}
            </div>
          </div>
          <div className="gst-results-block" onClick={handleTagsBarClick}>
            <GameResults gameResults={gameSessionExtra.results} />
          </div>
          <div className="gst-buttons-block"></div>
        </div>
        {expanded && (
          <div className="gst-stages-view-wraper">
            {gameConfig?.game &&
              gameConfig.game.stages.map((stage) => (
                <StageCardUniversal className="gst-stages-view-stageIcon" stage={stage} controls={false} />
              ))}
          </div>
        )}
        {expanded && (
          <div className="events-view-wrapper">
            <StatisticEventsView gameSessionEvents={gameSessionEvents} />
          </div>
        )}
        {expanded && (
          <div
            className="game-config-wrapper"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className={"header"} onClick={() => setShowConfig(!showConfig)}>
              <div className={"title"}>Конфігурація гри</div>
              <div className="buttons">
                <Tooltip title="Відкрити конфігурацію в новому вікні">
                  <div
                    className={`openConfigOnNewPage-button ${showConfig ? "" : ""}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      const x = window.open("_blank");
                      x.document.write(
                        `<html><body><pre>${JSON.stringify(
                          gameConfig.unityGame || gameConfig.game || null,
                          null,
                          2
                        )}</pre></body></html>`
                      );
                    }}
                  >
                    <CopyIcon />
                  </div>
                </Tooltip>
                <div className={`collapser ${showConfig ? "flip" : ""}`} onClick={() => setShowConfig(!showConfig)}>
                  <ArrowCollapse />
                </div>
              </div>
            </div>
            {showConfig && (
              <div className="content">
                <pre>
                  {JSON.stringify(gameConfig.unityGame || gameConfig.game || "Відсутні данні конфігурації", null, 4)}
                </pre>
              </div>
            )}
          </div>
        )}
        <div className="gst-footer"></div>
      </div>
    </GroupTile>
  );
};

export default GameSessionTile;
