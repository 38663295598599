import { getGameTypes, getGameNameFromLabel, getGameKnowledges, knowledgesIncludeString, getUpperCasedFilterValues } from "./gameFiltersSubHelper";

export const filterGamesByTypes = (games, filter) => {
  const allGameTypes = getGameTypes();

  return games.filter((game) => {
    return filter.value.some((value) => {
      return game.gameType === getGameNameFromLabel(value, allGameTypes);
    });
  });
};

export const filterGamesByName = (games, filter) => {
  return games.filter((game) => {
    return (
      game.name.toUpperCase().includes(filter.value.toUpperCase()) ||
      knowledgesIncludeString(getGameKnowledges(game), filter.value)
    );
  });
};

export const filterGamesByCreators = (games, filter) => {
  return games.filter((game) => filter.value.includes(game.createdBy)) 
};

export const filterGamesByKnowledgeTypes = (games, filter) => {
  return games.filter((game) => {
    return (
      (filter.value.includes("Навчальні") && game.knowledges?.uncontrolled?.length) ||
      (filter.value.includes("Контролюючі") && game.knowledges?.controlled?.length)
    );
  });
};

export const filterGamesByKnowledges = (games, filter) => {
  return games.filter((game) => {
    return getGameKnowledges(game).some((gameKnowledge) => {
      const upperCasedFilterValues = getUpperCasedFilterValues(filter)
      
      return upperCasedFilterValues.includes(gameKnowledge.toUpperCase());
    });
  });
};
