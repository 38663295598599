export const enUk = {
  about_bulbee_first_header: "Individual approach ",
  about_bulbee_first_text: "each game is designed for a specific child",
  about_bulbee_fourth_header: "Transferring skills from the tablet to real life",
  about_bulbee_fourth_text: "A system that engages parents to help transfer skills from the tablet to real life",
  about_bulbee_header: "About Bulbee",
  about_bulbee_sec_header: "All-round development of the child during the game",
  about_bulbee_sec_text:
    "Develop memory, attention, speech development, classify and generalize concepts, develop fine motor skills and visual-motor coordination, perseverance and purposefulness.",
  about_bulbee_third_header: "Interaction of parents with specialists",
  about_bulbee_third_text: "An opportunity for parents to get professional help and support",
  about_bulbee_subheader: "It is a unique educational platform that combines ",

  active_subscription_text: "Your subscription is active",

  benefits_header: "Don`t waste your time!",
  benefits_subheader:
    "Bulbee will help prepare a child for kindergarten and school in an exciting, interesting and simple way.",
  benefit_1_header: "Get real life skills",
  benefit_1_text: "Also, the child will be able to develop independence, intelligence and awareness.",
  benefit_2_header: "Effective and interesting learning",
  benefit_2_text: "We pay attention to whether children understand the learning process",
  benefit_3_header: "Consolidation of knowledge",
  benefit_3_text: "Alternating new knowledge and skills with already acquired ones so that the child feels successful.",
  benefit_4_header: "Under the control of specialists",
  benefit_4_text: "Are you worried about your child's progress?",
  benefit_5_header: "Psychological support of parents",
  benefit_5_text: "Are you worried about your child's progress?",
  benefit_6_header: "Incentives for small achievements",
  benefit_6_text: "Are you worried about your child's progress?",

  created_for_brain: "Are you worried about your child's progress?",
  created_for_skills: "Do you want to improve knowledge and skills?",
  created_for_book: "Do you dream of revealing your child's potential?",
  created_for_heart: "Your child's success will make you happy",
  created_for_header: "The project was created by parents for parents",
  created_for_subheader:
    "We understand you, because we ourselves are parents who have gone through the same path and understand like no one else what needs to be done and how to act to achieve success",

  confirm_yes: "Yes",
  confirm_no: "No",
  form_header: "Join the family of ",
  form_lock_text:
    "By clicking on the button, I agree to the processing of data for a specific action, with the rules of using the platform. All data is protected and not transferred to third parties",
  form_subheader: "We want to help you out and we know how",

  header_button_join: "Get in touch",
  header_button_login: "Log in",
  history_bulbee: "Bulbee",
  history_header: "The history of the founding parents of ",
  history_paragraph_1: "Привіт! Нас звати Юля і Вова. Ми сім’я, яка створила Bulbee.",
  history_paragraph_21: "А починалося все з нашого власного досвіду.",
  history_paragraph_22:
    "Коли ріс наш старший синочок ми зіткнулися з певними труднощами, діагнози затримка розвитку мовлення, дитячий аутизм та ін. ми чули неодноразово. А от шо це таке і що з цим робити, ото була загадка. Ми реально відвідали купу центрів та приватних спеціалістів, і всі  рекомендували різні методики та підходи, для однієї і тієї дитини. Якісь методики працювали, якісь зовсім ні. Але не було якогось одного злагодженого плану дій. Сказати, що ми були розгублені - нічого не сказати. Ми перечитали купу інформації, ознайомилися з купою методик, але це нас заплутало ще більше… В решті решт нам пощастило і ми знайшли гарного спеціаліста, і далі все пішло вперед і вгору, але ж пошуки зайняли  4.5 роки! І тоді ми почали думати про те що в наш час високих технологій та штучного інтелекту повинен бути розумний комплекс для розвитку дітей.",
  history_paragraph_3:
    "Забігаючи наперед скажу, що зараз наш син навчається в звичайній школі в Англії і всі його попередні діагнози ніяк йому не заважають.",
  history_paragraph_4:
    "Пізніше ми зустріли ще одну сімейну пару, Наташу та Арсенія, з такими ж проблемами і бажанням якось це виправити.",
  history_paragraph_5:
    "Вони так само на прийомі у невролога вперше почули  про червоні прапорці у дитини і ще багато чого про аутизм. Були шоковані, багато чого не зрозуміли. Навіть не уявляли яки довгий та складний шлях їм доведеться пройти. Той день змінив їхнє життя.",
  history_paragraph_6:
    "А потім Наташа зустріла мій пост у Facebook, де я розповідала про успіхи свого сина. Наташа зв'язалася з нами і ми почали спілкуватися та ділитися своїм досвідом.",
  history_paragraph_7:
    "Потім ми вирішили, що хочемо допомагати батькам, які опинилися в такій же ситуації, разом робити цінний продукт для розвитку особливих дітей.",
  read_more: "Read more",

  how_to_join_header: "How to join?",
  how_to_join_subheader: "We want to help you out and we know how",
  how_to_join_step_1: "Leave your contacts ",
  how_to_join_step_1_text: "Are you worried about your child's progress?",
  how_to_join_step_2: "Speak with a specialist",
  how_to_join_step_2_text: "Are you worried about your child's progress?",
  how_to_join_step_3: "Take part in the project",
  how_to_join_step_3_text: "Are you worried about your child's progress?",

  input_placeholder_address: "Email address",
  input_placeholder_name: "Name",
  input_placeholder_id: "Child ID",

  join_us_header: "Join us",
  join_us_subheader: "We want to help you out and we know how",
  join_family_header: "Join to our family",

  link_about: "About the subscription",
  link_child_btn: "Link",
  link_child_header: "Link your child",
  link_child_how_to: "Fill out the form with the ID of the child you want to link to your account",
  link_contacts: "Contacts",
  link_menu: "Menu",
  link_profile: "Profile",
  link_account: "Account",
  link_about_us: "About us",
  logout: "Log out",
  overview_child: "child",
  overview_header: "Designed to help you create the happiest future for yours",
  overview_link: "You are at the right place",
  overview_subheader: "The greatest happiness of parents is to support the success of their child",
  policy_description: "Privacy Policy",
  problems_card_1: "He is so naughty. Does not want to learn at all",
  problems_card_2: "I want him to spend time on the tablet with benefits",
  problems_card_3: "Does not respond to my desires",
  problems_card_4: "I need clear instructions on how to act in different situations",
  problems_card_5: "Distracted",
  problems_card_6: "She doesn't understand what I'm saying to her",
  problems_header: "What problems of parents we ",
  problems_header_span: "can help you ",
  problems_header_solve: "to solve",
  problems_subheader: "We want to help you out and we know how",

  profile_content_link_child: "Link your child",
  profile_content_link_header: "Navigation",
  profile_content_link_subscribe: "Manage your subscription",
  payment_successfull_header: "Payment successfull",
  payment_successfull_text: "Thank you for your payment. Your payment has been successfully processed.",
  payment_cancelled_header: "Payment cancelled",
  payment_cancelled_text: "Your payment has been cancelled. You will be redirected to your profile in 3 seconds.",

  rights_description: "All rights reserved 2023",

  subscription_confirm_cancel_text: "Are you sure you want to cancel the subscription?",
  subscription_confirm_uncancel_text: "Are you sure you want to uncancel the subscription?",

  subscription_create_btn: "Subscribe",
  subscription_cancel_btn: "Cancel subscription",
  subscription_uncancel_btn: "Uncancel subscription",

  subscription_canceled_end_date: "Subscription is active until ",
  subscription_header: "Manage your subscription",
  manage_subscriptiption_btn: "Manage subscription",
  subscription_subheader: "We want to help you out and we know how",
  subscription_end_date: "Next time you will be charged on ",
  subscription_per_month: "month",
  subscription_per_monthly: "Monthly",
  subscription_status: "Status",
  subscription_status_active: "Active",
  subscription_status_inactive: "Inactive",
  subscription_status_canceled: "Canceled",
  subscription_next_payment: "Next Payment Date",
  subscription_period: "Period",
  subscription_last_payment: "Last payment",
  subscription_uncancel_tooltip: "Automatic renewal is currently canceled. Resume now.",
  subscription_page_header: "What do you get with a subscription?",
  subscription_no_prev_payment: "No previous payments",
  subscription_after_action: "Please, contact us after payment for further instructions",
  terms_and_conditions: "Terms & Conditions",

  contacts_header: "Contacts",

  consultation: "Initial consultation",
  consultation_description: " with the remedial teacher and the author of the educational platform",
  testing: "Initial testing",
  testing_description: " of the child to determine the level of his development and educational needs",
  analysis: "Continuous analysis and testing",
  analysis_description: " child during education",
  games: "Unlimited number of games",
  games_description: " on a platform adapted to your child's development",
  games_stack: "Individual set of games",
  games_stack_description: " for the child in the app",
  exercises: "Regular game exercises",
  exercises_description:
    " created for parents to connect the knowledge gained in the application with the real" + " world",
  video: "Analysis of the videos",
  video_description: " with the parents interaction during co-play with the child",
  adaptation: "Continuous adaptation",
  adaptation_description: " of the learning process depending on the child's success and its features",
  language: "Opportunity to choose a language",
  language_description: " of learning",
  price: "Price",
};
