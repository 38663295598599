import { PROD_DIGITALOCEAN_URL, QA_DIGITALOCEAN_URL } from "constants/constants";

const getDigitalOceanUrl = (host) => {
  if (host.includes("localhost")) {
    return QA_DIGITALOCEAN_URL;
  }
  if (host.includes("qa.bulbee")) {
    return QA_DIGITALOCEAN_URL;
  }
  return PROD_DIGITALOCEAN_URL;
};

export { getDigitalOceanUrl };
