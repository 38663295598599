import { itemStartActions, taskCorrectReactions, taskIncorrectReactions } from "constants/constants";
import "./CommonGameParamsTab.scss";
import React from "react";
import { CardStyled, DropdownSelector } from "components";
import { Checkbox } from "@mui/material";

const CommonGameParamsTab = ({ unityUser, onUserChange }) => {
  return (
    <CardStyled className="gameOptions-tab-content">
      <div className="checkbox-with-label waitForQuestionCheckbox-block">
        <Checkbox
          disabled={false}
          checked={unityUser.commonGameOptions?.waitForQuestion}
          onChange={(event) => {
            unityUser.commonGameOptions = {
              ...(unityUser.commonGameOptions || {}),
              waitForQuestion: event.target.checked,
            };

            onUserChange({ ...unityUser });
          }}
          className="checkbox"
        />
        Очікувати питання
      </div>
      <div className="task-reaction-selector-block">
        <div className="task-reaction-selector-label">Реакція неправильно:</div>
        <DropdownSelector
          className="task-reaction-selector"
          withNone={false}
          options={taskIncorrectReactions}
          selectedOption={
            taskIncorrectReactions.find(
              (reaction) => reaction.name === unityUser.commonGameOptions?.incorrectReaction
            ) || ""
          }
          onChange={(option) => {
            unityUser.commonGameOptions = {
              ...(unityUser.commonGameOptions || {}),
              incorrectReaction: option.name,
            };

            onUserChange({ ...unityUser });
          }}
        />
      </div>
      <div className="task-reaction-selector-block">
        <div className="task-reaction-selector-label">Реакція правильно:</div>
        <DropdownSelector
          className="task-reaction-selector"
          withNone={false}
          options={taskCorrectReactions}
          selectedOption={
            taskCorrectReactions.find((reaction) => reaction.name === unityUser.commonGameOptions?.correctReaction) ||
            ""
          }
          onChange={(option) => {
            unityUser.commonGameOptions = {
              ...(unityUser.commonGameOptions || {}),
              correctReaction: option.name,
            };

            onUserChange({ ...unityUser });
          }}
        />
      </div>
      <div className="task-reaction-selector-block">
        <div className="task-reaction-selector-label">Реакція навчання:</div>
        <DropdownSelector
          className="task-reaction-selector"
          withNone={false}
          options={taskCorrectReactions}
          selectedOption={
            taskCorrectReactions.find(
              (reaction) => reaction.name === unityUser.commonGameOptions?.learningCorrectReaction
            ) || ""
          }
          onChange={(option) => {
            unityUser.commonGameOptions = {
              ...(unityUser.commonGameOptions || {}),
              learningCorrectReaction: option.name,
            };

            onUserChange({ ...unityUser });
          }}
        />
      </div>
      <div className="task-reaction-selector-block">
        <div className="task-reaction-selector-label">Ефект появи (навчання):</div>
        <DropdownSelector
          className="task-reaction-selector"
          options={itemStartActions}
          selectedOption={
            itemStartActions.find((action) => action.name === unityUser.commonGameOptions?.itemStartActionLearn) || ""
          }
          onChange={(option) => {
            unityUser.commonGameOptions = {
              ...(unityUser.commonGameOptions || {}),
              itemStartActionLearn: option.name,
            };
            onUserChange({ ...unityUser });
          }}
        />
      </div>
      <div className="task-reaction-selector-block">
        <div className="task-reaction-selector-label">Ефект появи (контроль):</div>
        <DropdownSelector
          className="task-reaction-selector"
          options={itemStartActions}
          selectedOption={
            itemStartActions.find((action) => action.name === unityUser.commonGameOptions?.itemStartActionControl) || ""
          }
          onChange={(option) => {
            unityUser.commonGameOptions = {
              ...(unityUser.commonGameOptions || {}),
              itemStartActionControl: option.name,
            };
            onUserChange({ ...unityUser });
          }}
        />
      </div>
    </CardStyled>
  );
};

export default CommonGameParamsTab;
