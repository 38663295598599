import { useState } from "react";
import levenshtein from "js-levenshtein";

const useKnowledgeTagList = () => {
  const [list, setList] = useState([]);

  const getKnowledgeTagList = () => {
    return fetch(`/api/knowledgeTags`, { method: "GET" })
      .then((res) => res.json())
      .then((res) => {
        setList(res);
        return res;
      })
      .catch((error) => {
        setList([]);
        console.log(error);
        return [];
      });
  };

  const createKnowledgeTag = (knowledgeTag) => {
    if (knowledgeTag.name) {
      if (list.some((tag) => String(tag.name).toLowerCase() === String(knowledgeTag.name).toLowerCase())) {
        return new Promise((resolve, reject) => resolve(null));
      }
    }

    return new Promise((resolve, reject) => {
      fetch("/api/knowledgeTags", {
        method: "POST",
        body: JSON.stringify(knowledgeTag),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((res) => {
          getKnowledgeTagList();
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
          resolve(null);
        });
    });
  };

  const updateKnowledgeTag = (knowledgeTag) => {
    if (knowledgeTag.name) {
      if (
        list.some((tag) => {
          return String(tag.name).toLowerCase() === String(knowledgeTag.name).toLowerCase();
        })
      ) {
        return new Promise((resolve, reject) => resolve(null));
      }
    }

    return new Promise((resolve, reject) => {
      fetch(`/api/knowledgeTags/${knowledgeTag._id}`, {
        method: "PUT",
        body: JSON.stringify(knowledgeTag),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => res.json())
        .then((res) => {
          getKnowledgeTagList();
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
          getKnowledgeTagList();
          resolve(null);
        });
    });
  };

  const deleteKnowledgeTag = (knowledgeTag) => {
    return new Promise((resolve, reject) => {
      fetch(`/api/knowledgeTags/${knowledgeTag._id}`, { method: "DELETE", headers: {} })
        .then((res) => res.json())
        .then((res) => {
          getKnowledgeTagList();
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
          getKnowledgeTagList();
          resolve(null);
        });
    });
  };

  const getSimilarKnowledgeTags = (knowledgeString, matchPercent) => {
    const similarKnowledgeTags = list
      .map((tag) => {
        if (!tag.name.length) {
          return null;
        }

        return knowledgeString
          .split(" ")
          .filter(Boolean)
          .map((stringPart) => {
            const distance = levenshtein(stringPart.toLowerCase(), tag.name.toLowerCase());
            const tagMatchPercent = Math.round(100 - (distance * 100) / Math.max(stringPart.length, tag.name.length));
            return tagMatchPercent >= matchPercent ? { tagMatchPercent, tag } : null;
          })
          .find(Boolean);
      })
      .filter(Boolean)
      .sort((a, b) => {
        if (a.tagMatchPercent > b.tagMatchPercent) return -1;
        if (a.tagMatchPercent < b.tagMatchPercent) return 1;
        return 0;
      })
      .map((elem) => elem.tag);

    return similarKnowledgeTags;
  };

  return {
    knowledgeTagList: list,
    createKnowledgeTag,
    updateKnowledgeTag,
    deleteKnowledgeTag,
    getSimilarKnowledgeTags,
    getKnowledgeTagList,
  };
};

export default useKnowledgeTagList;
