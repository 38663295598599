import "./Badge.scss"

const Badge = ({ content }) => {
  return (
    <div className={"badge"}>
      <span>{content}</span>
    </div>
  )
}

export default Badge
