import "./Stage.scss";
import React, { useEffect, useMemo, useState } from "react";
import {
  SoundSelector,
  CardStyled,
  ActiveItem,
  MainScreenUniversal,
  TaskItems,
  DropdownSelector,
  StyledInput,
  Tabs,
  ImageLibrary,
  Layers,
  StageItemLibrary,
  AlertDialog,
} from "components";
import { v4 as newStageItemId } from "uuid";
import { useBufferContext, useResourcesContext } from "resources";
import { ReactComponent as PlusIcon } from "assets/icons/bigPlus.svg";
import { ReactComponent as ArrowCollapse } from "assets/icons/Arrow.svg";
import { Tooltip } from "@material-ui/core";
import {
  dragDefaultCorrectReaction,
  dragDefaultIncorrectReaction,
  tapDefaultCorrectReaction,
  tapDefaultIncorrectReaction,
  taskCorrectReactions,
  taskIncorrectReactions,
} from "constants/constants";
import _ from "lodash";
import { findParentAndItem, findRootParentItem, getItemsWithChildrenPlain } from "helpers/stageItems";

const createStageItem = (selectedImage) => {
  let stageItem = {
    stageItemId: newStageItemId(),
    _id: selectedImage._id,
    valueX: 0,
    valueY: 0,
    scale: 100,
    scaleX: 100,
    scaleY: 100,
    angle: 0,
    flipHorizontal: false,
    flipVertical: false,
    blur: 0,
    opacity: 100,
  };

  return stageItem;
};

const Stage = ({ stage, onChange, locked, externalTabs = [] }) => {
  const [selectedTaskIndex, setSelectedTaskIndex] = useState(null);
  const [selectedItemsBlockIndex, setSelectedItemsBlockIndex] = useState(null);
  const [activeItemStageId, setActiveItemStageId] = useState();
  const [pairMode, setPairMode] = useState(false);
  const [showTaskAdditional, setShowTaskAdditional] = useState(false);
  //const [activeTab, setActiveTab] = useState("Бібліотека");
  const [activeTab, setActiveTab] = useState("Cлої");
  const [alertDialogData, setAlertDialogData] = useState(null);

  const activeItem = useMemo(() => {
    return stage && activeItemStageId ? findParentAndItem(stage, activeItemStageId)?.item : null;
  }, [activeItemStageId, stage]);

  const { itemCopyPastBuffer, setItemCopyPastBuffer } = useBufferContext();

  const {
    soundListStore: { soundList },
    tagListStore: { tagList },
  } = useResourcesContext();

  useEffect(() => {
    setSelectedTaskIndex(null);
    setSelectedItemsBlockIndex(null);
    setActiveItemStageId(null);
    setPairMode(false);
  }, [stage]);

  useEffect(() => {
    if (stage) {
      if (!stage.tasks?.length) {
        stage.tasks = [
          {
            soundId: "",
            type: "tap",
            correctReaction: tapDefaultCorrectReaction,
            incorrectReaction: tapDefaultIncorrectReaction,
            items: [],
          },
        ];
        onChange();
      }
      setSelectedTaskIndex(0);
      setShowTaskAdditional(stage.tasks.length && stage.tasks[0]?.soundId);
    }
  }, [stage]);

  const handleLibraryImageSelect = (libraryImage) => {
    if (libraryImage) {
      const imageHasTag = (image, tagName) => {
        return image.tags.some((imageTag) => {
          const imageTagName = tagList.find((tag) => tag._id === imageTag)?.name;
          return imageTagName === tagName;
        });
      };

      if (imageHasTag(libraryImage, "background") && stage) {
        stage.items?.length
          ? (stage.items[0] = { _id: libraryImage._id, stageItemId: newStageItemId() })
          : (stage.items = [{ _id: libraryImage._id, stageItemId: newStageItemId() }]);

        onChange();
        return;
      }

      // items array must have at least 1 item - background
      if (!stage?.items?.length) {
        return;
      }

      if (stage) {
        const newActiveItem = createStageItem(libraryImage);
        stage.items ? stage.items.push(newActiveItem) : (stage.items = [newActiveItem]);

        handleSorterClick(newActiveItem);

        onChange();
      }
    }
  };

  const addPairToBlock = (activeId, passiveId, blockIndex) => {
    const activeFinded = stage.tasks[selectedTaskIndex].items[blockIndex].active.includes(activeId);
    const passiveFinded = stage.tasks[selectedTaskIndex].items[blockIndex].passive.includes(passiveId);

    activeFinded && !passiveFinded && stage.tasks[selectedTaskIndex].items[blockIndex].passive.push(passiveId);
    !activeFinded && passiveFinded && stage.tasks[selectedTaskIndex].items[blockIndex].active.push(activeId);

    return activeFinded || passiveFinded;
  };

  const getEmptyItemsBlock = () => {
    return { active: [], passive: [] };
  };

  const handleSorterClick = (sorterItem) => {
    if (sorterItem.stageItemId === activeItem?.stageItemId) {
      return;
    }
    setActiveItemStageId(sorterItem.stageItemId);
    setShowTaskAdditional(false);

    if (pairMode && activeItem && typeof selectedTaskIndex === "number") {
      const rootActiveStageItemId = findRootParentItem(stage.items, activeItem.stageItemId).stageItemId;
      const rootPassiveStageItemId = findRootParentItem(stage.items, sorterItem.stageItemId).stageItemId;

      if (typeof selectedItemsBlockIndex === "number") {
        stage.tasks[selectedTaskIndex].items[selectedItemsBlockIndex].active = [rootActiveStageItemId];
        stage.tasks[selectedTaskIndex].items[selectedItemsBlockIndex].passive = [rootPassiveStageItemId];
        setSelectedItemsBlockIndex(null);
      } else {
        if (!stage.tasks[selectedTaskIndex].items) {
          stage.tasks[selectedTaskIndex].items = [getEmptyItemsBlock()];
        }
        const pairAdded = stage.tasks[selectedTaskIndex].items.some((itemsBlock, itemsBlockIndex) =>
          addPairToBlock(rootActiveStageItemId, rootPassiveStageItemId, itemsBlockIndex)
        );
        !pairAdded &&
          stage.tasks[selectedTaskIndex].items.push({
            active: [rootActiveStageItemId],
            passive: [rootPassiveStageItemId],
          });
      }
      setPairMode(false);
      onChange();
    }
  };

  const addItemToTasksInTapMode = (item) => {
    if (!stage.tasks[selectedTaskIndex].items?.length) {
      stage.tasks[selectedTaskIndex].items = [{ active: [] }];
    }

    if (!stage.tasks[selectedTaskIndex].items[0].active) {
      stage.tasks[selectedTaskIndex].items[0].active = [];
    }

    const rootStageItemId = findRootParentItem(stage.items, item.stageItemId).stageItemId;

    if (!stage.tasks[selectedTaskIndex].items[0].active.includes(rootStageItemId)) {
      stage.tasks[selectedTaskIndex].items[0].active.push(rootStageItemId);
    }
    onChange();
  };

  const deleteActiveItem = () => {
    const activeItemChildren = getItemsWithChildrenPlain(activeItem.items || []);
    [activeItem, ...activeItemChildren].forEach((item) => removeItemFromTasks(item.stageItemId));
    const { parent, index } = findParentAndItem(stage, activeItem.stageItemId);
    parent.items.splice(index, 1);
    setActiveItemStageId(null);
    onChange();
  };

  const removeItemFromTasks = (stageItemId) => {
    let configChanged = false;
    stage.tasks?.forEach((task) =>
      task.items?.forEach((itemsBlock) => {
        const activeIndex = itemsBlock.active?.indexOf(stageItemId);
        activeIndex > -1 && itemsBlock.active.splice(activeIndex, 1);
        const passiveIndex = itemsBlock.passive?.indexOf(stageItemId);
        passiveIndex > -1 && itemsBlock.passive.splice(passiveIndex, 1);
        configChanged = activeIndex > -1 || passiveIndex > -1;
      })
    );
    configChanged && onChange();
  };

  const isUsedItem = (stageItemId) => {
    return stage.tasks?.some((task) =>
      task.items?.some((itemsBlock) => {
        const activeIndex = itemsBlock.active?.indexOf(stageItemId);
        const passiveIndex = itemsBlock.passive?.indexOf(stageItemId);
        return activeIndex > -1 || passiveIndex > -1;
      })
    );
  };

  const handleDeleteTaskItemsBlock = (itemsBlockIndex) => {
    stage.tasks[selectedTaskIndex].items.splice(itemsBlockIndex, 1);

    typeof selectedItemsBlockIndex === "number" &&
      selectedItemsBlockIndex > itemsBlockIndex &&
      setSelectedItemsBlockIndex(selectedItemsBlockIndex - 1);

    typeof selectedItemsBlockIndex === "number" &&
      selectedItemsBlockIndex === itemsBlockIndex &&
      setSelectedItemsBlockIndex(null);

    onChange();
  };

  const handlePressCopy = () => {
    activeItem && setItemCopyPastBuffer(_.cloneDeep(activeItem));
  };

  const handlePressLayerItemCopy = (item) => {
    item && setItemCopyPastBuffer(_.cloneDeep(item));
  };

  const handlePressPaste = () => {
    const itemClone = _.cloneDeep(itemCopyPastBuffer);
    const renewStageItemId = (item) => {
      item.stageItemId = newStageItemId();
      item.items?.forEach((childItem) => renewStageItemId(childItem));
    };
    if (itemClone) {
      renewStageItemId(itemClone);
      itemClone.valueX = itemClone.valueX + Math.random() * 40 - 40;
      itemClone.valueY = itemClone.valueY + Math.random() * 40 - 40;
      stage.items.push(itemClone);
      onChange();
    }
  };

  const handleSelectLayersItem = (stageItemId) => {
    setActiveItemStageId(stageItemId);
    setShowTaskAdditional(false);
  };

  const handleDropLayersItem = (destParentId, destIndex, dropedStageItemId) => {
    if (destParentId === dropedStageItemId) {
      return;
    }

    const { item: destItem } =
      (destParentId === "root" && { item: stage }) || findParentAndItem(stage, destParentId) || {};
    const { parent: sourceItem, item: dropedItem } = findParentAndItem(stage, dropedStageItemId) || {};

    if (findParentAndItem(dropedItem, destItem.stageItemId)) {
      return;
    }

    const reverseDestIndex = (destItem.items || []).length - destIndex;
    // stage has else one leyer with index 0 but its not showing in the list - background
    const realDestIndex = destParentId === "root" ? reverseDestIndex : reverseDestIndex;
    const destPartOne = !destItem.items ? [] : destItem.items.slice(0, realDestIndex);
    const destPartTwo =
      !destItem.items || realDestIndex === destItem.items.length ? [] : destItem.items.splice(realDestIndex);
    const destNewItems = [...destPartOne, dropedItem, ...destPartTwo];

    if (destItem === sourceItem) {
      const oldItemIndex = destNewItems.findIndex((item, index) => item === dropedItem && index !== realDestIndex);
      destNewItems.splice(oldItemIndex, 1);
      destItem.items = destNewItems;
    } else {
      const sourceNewItems = sourceItem.items.map((item) => item !== dropedItem && item).filter(Boolean);
      sourceItem.items = sourceNewItems;
      destItem.items = destNewItems;
    }
    correctTasksItemsNesting();
    onChange();
  };

  const correctTasksItemsNesting = () => {
    if (stage?.tasks)
      stage.tasks = stage.tasks.map((task) => {
        const newTask = { ...task };
        if (task.items) {
          newTask.items = task.items.map((itemsBlock) => {
            const newItemsBlock = {};
            if (itemsBlock.active) {
              newItemsBlock.active = Array.from(
                new Set(itemsBlock.active.map((activeId) => findRootParentItem(stage.items, activeId).stageItemId))
              );
            }
            if (itemsBlock.passive) {
              newItemsBlock.passive = Array.from(
                new Set(itemsBlock.passive.map((passiveId) => findRootParentItem(stage.items, passiveId).stageItemId))
              );
            }
            return newItemsBlock;
          });
        }
        return newTask;
      });
  };

  const handleItemTemplateClick = (itemTemplate) => {
    const newItem = _.cloneDeep(itemTemplate);
    newItem.stageItemId = newStageItemId();
    const newSubItemsPlain = getItemsWithChildrenPlain(newItem.items || []);
    newSubItemsPlain.forEach((item) => (item.stageItemId = newStageItemId()));
    stage.items.push(newItem);
    handleSorterClick(newItem);
    onChange();
  };

  const makeAllLayersStatic = () => {
    const stageItemsPlain = getItemsWithChildrenPlain(stage.items || []);
    stageItemsPlain.forEach((item, index) => {
      if (index > 0) {
        item.static = true;
      }
    });
    onChange();
  };

  const openSetAllStaticDialog = () => {
    setAlertDialogData({
      onOk: async () => {
        makeAllLayersStatic();
        setAlertDialogData(null);
      },
      onCancel: () => setAlertDialogData(null),
      title: "Зміна параметрів групи слоїв",
      message: "Зробити всі слої static?",
    });
  };

  return (
    <>
      {stage && (
        <div className="universal-game-stage-component">
          <div className="left-box-wrapper">
            <div className="stage-common-content">
              <CardStyled className="game-main-screen">
                <MainScreenUniversal
                  stageItemsPlain={getItemsWithChildrenPlain(stage.items || [])}
                  activeItem={activeItem}
                  passiveItem={null}
                  onPressDelete={deleteActiveItem}
                  onPressCopy={handlePressCopy}
                  onPressPaste={handlePressPaste}
                  onSorterClick={handleSorterClick}
                  onChange={onChange}
                  locked={locked}
                />
              </CardStyled>

              <div className="task-editor-wrapper">
                <div className="task-editor-top-wrapper">
                  <div className="tasks-tabs-block">
                    {stage?.tasks &&
                      stage.tasks.map((task, index) => (
                        <div
                          key={index}
                          className={`tab-button ${index === selectedTaskIndex ? "selected" : ""}`}
                          onClick={() => {
                            setSelectedTaskIndex(index);
                            setSelectedItemsBlockIndex(null);
                          }}
                        >
                          {index + 1}
                        </div>
                      ))}
                  </div>

                  {typeof selectedTaskIndex === "number" && stage?.tasks?.length && (
                    <div className="task-trigger-block">
                      <div className="task-trigger-label">Завдання:</div>
                      <SoundSelector
                        soundType={"taskTrigger"}
                        value={soundList.find(
                          (sound) =>
                            typeof selectedTaskIndex === "number" &&
                            sound._id === stage?.tasks[selectedTaskIndex].soundId
                        )}
                        onChangeValue={(sound) => {
                          stage.tasks[selectedTaskIndex].soundId = sound ? sound._id : "";
                          onChange();
                        }}
                        onSoundRecorded={(createdSound) => {
                          stage.tasks[selectedTaskIndex].soundId = createdSound._id;
                          onChange();
                        }}
                      />
                    </div>
                  )}

                  {typeof selectedTaskIndex === "number" && stage?.tasks?.length && (
                    <div className="task-type-block">
                      <div className="task-type-label">Тип завдання:</div>
                      <div
                        className={`type-button ${
                          typeof selectedTaskIndex === "number" && stage.tasks[selectedTaskIndex].type === "tap"
                            ? "selected"
                            : ""
                        }`}
                        onClick={() => {
                          if (typeof selectedTaskIndex === "number") {
                            if (stage.tasks[selectedTaskIndex].type !== "tap") {
                              stage.tasks[selectedTaskIndex].items = [];
                            }
                            stage.tasks[selectedTaskIndex].type = "tap";
                            stage.tasks[selectedTaskIndex].correctReaction = tapDefaultCorrectReaction;
                            stage.tasks[selectedTaskIndex].incorrectReaction = tapDefaultIncorrectReaction;
                            onChange();
                          }
                        }}
                      >
                        tap
                      </div>
                      <div
                        className={`type-button ${
                          typeof selectedTaskIndex === "number" && stage.tasks[selectedTaskIndex].type === "drag"
                            ? "selected"
                            : ""
                        }`}
                        onClick={() => {
                          if (typeof selectedTaskIndex === "number") {
                            if (stage.tasks[selectedTaskIndex].type !== "drag") {
                              stage.tasks[selectedTaskIndex].items = [];
                            }
                            stage.tasks[selectedTaskIndex].type = "drag";
                            stage.tasks[selectedTaskIndex].correctReaction = dragDefaultCorrectReaction;
                            stage.tasks[selectedTaskIndex].incorrectReaction = dragDefaultIncorrectReaction;
                            onChange();
                          }
                        }}
                      >
                        drag
                      </div>
                    </div>
                  )}
                  {typeof selectedTaskIndex === "number" && stage?.tasks?.length && (
                    <Tooltip
                      title={`${showTaskAdditional ? "Сховати додаткові параметри" : "Показати додаткові параметри"}`}
                    >
                      <div
                        className={`collapser ${showTaskAdditional ? "flip" : ""}`}
                        onClick={() => setShowTaskAdditional(!showTaskAdditional)}
                      >
                        <ArrowCollapse />
                      </div>
                    </Tooltip>
                  )}
                </div>
                {typeof selectedTaskIndex === "number" && stage?.tasks?.length && showTaskAdditional && (
                  <div className="task-editor-additional-wrapper">
                    <div className="task-controlled-knowledge-block">
                      <div className="task-controlled-knowledge-label">Контролююче знання: </div>
                      <StyledInput
                        className={"task-controlled-knowledge-input"}
                        value={stage.tasks[selectedTaskIndex].controlledKnowledge || ""}
                        onChange={(event) => {
                          stage.tasks[selectedTaskIndex].controlledKnowledge = event.target.value;
                          onChange();
                        }}
                      />
                    </div>
                    <div className="task-reactions-block">
                      <div className="task-reaction-selector-block">
                        <div className="task-reaction-selector-label">Реакція на правильну дію:</div>
                        <DropdownSelector
                          className="task-reaction-selector"
                          options={taskCorrectReactions}
                          selectedOption={
                            taskCorrectReactions.find(
                              (reaction) => reaction.name === stage.tasks[selectedTaskIndex].correctReaction
                            ) || ""
                          }
                          onChange={(option) => {
                            stage.tasks[selectedTaskIndex].correctReaction = option ? option.name : "";
                            onChange();
                          }}
                        />
                      </div>
                      <div className="task-reaction-selector-block">
                        <div className="task-reaction-selector-label">Реакція на неправильну дію:</div>
                        <DropdownSelector
                          className="task-reaction-selector"
                          options={taskIncorrectReactions}
                          selectedOption={
                            taskIncorrectReactions.find(
                              (reaction) => reaction.name === stage.tasks[selectedTaskIndex].incorrectReaction
                            ) || ""
                          }
                          onChange={(option) => {
                            stage.tasks[selectedTaskIndex].incorrectReaction = option ? option.name : "";
                            onChange();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="task-editor-middle-wrapper">
                  <CardStyled className="item-editor">
                    {activeItem && (
                      <>
                        <div className="item-active-wrapper">
                          <ActiveItem
                            gameType={"universal"}
                            disabled={locked}
                            item={activeItem}
                            onChange={onChange}
                            onDelete={deleteActiveItem}
                            deleteButton={true}
                            canBeStatic={!isUsedItem(findRootParentItem(stage?.items || [], activeItem.stageItemId))}
                          />
                        </div>
                        <div className="item-active-buttons-block">
                          {typeof selectedTaskIndex === "number" &&
                            stage?.tasks?.length &&
                            stage.tasks[selectedTaskIndex].type === "drag" && (
                              <button
                                disabled={locked || activeItem.static || !(typeof selectedTaskIndex === "number")}
                                className={`add-remove-item-button add-pair-button ${pairMode ? "pair-mode" : ""}`}
                                title={"Обрати пару"}
                                onClick={() => {
                                  setPairMode(!pairMode);
                                }}
                              >
                                <PlusIcon />
                              </button>
                            )}
                          {typeof selectedTaskIndex === "number" &&
                            stage?.tasks?.length &&
                            stage.tasks[selectedTaskIndex].type === "tap" && (
                              <button
                                disabled={
                                  locked ||
                                  activeItem.static ||
                                  !(typeof selectedTaskIndex === "number") ||
                                  isUsedItem(findRootParentItem(stage?.items || [], activeItem.stageItemId))
                                }
                                className={`add-remove-item-button add-pair-button`}
                                title={"Додати"}
                                onClick={() => {
                                  addItemToTasksInTapMode(activeItem);
                                }}
                              >
                                <PlusIcon />
                              </button>
                            )}
                        </div>
                      </>
                    )}
                  </CardStyled>
                  <CardStyled className="task-items">
                    {typeof selectedTaskIndex === "number" && stage?.tasks?.length && (
                      <TaskItems
                        taskType={stage?.tasks[selectedTaskIndex].type}
                        taskItems={stage?.tasks[selectedTaskIndex].items}
                        stageItemsPlain={getItemsWithChildrenPlain(stage.items || [])}
                        selectedStageItem={activeItem}
                        selectedItemsBlockIndex={selectedItemsBlockIndex}
                        onItemBlockClick={() => {}}
                        onItemClick={(stageItemId) => {
                          setActiveItemStageId(stageItemId);
                          setShowTaskAdditional(false);
                        }}
                        onAddItemsBlock={() => {
                          if (typeof selectedItemsBlockIndex !== "number") {
                            if (stage.tasks[selectedTaskIndex].items) {
                              stage.tasks[selectedTaskIndex].items.push(getEmptyItemsBlock());
                            } else {
                              stage.tasks[selectedTaskIndex].items = [getEmptyItemsBlock()];
                            }
                            setSelectedItemsBlockIndex(stage.tasks[selectedTaskIndex].items.length - 1);
                            onChange();
                          } else {
                            stage.tasks[selectedTaskIndex].items.splice(selectedItemsBlockIndex, 1);
                            setSelectedItemsBlockIndex(null);
                            onChange();
                          }
                        }}
                        onDeleteItemsBlock={handleDeleteTaskItemsBlock}
                      />
                    )}
                  </CardStyled>
                </div>
              </div>
            </div>
          </div>
          <div className="right-box-wrapper">
            <Tabs
              className="tabs-block"
              tabs={["Бібліотека", "Cлої", "Групи", ...externalTabs.map((extTab) => extTab.tabLabel)]}
              activeTab={activeTab}
              onChange={setActiveTab}
            />
            <div className="tabs-content-block">
              {activeTab === "Бібліотека" && (
                <ImageLibrary
                  className="universal-game-stage-image-library"
                  selectedImageId={activeItem?._id}
                  onImageSelect={handleLibraryImageSelect}
                  withoutSelection
                  imagesOnPage={100}
                  disableOnImageSelect={locked}
                />
              )}
              {activeTab === "Cлої" && (
                <div className="layers-block">
                  <div className="layers-block-top-bar">
                    <Tooltip title="Зробити всі слої static" placement="left" arrow>
                      <button className="button-style" onClick={openSetAllStaticDialog}>
                        <span>Static</span>
                      </button>
                    </Tooltip>
                  </div>
                  <Layers
                    items={
                      stage?.items?.length
                        ? stage.items
                            .slice(1)
                            .map((element) => element)
                            .reverse()
                        : []
                    }
                    parentId={"root"}
                    selectedStageItemId={activeItemStageId}
                    onItemClick={handleSelectLayersItem}
                    onItemCopy={handlePressLayerItemCopy}
                    onPressPaste={handlePressPaste}
                    onDropItem={handleDropLayersItem}
                  />
                </div>
              )}
              {activeTab === "Групи" && (
                <StageItemLibrary onItemCopy={handlePressLayerItemCopy} onItemTemplateClick={handleItemTemplateClick} />
              )}
              {externalTabs.map((extTab) => activeTab === extTab.tabLabel && extTab.tabJsx)}
            </div>
          </div>
          <AlertDialog alertDialogData={alertDialogData} />
        </div>
      )}
    </>
  );
};

export default Stage;
