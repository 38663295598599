const calculateAge = (birthdayYear, birthdayMonth) => {
  if (!birthdayYear || isNaN(Number(birthdayYear))) {
    return "";
  }

  let years = 0;
  let months = 0;

  const now = new Date();
  const birthDate = new Date(birthdayYear, birthdayMonth ? birthdayMonth - 1 : 0);

  years = now.getFullYear() - birthDate.getFullYear();
  months = now.getMonth() - birthDate.getMonth();

  if (months < 0 || (months === 0 && now.getDate() < birthDate.getDate())) {
    years--;
    months = months < 0 ? 12 + months : months;
  }

  return `${years}.${months}`;
};

export default calculateAge;