import React, { useEffect, useState } from "react";

const DragDropWrapper = ({ data, onDrop, draggable = false, onDragEnd, children }) => {
  const [dragOver, setDragOver] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [element, setElement] = useState();

  useEffect(() => {
    const handleDrop = (e) => {
      const dropedData = JSON.parse(e.dataTransfer.getData("text"));
      setDragOver(false);
      setDragging(false);
      onDrop && onDrop(dropedData);
      e.stopPropagation();
    };

    const isChildHtmlElement = (parent, childToCheck) => {
      return Array.from(parent.children).some(
        (child) => child === childToCheck || isChildHtmlElement(child, childToCheck)
      );
    };

    const handleDragEnter = (e) => {
      setDragOver(true);
    };

    const handleDragLeave = (e) => {
      element != e.relatedTarget && !isChildHtmlElement(element, e.relatedTarget) && setDragOver(false);
    };

    const handleDragStart = (e) => {
      e.dataTransfer.effectAllowed = "all";
      e.dataTransfer.dropEffect = "none";
      e.dataTransfer.setData("text", JSON.stringify(data));
      setDragging(true);
    };

    const handleDragEnd = (e) => {
      setDragging(false);
      onDragEnd && onDragEnd(JSON.parse(data));
    };

    const handleDragOver = (e) => {
      e.preventDefault();
    };

    if (element) {
      element.addEventListener("dragover", handleDragOver);
      element.addEventListener("dragenter", handleDragEnter);
      element.addEventListener("dragleave", handleDragLeave);
      element.addEventListener("drop", handleDrop);
      if (draggable) {
        element.addEventListener("dragstart", handleDragStart);
        element.addEventListener("dragend", handleDragEnd);
        element.setAttribute("draggable", Boolean(draggable));
      }
      return () => {
        element.removeEventListener("dragover", handleDragOver);
        element.removeEventListener("dragenter", handleDragEnter);
        element.removeEventListener("dragleave", handleDragLeave);
        element.removeEventListener("drop", handleDrop);
        if (draggable) {
          element.removeEventListener("dragstart", handleDragStart);
          element.removeEventListener("dragend", handleDragEnd);
        }
      };
    }
  }, [element, data, draggable, onDrop, onDragEnd]);

  const handleDropDownElementMounted = (element) => {
    setElement(element);
  };

  return React.cloneElement(children, {
    dragging,
    dragOver,
    onDropDownElementMounted: handleDropDownElementMounted,
  });
};

export default DragDropWrapper;
