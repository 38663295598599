import { useState } from "react";

const useCommonStates = () => {
  const [itemCopyPastBuffer, setItemCopyPastBuffer] = useState();
  const [planElementCopyPasteBuffer, setPlanElementCopyPasteBuffer] = useState();

  return {
    itemCopyPastBuffer,
    setItemCopyPastBuffer,

    planElementCopyPasteBuffer,
    setPlanElementCopyPasteBuffer,
  };
};

export default useCommonStates;
