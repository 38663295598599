import "./JoinUsForm.scss";
import React from "react";
import Socials from "components/Socials/Socials";
import { useI18N } from "i18n";

const JoinUsForm = () => {
  const { I18N } = useI18N();

  return (
    <div className="joinUsForm background">
      <div className="joinUsForm-content">
        <div className="joinUsForm-content__heading">
          <h3>
            <I18N id="join_family_header" />
            <span>
              <I18N id="history_bulbee" />
            </span>
          </h3>
        </div>
        <Socials />
        <div className="joinUsForm-content__heading">
          <p>
            <I18N id="join_us_subheader" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default JoinUsForm;
