export const gameFilterStructure = [
  {
    type: "text",
    label: "Пошук за іменем",
    name: "name",
    value: "",
  },
  {
    type: "multi",
    label: "Типи ігр",
    name: "gameTypes",
    value: [],
    options: [{
      value: "",
      count: 0,
    }],
    settings: [
      "checkBox",
    ]
  },
  {
    type: "multi",
    label: "Типи знань",
    name: "knowledgeTypes",
    value: [],
    options: [{
      value: "",
      count: 0,
    }],
    settings: [
      "checkBox",
    ]
  },
  {
    type: "multi",
    label: "Знання",
    name: "knowledges",
    value: [],
    options: [{
      value: "",
      count: 0,
    }],
    settings: [
      "withSearch",
      "selectedShownAbove",
      "checkBox",
    ]
  },
  {
    type: "single",
    label: "Автори",
    name: "creators",
    value: "",
    options: [{
      value: "",
      count: 0,
    }]
  },
]
