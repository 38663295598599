import "./ImageList.scss";
import React, { useEffect, useState } from "react";
import { CardStyled } from "components";
import ImageListElement from "./ImageListElement";

const ImageList = ({
  imageList,
  selectedImageId,
  onImageSelect,
  onImageDelete,
  withoutSelection,
  imagesOnPage,
  untagged,
  styles = null,
}) => {
  const [page, setPage] = useState(1);
  const [slicedImageList, setSlicedImageList] = useState();

  useEffect(() => setPage(1), []);

  useEffect(() => {
    if (imageList && page) {
      let list = imageList;

      if (untagged) {
        list = list.filter((image) => !image.tags || !image.tags.length);
      }

      !imagesOnPage && setSlicedImageList(list);
      imagesOnPage && setSlicedImageList(list.slice(page * imagesOnPage - imagesOnPage, page * imagesOnPage));
    }
  }, [imageList, imagesOnPage, page, untagged]);

  return (
    <CardStyled className="image-list-wrapper" style={styles}>
      <div className="image-box">
        {slicedImageList &&
          slicedImageList.map((image, index) => {
            return (
              <ImageListElement
                key={image._id}
                image={image}
                selectedImageId={selectedImageId}
                onImageSelect={onImageSelect}
                withoutSelection={withoutSelection}
                onDelete={onImageDelete}
              />
            );
          })}
      </div>
    </CardStyled>
  );
};
export default ImageList;
