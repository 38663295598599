import _ from "lodash";
import { useCallback, useState } from "react";

const useUndoRedoBuffer = () => {
  const [buffer, setBuffer] = useState([]);
  const [index, setIndex] = useState(-1);

  const addToUndoBuffer = useCallback(
    (data) => {
      setBuffer((prevBuffer) => {
        const newBuffer = [...prevBuffer.slice(0, index + 1), _.cloneDeep(data)];
        setIndex(newBuffer.length - 1);
        return newBuffer;
      });
    },
    [index]
  );

  const undo = useCallback(() => {
    if (index <= 0) {
      return { data: null, index };
    }

    setIndex(index - 1);
    return { data: _.cloneDeep(buffer[index - 1]), index: index - 1 };
  }, [index, buffer]);

  const redo = useCallback(() => {
    if (index === buffer.length - 1) {
      return { data: null };
    }
    setIndex(index + 1);
    return { data: _.cloneDeep(buffer[index + 1]), index: index + 1 };
  }, [index, buffer]);

  const init = useCallback((data) => {
    setIndex(data ? 0 : -1);
    setBuffer(data ? [_.cloneDeep(data)] : []);
  }, []);

  return { addToUndoBuffer, undo, redo, init };
};
export default useUndoRedoBuffer;
