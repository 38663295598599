import { languages } from "constants/constants";


export const getSoundBySoundSetId = (soundSetId, soundListWithSoundSetIdField, preferedLanguage) => {
  if (!soundSetId) {
    return null;
  }

  const sounds = soundListWithSoundSetIdField.filter((sound) => sound.soundSetId === soundSetId);

  return (
    (preferedLanguage && sounds.find((sound) => sound.language === preferedLanguage)) ||
    sounds.find((sound) => sound.language === "uk") ||
    sounds.find((sound) => sound.language === "ru") ||
    sounds.find((sound) => sound.language === "en") ||
    sounds.find((sound) => !sound.language)
  );
};

export const getUserLangSound = (soundId, userLanguage, soundSets, sounds) => {
  if (!soundId) {
    return "";
  }

  const soundSet = soundSets.find((soundSet) => soundSet.sounds.includes(soundId));
  if (soundSet) {
    const soundSetSounds = soundSet.sounds.filter(Boolean);
    const sound = sounds.find((sound) => soundSetSounds.includes(sound._id) && sound.language === userLanguage);
    return sound || "";
  }

  const sound = sounds.find((sound) => {
    return sound._id === soundId && sound.language === userLanguage;
  });
  if (sound) {
    return sound;
  }

  return "";
};

export const getAllLangSoundsIds = (soundId, soundSets, sounds) => {
  return languages
    .map((lang) => getUserLangSound(soundId, lang.name, soundSets, sounds))
    .filter(Boolean)
    .map((sound) => sound._id);
};
