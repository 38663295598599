import React from "react";
import googlePlayLogo from "assets/landing/icons/Google-Play.svg";
import appStoreLogo from "assets/landing/icons/Apple.svg";
import appStoreLogoLight from "assets/landing/icons/Apple-light.svg";
import { googlePlayUrl, appStoreUrl } from "constants/constants.js";
import { ReactComponent as PlayMarketButton } from "assets/appmarkets/googlePlay.svg";
import { ReactComponent as AppStoreButton } from "assets/appmarkets/appStore.svg";
import "./AppMarketLinkButton.scss";

const AppMarketLinkButton = ({ appMarket, theme = "dark", onlyLogo, blinking, useSvg = true }) => {
  const buttonConfigs = {
    "play-market": {
      logo: googlePlayLogo,
      title: "Завантажити з",
      storeName: "Google Play",
      url: googlePlayUrl,
    },
    "app-store": {
      logo: theme === "light" ? appStoreLogo : appStoreLogoLight,
      title: "Завантажити з",
      storeName: "App Store",
      url: appStoreUrl,
    },
  };

  const { logo, title, storeName, url } = buttonConfigs[appMarket];

  return useSvg ? (
    <a className="appMarketLinkButtonSvgHref" href={url} target="_blank">
      {appMarket === "play-market" ? <PlayMarketButton /> : <AppStoreButton />}
    </a>
  ) : (
    <a
      className={`appMarketLinkButtonHref ${blinking ? "blinking" : ""} ${onlyLogo ? "onlyLogo" : ""} ${theme}`}
      href={url}
      target="_blank"
    >
      <div className={`button-container`}>
        <img src={logo} alt={storeName} />
        {!onlyLogo && (
          <div className="button-container_text">
            <span className="button-container_text__title">{title}</span>
            <span className="button-container_text__store-name">{storeName}</span>
          </div>
        )}
      </div>
    </a>
  );
};

export default AppMarketLinkButton;
