import "./GroupTile.scss";
import React from "react";

const GroupTile = ({
  mode,
  className = "",
  tile1ClassName = "",
  tile2ClassName = "",
  tile3ClassName = "",
  children,
  withoutTranform,
  onClick = () => {},
}) => {
  const addClassIfSerial = (mode) => (mode === "serial" ? " group-serial" : "");

  return (
    <>
      {mode === "single" ? (
        <div
          className={`single-tile-component ${withoutTranform ? "no-hover-transforms" : ""} ${className}`}
          onClick={onClick}
        >
          {children}
        </div>
      ) : (
        <div
          className={
            `group-tile-component ${withoutTranform ? "no-hover-transforms" : ""} ${className}` + addClassIfSerial()
          }
          onClick={onClick}
        >
          <div className={`tile1 group-game-tile1-background ${tile1ClassName}` + addClassIfSerial(mode)}>
            <div className={`tile2 group-game-tile2-background ${tile2ClassName}` + addClassIfSerial(mode)}>
              <div className={`tile3 group-game-tile-background ${tile3ClassName}` + addClassIfSerial(mode)}>
                {children}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GroupTile;
