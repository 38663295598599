import React from "react";
import "./Table.scss";

const CustomerHead = () => {
  return (
    <div className="customer-tile-wrapper" style={{ border: 0, borderRadius: 8, background: '#F2F3F3' }}>
      <div className="tile-content">
        <div className="tile-row">
          <div className="tile-cell">
            Дитина
          </div>
          <div className="tile-cell">
            Вік
          </div>
          <div className="tile-cell">
            Дата створення
          </div>
          <div className="tile-cell">
            Режим
          </div>
          <div className="tile-cell">
            Статус
          </div>
          <div className="tile-cell">
            Остання активність
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerHead;