import "./BindedBundleKnowledges.scss";
import { useResourcesContext } from "resources";
import React, { useCallback, useEffect, useRef, useState } from "react";
import LinkIcon from "@mui/icons-material/Link";
import Tooltip from "@mui/material/Tooltip";
import _, { get } from "lodash";
import axios from "axios";
import { LazyLoadComponent } from "react-lazy-load-image-component";

const BindedBundleKnowledges = ({ soundSetId, bundlesKnowledges, withSearch = false }) => {
  const searchInputRef = useRef(null);
  const {
    assetBundleListStore: { assetBundleList, updateAssetBundleLocal },
  } = useResourcesContext();

  const [linkedBundlesKnowledges, setLinkedBundlesKnowledges] = useState([]);
  const [unlinkedBundlesKnowledges, setUnlinkedBundlesKnowledges] = useState([]);

  const getUnlinkedFilteredBundlesKnowledges = useCallback(
    (bundlesKnowledges, searchValue) => {
      const unlinkedBundlesKnowledges = bundlesKnowledges.filter((bundleKnowledge) => !bundleKnowledge.soundSet);

      if (!searchValue || !withSearch) return unlinkedBundlesKnowledges;

      return unlinkedBundlesKnowledges.filter((bundleKnowledge) => {
        return bundleKnowledge.bundleName.toLowerCase().includes(searchValue.toLowerCase());
      });
    },
    [withSearch]
  );

  const filterBundlesKnowledgesDebounced = useCallback(
    _.debounce((searchValue) => {
      setUnlinkedBundlesKnowledges(getUnlinkedFilteredBundlesKnowledges(bundlesKnowledges, searchValue));
    }, 300),
    [bundlesKnowledges]
  );

  useEffect(() => {
    soundSetId
      ? setLinkedBundlesKnowledges(
          bundlesKnowledges.filter((bundleKnowledge) => bundleKnowledge.soundSet === soundSetId)
        )
      : setLinkedBundlesKnowledges([]);
  }, [soundSetId, bundlesKnowledges]);

  useEffect(() => {
    soundSetId
      ? setUnlinkedBundlesKnowledges(getUnlinkedFilteredBundlesKnowledges(bundlesKnowledges))
      : setUnlinkedBundlesKnowledges([]);
  }, [soundSetId, bundlesKnowledges, getUnlinkedFilteredBundlesKnowledges]);

  const setBundleKnowledgeSoundSet = (bundleKnowledge, newSoundSetId) => {
    if (!bundleKnowledge) return;
    if (bundleKnowledge.soundSet === newSoundSetId) return;
    if (!bundleKnowledge.bundleId) return;

    const assetBundle = assetBundleList.find((bundle) => bundle._id === bundleKnowledge.bundleId);
    const newBundle = _.cloneDeep(assetBundle);
    newBundle.knowledges[bundleKnowledge.knowledgeIndex].soundSet = newSoundSetId;
    axios
      .put(`/api/assetBundles/${newBundle._id}`, newBundle)
      .then((res) => {
        updateAssetBundleLocal(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="bindedBundleKnowledges-component">
      {withSearch && (
        <div className="bundlesKnowledges-filter-box">
          <input
            ref={searchInputRef}
            className={`searchValue-input`}
            placeholder={"Пошук ..."}
            onChange={(event) => {
              filterBundlesKnowledgesDebounced(event.target.value);
            }}
          />
        </div>
      )}
      <div className="bindedBundleKnowledges-list">
        {linkedBundlesKnowledges.map((bundleKnowledge) => (
          <div className={`bindedBundleKnowledge-card active`}>
            <Tooltip title="Відлінкувати">
              <div className="selectIconWrapper" onClick={() => setBundleKnowledgeSoundSet(bundleKnowledge, "")}>
                <LinkIcon className="selectIcon active" />
              </div>
            </Tooltip>

            <div className="bundle-name-box">
              <div className="label">Бандл:</div>
              <div className="bundle-name"> {bundleKnowledge.bundleName}</div>
            </div>

            <div className="knowledge-items-list">
              {bundleKnowledge.items.map((knowledgeItem, index) => (
                <div className="knowledge-item" key={index}>
                  <img src={knowledgeItem.imagePath} alt="" />
                </div>
              ))}
            </div>
          </div>
        ))}
        <div className="splitter"></div>
        {unlinkedBundlesKnowledges.map((bundleKnowledge) => (
          <LazyLoadComponent>
            <div className={`bindedBundleKnowledge-card`}>
              <Tooltip title="Залінкувати">
                <div
                  className="selectIconWrapper"
                  onClick={() => setBundleKnowledgeSoundSet(bundleKnowledge, soundSetId)}
                >
                  <LinkIcon className="selectIcon" />
                </div>
              </Tooltip>

              <div className="bundle-name-box">
                <div className="label">Бандл:</div>
                <div className="bundle-name"> {bundleKnowledge.bundleName}</div>
              </div>

              <div className="knowledge-items-list">
                {bundleKnowledge.items.map((knowledgeItem, index) => (
                  <div className="knowledge-item" key={index}>
                    <img src={knowledgeItem.imagePath} alt="" />
                  </div>
                ))}
              </div>
            </div>
          </LazyLoadComponent>
        ))}
      </div>
    </div>
  );
};

export default BindedBundleKnowledges;
