import "./StatisticEventsView.scss";
import React, { useState, useEffect, useCallback } from "react";
import Tooltip from "@mui/material/Tooltip";

const StatisticEventsView = ({ gameSessionEvents }) => {
  const [timelineWidth, setTimelineWidth] = useState(0);
  const [trackTime, setTrackTime] = useState(0);
  const [events, setEvents] = useState([]);
  const [timelineColumnNode, setTimelineColumnNode] = useState(null);

  const onTimelineColumnChange = useCallback((node) => {
    if (node) {
      setTimelineWidth(node.clientWidth);
      setTimelineColumnNode(node);
    }
  }, []);

  const handleResizeWindow = () => {
    setTimelineWidth(timelineColumnNode.clientWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  });

  useEffect(() => {
    if (gameSessionEvents?.length) {
      const newEvents = [];
      gameSessionEvents.forEach((track) => {
        !newEvents.includes(track.type) && track.time >= 0 && newEvents.push(track.type);
      });
      setEvents(newEvents);

      const getMaxOfArray = (numArray) => {
        return Math.max.apply(null, numArray);
      };
      setTrackTime(getMaxOfArray(gameSessionEvents.map((element) => element.time)));
    } else {
      setTrackTime(null);
    }
  }, [gameSessionEvents]);

  const eventPosition = (eventTime) => {
    const left = ((timelineWidth - 30) / trackTime) * eventTime + 13;
    return { top: 2, left: left };
  };

  const timeLineElementPosition = (time) => {
    const left = ((timelineWidth - 30) / trackTime) * time;
    return { top: 3, left: left };
  };

  const timeLine = (size) => {
    if (trackTime) {
      const timeLineArr = [0];
      for (let step = 0; step < size; step++) {
        timeLineArr.push(((step + 1) * trackTime) / size);
      }
      return timeLineArr;
    }
    return [];
  };

  const getTimeMMSS = (timeMs) => {
    const minutes = Math.floor(timeMs / 1000 / 60);
    const seconds = Math.round(timeMs / 1000 - minutes * 60);
    return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
  };

  return (
    <div className="statistic-events-view-component">
      {!events.length ? (
        <div className="no-events-message">Відсутня статистика або час старту ігри.</div>
      ) : (
        <>
          <div className="events-view-header">
            <div className="events-view-header-col1">Event type</div>
            <div className="events-view-header-col2">Timelapse</div>
          </div>

          {events.map((eventType, eventIndex) => (
            <div key={eventType} className="events-view-content">
              <Tooltip key={eventIndex} title={eventType} followCursor={true} arrow={true}>
                <div className="events-view-col1">{eventType}</div>
              </Tooltip>
              <div ref={onTimelineColumnChange} className="events-view-col2">
                {gameSessionEvents
                  .filter((gameTrackEvent) => gameTrackEvent.type === eventType)
                  .map((gameTrackEvent, index) => (
                    <Tooltip key={index} title={JSON.stringify(gameTrackEvent)} followCursor={true} arrow={true}>
                      <div
                        key={"gameTrackEvent" + index}
                        className="events-view-element"
                        style={{
                          backgroundColor: gameTrackEvent.color,
                          position: "absolute",
                          top: `${eventPosition(gameTrackEvent.time).top}px`,
                          left: `${eventPosition(gameTrackEvent.time).left}px`,
                        }}
                      ></div>
                    </Tooltip>
                  ))}
              </div>
            </div>
          ))}

          <div className="events-view-footer">
            <div className="events-view-footer-col1">{`Total time: ${getTimeMMSS(trackTime)}`} </div>
            <div className="events-view-footer-col2">
              {timeLine(10).map((time) => {
                return (
                  <div
                    key={time}
                    className="events-view-footer-element"
                    style={{
                      position: "absolute",
                      top: `${timeLineElementPosition(time).top}px`,
                      left: `${timeLineElementPosition(time).left}px`,
                    }}
                  >
                    <span className="vertical-line">❙</span>
                    <span className="footer-element-text">{`${(time / 1000).toFixed(1)}`}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default StatisticEventsView;
