import "./ParentHistory.scss";
import React, { useState } from "react";
import { useI18N } from "i18n";

import parent_one from "assets/landing/history/history_parent.png";
import parent_two from "assets/landing/history/history_parent_2.png";
import { sendGtagData } from "helpers/sendGtagEvent";

const ParentHistory = () => {
  const { I18N } = useI18N();
  const [showFullHistory, setShowFullHistory] = useState(false);

  return (
    <section id={"about_us"} className="history">
      <div className="history-images-left">
        <img className="history-images-left__p1" src={parent_one} alt="parent_one" />
        <img className="history-images-left__p2" src={parent_two} alt="parent_two" />
      </div>
      <div className="history_text">
        <div className="history_text__heading">
          <h3>
            <I18N id="history_header" />
            <span>
              <I18N id="history_bulbee" />
            </span>
          </h3>
          <img src="" alt="" />
        </div>
        <div className="history-images-inside">
          <img className="history-images-inside__p1" src={parent_one} alt="parent_one" />
          <img className="history-images-inside__p2" src={parent_two} alt="parent_two" />
        </div>
        <p>
          <I18N id="history_paragraph_1" />
        </p>
        <p>
          <I18N id="history_paragraph_21" />
          {!showFullHistory && (
            <div className="read-more" onClick={() => {setShowFullHistory(!showFullHistory)
              sendGtagData("event", "parent_history_read_more");
            }}>
              <I18N id="read_more" />
            </div>
          )}
        </p>

        {showFullHistory && (
          <>
            <p>
              <I18N id="history_paragraph_22" />
            </p>
            <p>
              <I18N id="history_paragraph_3" />
            </p>
            <p>
              <I18N id="history_paragraph_4" />
            </p>
            <p>
              <I18N id="history_paragraph_5" />
            </p>
            <p>
              <I18N id="history_paragraph_6" />
            </p>
            <p>
              <I18N id="history_paragraph_7" />
            </p>
          </>
        )}
      </div>
    </section>
  );
};

export default ParentHistory;
