import React, { useEffect, useState, useMemo } from 'react';
import debounce from 'lodash.debounce';

const SearchFilter = ({ filter, onFilterChange }) => {
  const [searchString, setSearchString] = useState(filter?.value || '');

  const handleChange = useMemo(
    () => debounce((value) => onFilterChange({ ...filter, value }), 1000),
    []
  );
  
  useEffect(() => {
    return () => {
      handleChange.cancel();
    };
  }, [handleChange]);

  const handleFilterChange = (event) => {
    const searchString = event.target.value;
    handleChange(searchString)
    setSearchString(searchString);
  };

  return (
    <input
      className="search"
      type="text"
      placeholder={filter?.label}
      onChange={handleFilterChange}
      value={searchString}
    />
  );
};

export default SearchFilter;
