import { Paper, Fade, Grid, makeStyles } from "@material-ui/core";
import React from "react";
import CheckIcon from "@material-ui/icons/Check";
import SaveIcon from "@material-ui/icons/Save";
import ErrorIcon from "@material-ui/icons/Error";
import DeleteIcon from "@material-ui/icons/Delete";
import { CircularProgress } from "@material-ui/core";
import { Fab } from "@material-ui/core";
import { blue } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonSuccess: {
    backgroundColor: blue[500],
    "&:hover": {
      backgroundColor: blue[700],
    },
  },
  fabProgress: {
    color: blue[500],
    position: "absolute",
    top: -2,
    left: -2,
    zIndex: 1,
  },
}));

function FetchStatusElement({ text, icon, withProgress }) {
  const classes = useStyles();
  return (
    <Grid container direction="row" alignItems="center" spacing={1}>
      <Grid item>
        <div className={classes.wrapper}>
          <Fab color="primary" size="small" className={classes.buttonSuccess}>
            {icon}
          </Fab>
          {withProgress && <CircularProgress size={44} className={classes.fabProgress} />}
        </div>
      </Grid>
      <Grid item xs>
        {text}
      </Grid>
    </Grid>
  );
}

export default function FetchStatus({ status }) {
  return (
    <Fade in={status}>
      <Paper elevation={0}>
        {status === "DELETE_FAILURE" && (
          <FetchStatusElement text="Виникла помилка під час збереження." icon={<ErrorIcon />} />
        )}
        {status === "DELETE_INIT" && (
          <FetchStatusElement text="Видалення картинки ..." icon={<DeleteIcon />} withProgress />
        )}
        {status === "DELETE_SUCCESS" && <FetchStatusElement text="Картинка успішно видалена." icon={<CheckIcon />} />}

        {status === "TRANSFORM_FAILURE" && (
          <FetchStatusElement text=" Виникла помилка під час трансформаціі." icon={<ErrorIcon />} />
        )}
        {status === "TRANSFORM_INIT" && (
          <FetchStatusElement text="Обробка зображення ..." icon={<DeleteIcon />} withProgress />
        )}
        {status === "TRANSFORM_SUCCESS" && (
          <FetchStatusElement text="Зображення успішно оброблено." icon={<CheckIcon />} />
        )}

        {status === "SAVE_FAILURE" && (
          <FetchStatusElement text="Виникла помилка під час збереження." icon={<ErrorIcon />} />
        )}
        {status === "SAVE_INIT" && (
          <FetchStatusElement text="Збереження зображення ..." icon={<SaveIcon />} withProgress />
        )}
        {status === "SAVE_SUCCESS" && <FetchStatusElement text="Зображення успішно збережено." icon={<CheckIcon />} />}
      </Paper>
    </Fade>
  );
}
