import "./GamesSessions.scss";
import React, { useEffect, useState } from "react";
import GameSessionTile from "./GameSessionTile/GameSessionTile";
import GameSetTile from "./GameSetTile/GameSetTile";


const GameSessions = ({ gamesSets, gamesSessions = [] }) => {
  const [expandedSessionId, setExpandedSessionId] = useState(null);

  const [gamesSessionsExtra, setGameSessionsExtra] = useState([]);
  const [dayPlayTime, setDayPlayTime] = useState(0);

  useEffect(() => {
    const getGameTimeInSec = (gameTime) => {
      const [min, sec] = gameTime.split(":");
      return parseInt(min) * 60 + parseInt(sec);
    };

    const getMinSecFormt = (timeInSec) => {
      const min = Math.floor(timeInSec / 60);
      const sec = timeInSec % 60;

      return `${min}:${String(sec).length === 1 ? `0${sec}` : sec}`;
    };

    const dayPlayTimeInSec = gamesSessions.reduce((acc, gameSession) => {
      const gameTimeInSec = getGameTimeInSec(gameSession.gameTime);
      if (gameTimeInSec > 200) {
        return acc;
      }
      return acc + gameTimeInSec;
    }, 0);

    setDayPlayTime(getMinSecFormt(dayPlayTimeInSec));
  }, [gamesSessions]);

  useEffect(() => {
    const localGameSessions = gamesSessions || [];
    const localGameSets = gamesSets || [];

    const unusedGameSetsData = localGameSets
      .filter((gameSet) => !gameSet.trackingId)
      .map((gameSet) => {
        return { ...gameSet, isOnlyGameSet: true };
      });

    const gameSessionsData = localGameSessions.map((gameSession) => {
      return {
        ...gameSession,
        gamesSet: localGameSets.find((gamesSet) => gamesSet.trackingId === gameSession.gameSessionId),
      };
    });

    const newGameSessionsExtra = [...unusedGameSetsData, ...gameSessionsData].sort((a, b) => {
      if (a.createdAt > b.createdAt) {
        return 1;
      }
      if (a.createdAt < b.createdAt) {
        return -1;
      }
      return 0;
    });

    setGameSessionsExtra(newGameSessionsExtra);
  }, [gamesSessions, gamesSets]);

  const handleTileClick = (gameSessionId) => {
    if (expandedSessionId === gameSessionId) {
      setExpandedSessionId(null);
    } else {
      setExpandedSessionId(gameSessionId);
    }
  };

  return (
    <div className="games-sessions-component">
      <div className="games-sessions-time">Ігровий час: {dayPlayTime}</div>
      <div className="games-sessions-list">
        {gamesSessionsExtra
          .map((gameSessionExtra, idx) => {
            return (
              <>
                {gameSessionExtra.isOnlyGameSet ? (
                  <GameSetTile key={idx} gameSet={gameSessionExtra} />
                ) : (
                  <GameSessionTile
                    key={idx}
                    gameSessionExtra={gameSessionExtra}
                    expanded={gameSessionExtra.gameSessionId === expandedSessionId}
                    onDoubleClick={() => {}}
                    onClick={() => handleTileClick(gameSessionExtra.gameSessionId)}
                  />
                )}
              </>
            );
          })
          .reverse()}
      </div>
    </div>
  );
};

export default GameSessions;
