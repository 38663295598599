import "./UnityUserTile.scss";
import { Tooltip } from "@mui/material";
import React, { useMemo, useRef } from "react";
import { getDateTimeUa } from "helpers/utils";
import calculateAge from "helpers/calculateAge";
import PushPinIcon from "@mui/icons-material/PushPin";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { ReactComponent as TelegramIcon } from "assets/landing/socials/telegram.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LoginIcon from "@mui/icons-material/Login";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuthz } from "auth/AuthzProvider";
import { ROLE_TEACHER } from "constants/constants";

const UnityUserTile = ({
  className = "",
  unityUser,
  selected,
  onClick,
  onDoubleClick,
  pinned,
  hidden,
  onPushPin,
  onPushHideByDeviceId,
  onPushCancelHideByDeviceId,
  noPin,
  noHideByDeviceId,
}) => {
  const { user, setUser } = useAuthz();
  const singleClickTimoutIdRef = useRef();

  const handleOnClick = (e) => {
    e.stopPropagation();
    singleClickTimoutIdRef.current = setTimeout(onClick, 200);
  };

  const handleOnDoubleClick = (e) => {
    e.stopPropagation();
    clearTimeout(singleClickTimoutIdRef.current);
    onDoubleClick();
  };

  const age = useMemo(() => {
    if (!unityUser) {
      return "";
    }

    return calculateAge(unityUser.birthdayYear, unityUser.birthdayMonth);
  }, [unityUser]);

  const onOpenSendPulse = (event) => {
    event.stopPropagation();
    unityUser.telegramBotClientId &&
      window.open(
        `https://login.sendpulse.com/messengers/chats/6614fc966997603056065538/contacts/all/${unityUser.telegramBotClientId}`,
        "_blank"
      );
  };

  const navigate = useNavigate();
  const handleImpersonate = (event) => {
    event.stopPropagation();
    unityUser.webUserId &&
      axios.get(`/api/auth/impersonate/${unityUser.webUserId}`).then((response) => {
        navigate("/");
        setUser(response.data);
      });
  };

  return (
    <>
      <div
        className={`tile-wrapper ${selected ? "tile-wrapper-selected" : ""} ${className}`}
        onDoubleClick={handleOnDoubleClick}
        onClick={handleOnClick}
      >
        <div className="tile-content">
          <div className="header">
            <div className="header-left-content">
              <Tooltip
                title={`${unityUser.webUserEmail ? "Приєданий веб користувач" : "Веб користувач не приєднаний"} `}
              >
                <CheckCircleIcon className={`webUserEmailFlag ${unityUser.webUserEmail ? "active" : ""} `} />
              </Tooltip>
              {unityUser.demo ? <div className="demo-status">Demo</div> : ""}
              {unityUser.webUserSubscriptionActive && !unityUser.webUserSubscriptionCanceled ? (
                <Tooltip
                  title={`${"Підписка активна до "}${getDateTimeUa(unityUser.webUserSubscriptionEndDate) || ""} (${
                    unityUser.webUserSubscriptionOriginalStatus
                  })`}
                >
                  <div className="active-subscription-status">Підписка</div>
                </Tooltip>
              ) : null}
              {unityUser.webUserSubscriptionActive && unityUser.webUserSubscriptionCanceled ? (
                <Tooltip
                  title={`${"Підписка буде деактивована з "}${
                    getDateTimeUa(unityUser.webUserSubscriptionEndDate) || ""
                  } (${unityUser.webUserSubscriptionOriginalStatus})`}
                >
                  <div className="active-canceled-subscription-status">Підписка відмінена </div>
                </Tooltip>
              ) : null}
              {!unityUser.webUserSubscriptionActive && unityUser.webUserSubscriptionCanceled ? (
                <Tooltip
                  title={`${"Підписка відмінена з "}${getDateTimeUa(unityUser.webUserSubscriptionEndDate) || ""} (${
                    unityUser.webUserSubscriptionOriginalStatus
                  })`}
                >
                  <div className="canceled-subscription-status">Підписка відмінена </div>
                </Tooltip>
              ) : null}
              {unityUser.webUserSubscriptionEndDate &&
              !unityUser.webUserSubscriptionActive &&
              !unityUser.webUserSubscriptionCanceled ? (
                <Tooltip title={`${"Підписка не активна"} (${unityUser.webUserSubscriptionOriginalStatus})`}>
                  <div className="subscription-not-active-status">{`Підписка не активна`}</div>
                </Tooltip>
              ) : null}
              {unityUser.webUserRoles.includes(ROLE_TEACHER) ? (
                <Tooltip title={`${"Викладач"}`}>
                  <div className="teacher-status">Викладач</div>
                </Tooltip>
              ) : null}
              {unityUser.telegramBotClientId ? (
                <Tooltip title={`${"Відкрити чатбот"}`}>
                  <div className="telegram-bot-status" onClick={onOpenSendPulse}>
                    <TelegramIcon />
                  </div>
                </Tooltip>
              ) : null}
              {unityUser.quickTest ? <div className="quickTest-status">Тестування</div> : null}
            </div>
            <div className="user-name-block">
              <div className="user-name">{`${unityUser.name} ${age ? `(Вік: ${age})` : ""}`}</div>
            </div>
            <div className="header-right-content">
              {!noHideByDeviceId && (
                <>
                  {hidden && (
                    <Tooltip title={`${"Повернути до основного списку "}`}>
                      <div
                        className="hideByDeviceIdIconClickArea"
                        onClick={(e) => {
                          e.stopPropagation();
                          onPushCancelHideByDeviceId && onPushCancelHideByDeviceId(unityUser.deviceId);
                        }}
                      >
                        <VisibilityIcon className={`hideByDeviceIdIcon`} />
                      </div>
                    </Tooltip>
                  )}
                  {!hidden && (
                    <Tooltip title={`${"Сховати всі з аналогічним deviceId"}`}>
                      <div
                        className="hideByDeviceIdIconClickArea"
                        onClick={(e) => {
                          e.stopPropagation();
                          onPushHideByDeviceId && onPushHideByDeviceId(unityUser.deviceId);
                        }}
                      >
                        <VisibilityOffIcon className={`hideByDeviceIdIcon`} />
                      </div>
                    </Tooltip>
                  )}
                </>
              )}
              {!noPin && (
                <Tooltip title={`${pinned ? "Відчепити" : "Причепити до верху"}`}>
                  <div
                    className="pushPinIconClickArea"
                    onClick={(e) => {
                      e.stopPropagation();
                      onPushPin && onPushPin(unityUser._id);
                    }}
                  >
                    <PushPinIcon className={`pushPinIcon ${pinned ? "active" : ""}`} />
                  </div>
                </Tooltip>
              )}
            </div>
          </div>

          <div className="body">
            <div className="body-left-content"></div>
            <div className="body-middle-content">
              <div className="info-row">
                <div className="unityId-block">
                  <div className="label">unity ID:</div>
                  <div className="unityId">{`${unityUser.unityId} ${unityUser.webUserEmail ? "" : ""}`}</div>
                </div>
                {unityUser.webUserEmail && (
                  <div className="webUserEmail-block">
                    <div className="webUserEmail">{`(${unityUser.webUserEmail})`}</div>
                    <Tooltip title={`${"Перезайти від імені веб користувача"}`}>
                      <div className="loginIcon" onClick={handleImpersonate}>
                        <LoginIcon />
                      </div>
                    </Tooltip>
                  </div>
                )}
              </div>
              {(unityUser.deviceId || unityUser.deviceModel) && (
                <div className="info-row">
                  <div className="deviceInfo-block">
                    <div className="label">DeviceId:</div>
                    <div className="deviceParam">{unityUser.deviceId}</div>
                  </div>

                  <div className="deviceInfo-block">
                    <div className="label">DeviceModel:</div>
                    <div className="deviceParam">{unityUser.deviceModel}</div>
                  </div>

                  {unityUser.appVersion && (
                    <div className="deviceInfo-block">
                      <div className="label">App:</div>
                      <div className="deviceParam">{unityUser.appVersion}</div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="body-right-content"></div>
          </div>
          <div className="tile-footer">
            <div className="date-block">
              <div className="date-label">{`${unityUser.lastActivityDate ? "Остання активність" : ""}`}</div>
              <div className="date">
                <p>{`${unityUser.lastActivityDate ? getDateTimeUa(unityUser.lastActivityDate) : ""}`}</p>
              </div>
            </div>
            <div className="date-block">
              <div className="date-label">Створений:</div>
              <div className="date">
                <p>{`${getDateTimeUa(unityUser.createdAt)}`}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UnityUserTile;
