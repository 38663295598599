import "./userAchievementCard.scss";
import React, { useEffect, useMemo } from "react";
import app_logo2 from "../assets/app-logo2.png";
import corona_level1 from "../assets/corona-level1.png";
import group_img from "../assets/group-img.png";
import battery from "../assets/battery.png";
import chip_ok from "../assets/chip-ok.png";
import useSearchParamWorker from "helpers/useSearchParam";

function UserAchievementCard() {
  const { getSearchParam } = useSearchParamWorker();

  const [unityUser, setUnityUser] = React.useState(null);
  const [populeatedKnowledgeGroup, setPopulatedKnowledgeGroup] = React.useState(null);
  const [mainImageSrc, setMainImageSrc] = React.useState("");

  const unityUserId = useMemo(() => getSearchParam("unityUserId"), []);
  const knowledgeGroupId = useMemo(() => getSearchParam("knowledgeGroupId"));

  const nameEqualEmail = useMemo(() => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isEmail = unityUser?.name && emailRegex.test(unityUser.name);
    return Boolean(isEmail);
  }, unityUser);

  useEffect(() => {
    unityUserId &&
      fetch(`/api/unityUsers/${unityUserId}`)
        .then((res) => res.json())
        .then((data) => {
          setUnityUser(data);
        })
        .catch((err) => {
          console.error(err);
        });

    knowledgeGroupId &&
      fetch(`/api/knowledgeGroups/${knowledgeGroupId}`)
        .then((res) => res.json())
        .then((data) => {
          setPopulatedKnowledgeGroup(data);
        })
        .catch((err) => {
          console.error(err);
        });
  }, [unityUserId, knowledgeGroupId]);

  useEffect(() => {
    if (populeatedKnowledgeGroup?.knowledgeTags.length) {
      const randomKnTag =
        populeatedKnowledgeGroup.knowledgeTags[
          Math.floor(Math.random() * populeatedKnowledgeGroup.knowledgeTags.length)
        ];
      if (randomKnTag) {
        fetch(`/api/statistic/getKnowledgeRandomImagePath?knowledgeId=${randomKnTag._id}`)
          .then((res) => res.json())
          .then((data) => {
            setMainImageSrc(data.imagePath || group_img);
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }, [populeatedKnowledgeGroup]);

  const getUserAction = (unityUser) => {
    if (!unityUser?.gender || unityUser?.gender === "male") {
      return "вивчив";
    }
    return "вивчила";
  };

  return (
    <div className="userAchievementCard">
      <div className="top">
        <img src={app_logo2} alt="logo" />
      </div>
      <div className="middle">
        <div className="middle-left">
          <div className="top">
            <div className="level-line">
              <div className="image-container">
                <img src={corona_level1} alt="icon" />
              </div>
              <div className="level-label">1-й рівень</div>
            </div>

            <div className="user-block-header">{nameEqualEmail && "Користувач"}</div>
            <div className="user-block">
              <div id="userName" className="user-name">
                {unityUser?.name}
              </div>
              <div id="userAction" className="user-action">
                {getUserAction(unityUser)}
              </div>
            </div>
          </div>
          <div className="middle">
            <div id="groupName" className="group-name">
              {populeatedKnowledgeGroup?.name || "Група не визначена"}
            </div>
          </div>
        </div>

        <div className="middle-right">
          <img id="groupImage" src={mainImageSrc} alt="group image" />
        </div>
      </div>
      <div className="bottom">
        <div className="battery-container">
          <img src={battery} alt="battery" />
        </div>
        <div id="chipList" className="chip-list">
          {populeatedKnowledgeGroup?.knowledgeTags.map((knowledgeTag) => (
            <div key={knowledgeTag._id} className="chip">
              <div className="chip-ok">
                <img src={chip_ok} alt="ok" />
              </div>
              <div className="chip-label">{knowledgeTag.name}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default UserAchievementCard;
