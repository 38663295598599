import "./GameDataTile.scss";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useResourcesContext } from "resources";
import { Tooltip } from "@mui/material";
import { StageCardUniversal } from "components";

const GameDataTile = ({ gameData, knResults, expanded = true }) => {
  const [combinations, setCombinations] = useState([]);
  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const {
    knowledgeTagListStore: { knowledgeTagList: knowledgeTags },
  } = useResourcesContext();

  useEffect(() => {
    if (!gameData) {
      setCombinations([]);
      return;
    }

    const getKnTagsNames = (knTagsToPopulate = [], knTags = []) => {
      return knTagsToPopulate
        .map((id) => knTags.find((knTag) => knTag._id === id))
        .map((knTag) => knTag.name)
        .filter(Boolean);
    };

    setCombinations(
      gameData.fullfilledPreconfigs.map((fullfilledPreconfig) => {
        return {
          ...fullfilledPreconfig,
          rightKnowledgeTagsNames: getKnTagsNames(fullfilledPreconfig.rightKnowledgeTags, knowledgeTags),
          wrongKnowledgeTagsNames: getKnTagsNames(fullfilledPreconfig.wrongKnowledgeTags, knowledgeTags),
          questionKnowledgeTagsNames: getKnTagsNames(fullfilledPreconfig.questionKnowledgeTags, knowledgeTags),
        };
      })
    );
  }, [gameData, knowledgeTags]);

  return (
    <div className="gameDataTile-component">
      {gameData.mixedGame && <div className="gameDataTile-gameType">Гра Вінегрет </div>}
      {gameData.doubleStages && <div className="gameDataTile-gameType">Гра з дублюванням рівнів</div>}
      {!gameData.mixedGame && !gameData.doubleStages && <div className="gameDataTile-gameType">Звичайна гра</div>}
      {expanded && (
        <div className="gameDataTile-stages-view-wraper">
          {gameData.game &&
            gameData.game.stages.map((stage) => (
              <StageCardUniversal className="gameDataTile-stages-view-stageIcon" stage={stage} controls={false} />
            ))}
        </div>
      )}
      <div className="fullfilledPreconfigs">
        {combinations.map((combination, index) => (
          <div className="fullfilledPreconfig-block" key={index}>
            <div className={`fullfilledPreconfig }`} key={index}>
              <Tooltip title={""}>
                <div className={`rightKnowledgeTags }`}>
                  {combination.rightKnowledgeTagsNames.length > 1
                    ? combination.rightKnowledgeTagsNames.join(" ")
                    : combination.rightKnowledgeTagsNames.join("")}
                </div>
              </Tooltip>
              {combination.wrongKnowledgeTagsNames.length ? "_" : ""}
              <Tooltip title={""}>
                <div className={`wrongKnowledgeTags }`}>
                  {combination.wrongKnowledgeTagsNames.length > 1
                    ? combination.wrongKnowledgeTagsNames.join(" ")
                    : combination.wrongKnowledgeTagsNames.join("")}
                </div>
              </Tooltip>
              .
              <Tooltip title={""}>
                <div className={`questionKnowledgeTags }`}>
                  {combination.questionKnowledgeTagsNames.length > 1
                    ? combination.questionKnowledgeTagsNames.join(" ")
                    : combination.questionKnowledgeTagsNames.join("")}
                </div>
              </Tooltip>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GameDataTile;
