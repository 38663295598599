import "./Header.scss";
import React from "react";
import IconHome from "assets/icons/home.svg";
import { NavigateButton } from "components";

const Header = ({ className, children }) => {
  return (
    <div className={`header-wrapper ${className}`}>
      <NavigateButton
        iconHeader={<img src={IconHome} alt="" />}
        title="головне меню"
        className="customize-button home"
        path="/menu"
      />
      {children}
    </div>
  );
};
export default Header;
