import "./StageTemplateCard.scss";
import React, { useMemo, useState } from "react";
import { AlertDialog, StyledButton } from "components";
import { ReactComponent as IconAddToLibrary } from "assets/icons/plus2.svg";

import IconEmptyIcon from "assets/icons/emptyIcon.svg";
import { useResourcesContext } from "resources";

const StageTemplateCardSimple = ({ className = "", stageTemplate, wrong, onClick, onDoubleClick, onStageTemplateDelete }) => {
  const [alertDialogData, setAlertDialogData] = useState(null);

  const {
    imageListStore: { getImagePathById },
  } = useResourcesContext();

  const stageBackground = useMemo(
    () => (stageTemplate.icon ? getImagePathById(stageTemplate.icon) : null),
    [stageTemplate.icon, getImagePathById]
  );

  const openDeleteStageTemplateDialog = () => {
    setAlertDialogData({
      onOk: () => {
        onStageTemplateDelete(stageTemplate);
        setAlertDialogData(null);
      },
      onCancel: () => setAlertDialogData(null),
      title: "Видалення шаблону етапу",
      message: "Натисніть Ok для видалення, або Cancel для відміни операції.",
    });
  };

  return (
    <>
      <div className={`stage-template-card ${className}`} onClick={onClick} onDoubleClick={onDoubleClick}>
        <div id={`stageCardTemplate`}>
          <img
            className={`stage-bg  ${!wrong ? "no-scale" : "srcSvg"}`}
            src={stageBackground || IconEmptyIcon}
            alt="Empty background"
          />
        </div>

        {onStageTemplateDelete && (
          <StyledButton
            tooltipProps={{ title: "Видалити з бібліотеки", placement: "right", arrow: true }}
            className="customize-button deleteStageFromLibrary"
            onClick={(e) => {
              openDeleteStageTemplateDialog();
              e.stopPropagation();
            }}
          >
            <IconAddToLibrary className="deleteStageFromLibrary-button-icon" />
          </StyledButton>
        )}
      </div>
      <AlertDialog alertDialogData={alertDialogData} />
    </>
  );
};
export default StageTemplateCardSimple;
