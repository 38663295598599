export const getFilterByName = (filters, name) => {
  return filters?.find((filter) => filter.name === name)
}

export const getFilterOptionByValue = (filter, value) =>
  filter.options.find((option) => option.value.toUpperCase() === value.toUpperCase())


export const filterEmpty = (filter) => {
  return !filter?.value?.length
}

export const allFiltersEmpty = (filters) => {
  return filters?.every((filter) => filterEmpty(filter))
}

export const filterHasEmtpyOptions = (filter) => {
  return (filter.type !== "text") && (!filter.options[0]?.value);
}

export const someFiltersHaveEmptyOptions = (filters) => {
  return filters?.some((filter) => filterHasEmtpyOptions(filter))
}

export const convertOptionToFilterValue = (option, filter) => {
  return filter.type === "multi" ? [option.value] : option.value
}

export const filtersWithoutOne = (filters, excludedFilterName) => {
  return filters.filter((el) => el.name !== excludedFilterName)
}

export const replaceFilterValue = (filter, value) => {
  return { ...filter, value: value }
}

export const replaceFilterOptions = (filter, options) => {
  return { ...filter, options: options }
}

export const countItemsForFilter = (list, filter, filteringMethods) => {
  return getFilteredList(list, filter, filteringMethods).length
}

export const addOptionCounter = (option, count) => {
  return { ...option, count: count }
}

export const getListFromOtherFilters = (list, filters, currentFilterName, filteringMethods) => {
  const currentFilters = filtersWithoutOne(filters, currentFilterName)
  const filteredList = getFilteredList(list, currentFilters, filteringMethods)

  return getFilteredList(filteredList, currentFilters, filteringMethods)
}

export const addCountersToOptions = (list, filters, filter, filteringMethods) => {
  const listFromOtherFilters = getListFromOtherFilters(list, filters, filter.name, filteringMethods)

  return filter.options?.map((option) => {
    const convertedValue = convertOptionToFilterValue(option, filter)
    const filterWithReplacedValue = [replaceFilterValue(filter, convertedValue)]
    const count = countItemsForFilter(listFromOtherFilters, filterWithReplacedValue, filteringMethods)
    return addOptionCounter(option, count)
  })
}

export const getOptionsWithCounters = (list, filters, filter, filteringMethods) => {
  return replaceFilterOptions(
    filter,
    addCountersToOptions(list, filters, filter, filteringMethods)
  )
}

export const getFilteredList = (list, filters, filteringMethods) => {
  if (allFiltersEmpty(filters) || someFiltersHaveEmptyOptions(filters)) {
    return list;
  }

  Object.entries(filteringMethods).forEach((entry) => {
    const [name, apply] = entry;
    const filter = getFilterByName(filters, name);

    if (!filterEmpty(filter)) {
      list = apply(list, filter)
    }
  })

  return list;
}
