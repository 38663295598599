import React, { useState, useEffect } from "react";
import "./Administration.scss";
import WebUserCard from "./WebUserCard/WebUserCard";
import { Header, AlertDialog, ReloadButton, StyledInput, RemoveButton, DeleteRoundButton } from "components";
import * as API from "../../API/API";

const Administration = () => {
  const [alertDialogData, setAlertDialogData] = useState(null);
  const [webUserList, setWebUserList] = useState(null);
  const [filteredWebUserList, setFilteredWebUserList] = useState(webUserList);
  const [searchWebUsers, setSearchWebUsers] = useState("");

  const reloadWebUserList = () => {
    API.getAll("webUsers").then((res) => setWebUserList(res));
  };

  const removeWebUser = (webUserToRemove) => {
    API.deleteOne("webUsers", webUserToRemove._id).then(() => reloadWebUserList());
  };

  const openWindowRemoveWebUser = (webUser) => {
    setAlertDialogData({
      onOk: () => {
        removeWebUser(webUser);
        setAlertDialogData(null);
      },
      onCancel: () => setAlertDialogData(null),
      title: "Removing user",
      message: `Press Ok to remove user with email: ${webUser.email} or Cancel to cancel operation.`,
    });
  };

  const updateWebUserRole = async (webUser, newWebUserRoles) => {
    API.updateOne("webUsers", webUser._id, { roles: newWebUserRoles }).then(() => reloadWebUserList());
  };

  useEffect(() => {
    reloadWebUserList();
  }, []);

  useEffect(() => {
    setFilteredWebUserList(
      webUserList?.filter(
        (webUser) => webUser.email && webUser.email.toUpperCase().includes(searchWebUsers.toUpperCase())
      )
    );
  }, [searchWebUsers, webUserList]);

  return (
    <div className="administration-wrapper">
      <Header className="main-header" />
      <div className="content-wrapper">
        <div className="left"></div>
        <div className="middle">
          <div className="user-list-area">
            <div className="title-web-user-box">
              <div className="title">Список web користувачів</div>
            </div>
            <div className="web-user-list">
              <div className="search-box-and-button">
                <div className="search-box">
                  <StyledInput
                    className="input"
                    placeholder="Пошук користувача"
                    onChange={(event) => setSearchWebUsers(event.target.value)}
                  />
                </div>
                <ReloadButton className="reload-button" title="Reload users list" onClick={reloadWebUserList} />
              </div>
              {filteredWebUserList && (
                <div className="web-users-list">
                  {filteredWebUserList.map((webUser) => (
                    <div key={webUser._id} className="web-user">
                      <WebUserCard
                        webUser={webUser}
                        webUserRoles={webUser.roles}
                        updateWebUserRole={updateWebUserRole}
                      />

                      <DeleteRoundButton
                        className="remove-user-button"
                        title="Remove User"
                        onClick={(event) => {
                          event.stopPropagation();
                          openWindowRemoveWebUser(webUser);
                        }}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <AlertDialog alertDialogData={alertDialogData} />
    </div>
  );
};
export default Administration;
