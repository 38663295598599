import "./LanguageSwitch.scss";
import * as React from "react";
import { languages } from "constants/constants";
import { InputLabel, NativeSelect } from "@mui/material";

const LanguageSwitch = ({ value, onChange = () => {}, useLabel, disableNoneOption }) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  return (
    <div className="language-switch">
      <InputLabel htmlFor="uncontrolled-native"></InputLabel>
      <NativeSelect
        variant="outlined"
        value={value}
        disableUnderline
        onChange={handleChange}
        inputProps={{
          name: "",
          id: "uncontrolled-native",
        }}
      >
        {languages.map((lang) => (
          <option key={lang.name} value={lang.name}>
            {useLabel ? lang.label : lang.name}
          </option>
        ))}
        {!disableNoneOption && (
          <option key={"empty-value"} value={""}>
            Не обрано
          </option>
        )}
      </NativeSelect>
    </div>
  );
};
export default LanguageSwitch;
