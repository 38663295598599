import "./ActiveItem.scss";
import React, { useEffect, useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import {
  RoundButton,
  SliderWithInput,
  SoundSelector,
  CardStyled,
  ColorPickerButton,
  DropdownSelector,
} from "components";
import { ReactComponent as IconRemove } from "assets/icons/remove.svg";
import { useResourcesContext } from "resources";
import { itemStartActions } from "constants/constants";

const ActiveItem = ({ item, onChange, onDelete, canBeStatic = true, disabled, gameType }) => {
  const [editOutlineColor, setEditOutlineColor] = useState(false);
  const [editFillColor, setEditFillColor] = useState(false);

  const [valueX, setValueX] = useState(0);
  const [valueY, setValueY] = useState(0);

  const {
    soundListStore: { soundList },
    imageListStore: { getImagePathById },
  } = useResourcesContext();

  useEffect(() => {
    if (item) {
      setValueX(item.valueX);
      setValueY(item.valueY);
    }
  }, [item?.valueX, item?.valueY, item]);

  const aplyValueYtoItem = (min = -540, max = 540) => {
    if (valueY < min) {
      setValueY(min);
      item.valueY = min;
      onChange();
      return;
    }
    if (valueY > max) {
      setValueY(max);
      item.valueY = max;
      onChange();
      return;
    }

    item.valueY = valueY;
    onChange();
  };

  const aplyValueXtoItem = (min = -960, max = 960) => {
    if (valueX < min) {
      setValueX(min);
      item.valueX = min;
      onChange();
      return;
    }
    if (valueX > max) {
      setValueX(max);
      item.valueX = max;
      onChange();
      return;
    }

    item.valueX = valueX;
    onChange();
  };

  useEffect(() => {
    if (item) {
      setEditOutlineColor(Boolean(item.outline));
      setEditFillColor(Boolean(item.fill));
    }
  }, [item]);

  return (
    <div className="item-active-component">
      <CardStyled className="item-active-card">
        <div className="iac-remove-btn-sound-selector-wrapper">
          <RoundButton
            icon={IconRemove}
            className="remove-btn"
            onClick={onDelete}
            tooltipTitle="Удалить пропс"
            disabled={disabled}
          />
          <SoundSelector
            sounds={soundList}
            soundType={"props"}
            value={soundList.find((sound) => sound._id === item.sounds?.main)}
            onChangeValue={(sound) => {
              item.sounds = { ...item.sounds, main: sound ? sound._id : "" };
              onChange();
            }}
            onSoundRecorded={(createdSound) => {
              item.sounds = { ...item.sounds, main: createdSound._id };
              onChange();
            }}
          />
        </div>
        <div className="item-active-content">
          <div className="item-img">
            <img src={(item?._id || item?.imageId) && getImagePathById(item._id || item.imageId)} alt="" />
          </div>

          <div className="item-ranges">
            <div className="color-pickers">
              <div className="color-picker">
                Outline:
                <Checkbox
                  disabled={disabled}
                  checked={Boolean(item?.outline)}
                  onChange={(event) => {
                    if (!event.target.checked) {
                      item.outline = undefined;
                      onChange();
                    }
                    if (event.target.checked) {
                      item.outline = "#ff0000ff";
                      onChange();
                    }
                    setEditOutlineColor(event.target.checked);
                  }}
                  className="outline-check"
                />
                <ColorPickerButton
                  className="color-picker-button"
                  disabled={!editOutlineColor || disabled}
                  color={item?.outline}
                  onChange={(color) => {
                    item.outline = color;
                    onChange();
                  }}
                />
                <input
                  disabled={!editOutlineColor || disabled}
                  value={item?.outline ? item.outline : ""}
                  onChange={(event) => {
                    item.outline = event.target.value;
                    onChange();
                  }}
                  className="custom-color"
                />
              </div>
              <div className="color-picker">
                Fill:
                <Checkbox
                  disabled={disabled}
                  checked={Boolean(item?.fill)}
                  onChange={(event) => {
                    if (!event.target.checked) {
                      item.fill = undefined;
                      onChange();
                    }
                    if (event.target.checked) {
                      item.fill = "#ff0000ff";
                      onChange();
                    }
                    setEditFillColor(event.target.checked);
                  }}
                  className="outline-check"
                />
                <ColorPickerButton
                  className="color-picker-button"
                  disabled={!editFillColor || disabled}
                  color={item?.fill}
                  onChange={(color) => {
                    item.fill = color;
                    onChange();
                  }}
                />
                <input
                  disabled={!editFillColor || disabled}
                  value={item?.fill ? item.fill : ""}
                  onChange={(event) => {
                    item.fill = event.target.value;
                    onChange();
                  }}
                  className="custom-color"
                />
              </div>
            </div>
            <div className="reaction-zindex">
              <div className="item-reaction-selector-block">
                <div className="item-reaction-selector-label">Ефект появи:</div>
                <DropdownSelector
                  className="item-reaction-selector"
                  options={itemStartActions}
                  selectedOption={itemStartActions.find((action) => action.name === item.startAction) || ""}
                  onChange={(option) => {
                    item.startAction = option ? option.name : "";
                    onChange();
                  }}
                />
              </div>
              <div className="static-checkbox">
                Static:
                <Checkbox
                  disabled={!canBeStatic || disabled}
                  checked={Boolean(item?.static)}
                  onChange={(event) => {
                    item.static = !item.static;
                    item.zOrder = undefined;
                    if (!item.static) item.static = undefined;
                    onChange();
                  }}
                  className="outline-check"
                />
              </div>
            </div>
            {gameType !== "universal" && item.static && (
              <SliderWithInput
                disabled={disabled}
                onlyInput
                title="z-index"
                value={item?.zOrder ? item.zOrder : "0"}
                onChange={(value) => {
                  item.zOrder = value;
                  onChange();
                }}
              />
            )}
            <div className="two-columns">
              <div className="column1">
                <div className="static-checkbox">
                  Магнетизм:
                  <Checkbox
                    disabled={disabled}
                    checked={Boolean(item?.magnetism)}
                    onChange={(event) => {
                      item.magnetism = !item.magnetism;
                      if (!item.magnetism) item.magnetism = undefined;
                      onChange();
                    }}
                    className="outline-check"
                  />
                </div>
              </div>
              <div className="column2">
                <SliderWithInput
                  disabled={disabled}
                  onlyInput
                  title="Y"
                  min={-540}
                  max={540}
                  value={valueY}
                  onChange={(value) => {
                    setValueY(value);
                    if (typeof value === "number" && Math.abs(Math.round(Number(value) - Number(valueY))) === 1) {
                      item.valueY = value;
                      onChange();
                    }
                  }}
                  onKeyDown={(event) => {
                    event.key === "+" && event.preventDefault();
                    if (event.key === "Enter" && typeof valueY === "number") {
                      aplyValueYtoItem();
                    }
                  }}
                  onBlur={() => {
                    if (typeof valueY === "number") {
                      aplyValueYtoItem();
                    }
                  }}
                />
                <SliderWithInput
                  disabled={disabled}
                  onlyInput
                  title="X"
                  min={-960}
                  max={960}
                  value={valueX}
                  onChange={(value) => {
                    setValueX(value);
                    if (typeof value === "number" && Math.abs(Math.round(Number(value) - Number(valueX))) === 1) {
                      item.valueX = value;
                      onChange();
                    }
                  }}
                  onKeyDown={(event) => {
                    event.key === "+" && event.preventDefault();
                    if (event.key === "Enter" && typeof valueX === "number") {
                      aplyValueXtoItem();
                    }
                  }}
                  onBlur={() => {
                    if (typeof valueX === "number") {
                      aplyValueXtoItem();
                    }
                  }}
                />
              </div>
            </div>
            <div className="scale-and-flippers">
              <Tooltip arrow className="disabled-delete-tooltip" title="Перевернуть по горизонтали">
                <div className="flipper-checkbox">
                  HF:
                  <Checkbox
                    checked={Boolean(item?.flipHorizontal)}
                    onChange={(event) => {
                      item.flipHorizontal = !item.flipHorizontal;
                      item.scaleX = item.scale * (item.flipHorizontal ? -1 : 1);
                      onChange();
                    }}
                    className="outline-check"
                  />
                </div>
              </Tooltip>
              <Tooltip arrow className="disabled-delete-tooltip" title="Перевернуть по вертикали">
                <div className="flipper-checkbox">
                  VF:
                  <Checkbox
                    checked={Boolean(item?.flipVertical)}
                    onChange={(event) => {
                      item.flipVertical = !item.flipVertical;
                      item.scaleY = item.scale * (item.flipVertical ? -1 : 1);
                      onChange();
                    }}
                    className="outline-check"
                  />
                </div>
              </Tooltip>
              <SliderWithInput
                disabled={disabled}
                title="Scale"
                value={item?.scale >= 0 ? item.scale : 100}
                min={0}
                max={200}
                onChange={(value) => {
                  item.scale = value;
                  item.scaleX = item.scale * (item.flipHorizontal ? -1 : 1);
                  item.scaleY = item.scale * (item.flipVertical ? -1 : 1);
                  onChange();
                }}
                onKeyDown={(event) => {
                  (event.key === "-" || event.key === "+") && event.preventDefault();
                }}
              />
            </div>

            <SliderWithInput
              disabled={disabled}
              title="Rotate"
              value={item?.angle}
              min={0}
              max={360}
              onChange={(value) => {
                item.angle = value;
                onChange();
              }}
              onKeyDown={(event) => {
                (event.key === "+" || event.key === "-") && event.preventDefault();
              }}
            />
            <SliderWithInput
              disabled={disabled}
              title="Blur"
              value={item?.blur >= 0 ? item.blur : 0}
              min={0}
              max={100}
              onChange={(value) => {
                item.blur = value;
                onChange();
              }}
              onKeyDown={(event) => {
                (event.key === "-" || event.key === "+") && event.preventDefault();
              }}
            />
            <SliderWithInput
              disabled={disabled}
              title="Opacity"
              value={item?.opacity >= 0 ? item.opacity : 100}
              min={0}
              max={100}
              onChange={(value) => {
                item.opacity = value;
                onChange();
              }}
              onKeyDown={(event) => {
                (event.key === "-" || event.key === "+") && event.preventDefault();
              }}
            />
          </div>
        </div>
      </CardStyled>
    </div>
  );
};
export default ActiveItem;
