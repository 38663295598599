import { useParams } from "react-router-dom";

const withIdParam = (WrappedComponent) => {
  return (props) => {
    const { id } = useParams();
    return <WrappedComponent {...props} id={id} />;
  };
};

export default withIdParam;
