import "./AssetBundleCommonConfig.scss";
import React from "react";
import { ErrorBoard, SoundSelector, GameIcon } from "components";
import { ReactComponent as RemoveBtnIcon } from "assets/icons/remove.svg";
import { ReactComponent as CopyBtnIcon } from "assets/icons/copy.svg";
import { useResourcesContext } from "resources";
import { getSoundBySoundSetId } from "helpers/soundLanguage";

const AssetBundleCommonConfig = ({
  assetBundle,
  usedOtherSounds,
  availableOtherSounds,
  assetBundleExtraData,
  onDelete,
  onCopy,
  onChange,
}) => {
  const {
    imageListStore: { getImagePathById },
  } = useResourcesContext();

  const handleCorrectSoundsChange = async (values) => {
    const newSounds = { ...assetBundle.sounds, correctSoundSets: values.map((value) => value.soundSetId) };
    onChange({ ...assetBundle, sounds: newSounds });
  };

  const handleIncorrectSoundsChange = async (values) => {
    const newSounds = { ...assetBundle.sounds, incorrectSoundSets: values.map((value) => value.soundSetId) };
    onChange({ ...assetBundle, sounds: newSounds });
  };

  const handleEndSoundChange = (value) => {
    const newSounds = { ...assetBundle.sounds, endSoundSet: value?.soundSetId || "" };
    onChange({ ...assetBundle, sounds: newSounds });
  };

  return (
    <div className="assetBundleCommonConfig-component">
      <div className="assetBundleCommonConfig-content">
        <div className="main-assetBundle-config">
          <div className="mgc-one-label gc-one-width">Основні:</div>

          <div className="mgc-two">
            <div className="assetBundle-icon-block">
              <div className="mgc-assetBundle-icon">
                <GameIcon
                  gameIcon={
                    typeof assetBundle?.icon === "string" ? getImagePathById(assetBundle.icon) : assetBundle.icon
                  }
                />
              </div>
              <div className="type-and-name">
                <input
                  className="mgc-assetBundle-name"
                  placeholder="Назва ігри"
                  value={assetBundle.name}
                  onChange={(event) => onChange({ ...assetBundle, name: event.target.value })}
                />

                <div className="mgc-assetBundle-created-updated">
                  <div className="mgc-assetBundle-createdBy">{`Автор:  ${
                    assetBundle?.createdBy || "немає даних"
                  } `}</div>
                  <div className="mgc-assetBundle-updatedBy">{`Збережено:  ${
                    assetBundle?.updatedBy || "немає даних"
                  }`}</div>
                </div>
              </div>
            </div>

            <textarea
              className="mgc-assetBundle-description"
              placeholder="Опис ігри"
              value={assetBundle.description}
              onChange={(event) => onChange({ ...assetBundle, description: event.target.value })}
            ></textarea>
          </div>
          <div className="mgc-three">
            <div className="top">
              <button className=" gc-assetBundle-btn-style" onClick={() => onCopy()}>
                <div className=" gc-btn-icon-style">
                  <CopyBtnIcon />
                </div>
                {"Копіювати ігру"}
              </button>
              <button className=" gc-assetBundle-btn-style" onClick={() => onDelete()}>
                <div className=" gc-btn-icon-style background-color-red">
                  <RemoveBtnIcon />
                </div>
                {"Видалити ігру"}
              </button>
            </div>
            <div className="middle"></div>
            <div className="bottom"></div>
          </div>
        </div>
        <div className="sounds-assetBundle-config">
          <div className="sgc-one-label gc-one-width">Звуки:</div>
          <div className="sgc-two">
            <div className="sgc-sound-selector-wrapper">
              <div className="sgc-sound-selector-label">Вірно:</div>
              <div className="sgc-assetBundle-sound-selector">
                <div className="sgc-multiple">
                  <SoundSelector
                    multiple={true}
                    sounds={availableOtherSounds}
                    value={assetBundle.sounds.correctSoundSets.map((soundSetId) =>
                      getSoundBySoundSetId(soundSetId, usedOtherSounds)
                    )}
                    onChangeValue={handleCorrectSoundsChange}
                  />
                </div>
              </div>
            </div>
            <div className="sgc-sound-selector-wrapper">
              <div className="sgc-sound-selector-label">Не вірно:</div>
              <div className="sgc-assetBundle-sound-selector">
                <div className="sgc-multiple">
                  <SoundSelector
                    multiple={true}
                    sounds={availableOtherSounds}
                    value={assetBundle.sounds.incorrectSoundSets.map((soundSetId) =>
                      getSoundBySoundSetId(soundSetId, usedOtherSounds)
                    )}
                    onChangeValue={handleIncorrectSoundsChange}
                  />
                </div>
              </div>
            </div>
            <div className="sgc-sound-selector-wrapper">
              <div className="sgc-sound-selector-label">Закінчення гри:</div>
              <div className="sgc-assetBundle-sound-selector">
                <div className="sgc-single">
                  <SoundSelector
                    sounds={availableOtherSounds}
                    value={getSoundBySoundSetId(assetBundle.sounds?.endSoundSet, usedOtherSounds)}
                    onChangeValue={handleEndSoundChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="errors-assetBundle-config">
          <div className="egc-one-label gc-one-width">Помилки:</div>

          <div className="egc-two">
            {assetBundleExtraData && !assetBundleExtraData.validation?.valid && (
              <ErrorBoard errors={assetBundleExtraData.validation.errors} />
            )}
          </div>
          <div className="egc-three"></div>
        </div>
      </div>
    </div>
  );
};

export default AssetBundleCommonConfig;
