import { sendAPIRequest, createAPIOptionsEndData, createData, createHeaders } from "./APIService";

export const createOne = async (endPoint, dataToCreate = {}) => {
  const createdData = createData(endPoint, dataToCreate);
  const createdHeaders = createHeaders(endPoint);

  const apiOptions = await createAPIOptionsEndData("POST", createdData, createdHeaders);
  const createdUnit = await sendAPIRequest(endPoint, apiOptions);

  return createdUnit;
};

export const getAll = async (endPoint) => {
  const apiOptions = await createAPIOptionsEndData("GET");
  const allUnits = await sendAPIRequest(endPoint, apiOptions);

  return allUnits;
};

export const getOne = async (endPoint, unitId) => {
  const apiOptions = createAPIOptionsEndData("GET");
  const oneUnit = await sendAPIRequest(endPoint, apiOptions, unitId);

  return oneUnit;
};

export const deleteOne = async (endPoint, unitId) => {
  const apiOptions = await createAPIOptionsEndData("DELETE");
  const deletedUnit = await sendAPIRequest(endPoint, apiOptions, unitId);

  return deletedUnit;
};

export const deleteAll = async (endPoint) => {
  const apiOptions = await createAPIOptionsEndData("DELETE");
  const deletedUnits = await sendAPIRequest(endPoint, apiOptions);

  return deletedUnits;
};

export const updateOne = async (endPoint, unitId, dataToUpdate = {}) => {
  const createdData = createData(endPoint, dataToUpdate);
  const createdHeaders = createHeaders(endPoint);
  
  const apiOptions = await createAPIOptionsEndData("PUT", createdData, createdHeaders);
  const updatedUnit = await sendAPIRequest(endPoint, apiOptions, unitId);

  return updatedUnit;
};
