import "./DeleteRoundButton.scss";
import React from "react";
import { ReactComponent as IconUpdate } from "../../assets/icons/remove.svg";

const DeleteRoundButton = ({ className, disabled, title, onClick }) => {
  return (
    <button disabled={disabled} className={`deleteRoundButton-component ${className}`} title={title} onClick={onClick ? onClick : null}>
      <IconUpdate />
    </button>
  );
};
export default DeleteRoundButton;
