import { setStateAsync } from "helpers/utils";
import React, { useEffect, useState } from "react";
import { ReactComponent as DeleteIcon } from "assets/icons/RemoveTag.svg";

const ImageListElement = ({ image, selectedImageId, onImageSelect, withoutSelection, onDelete }) => {
  const [selected, setSelected] = useState(false);
  const [path, setPath] = useState(null);

  useEffect(() => {
    !withoutSelection && setSelected(selectedImageId === image?._id);
  }, [selectedImageId, image, withoutSelection]);

  useEffect(() => {
    if (image) {
      setPath("");
      setStateAsync(setPath, image.path);
    }
  }, [image]);

  return (
    <div className="image" onClick={() => onImageSelect({ ...image })}>
      <img src={path} alt="Empty" className={`image-main ${selected ? "img-selected" : ""}`} />
      {selected && onDelete && (
        <div className="delete-button-wrapper" onClick={() => onDelete && onDelete(image?._id)}>
          <DeleteIcon className="delete-button" />
        </div>
      )}
    </div>
  );
};
export default ImageListElement;
