import React, { useEffect, useState } from "react";
import { useResourcesContext } from "resources";

const Item = ({ imageId, selected, onClick }) => {
  const [imagePath, setImagePath] = useState("");

  const {
    imageListStore: { getImagePathById },
  } = useResourcesContext();

  useEffect(() => {
    setImagePath(getImagePathById(imageId));
  }, [imageId, getImagePathById]);

  return (
    <div
      className={`knowledge-item ${selected ? "selected" : ""}`}
      onClick={(event) => {
        event.stopPropagation();
        onClick();
      }}
    >
      <img src={imagePath} alt="" />
    </div>
  );
};

export default Item;
