import "./CustomerHeader.scss";
import React, { useState } from "react";
import appLogo from "assets/app-logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthz } from "auth/AuthzProvider";
import { useResourcesContext } from "resources";
import { StyledButton, ModalDialog, UserDetailsForm } from "components";
import { ReactComponent as IconLogout } from "assets/icons/logout.svg";
import { ReactComponent as IconSettigns } from "assets/icons/settings.svg";
import { ReactComponent as IconPlusHexagon } from "assets/icons/plusHexagon.svg";
import { Avatar, Menu, Button, MenuItem, IconButton, Divider, Typography, Box } from "@mui/material";

const CustomerHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, logout } = useAuthz();
  const {
    userListStore: { reloadList },
  } = useResourcesContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleLogout = () => {
    logout();
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const redirectToProfile = () => {
    handleClose();
    navigate("/profile");
  };

  const handleSubmit = () => {
    handleDialogClose();
    reloadList();
  };

  if (!user) {
    return null;
  }

  let userInitials = "";

  if (user && user.unityUserName) {
    userInitials = user.unityUserName
      .match(/(^\S\S?|\s\S)?/g)
      .map((v) => v.trim())
      .join("")
      .match(/(^\S|\S$)?/g)
      .join("")
      .toLocaleUpperCase();
  }

  return (
    <header className="customer-header">
      <Link to={"/"}>
        <img src={appLogo} alt="application logo" />
      </Link>
      <ul className="landing-header_links">
        <Link to={"/dashboard"} className={location.pathname === "/dashboard" ? "active-link" : ""}>
          Діти
        </Link>
        <Link to={"/dashboard/billing"} className={location.pathname === "/dashboard/billing" ? "active-link" : ""}>
          Біллінг
        </Link>
      </ul>
      <div className="landing-header_features">
        <StyledButton className="add-customer-btn-content" onClick={handleDialogOpen} iconHeader={<IconPlusHexagon />}>
          Зареєструвати дитину
        </StyledButton>
        <div>
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <IconButton
              size="small"
              onClick={handleClick}
              sx={{ maxWidth: "32px", maxHeight: "32px", borderRadius: "50%" }}
            >
              <Avatar
                sx={{
                  maxWidth: "32px",
                  maxHeight: "32px",
                  fontSize: "14px",
                  background: "#fff",
                  color: "#FAA400",
                }}
              >
                {userInitials}
              </Avatar>
            </IconButton>
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              sx: {
                p: 1,
                gap: 1,
                zIndex: "99999",
                borderRadius: "10px",
                boxShadow:
                  "30px 122px 35px 0px rgba(131, 156, 180, 0.00), 20px 78px 32px 0px rgba(131, 156, 180, 0.01), 11px 44px 27px 0px rgba(131, 156, 180, 0.05), 5px 20px 20px 0px rgba(131, 156, 180, 0.09), 1px 5px 11px 0px rgba(131, 156, 180, 0.10)",
              },
            }}
          >
            <MenuItem onClick={handleClose} sx={{ "&:hover": { backgroundColor: "transparent" } }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ ml: 1.5 }}>
                  <Typography variant="subtitle1" color="textPrimary" sx={{ textTransform: "capitalize" }}>
                    {user.unityUserName}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {user.email}
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
            <Divider sx={{ margin: 0 }} />
            <MenuItem
              onClick={redirectToProfile}
              sx={{ display: "flex", justifyContent: "center", "&:hover": { backgroundColor: "transparent" } }}
            >
              <IconSettigns />
              <Typography variant="body1" sx={{ ml: 1 }}>
                Налаштування
              </Typography>
            </MenuItem>
            <Divider sx={{ margin: 0 }} />
            <MenuItem
              onClick={handleLogout}
              sx={{ display: "flex", justifyContent: "center", "&:hover": { backgroundColor: "transparent" } }}
            >
              <IconLogout />
              <Typography variant="body1" sx={{ ml: 1, color: "#F94C3D" }}>
                Вийти
              </Typography>
            </MenuItem>
          </Menu>
        </div>
      </div>
      <ModalDialog open={openDialog} onClose={handleDialogClose} title="Зареєструвати дитину">
        <UserDetailsForm handleSubmit={handleSubmit}>
          <Button onClick={handleDialogClose} color="primary" sx={{ color: "#798389", textTransform: "initial" }}>
            Скасувати
          </Button>
        </UserDetailsForm>
      </ModalDialog>
    </header>
  );
};

export default CustomerHeader;
