import stageTemplatesApi from "API/stageTemplatesApi";
import { useAuthz } from "auth/AuthzProvider";
import _ from "lodash";
import { useEffect, useState } from "react";

const useStageTemplatesList = () => {
  const { user } = useAuthz();
  const [list, setList] = useState([]);

  const getStageTemplatesList = () => {
    stageTemplatesApi
      .getAll()
      .then((res) => setList(res))
      .catch(() => setList([]));
  };

  const findEqvalTemplate = (template) => {
    return list.some((element) => {
      return _.isEqual(template, element.template);
    });
  };

  const createStageTemplate = (stageTemplate) => {
    if (findEqvalTemplate(stageTemplate.template)) {
      return new Promise((resolve) => resolve(null));
    }

    return stageTemplatesApi
      .create(stageTemplate)
      .then((stageTemplate) => {
        getStageTemplatesList();
        return stageTemplate;
      })
      .catch(() => null);
  };

  const deleteStageTemplate = (id) => {
    return stageTemplatesApi
      .deleteOne(id)
      .then((stageTemplate) => {
        getStageTemplatesList();
        return stageTemplate;
      })
      .catch(() => null);
  };

  const getStageTemplatesWithImage = (imageId) => {
    const itemUseImageRecursive = (item, imageId) => {
      return item._id === imageId || item.items?.some((item) => itemUseImageRecursive(item, imageId));
    };

    return list.filter((stageTemplate) => {
      return stageTemplate.icon === imageId || (stageTemplate.template&&itemUseImageRecursive(stageTemplate.template, imageId));
    });
  };

  return {
    stageTemplatesList: list,
    createStageTemplate,
    deleteStageTemplate,
    getStageTemplatesWithImage,
    getStageTemplatesList
  };
};

export default useStageTemplatesList;
